import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Box from "@material-ui/core/Box";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

export default function SimpleModal({
  isOpenReviewModal,
  reviewModal,
  addReview,
  editReview,
  ratingStar,
  comment,
  updateComment,
  updateRatingStar,
  setClose,
  disableBtn,
  hasLoggedUserReviewed
}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="atbd_content_module atbd_review_form">
        <div className="atbd_content_module__tittle_area">
          <div className="atbd_area_title" style={{textAlign:'end',alignItems:'end'}}>
            <div onClick={setClose} style={{fontSize:20,fontWeight:600,cursor:'pointer'}}>x</div>
            <h4 style={{marginLeft:'auto'}}>
              <span className="la la-star"></span>{!hasLoggedUserReviewed? "Add a Review":"Edit a Review"}
            </h4>
          </div>
        </div>
        <div className="atbdb_content_module_contents atbd_give_review_area">
          <form action="/" id="atbdp_review_form" method="post">
            <div className="atbd_review_rating_area">
              <div className="atbd_review_update_rating">
                <span>Rating: </span>
                <Box
                  component="fieldset"
                  borderColor="transparent"
                  stye={{ marginTop: "5px" }}
                >
                  <Rating
                    name="customized-empty"
                    value={ratingStar}
                    precision={1}
                    onClick={updateRatingStar}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    required
                  />
                  
                </Box>
              </div>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Message"
                onChange={updateComment}
                value={comment}
                required
              ></textarea>
            </div>
            <button
              className="btn btn-color-blue"
              type="submit"
              id="atbdp_review_form_submit"
              onClick={!hasLoggedUserReviewed ? addReview:editReview}
              disabled={ratingStar==='' && comment==='' || disableBtn}
            >
              Submit Review
            </button>
          </form>
        </div>
      </div>
      <SimpleModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={isOpenReviewModal}
        onClose={() => reviewModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
