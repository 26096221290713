import React from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ga4Events } from '../../../utils';
import {
    AccountBalanceWalletOutlinedIcon, AddBoxOutlinedIcon, DashboardOutlinedIcon,
    ListAltOutlinedIcon, ListOutlinedIcon, LogoutOutlinedIcon, PersonOutlineOutlinedIcon,
    StoreOutlinedIcon
} from '../../common/MaterialIcons';

export const SellerMenu = ({ t }) => {
    const history = useHistory();
    const closeMenu = (route, label) => {
        ga4Events({
            action: "profile_menu_click",
            category: "User",
            menu_item_name: label,
        })
        history.push(route)
    }

    const logOut = (e) => {
        e.preventDefault();
        // this.props.logOutdata(null);
        // this.setState({ showModal: false });
        ga4Events({ action: "profile_menu_logout", category: "User" });
        localStorage.removeItem("user");
        localStorage.clear();

        window.location.href = "/";
    };
    return (
        <ul className="list-unstyled">
            <li className="close-menu-modal">
                <NavLink
                    to="/my-dashboard"
                    onClick={() =>
                        closeMenu("/my-dashboard", "Dashboard")
                    }
                >
                    <DashboardOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Dashboard
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/my-profile"
                    onClick={() =>
                        closeMenu("/my-profile", "Profile")
                    }
                >
                    <PersonOutlineOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Profile
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/my-store"
                    onClick={() =>
                        closeMenu("/my-store", "My Store")
                    }
                >
                    <StoreOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Store
                </NavLink>
            </li>

            <li className="close-menu-modal">
                <NavLink
                    to="/my-listings"
                    onClick={() =>
                        closeMenu("/my-listings", "Listings")
                    }
                >
                    <ListOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Listings
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/sell-your-parts"
                    onClick={() =>
                        closeMenu("/sell-your-parts", "Add New")
                    }
                >
                    <AddBoxOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Add New
                </NavLink>
            </li>

            <li className="close-menu-modal">
                <NavLink
                    to="/my-order"
                    onClick={() =>
                        closeMenu("/my-order", "Orders")
                    }
                >
                    <ListAltOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Orders
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/payments"
                    onClick={() =>
                        closeMenu("/payments", "Payments")
                    }
                >
                    <AccountBalanceWalletOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Payments
                </NavLink>
            </li>
            <li className="log-out">
                <div onClick={logOut}>
                    <NavLink to="#">
                        <LogoutOutlinedIcon
                            style={{
                                height: "18px",
                                width: "18px",
                                marginTop: "-1px",
                                cursor: "pointer",
                                color: "#333333",
                                top: 0,
                                marginRight: "5px",
                            }}
                        />
                        {t("menu_signout")}
                    </NavLink>
                </div>
            </li>
        </ul>
    )
}
