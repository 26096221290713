import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import axios from 'axios'

import Listing from '../container/all-listing'
import {
  Initialize,
  FilterListing,
  SetSearchQuery,
  setFilters,
  setPathname,
  SetListings,
  SetTradesoftSKUs,
  VinSearchDetails,
  SetLoading,
} from '../../Store/action/listingActions'
import VisitorModal from '../content/element/modal/visitor-modal'
import { ga4Events, getCurrentTime, popUp } from '../../utils'

class AllListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locationPath: '',
      count: 1,
      categories: [],
      subCategories: { data: [] },
      showVisitorModal: true,
    }
    this.handleFilter = this.handleFilter.bind(this)
    this.initialize = this.initialize.bind(this)
    this.closeVisitorModal = this.closeVisitorModal.bind(this)
    this.calculateDuration = this.calculateDuration.bind(this)
  }

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    let search = ''

    let querykey

    if (params.search) {
      search = params.search
      querykey = 'search'
    }
    if (params.partnumber) {
      search = params.partnumber
      querykey = 'partnumber'
    }
    if (params.partname) {
      search = params.partname
      querykey = 'partname'
    }
    if (search) {
      this.props.setPathname(
        `${this.props.location.pathname}/?${querykey}=${search}`
      )
    } else {
      this.props.setPathname(this.props.location.pathname)
    }

    //CLEARING THE STATE DATA
    if (!params.type) {
      this.props.history.replace({
        ...this.props.location,
        state: {},
      })
    }

    if (!this.props.category.isAllCategoriesLoading) {
      this.setState({ locationPath: this.props.location.pathname }, () => {
        this.initialize()
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search ||
      this.props.category.isAllCategoriesLoading !==
        prevProps.category.isAllCategoriesLoading
    ) {
      this.setState({ locationPath: this.props.location.pathname })
      const params = queryString.parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      })

      this.setState({ locationPath: this.props.location.pathname }, () => {
        this.initialize()
      })
    }
  }

  calculateDuration = () => {
    const { extraParams } = this.props.location.state
    if (extraParams?.extraData?.startTime && extraParams?.extraData?.endTime) {
      const duration =
        extraParams?.extraData?.endTime - extraParams?.extraData?.startTime
      return duration / 1000
    }
    return 0
  }

  getListingType = (slug = '') => {
    let listingType = ''

    switch (slug) {
      case 'genuine-oem':
        listingType = 'Genuine'
        break
      case 'after-market':
        listingType = 'Aftermarket'
        break
      default:
        listingType = ''
    }

    return listingType
  }

  initialize() {
    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const extraParams = this.props.location.state
    const items = JSON.parse(localStorage.getItem('searchQuery'))

    if (extraParams?.isSearchByCatSubCat) {
      let emirate =
        this.props.login?.role === 'BUYER'
          ? this.props.login?.details?.emirate
          : ''
      let user_id =
        this.props.login?.role === 'BUYER' ? this.props.login?._id : ''
      if (extraParams?.skus?.length) {
        axios
          .post(
            `/api/info/tradesoft-skus?emirate=${emirate}&user_id=${user_id}`,
            { skus: [...new Set(extraParams?.skus)] }
          )
          .then((res) => {
            let bp24 = res.data
            let _bp24 = bp24.map((b) => {
              return {
                ...b,
                date: new Date(),
                pic: b.imageArray[0],
                istecAll: false,
              }
            })
            this.props.setListings({ listings: [..._bp24] })
            this.props.SetTradesoftSKUs([...new Set(extraParams?.skus)])
            ga4Events({
              action: 'parts_searched_from_VIN_Duration',
              category: 'Listing',
              currentTime: getCurrentTime(),
              platform: 'web',
              duration: (extraParams?.endTime - extraParams?.startTime) / 1000,
              user_id: user_id,
            })
            _bp24?.length > 0 &&
              ga4Events({
                action: 'parts_searched_from_VIN_Success',
                category: 'Listing',
                user_id: user_id,
                currentTime: getCurrentTime(),
                platform: 'web',
                skus: [...new Set(extraParams?.skus)],
              })

            _bp24?.length < 0 &&
              ga4Events({
                action: 'parts_searched_from_VIN_Failure',
                category: 'Listing',
                user_id: user_id,
                currentTime: getCurrentTime(),
                platform: 'web',
                message: 'No results found',
              })
          })
          .catch((err) => {
            popUp('There was a problem with server. Please try again.')
          })
      }
    } else {
      let filterQuery = { search: '' }
      extraParams?.extraData?.make &&
        (filterQuery.make = extraParams?.extraData?.make)
      extraParams?.extraData?.model &&
        (filterQuery.model = extraParams?.extraData?.model)
      extraParams?.extraData?.vinSearch &&
        (filterQuery.isVINSearch = extraParams?.extraData?.vinSearch)
      extraParams?.extraData?.skus &&
        (filterQuery.skus = extraParams?.extraData?.skus)

      extraParams?.extraData?.type &&
        (filterQuery.type = extraParams?.extraData?.type)

      extraParams?.extraData?.voltage &&
        (filterQuery.voltage = extraParams?.extraData?.voltage)
      extraParams?.extraData?.amp &&
        (filterQuery.amp = extraParams?.extraData?.amp)
      extraParams?.extraData?.batteryYear &&
        (filterQuery.batteryYear = extraParams?.extraData?.battery_year)
      extraParams?.extraData?.brands &&
        (filterQuery.brands = extraParams?.extraData?.brands)

      extraParams?.extraData?.width &&
        (filterQuery.width = extraParams?.extraData?.width)
      extraParams?.extraData?.height &&
        (filterQuery.height = extraParams?.extraData?.height)
      extraParams?.extraData?.rim_size &&
        (filterQuery.rimSize = extraParams?.extraData?.rim_size)
      extraParams?.extraData?.tire_year &&
        (filterQuery.tireYear = extraParams?.extraData?.tire_year)

      extraParams?.extraData?.file_id &&
        (filterQuery.file_id = extraParams?.extraData?.file_id)

      if (items?.searchQuery?.businessModel?.length) {
        filterQuery.businessModel = items.searchQuery.businessModel
          ?.map((data) => data?._id)
          ?.toString()
      }

      if (
        this.props.match.path === '/brand-list/:brand/:type' &&
        this.props.match.params.brand &&
        this.props.match.params.type
      ) {
        filterQuery.brands = this.props.match.params.brand.replace('-', ' ')
        filterQuery.brands = this.props.match.params.brand.replace('_', '/')
        filterQuery.listingType = this.getListingType(
          this.props.match.params.type
        )
        filterQuery.type =  this.props.match.params.type == 'battery' ? 'batteries'  :  this.props.match.params.type === 'lubricants' ? 'lubricants' : '';
      }

      if (params.search) filterQuery.search = params.search
      if (params.partnumber) filterQuery.partnumber = params.partnumber
      if (params.partname) filterQuery.partname = params.partname

      if (params.brand) filterQuery.brands = params.brand.replace('-', ' ')

      if (
        this.props.match.path.includes('/spare-parts/:category') &&
        this.props.match.params.category
      ) {
        // const cat = this.props.category.allCategories?.filter(
        //   (e) =>
        //     e.name.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-') ===
        //     this.props.match.params.category
        // )
         const cat = this.props.category.allCategories?.filter(
          (e) =>
            e.name.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-') ===
            this.props.match.params.category.toLowerCase().replaceAll(' ', '-').replaceAll('/', '-')
        )
        filterQuery.category = cat[0]

        if (this.props.match.params.subcategory) {
          const subCatList = this.props.category.allSubCategories
          const subCat = subCatList?.filter(
            (e) =>
              e?.name
                ?.toLowerCase()
                .replaceAll(' ', '-')
                ?.replaceAll('%20', '-')
                ?.replaceAll('/', '-') === this.props.match.params.subcategory
          )
          const catIndex = subCat?.findIndex(
            (obj) => obj?.category_id === cat[0]?._id
          )
          filterQuery.subcategory = subCat?.[catIndex]
        }
      }
      if (
        this.props.match.path === '/car-parts/:make' &&
        this.props.match.params.make
      )
        filterQuery.make = this.props.match.params.make

      this.props.initializeListing(filterQuery, this.props?.list?.count)

      if (extraParams?.extraData?.vinSearch) {
        ga4Events({
          action: 'parts_searched_from_VIN_Duration',
          category: 'Listing',
          query: extraParams?.search,
          currentTime: getCurrentTime(),
          platform: 'web',
          duration:
            (extraParams?.extraData?.endTime -
              extraParams?.extraData?.startTime) /
            1000,
          user_id: this.props.login?._id,
        })
      }
    }
  }

  closeVisitorModal = (e) => {
    e.preventDefault()
    this.setState({
      showVisitorModal: !this.state.showVisitorModal,
    })
  }

  handleFilter(query, action = '') {
    const extraParams = this.props.location.state
    extraParams?.skus && (query['skus'] = extraParams?.skus)
    extraParams?.extraData?.skus &&
      (query['skus'] = extraParams?.extraData?.skus)
    if (
      this.props.match.path === '/brand-list/:brand' &&
      this.props.match.params.brand
    )
      query.brandSearch = this.props.match.params.brand
    this.props.filterListing(query, action)
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  render() {
    const { isLoading, isAfterloading } = this.props.list

    const params = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    const extraParams = this.props.location.state
    let { category: cat1, subcategory: sub1 } = this.props.match.params
    let cat = cat1
      ?.split('-')
      .map((res) => this.capitalizeFirstLetter(res))
      .join(' ')
    let sub = sub1
      ?.split('-')
      .map((res) => this.capitalizeFirstLetter(res))
      .join(' ')
    const currentUser = JSON.parse(localStorage.getItem('login'))

    return (
      <Fragment>
        {!currentUser && this.props.list.count >= 3 ? (
          <>
            <VisitorModal
              showModal={this.state.showVisitorModal}
              onClose={this.closeVisitorModal}
              msg={
                "You have exceeded your visitor's limit ! Please Login Or Register for further process."
              }
            />
          </>
        ) : null}
        <Listing
          handleFilter={this.handleFilter}
          api={params.api}
          type={params.tire ? 'tire' : params.batteries ? 'batteries' : null}
          category={sub ? sub : cat}
          loading={isLoading}
          extraParams={extraParams}
          isAfterloading={isAfterloading}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    category: state.category,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    initializeListing: (data, data1) => dispatch(Initialize(data, data1)),
    setFilter: (filterQuery) => dispatch(setFilters(filterQuery)),
    filterListing: (query, action) => dispatch(FilterListing(query, action)),
    setSearchQuery: (query) => dispatch(SetSearchQuery(query)),
    setPathname: (pathname) => dispatch(setPathname(pathname)),
    setListings: (data) => dispatch(SetListings(data)),
    SetTradesoftSKUs: (data) => dispatch(SetTradesoftSKUs(data)),
    vinSearchDetails: (data) => dispatch(VinSearchDetails(data)),
    SetLoading: (data) => dispatch(SetLoading(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProp)(AllListing)
