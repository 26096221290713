import React, { Fragment, Component } from 'react';
import axios from "axios";
import moment from "moment-timezone";
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import { popUp } from '../../../../utils';

class SellerList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sellerList: [],
			loading: true,
		};
	}
	componentDidMount() {
		this.setState({ loading: true });
		axios
			.get("/api/users?role=SELLER&all=true&status=Approved")
			.then((res) => {
				this.setState({ sellerList: res.data?.users, loading: false });
			})
			.catch((err) => {
				popUp("There was a problem with server. Please try again.");
			});
	}

	handleOnItemSelected(index) {
		if (this.props.onItemSelected) {
			const seller = this.state.sellerList[index];
			this.props.onItemSelected(seller);
		}
	}

	render() {
		return (
			<div style={{ maxHeight: "90vh", overflow: "auto" }}>
				{this.state.loading ? "LOADING" : <div>
					{this.state.sellerList && <table className="table table-fixed-header">
						<thead>
							<tr>
								<th>#</th>
								<th></th>
								<th>Seller</th>
								<th>Listings</th>
							</tr>
						</thead>
						<tbody style={{ maxHeight: "70vh" }}>
							{this.state.sellerList.map((seller, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>
											<NavLink to={`/stores/seller-details/${seller._id}`} onClick={(e) => { this.handleOnItemSelected(index); e.preventDefault(); return false; }}>
												<img
													src={
														seller.avatar || "/assets/img/author-profile.jpg"
													}
													alt="AuthorImage"
													width={120}
												/>
											</NavLink>
										</td>
										<td>
											<NavLink to={`/stores/seller-details/${seller._id}`} onClick={(e) => { this.handleOnItemSelected(index); e.preventDefault(); return false; }}>
												<h2>{seller.details.company_name}</h2>
											</NavLink>
											<p>
												Registered on {moment(seller.date).tz('Asia/Dubai').format("DD/MM/YYYY")}
											</p>
										</td>
										<td>
											{seller.listings.length} Items
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>}
				</div>}
			</div>
		);
	}
}

SellerList.propTypes = {
	onItemSelected: PropTypes.func
};

export default SellerList;