import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";

import NotLoggedInModal from "../modal/not-logged-modal";
import { checkAndLogExceedLimit, countIdsWithQuantity, ga4Events, popUp, ProductImage } from "../../../../utils";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import TextOverlay from "../../../common/TextOverlay";
import { handRequestForPrice } from "./card-listing-grid";

import './style.css'

export default ({
	link,
	url,
	badge,
	title,
	type,
	addToCart,
	role,
	isLoggedIn,
	_id,
	quantity,
	partSKU,
	partBrand,
	category,
	partName,
	variant,
	priceExclVat,
	pic,
	deliveryTime,
	aliasName
}) => {
	const [showModal, setShowModal] = useState(false)
	const { allCategories } = useSelector(state => state.category);
	const { quotationItems } = useSelector((state) => state.quotation);
	const { itemsInCart, isAddToCartLoading } = useSelector((state) => state.list)
	const dispatch = useDispatch()


	const handleGaEvent = () => {
		ga4Events({
			action: "customer_also_view_click",
			category: "Listing",
			product_id: _id
		});
	}

	const handleGa4Event = (action) => {
		const findCategory = allCategories?.length !== 0 && allCategories?.find(c => c?._id === category);
		const product_sku = action === 'customer_also_view_sku_copied' ? partSKU : undefined
		ga4Events({
			action,
			category: "Listing",
			product_id: _id,
			product_name: partName,
			product_category: findCategory?.name,
			product_brand: partBrand,
			product_variant: variant,
			product_sku
		});
	}


	const handleModal = (e) => {
		setShowModal(true);
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const [isCopied, setIsCopied] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);

	}, [isCopied])

	function handleNewQuotation() {
		if (isLoggedIn) {
			handRequestForPrice(
				quotationItems,
				{ _id, partName, priceExclVat, qty: 1, pic },
				dispatch
			)
		} else {
			popUp(
				'You need to register as a buyer to be able to request price'
			)
		}
	}

	const msg = "You need to register as a buyer to be able to make an order on BuyParts24";

	const result = countIdsWithQuantity(itemsInCart);
	const isAvailable = checkAndLogExceedLimit(_id, result, quantity);

	return (
		<div className="listing-card-grid">
			<div className="atbd_single_listing ">
				<article
					className="atbd_single_listing_wrapper"
					style={{ boxShadow: "none" }}
				>
					<figure
						className="atbd_listing_thumbnail_area"
						style={{ border: "1px solid #e3e6ef" }}
					>
						<NavLink to={`/spare-part-details/${link}`} onClick={handleGaEvent}>
							<div className="atbd_listing_image">
								<ProductImage avatar={url} alt="Listing" />
								{
									deliveryTime ?
										<TextOverlay value={deliveryTime} /> :
										null
								}
							</div>
						</NavLink>
						<div className="atbd_thumbnail_overlay_content">
							<ul className="atbd_upper_badge">
								<li>
									<span
										className={
											"text-capitalize atbd_badge atbd_badge_" +
											badge.replace(" ", "_")
										}
									>
										{badge}
									</span>
								</li>
							</ul>
						</div>
					</figure>
					<div className="atbd_listing_info">
						<h4 className="atbd_listing_title">
							<NavLink to={`/spare-part-details/${link}`} onClick={handleGaEvent}>{title}</NavLink>
						</h4>
						<div className="rating-group">
							<button className="btn part-brand">
								<NavLink className={"cursur-pointer"}
									onClick={() => handleGa4Event("customer_also_view_brand_click")}
									to={`/all-listings?brand=${partBrand
										.toLocaleLowerCase()
										.replace(" ", "-")}`}
									style={{
										fontSize: "9px"
									}}
								>{partBrand?.toUpperCase()}</NavLink>
							</button>
							<span className="review-value" style={{ fontSize: "12px" }}>{type}</span>
						</div>
						<>
							<div className="d-flex justify-content-between align-items-center card__sku-container">
								<button style={{flex: aliasName ? "1" : ""}} className="btn part-sku"
									onClick={() => {
										handleGa4Event("customer_also_view_sku_copied")
										setIsCopied(true)
										navigator.clipboard.writeText(partSKU)
									}}
									title={partSKU}
								>
									SKU
									<span className='part' style={{marginLeft: "4px"}}>{partSKU}</span>
									<span className="ml-2">|</span>
									<Tooltip
										className="ml-1 cursur-pointer"
										title="SKU number copied to clipboard"
										placement="top"
										open={isCopied}
										arrow
										disableHoverListener>
										<svg xmlns="http://www.w3.org/2000/svg"
											height="18" viewBox="0 0 24 24" width="24">
											<path d="M0 0h24v24H0z" fill="none" />
											<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg>
									</Tooltip>
								</button>
								{/* <span className="alias-name">{aliasName}</span> */}
							</div>
							<div className="pricing-group">
								{quantity && quantity > 0 ? (
									isLoggedIn ? <p className="symbol">
										<ShowDynamicCurrency />
										<span className="price">
											<ShowDynamicPrice price={priceExclVat} hideExc />
										</span>
									</p> : <p></p>
								) : <p></p>}
								{quantity && quantity > 0 ? (
									<button
										className="btn cart-btn"
										disabled={role == "SELLER" || isAddToCartLoading}
										onClick={() => {
											if (isAvailable) {
												popUp("Your cart items quantity exceeded stock quantity.")
											} else {
												if (isLoggedIn) {
													addToCart(_id, {
														action: "customer_also_view_add_cta",
														category: "Cart",
														product_id: _id
													});
												} else {
													popUp(msg)
												}
											}
										}}
									>
										Add To Cart
									</button>
								) : (
									<div style={{
										fontSize: 12,
										fontWeight: 600, color: "black",
										padding: isLoggedIn && '7px',
									}}>
										<button className="btn cart-btn"
											onClick={handleNewQuotation}
											style={{
												backgroundColor: "#1d71b8",
												color: "white"
											}}

										>
											Request For Price
										</button>
									</div>
								)}
							</div>
							{quantity > 0 && isLoggedIn && (<span
								style={{ fontSize: 10, fontWeight: 600, margin: 0, padding: 0, display: "flex" }}
								className="text-muted">Exc. VAT</span>)}
						</>

					</div>
				</article>
			</div>
			<NotLoggedInModal
				showModal={showModal}
				onClose={handleCloseModal}
				msg={msg}
			/>
		</div>
	);
};


