import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  Category,
  PopularTags,
} from "../content/element/widget";
import DetailsContent from "../container/news-details";

const NewsDetails = () => {
  const [newsDetailContent, setNewsDetailContent] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await axios.get(`/api/news/${id}`);
      setNewsDetailContent(response?.data);
    }
    fetchMyAPI();
  }, [id]);

  return (
    <Fragment>
      <section className="blog-area section-padding-strict border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <DetailsContent contents={newsDetailContent} />
            </div>
            <div className="col-md-4 mt-5 mt-md-0">
              <div className="sidebar">
                <div className="widget-wrapper">
                  <Category />
                  <PopularTags />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    news: state.news
  };
};
export default connect(mapStateToProps)(NewsDetails);
