import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { PageBanner } from "../content/element/page-banner";
import MaterialTable from "material-table";
import NoData from "../content/element/Nodata";
import Loader from "../content/element/Loader";
import { popUp } from "../../utils";
import CssTextField from "../content/element/SearchField";
import PageTitle from "../content/element/PageTitle";
import Pending from "../content/element/UserStatus/Pending";

class SellerOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      loader: false,
      searchField: "",
    };
  }
  async componentDidMount() {
    this.setState({ loader: true });

    try {
      let res = await axios.get(
        "/api/payment/get-my-payments?user_id=" + this.props.login._id
      );
      this.setState({ payments: res.data.payment });
    } catch (err) {
      popUp("There was a problem with server. Please try again.");
    }
    this.setState({ loader: false });
  }

  getIncodedCardNumber(card_number) {
    const card = card_number?.split(" ");

    return card_number?.replaceAll(
      card_number,
      `**** **** **** ${card[card.length - 1]}`
    );
  }

  getTotalAmount(amount) {
    const countryCurrency = amount?.toString().slice(0, 3);
    const totalAmount = amount?.toString().slice(3);
    return (
      <div>
        <span className="country-currency">{countryCurrency}</span>
        <span>{totalAmount}</span>
      </div>
    );
  }

  render() {
    const columns = [
      {
        title: <span className="tryit">ID</span>,
        field: "",
        render: (payments) => payments?._id.slice(0, 8).toUpperCase(),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Name</span>,
        field: "user.name",

        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Email</span>,
        field: "user.email",
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Phone</span>,
        field: "user.phone",
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">CardName</span>,
        field: "card_name",
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Card Number</span>,
        field: "Card_number",
        render: (payments) => this.getIncodedCardNumber(payments.card_number),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Total</span>,
        field: "total",
        render: (payments) => this.getTotalAmount(payments.total),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
    ];
    const filteredOrders = this.state.payments?.filter((item) => {
      return (
        item?.card_number
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?._id
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.card_name
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.total
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.user.name
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.user.email
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.user.phone
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.expired_year
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase())
      );
    });
    if (this.state.Loader) return <Loader header={true} />;

    if (!this.state?.payments?.length)
      return (
        <NoData
          text="You have not made any sales yet. They will show here as soon as buyer buys your products"
          header={true}
        />
      );

    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;

    return (
      <Fragment>
        <PageBanner />
        <section className="pt-2 section-bg pb-5">
          <div className="container">
            <PageTitle title="My Payments" />
            <div
              className="mb-2"
              style={{
                marginRight: 2,
                position: "relative",
                display: "flex",
                width: "100%",
              }}
            >
              <div className="ml-auto">
                <CssTextField
                  id="standard-basic"
                  value={this.state.searchField}
                  onChange={(e) =>
                    this.setState({ searchField: e.target.value })
                  }
                  label="Search Field"
                />
              </div>
              <div>
                <i className="las la-search la-search search-icon-datatable"></i>
              </div>
            </div>

            <MaterialTable
              title={null}
              style={{ zIndex: 0 }}
              data={filteredOrders}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <img
                      src="/assets/img/nodata.png"
                      className="datatableimage"
                    />
                  ),
                },
              }}
              columns={columns}
              isLoading={this.state.loader}
              options={{
                search: false,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 50, 100],
              }}
            />
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(SellerOrder);
