import React from 'react';
import PropType from 'prop-types';
import { NavLink } from 'react-router-dom';
import { handleHomepageSectionEvents } from '../../../../utils';

const ThumbnailCard = ({ id, title = "", thumbnail = "", slug, section }) => {
  return (
    <NavLink
      to={`/collections/${slug}`}
      onClick={() => handleHomepageSectionEvents(section, {
        collection_id: id,
        collection_name: title
      })}
    >
      <div className='thumbnail__card'>
        <div className='thumbnail__img-container'>
          <div style={{
            height: "200px"
          }}>
          <img
            src={thumbnail || "/assets/img/byparts.jpg"}
            alt="thumbnail"
            className='thumbnail__img'
          />
          </div>
         
        </div>

        <p className='thumbnail__label' title={title}>{title}</p>
      </div>
    </NavLink>
  )
}

ThumbnailCard.propTypes = ({
  id: PropType.string,
  title: PropType.string,
  thumbnail: PropType.string,
  slug: PropType.string
})

export default ThumbnailCard