import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { popUp } from "../../../../utils";
import { uploadFileToAssets } from "../../../../services/upload";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2)
  }
}));

const UploadProduct = ({ handleUploadQuotationModal, openSendQuotationModal, buyerId,rfqid }) => {
  const [file, setFile] = useState(null);
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!file) {
      alert("Please upload excel file");
    }

    try{
      const currentUser = JSON.parse(localStorage.getItem("login"));
      const fileData = await uploadFileToAssets({
        file,
        type: "quotation"
      });

      const data = {
        buyer: buyerId,
        file_id: fileData?._id
      }
  
      axios
      .patch(
        "/api/products/send-quotation?rfq_id=" + rfqid,
        data
      ).then((res) => {
          popUp("Successfully Uploaded")
          handleUploadQuotationModal(false);
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
          handleUploadQuotationModal(false);
        });
    } catch(error) {
      popUp(error?.message || "Error while sending quotation")
    }
  
  };
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="upload-product-wrapper">
        <div className="container">
          <div className="upload-product-content">
            <form onSubmit={handleSubmit}>
              <label>Please upload quotation file</label>
              <input
                type="file"
                onChange={handleChange}
                name="file"
                accept=".xlsx, .xls, .csv, .txt"
                style={{
                  border: "1px solid #ddd",
                  height: 45,
                  lineHeight: "45px",
                  width: "100%"
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  className="btn btn-color-blue file_upload_btn"
                  type="submit"
                  style={{ marginTop: 20, width: "48%" }}
                >
                  Send quotation
                </button>
                <button
                  className="btn"
                  type="button"
                  onClick={() => handleUploadQuotationModal(false)}
                  style={{
                    marginTop: 20,
                    width: "48%",
                    background: "#f5f7fc"
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      open={openSendQuotationModal}
      onClose={() => handleUploadQuotationModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default UploadProduct;
