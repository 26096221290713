import React from "react";

const Consolidation = (props) => {
	const { business_model } = props;
	//const classes = useStyles(props);

	return (
		<>
		</>
	);
};

export default Consolidation;
