import axios from 'axios'
import { popUp } from '../../utils'
export const setNotification = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'ADD_UNSEEN_NOTIFICATION_COUNT',
      data: data.noOfUnseen,
    })
    if (data.notificationType === 'expiry') {
      popUp(`Your trade license is going to expire soon.`)
    }
  }
}
export const getNotification = (user_id) => {
  return async (dispatch) => {
    try {
      axios
        .get(`/api/admin_users/notification?user_id=${user_id}`)
        .then((res) => {
          dispatch({
            type: 'GET_NOTIFICATION',
            data: res.data,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
    }
  }
}

export const getNotificationInital = (user_id) => {
  return async (dispatch) => {
    try {
      axios
        .get(`/api/admin_users/get-notification-inital?user_id=${user_id}`)
        .then((res) => {
          dispatch({
            type: 'GET_NOTIFICATION',
            data: res.data,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
    }
  }
}
