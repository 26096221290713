import React, { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { FormControl } from '@material-ui/core'
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import { OptionSelection } from '../filter/selection'
import FormControlLabel from '@mui/material/FormControlLabel';

export const PartsComponent = ({ data, options, state, setState }) => {

    const [search, setSearch] = useState('');
    const [partBrand, setPartBrand] = useState([]);
    const [vparts, setVparts] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [partsIntermidiate, setPartsIntermidiate] = useState([]);

    const [origData, setOrigData] = useState([]);
    const [selectAll, setSelectAll] = useState("SELECT ALL");
    
    useEffect(() => {
        setOrigData(data);
    }, [])

    const onKeyPress = (event) => {
        const searchedText = event.target.value;
        const filteredData = data.filter(obj => obj.name.toLowerCase().includes(searchedText.toLowerCase()));
        setOrigData(filteredData);

    }

    const handleParentChange = (event, voption) => {
        let cat = [];
        let subCat = [];

        if (event.target.checked) {

            let lm = partsIntermidiate.filter(e => e !== voption.name + "_t");

            setPartsIntermidiate(lm);

            if (partBrand?.length > 0) {
                cat = [...partBrand, { ...voption }];
            } else {
                cat = [voption];
            }

            setVparts(cat);

            [voption]?.map((oo) => {
                oo?.subCategories?.map((io) => {
                    subCat.push({ _id: io._id, name: io.name });
                })
            })

            if (subCategory?.length > 0) {
                subCat = [...subCategory, ...subCat];
            }

        } else {
            cat = partBrand.filter(e => e.name !== voption.name);
            subCat = subCategory.filter(i => !voption?.subCategories.some(j => j.name === i.name));
        }

        subCat = [...new Set(subCat.map(JSON.stringify))].map(JSON.parse);

        setSubCategory(subCat);
        setPartBrand(cat);

        options.categoryLists = cat;
        options.subCategoryLists = subCat;
        options.isSelectedFilteredEvent = false;
        setState(options)

    }

    const handleSelectionChange = (voptions, parents, fromParent = false) => {

        let newArr = { subCategories: [] };

        let cat = [];
        let subCat = [];

        subCat = voptions;

        let newParent = {};
        let lm = [];
        let arrLm = [];

        if (!fromParent) {

            [parents].forEach(p => {
                newArr = {
                    _id: p._id,
                    name: p.name,
                    subCategories: [],
                }
                newParent = p;
                p.subCategories.map((o) => {

                    let sx = voptions.some(removeObj => o._id === removeObj._id && o.name === removeObj.name);

                    if (sx) {
                        newArr.subCategories = [...newArr.subCategories, o];
                    }
                })
            });
            let xparts = [];
            xparts.push(newParent);
            if (vparts.length > 0) {
                xparts = [...new Set(vparts.map(JSON.stringify))].map(JSON.parse);
            }
            setVparts(xparts);

            if (partsIntermidiate.length > 0) {
                partsIntermidiate?.map((io) => {
                    arrLm.push(io);
                })
            }

            if (arrLm.length > 0) {
                lm = arrLm.filter(x => x !== newParent.name + "_t");
            }

            if (partBrand.length > 0) {
                cat = [...partBrand, ...[newParent]];

            }

            if (parents.subCategories.length == newArr.subCategories.length) {
                cat = [...partBrand, ...[newParent]];

            } else {
                cat = cat.filter(x => x.name !== newParent.name);
                if (newArr.subCategories.length > 0) {

                    if (lm.length > 0) {
                        lm = [...lm, newParent.name + "_t"];

                    } else {

                        lm = [newParent.name + "_t"];
                    }

                    let xparts = [...new Set(lm.map(JSON.stringify))].map(JSON.parse);

                }
            }

            setPartsIntermidiate(lm);
        }

        cat = [...new Set(cat.map(JSON.stringify))].map(JSON.parse);

        setSubCategory(subCat);
        setPartBrand(cat);

        options.categoryLists = cat;
        options.subCategoryLists = subCat;
        options.isSelectedFilteredEvent = false;

        setState(options)
        if (cat.length >= origData.length) {
            setSelectAll('CLEAR ALL')
        }
    }

    const handleSelectAll = (e, datas) => {
        options.isSelectedFilteredEvent = false;
        if (selectAll == 'SELECT ALL') {
            setSelectAll('CLEAR ALL');
            let newData = publicSelectAll(datas, true);

            setPartBrand(datas);
            setSubCategory(newData);

            options.categoryLists = datas;
            options.subCategoryLists = newData;

        } else {
            setSelectAll('SELECT ALL');
            setPartBrand([]);
            setSubCategory([]);
            options.categoryLists = [];
            options.subCategoryLists = [];
        }
        setState(options)
    }

    return (
        <div>
            <div
                className='accordion'

                style={{ marginBottom: 15 }}
                role='tablist'
                aria-multiselectable='true'>
                <div className='card'>
                </div>

                <div
                    className='search-area-header mx-1 pt-2'
                    style={{ color: 'black', paddingBottom: 0 }}>
                    PARTS
                    <p
                        style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            gap: 2,

                            padding: 0,
                            margin: 0,
                        }}
                    >

                        <span
                            style={{
                                color: 'black',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                padding: 0,
                                color: '#1D71B8',
                                margin: 0,
                            }}
                            onClick={(e) => handleSelectAll(e, origData)}
                        >
                            {selectAll}
                        </span>
                    </p>
                </div>
                <div class="my-2">
                    <FormControl style={{ margin: 1, backgroundColor: '#fff' }} variant="outlined">

                        <OutlinedInput onChange={onKeyPress}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon edge="start" className='cursor-pointer' />
                                </InputAdornment>
                            }
                            placeholder="Search"
                            size="small"

                        />
                    </FormControl>
                </div>
                <div className='card sidebar-menu'>

                    {origData?.map((o, i) => {
                        return (
                            <>
                                <div
                                    className='card-header card-white'
                                    style={{padding: 0}}
                                    role='tab'
                                    key={o.name}
                                    id={o.name}>
                                    <a data-toggle='collapse'
                                        href={"#a" + o.name.replace(/[^\w\s]/g, '').replace(/\s+/g, '_') + "_" + i}
                                        aria-expanded='true'
                                        aria-controls={"a" + o.name.replace(/\s+/g, '_') + "_" + i}
                                        className="collapsed link-checkbox-dropdown text-capitalize"

                                    >
                                        <span className='mb-0'>
                                            <FormControlLabel
                                                label={o.name}
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        color: "black",
                                                        fontSize: '12px',
                                                        marginTop: '5px'
                                                    }
                                                }}
                                                className={partBrand?.filter(d => d.name === o.name)[0] || 
                                                    partsIntermidiate?.includes(o.name + "_t") ? "full-width checked" : "full-width"}
                                                control={
                                                    <Checkbox
                                                        defaultChecked={false}
                                                        checked={partBrand?.filter(d => d.name === o.name)[0] ? true : false}
                                                        indeterminate={partsIntermidiate?.includes(o.name + "_t")}
                                                        name={o.name}
                                                        onChange={(e) => handleParentChange(e, o)}
                                                        sx={{
                                                            color: 'black',
                                                            fontSize: '12px',
                                                            paddingLeft: '12px',
                                                            paddingRight: '5px',
                                                            paddingY: '1px',
                                                            '& .MuiSvgIcon-root': {
                                                                width: '20px',
                                                                color: 'black',
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </span>

                                        {o?.subCategories?.length > 0 ?
                                            <i className='la la-angle-up' style={{ marginRight: 5, marginBottom: 14 }}></i>
                                            : ''}
                                    </a>
                                </div>
                                {o?.subCategories?.length > 0 ?
                                    <div
                                        id={"a" + o.name.replace(/[^\w\s]/g, '').replace(/\s+/g, '_') + "_" + i}
                                        className='collapse li-children'
                                        style={{marginLeft: '22px'}}
                                        role='tabpanel'
                                        aria-labelledby={"a" + o.name}>
                                        <OptionSelection
                                            options={o.subCategories || []}
                                            name='subCategory'
                                            parents={o}
                                            activeOptions={
                                                subCategory
                                                    ? subCategory
                                                    : []
                                            }
                                            onChange={(e) => handleSelectionChange(e, o)}

                                        />
                                    </div>
                                    : ''}
                            </>)
                    })}
                </div>


            </div>
        </div>
    )
}


function publicSelectAll(datas, isSubCategoryOnly = false) {
    let newData = [];
    datas?.map((o) => {
        if (!isSubCategoryOnly) { newData.push(o); }
        o?.subCategories?.map((oo) => {
            newData.push(oo);
        })
    })
    return newData;
}