import React from 'react'
import { useRef } from 'react';
import { validateFile } from '../../../../../utils';

const UploadProfileImg = ({ avatar, setAvatar }) => {
    const uploadRef = useRef();

    const openFileDlg = (e) => {
        e.preventDefault();
        uploadRef.current.click();
    }

    const uploadAvatar = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let avatar = Object.assign(file, {
            preview: URL.createObjectURL(file),
        });
        setAvatar(avatar);
    }

    return (
        <div className="col-lg-3 col-md-4 mb-5 mb-lg-0 ">
            <div className="user_pro_img_area">
                {avatar ? (
                    <img
                        git
                        src={avatar?.preview || avatar}
                        onClick={openFileDlg}
                        alt="avatar"
                        className="imageAvatar"
                        style={{ height: 180, width: 180, borderRadius: "100%" }}
                    />
                ) : (
                    <img
                        src={`/assets/img/avatar.png`}
                        onClick={openFileDlg}
                        alt="avatar"
                        className="dashboard-profile-img imageAvatar"
                    />
                )}
                <div className="image-info">
                    <h6>Profile Image</h6>
                    <span>JPG or PNG 120x120 px</span>
                </div>
                <div className="custom-file-upload">
                    <input
                        id="customUpload"
                        type="file"
                        ref={uploadRef}
                        style={{ display: "none" }}
                        onChange={(e) => {
                            let isLt5M = validateFile(e.target.files[0])
                            if (!isLt5M) {
                                e.target.value = ''
                                return
                            }
                            uploadAvatar(e)
                        }}
                    />

                    <label
                        htmlFor="customUpload"
                        className="btn btn-sm btn-secondary text-white"
                        onClick={openFileDlg}
                    >
                        Upload New Image
                    </label>
                </div>
            </div>
        </div>
    )
}

export default UploadProfileImg