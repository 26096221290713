import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { LogInAc } from "../../../Store/action/loginActions";
import { ga4Events, popUp, validateFile } from "../../../utils";
import Select from "react-select";

import { garageTypes, emiratesOptions } from "../../../constants";
import GoogleMapLocation from "../../common/GoogleMapLocation";
import SelectionInput from "../../common/SelectionInput";
import SearchCityInput from "../../common/SearchCityInput";

class RegisterBuyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emirate: null,
      garage_type: null,
      garage_name: null,
      garage_address: null,
      garage_area: null,
      opening_hours: null,
      closing_hours: null,
      trade_license_no: null,
      trade_license_upload: null,
      vat_registration_no: null,
      vat_registration_upload: null,
      owner_manager_name: null,
      owner_manager_emirates_id: null,
      emirates_id: null,
      goods_receiver_name: null,
      submitLoading: false,
      stateData: [],
      regions: [],
      listOfAllTradeNumberInSys: [],
      listOfAllVatNumberInSys: [],
      isRegisteredForVat: false,
      buyer_location: null,
      latLong: {
        lat: 0,
        lng: 0
      },
      isModalOpen: false
    };
    this.validator = new SimpleReactValidator({
      validators: {
        tradeUniqueCheck: {
          message: "Already registered with this Trade Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllTradeNumberInSys.includes(val)) {
              return true;
            }
            return false;
          },
          required: true,
        },
        vatUniqueCheck: {
          message: "Already registered with this Vat Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllVatNumberInSys.includes(val)) {
              return true;
            }
            return true
          },
          required: false,
        },
        tradeOrVatRegexCheck: {
          message:
            "The :attribute registration number should be between 4 to 15 digits only.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z0-9]{4,15}$/);
          }
        },
      },
    });
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSelectedFile = this.setSelectedFile.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.update = this.update.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/products/get-regions")
      .then((res) => {
        this.setState({ stateData: res?.data?.regions, regions: res?.data?.regions });
      })
      .catch((e) =>
        popUp("There was a problem with server. Please try again.")
      );

    axios
      .get(`/api/users/get-all-trade-no?type=BUYER`)
      .then((res) => {
        this.setState({ listOfAllTradeNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/api/users/get-all-vat-no?type=BUYER`)
      .then((res) => {
        this.setState({ listOfAllVatNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  update(address, cordinates) {
    this.setState({ 
      buyer_location: address,
      latLong: cordinates,
    });
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  setAddress(address) {
    this.setState({ garage_address: address });
  }

  handleSelect(selectedOption, fieldAttr) {
    if(fieldAttr.name === "emirate") {
      let newRegions = this.state.regions?.filter(region => region?.state?.name === selectedOption.value);

      this.setState({
        stateData: newRegions,
        garage_area: ""
      })
    }

    this.setState({ [fieldAttr.name]: selectedOption.value });
  }

  handleInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  setSelectedFile(file, name) {
    if (file) {
      let data = new FormData();
      data.append(`listing`, file);
      axios
        .post("/api/imageupload", data)
        .then((res) => {
          if (Array.isArray(res.data)) {
            this.setState({ [name]: res.data[0] });
          } else {
            this.setState({ [name]: res.data });
          }
        })
        .catch((error) => {
          this.setState({ [name]: "" });
          console.error(error);
        });
    }
  }

  hasSelectedCoordinates = () => {
    if(this.state.latLong?.lat === 0 || this.state.latLong?.lng === 0) {
      popUp("Please select your exact location from map");
      this.setState({
        isModalOpen: !this.state.isModalOpen
      });
      return false;
    }
    return true;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid() && this.hasSelectedCoordinates()) {
      this.setState({ submitLoading: true });
      const fulladdress =
        this.state.garage_area + "," + this.state.garage_address;
      const details = {
        emirate: this.state.emirate,
        garage_type: this.state.garage_type,
        garage_name: this.state.garage_name,
        garage_address: fulladdress,
        opening_hours: this.state.opening_hours,
        closing_hours: this.state.closing_hours,
        trade_license_no: this.state.trade_license_no,
        vat_registration_no: this.state.vat_registration_no,
        owner_manager_name: this.state.owner_manager_name,
        goods_receiver_name: this.state.goods_receiver_name,
        trade_license_upload: this.state.trade_license_upload,
        vat_registration_upload: this.state.vat_registration_upload,
        owner_manager_emirates_id: this.state.owner_manager_emirates_id,
        emirates_id: this.state.emirates_id,
        buyer_location: this.state.buyer_location,
        latLong: this.state.latLong
      };

      let obj = {
        password: this.props.signup.password?.trim(),
        email: this.props.signup.email?.trim().toLowerCase(),
        phone: this.props.signup.phone?.trim(),
        role: "BUYER",
        details: details,
      };

      ga4Events({
        action: 'signup_button_click',
        category: 'User'
      });

      axios
          .post(`/api/users/signup`, obj)
          .then((res) => {
            popUp("You have successfully registered as buyer.");
            this.setState({ submitLoading: false });
            ga4Events({
            action: 'create_user', 
              category: 'User', 
              bp_user_id: res.data?._id, 
            });
            window.location.href = "/thankyou-register";
          })
          .catch((err) => {
            popUp("There was a problem with server. Please try again.");
            this.setState({ submitLoading: false });
          });
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Fragment>
        <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>Mandatory
                        Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents mb-5">
                    <form action="/">
                      <div className="form-group">
                        <label htmlFor="garage-name" className="form-label">
                          Garage Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="garage-name"
                          placeholder="Enter Garage Name"
                          value={this.state.garage_name || ""}
                          name="garage_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "garage_name",
                            this.state.garage_name,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="garage-type" className="form-label">
                          Garage Type <span style={{ color: 'red' }}>*</span>
                        </label>

                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="garage_type"
                          id="garage-type"
                          placeholder="Choose Garage Type..."
                          options={garageTypes?.map((type) => {
                            return {
                              label: type,
                              value: type,
                            };
                          })}
                          onChange={this.handleSelect}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "garage_type",
                            this.state.garage_type,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="emirate" className="form-label">
                          Emirate <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="emirate"
                          id="emirate"
                          placeholder="Choose Emirate..."
                          options={emiratesOptions?.map((emirate) => {
                            return {
                              label: emirate,
                              value: emirate,
                            };
                          })}
                          onChange={this.handleSelect}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "emirate",
                            this.state.emirate,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="garage-address" className="form-label">
                          Garage Address <span style={{ color: 'red' }}>*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-4">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              name="garage_area"
                              id="garage_area"
                              placeholder="Choose Garage Area"
                              isDisabled={!this.state.emirate}
                              options={this.state?.stateData?.map((type) => {
                                return {
                                  label: type.name,
                                  value: type.name,
                                };
                              })}
                              value={this.state.garage_area ?
                                {
                                label: this.state.garage_area ,
                                value: this.state.garage_area
                              }: ""}
                              onChange={this.handleSelect}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "garage_area",
                                this.state.garage_area,
                                `required`
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <SearchCityInput
                              placeholder="Enter a city"
                              address={this.state.garage_address}
                              setAddress={this.setAddress}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "garage_address",
                                this.state.garage_address,
                                `required`
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    <div className="form-group">
                      <SelectionInput
                        input={this.state.buyer_location}
                        setInput={(value) => {
                          console.log(value)
                          this.setState({
                            buyer_location: value
                          })
                        }}
                        label={"Buyer Location"}
                        name="buyerLocation"
                        btnLabel="Change"
                        eventFn={this.toggle}
                        required={true}
                      />

                      <div className="form-group">
                        <label htmlFor="garage-type" className="form-label">
                          Garage Type <span style={{ color: 'red' }}>*</span>
                        </label>

                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="garage_type"
                          id="garage-type"
                          placeholder="Choose Garage Type..."
                          options={garageTypes?.map((type) => {
                            return {
                              label: type,
                              value: type,
                            };
                          })}
                          onChange={this.handleSelect}
                        />

                        <div className="text-danger">
                          {this.validator.message(
                            "garage_type",
                            this.state.garage_type,
                            `required`
                          )}
                        </div>
                      </div>
                    </div>

                    <GoogleMapLocation
                      selectedAddress={this.state.buyer_location}
                      selectedLatitude={this.state.latLong.lat}
                      selectedLongitude={this.state.latLong.lng}
                      isModalOpen={this.state.isModalOpen}
                      toggle={this.toggle}
                      updateLocation={this.update}
                      label="Buyer Location"
                    />

                      <div className="form-group">
                        <label htmlFor="opening-hours" className="form-label">
                          Opening Hours <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="time"
                          id="opening-hours"
                          className="form-control directory_field"
                          value={this.state.opening_hours || ""}
                          name="opening_hours"
                          onChange={this.handleInput}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "opening_hours",
                            this.state.opening_hours,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="closing-hours" className="form-label">
                          Closing Hours <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="time"
                          id="closing-hours"
                          className="form-control directory_field"
                          value={this.state.closing_hours || ""}
                          name="closing_hours"
                          onChange={this.handleInput}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "closing_hours",
                            this.state.closing_hours,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-no"
                          className="form-label"
                        >
                          Trade License No <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="trade-license-no"
                          placeholder="Enter Trade Licnse No"
                          value={this.state.trade_license_no || ""}
                          name="trade_license_no"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade",
                            this.state.trade_license_no,
                            `required|tradeUniqueCheck`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-upload"
                          className="form-label"
                        >
                          Trade License Upload <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="trade-license-upload"
                          onChange={(e) => {
                            let isLt5M = validateFile(e.target.files[0])
                            if (!isLt5M) {
                              e.target.value = ''
                              return
                            }
                            this.setSelectedFile(
                              e.target.files[0],
                              "trade_license_upload"
                            )
                          }
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade_license_upload",
                            this.state.trade_license_upload,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="owner-manager-name"
                          className="form-label"
                        >
                          Owner/Manager Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="owner-manager-name"
                          placeholder="Enter Name"
                          value={this.state.owner_manager_name || ""}
                          name="owner_manager_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "owner_manager_name",
                            this.state.owner_manager_name,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="goods-receiver-name"
                          className="form-label"
                        >
                          Name of Goods Receiver <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="goods-receiver-name"
                          placeholder="Enter Name"
                          value={this.state.goods_receiver_name || ""}
                          name="goods_receiver_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "goods_receiver_name",
                            this.state.goods_receiver_name,
                            `required`
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>Optional Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents mb-3">
                    <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="register_vat"
                          id="register_vat"
                          value={this.state.isRegisteredForVat}
                          onChange={() => {
                            this.setState(prevState => ({
                            isRegisteredForVat: !prevState.isRegisteredForVat
                          }));
                          this.validator.purgeFields(['vat_registration_no', 'vat_certification_upload']);
                          }}
                        />
                        <label
                          htmlFor="register_vat"
                          className="not_empty custom-control-label"
                        >
                          Are you registered for VAT?
                        </label>
                      </div>
                    
                    {
                      this.state.isRegisteredForVat && 
                      <>
                        <div className="form-group">
                          <label htmlFor="vat-reg-no" className="form-label">
                            VAT Registration No
                            {
                              this.state.isRegisteredForVat &&
                              <span style={{ color: 'red' }}>*</span>
                            }
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="vat-reg-no"
                            placeholder="Enter Registration No"
                            value={this.state.vat_registration_no || ""}
                            name="vat_registration_no"
                            onChange={this.handleInput}
                            required={this.state.isRegisteredForVat}
                          />
                          {
                            this.state.isRegisteredForVat &&
                            <div className="text-danger">
                              {this.validator.message(
                                "vat",
                                this.state.vat_registration_no,
                                `required|tradeOrVatRegexCheck|vatUniqueCheck`,
                              )}
                            </div>
                          }
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="vat-cretificate-upload"
                            className="form-label"
                          >
                            VAT Certificate Upload 
                            {
                              this.state.isRegisteredForVat &&
                              <span style={{ color: 'red' }}>*</span>
                            }
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="vat-cretificate-upload"
                            onChange={(e) => {
                              let isLt5M = validateFile(e.target.files[0])
                              if (!isLt5M) {
                                e.target.value = ''
                                return
                              }
                              this.setSelectedFile(
                                e.target.files[0],
                                "vat_certification_upload"
                              )
                            }
                            }
                          />
                          {
                            this.state.isRegisteredForVat &&
                            <div className="text-danger">
                              {this.validator.message(
                                "vat_certification_upload",
                                this.state.vat_certification_upload,
                                `required`
                              )}
                          </div>
                          }
                        </div>
                      </>
                    }

                    <div className="form-group">
                      <label
                        htmlFor="owner_manager_emirates_id"
                        className="form-label"
                      >
                        Owner/Manager Emirates ID / Passport No
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="owner_manager_emirates_id"
                        placeholder="Enter Owner/Manager Emirates ID / Passport No"
                        value={this.state.owner_manager_emirates_id || ""}
                        name="owner_manager_emirates_id"
                        onChange={this.handleInput}
                        required
                      />
                      <div className="text-danger">
                        {this.validator.message(
                          "owner_manager_emirates_id",
                          this.state.owner_manager_emirates_id,
                          `required`
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="emirates-id-upload"
                        className="form-label"
                      >
                        Emirates ID Upload
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="emirates-id-upload"
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.setSelectedFile(e.target.files[0], "emirates_id")
                        }
                        }
                      />
                    </div>

                    <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="listing_t"
                        value="1"
                        id="listing_t"
                      />
                      <label
                        htmlFor="listing_t"
                        className="not_empty custom-control-label"
                      >
                        By Clicking, you are accepting{" "}
                        <NavLink to="/terms">
                          the terms and conditions and privacy policy
                        </NavLink>
                      </label>
                    </div>
                    <div className="btn_wrap list_submit m-top-25">
                      <button
                        type="submit"
                        disabled={
                          this.state.submitLoading === false ? false : true
                        }
                        className="btn btn-primary btn-lg listing_submit_btn"
                        onClick={this.handleSubmit}
                      >
                        {this.state.submitLoading && (
                          <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    login: (data) => dispatch(LogInAc(data)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(RegisterBuyer);
