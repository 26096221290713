
import React, { Component, Fragment } from "react";

export class ListingFetures extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { listing } = this.props;
    let make = listing?.makes?.length && Array.isArray(listing?.makes) ? listing?.makes?.map(m => m?.name)?.join('/') : listing?.makes;
    let model = listing?.models?.length && Array.isArray(listing?.models) ? listing?.models?.map(m => m?.name)?.join('/') : listing?.models;


    return (
      <Fragment>
        <div className="atbd_content_module atbd_listing_features">
          <div className="atbd_content_module__tittle_area">
            <div className="atbd_area_title">
              <h4>
                <span className="la la-list-alt"></span>Details
              </h4>
            </div>
          </div>
          <div className="atbdb_content_module_contents">
            <ul className="atbd_custom_fields features-table">
              {listing?.partName?.length > 0 && <li>
                <div className="atbd_custom_field_title">
                  <p>PartName: </p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.partName}</p>
                </div>
              </li>}

              {this.props.login && (listing?.priceExclVat?.length > 0) && <li>
                <div className="atbd_custom_field_title">
                  <p>Price(Exc. VAT):</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>AED {listing?.priceExclVat?.toFixed(2)}</p>
                </div>
              </li>}
              {listing?.partBrand?.length > 0 && <li>
                <div className="atbd_custom_field_title">
                  <p>PartBrand:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.partBrand}</p>
                </div>
              </li>}
              {listing?.partSKU?.length > 0 && <li>
                <div className="atbd_custom_field_title">
                  <p>PartSKU:</p>
                </div>
                <div className="atbd_custom_field_content" style={{
                  whiteSpace: "pre-wrap"
                }}>
                  <p>{listing?.partSKU}</p>
                </div>
              </li>}

              {listing?.amp?.length > 0 && <li className={listing?.amp === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Battery Amp:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.amp}</p>
                </div>
              </li>}
              {listing?.voltage?.length > 0 && <li className={listing?.voltage === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Voltage:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.voltage}</p>
                </div>
              </li>}


              {listing?.size?.length > 0 && <li className={listing?.size === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Size:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.size}</p>
                </div>
              </li>}
              {listing?.rimSize?.length > 0 && <li className={listing?.rimSize === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Rim Size:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.rimSize}</p>
                </div>
              </li>}

              {listing?.heightDimension?.length > 0 && <li className={listing?.heightDimension === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Height:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.heightDimension}</p>
                </div>
              </li>}

              {listing?.widthDimension?.length > 0 && <li className={listing?.widthDimension === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Width:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.widthDimension}</p>
                </div>
              </li>}


              {listing?.utqg?.length > 0 && <li className={listing?.utqg === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>UTQG:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.utqg}</p>
                </div>
              </li>}
              {listing?.makeYear?.length > 0 && <li className={listing?.makeYear === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Make Year:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.makeYear}</p>
                </div>
              </li>}

              {make?.length > 0 && <li>
                <div className="atbd_custom_field_title">
                  <p>Makes:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{make}</p>
                </div>
              </li>}
              {model?.length > 0 && <li>
                <div className="atbd_custom_field_title">
                  <p>Models:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{model}</p>
                </div>
              </li>}
              {listing?.category?.length > 0 && <li className={listing?.category === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Category:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.category?.name}</p>
                </div>
              </li>}
              {listing?.subCategory?.length > 0 && <li className={listing?.subCategory === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Sub Category:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.subCategory?.name}</p>
                </div>
              </li>}

              {listing?.subCategoryTire?.length > 0 && <li className={listing?.subCategoryTire === "" ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Sub Category Tire:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.subCategoryTire?.name}</p>
                </div>
              </li>}

              {!listing?.countryOrigin?.length > 0 && <li className={!listing?.countryOrigin ? "d-none" : ""}>
                <div className="atbd_custom_field_title">
                  <p>Origin Of Country:</p>
                </div>
                <div className="atbd_custom_field_content">
                  <p>{listing?.countryOrigin}</p>
                </div>
              </li>}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

