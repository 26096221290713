import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core';
import { NotListedLocation, PersonPinCircle } from '@material-ui/icons';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const SelectLocationModal = ({ isOpen, handleConfirmSelection }) => {
    const [deliveryType, setDeliveryType] = useState("current");

    const handleSelectDeliveryType = (type) => {
        setDeliveryType(type);
    }

    return (
        <Modal
            isOpen={isOpen}
            centered
            size='md'
        >
            <ModalHeader>
                <h6>Pin the marker to</h6>
            </ModalHeader>
            <ModalBody className='select__location-container'>
                <Box onClick={()=> handleSelectDeliveryType("current")} className={`select__location-card ${deliveryType === "current" ? "active__type" : ""}`}>
                    <PersonPinCircle fontSize='large' style={{marginBottom: ".5rem"}}/>
                    <Typography variant='caption'>
                        Current
                    </Typography>
                    <Typography variant='caption'>
                        Location
                    </Typography>
                </Box>

                <Box onClick={()=> handleSelectDeliveryType("different")} className={`select__location-card ${deliveryType === "different" ? "active__type" : ""}`}>
                    <NotListedLocation fontSize='large' style={{marginBottom: ".5rem"}}/>
                    <Typography variant='caption'>
                        Different
                    </Typography>
                    <Typography variant='caption'>
                        Location
                    </Typography>
                </Box> 
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="contained"
                    onClick={() => handleConfirmSelection(deliveryType)}
                    color="primary"
                >
                    Select
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default SelectLocationModal