import React from 'react'

export default function StepButton({ step, stepForward }) {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div
                    className="checkout-order-steps"
                    style={{ margin: "0 -15px", marginBottom: 30 }}
                >
                    <div
                        className={
                            "col-lg-2 checkout-tabs " +
                            (step > 0 ? "active" : "") +
                            (step > 1 ? " active1" : "")
                        }
                        onClick={(e) => stepForward(e, 1)}
                    >
                        <span>Review Order</span>
                    </div>

                    <div
                        className={
                            "col-lg-2 checkout-tabs " +
                            (step > 1 ? "active" : "") +
                            (step > 2 ? " active1" : "")
                        }
                        onClick={(e) => stepForward(e, 2)}
                    >
                        <span>Address</span>
                    </div>
                    <div
                        className={
                            "col-lg-2 checkout-tabs " +
                            (step > 2 ? "active" : "") +
                            (step > 3 ? " active1" : "")
                        }
                        onClick={(e) => stepForward(e, 3)}
                    >
                        <span>Shipping </span>
                    </div>

                    <div
                        className={
                            "col-lg-2 checkout-tabs " +
                            (step > 3 ? "active" : "") +
                            (step > 4 ? " active1" : "")
                        }
                        onClick={(e) => stepForward(e, 4)}
                    >
                        <span>Payments</span>
                    </div>

                    <div
                        className={
                            "col-lg-2 checkout-tabs " +
                            (step > 4 ? "active" : "") +
                            (step > 5 ? " active1" : "")
                        }
                        onClick={(e) => stepForward(e, 5)}
                    >
                        <span>Confirmation</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
