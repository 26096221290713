import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RSC from "react-scrollbars-custom";
import moment from "moment-timezone";
import $ from "jquery";
import { WebSocketContext } from "../../WebSocket";
import Pending from "../content/element/UserStatus/Pending";
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  UpdateRooms,
  UpdateActiveRoom,
  AddMessage,
  GetConversations,
  Initialize,
} from "../../Store/action/chatActions";
import NoData from "../../components/content/element/Nodata";
import { UserAvatar } from "../../utils";

const noAction = (e) => e.preventDefault();
const getChatTime = (momentDate) => {
  const REFERENCE = moment().tz("Asia/Dubai"); 
  const TODAY = REFERENCE.clone().startOf("day");
  const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
  if (momentDate.isSame(TODAY, "d"))
    return "Today " + momentDate.format("h:mm A");
  if (momentDate.isSame(YESTERDAY, "d"))
    return "Yesterday " + momentDate.format("h:mm A");
  else return momentDate.format("DD/MM/YYYY - h:mm A");
};

class Chats extends Component {
  static contextType = WebSocketContext;
  constructor(props) {
    super(props);
    this.state = {
      listing: null,
      members: [],
      activeUser: this.props.chat.activeUser,
      conversations: [],
      message: null,
      connectedUsers: [],
      isTyping: false,
      typingTimeout: 0,
      typingUsers: [],
      queryId: false,
      allUsers: [],
    };

    this.handleChatRoom = this.handleChatRoom.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleInputMessage = this.handleInputMessage.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleBackUserList = this.handleBackUserList.bind(this);
  }

  componentDidMount() {
    if (this.props?.chat?.rooms?.length > 0) {
      this.props
        .chatRoomInitialize()
        .then((res) =>
          this.handleChatRoom(null, this.props?.activeRoomId)
        );
      this.scrollToBottom();
    }
  }
  componentWillUnmount() {
    this.props.updateActiveChatRoom(null);
  }

  scrollToBottom() {
    if (this.messagesEnd) this.messagesEnd.scrollToBottom();
  }


  handleChatRoom(e, roomId) {
    this.setState({ setactive: false });
   
    if (e) noAction(e);
    let { rooms } = this.props.chat;
    const foundIndex = rooms.findIndex((x) => x.roomId === roomId);
    rooms[foundIndex].unreadMsgCnt = 0;

    this.props.updateChatRooms(rooms);
    this.props.updateActiveChatRoom(rooms[foundIndex]);
    this.props.getRoomConversations(rooms[foundIndex]?.roomId).then(() => {
      this.scrollToBottom();
    });
    if ($(window).width() <= 991) {
      $(".chat-window").addClass("chat-slide");
    }
  }

  handleInputMessage(e) {
    const self = this;
    const { isTyping, typingTimeout } = this.state;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (isTyping === false) {
      this.setState({ isTyping: true });
    }

    this.setState({
      typingTimeout: setTimeout(function () {
        self.setState({ isTyping: false });
      }, 1000),
    });

    this.setState({ message: e.target.value });
  }

  sendMessage(e) {
    noAction(e);
    const context = this.context;
    const { message } = this.state;
    const { activeRoom } = this.props.chat;
    if (!message) return;

    let { rooms } = this.props.chat;
    const foundIndex = rooms.findIndex((x) => x.roomId === activeRoom.roomId);
    rooms[foundIndex].lastMsg = message;

    this.props.updateChatRooms(rooms);

    this.setState({ isTyping: false, message: "" });
    context.sendMessage(
      activeRoom.roomId,
      this.props.login._id,
      activeRoom.userId,
      message
    );
    this.props.addNewMessage({
      roomId: activeRoom.roomId,
      sender: this.props.login._id,
      message,
    });

    if (rooms[foundIndex].roomId == rooms[0].roomId) {
      this.scrollToBottom();
    } else {
      this.props
        .chatRoomInitialize()
        .then((res) =>
          this.handleChatRoom(null, this.props?.chat?.rooms?.[0].roomId)
        );
      this.scrollToBottom();
    }
    this.props.updateActiveChatRoom(rooms[0]);

    this.props.getRoomConversations(rooms[0]?.roomId).then((res) => {
      this.scrollToBottom();
    });
    this.scrollToBottom();
  }

  handleBackUserList(e) {
    noAction(e);
    if ($(window).width() <= 991) {
      $(".chat-window").removeClass("chat-slide");
    }
  }
  

  render() {
    const { message } = this.state;
    const { activeRoom, onlineUsers, messages } = this.props.chat;
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (
      currentUser && 
      currentUser.status === "Pending" && 
      currentUser.KYCStatus && 
      currentUser?.details?.emirate === null
      ) {
        return <Pending />;
      }

    if (!this.props?.chat?.rooms?.length)
      return (
        <NoData
          text="You don't have any message yet. You will find your discussions with support and sellers here."
        />
      );

    return (
      <Fragment>
        <div className="chat-cont-right chat-discussion" >
          {/* chat header */}
          <div className="chat-header" style={{borderTopLeftRadius: 10,borderTopRightRadius: 10 }}>
            <a
              id="back_user_list"
              href="#!"
              className="back-user-list"
            >
              <i
                className="las la-angle-left"
                onClick={this.handleBackUserList}
              ></i>
            </a>
            {activeRoom && (
              <div className="media">
                <div className="media-img-wrap">
                  <div
                    className={`avatar ${
                      onlineUsers && onlineUsers[activeRoom.userId]
                        ? "avatar-online"
                        : "avatar-away"
                    }`}
                  >
                    {this.props.login?.role.toLowerCase() ===
                    "buyer" ? (
                      <Avatar
                        alt="buyparts"
                        src="/assets/img/favicon-buyparts24.svg"
                      />
                    ) : (
                      <UserAvatar
                        avatar={activeRoom?.userAvatar}
                              alt="UserImage"
                            className="avatar-img rounded-circle"/>
                    )}
                  </div>
                </div>
                <div className="media-body">
                  <div className="user-name">
                    {this.props.login?.role.toLowerCase() === "buyer"
                      ? "BuyParts24 Admin"
                      : activeRoom.userName}
                  </div>
                  <div className="user-status">
                    {onlineUsers && onlineUsers[activeRoom.userId]
                      ? "Online"
                      : "Away"}
                  </div>
                </div>
              </div>
            )}
            {activeRoom && (
              <div className="chat-options">       
                <IconButton onClick={()=>{this.props.setShowChatBox(false)}}>
                          <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          {/* end of chat header */}

          {/* chat body */}
          <div className="chat-body">
            {messages && messages.length > 0 ? (
              <RSC
                className="chat-scroll"
                style={{ height: "calc(80vh - 210px)" }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              >
                {activeRoom && (
                  <ul className="list-unstyled">
                    {messages &&
                      messages.length > 0 &&
                      messages.map((conv, index) => {
                        const isSent =
                          conv.sender === this.props.login._id
                            ? true
                            : false;
                        return (
                          <li
                            className={`media ${
                              isSent ? "sent" : "received"
                            }`}
                            key={index}
                          >
                            {isSent === false && (
                              <div className="avatar">
                                <img
                                  src={
                                    activeRoom.userAvatar
                                      ? `${activeRoom.userAvatar}`
                                      : "/assets/img/avatar.png"
                                  }
                                  alt="UserImage"
                                  className="avatar-img rounded-circle"
                                />
                              </div>
                            )}
                            <div className="media-body">
                              <div className="msg-box">
                                <div>
                                  <p>{conv.message}</p>
                                  <ul className="chat-msg-info">
                                    <li>
                                      <div className="chat-time">
                                        <span>
                                          {getChatTime(
                                            moment(conv.createdAt).tz(
                                              "Asia/Dubai"
                                            )
                                          )}
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </RSC>
            ) : (
              <div
                style={{
                  backgroundColor: "#fff",
                  textAlign: "center",
                  height: "60vh",
                }}
              >
                <img
                  src="./assets/img/nodata.png"
                  alt="empty_message"
                  className="emptyMessage"
                  style={{ height: "250px", width: "auto" }}
                />
              </div>
            )}
          </div>
          {/**end of chat body */}

          {/* chat footer */}
          <div className="chat-footer">
            {activeRoom && (
              <form onSubmit={this.sendMessage}>
                <div className="input-group">
                  <input
                    type="text"
                    name="message"
                    className="input-msg-send form-control"
                    placeholder="Type something"
                    value={message || ""}
                    onChange={this.handleInputMessage}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn msg-send-btn"
                      onClick={this.sendMessage}
                    >
                      <i className="fab fa-telegram-plane"></i>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    chat: state.chat,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateChatRooms: (data) => dispatch(UpdateRooms(data)),
    updateActiveChatRoom: (data) => dispatch(UpdateActiveRoom(data)),
    addNewMessage: (data) => dispatch(AddMessage(data)),
    getRoomConversations: (roomId) => dispatch(GetConversations(roomId)),
    chatRoomInitialize: () => dispatch(Initialize()),
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(withRouter(Chats));