import React, { useState } from 'react';
import "./MobileFooter.css";
import HomeIcon from '@mui/icons-material/Home';
import GridViewIcon from '@mui/icons-material/GridView';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useHistory } from "react-router";

const MobileFooter = ({moreMobileFooter}) => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState('home');

  const handleItemClick = (item) => {
    setActiveItem(item);
    if(item === 'more') {
      moreMobileFooter(true)
    } else {
      moreMobileFooter(false)
    }
  };

  return (
    <div className={'mobile-footer'}>
      <div
        className={`${'footer-item'} ${activeItem === 'home' ? 'active' : ''}`}
        onClick={() => {
          handleItemClick('home');
          history.push("/");
        }}
      >
        <HomeIcon />
        <span>Home</span>
      </div>
      <div
        className={`${'footer-item'} ${activeItem === 'catalog' ? 'active' : ''}`}
        onClick={() => {
          handleItemClick('catalog');
        }}
      >
        <GridViewIcon />
        <span>Catalog</span>
      </div>
      <div
        className={`${'footer-item'} ${activeItem === 'enquiry' ? 'active' : ''}`}
        onClick={() => {
          handleItemClick('enquiry');
          history.push("/enquiries");
        }}
      >
        <NoteAddOutlinedIcon />
        <span>Enquiry</span>
      </div>
      <div
        className={`${'footer-item'} ${activeItem === 'cart' ? 'active' : ''}`}
        onClick={() => {
          handleItemClick('cart');
          history.push("/checkout");
        }}
      >
        <ShoppingCartOutlinedIcon />
        <span>Cart</span>
      </div>
      <div
        className={`${'footer-item'} ${activeItem === 'more' ? 'active' : ''}`}
        onClick={() => handleItemClick('more')}
      >
        <MenuOutlinedIcon />
        <span>More</span>
      </div>
    </div>
  );
};

export default MobileFooter;