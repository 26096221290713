import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const Shipping = (props) => {
  const [modal, setModal] = useState(false);
  const [saveAddress, setSaveAddress] = useState(true);

  const ModalAddNewAddress = (props) => {
    const toggle = () => {
      setModal(!modal);
      setSaveAddress(false);
    };

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modal}
          toggle={toggle}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Add new address</ModalHeader>
          <Typography
            className=""
            style={{ textAlign: "left", marginLeft: "10px" }}
          >
            <p>*indicates required fields</p>
          </Typography>
          <ModalBody style={{ padding: "20px" }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="suburb">Suburb</Label>
                  <Input
                    type="text"
                    name="suburb"
                    id="suburb"
                    placeholder="Suburb"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="state">State</Label>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    placeholder="State"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="postCode">Post Code</Label>
                  <Input
                    type="text"
                    name="postCode"
                    id="postCode"
                    placeholder="Post Code"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <Input
                    type="text"
                    name="country"
                    id="country"
                    placeholder="Country"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button color="primary" onClick={toggle}>
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  return (
    <Fragment>
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container">
          {ModalAddNewAddress()}
          <Typography className="" style={{ textAlign: "left" }}>
            <h3>Shippings</h3>
            <p>*indicates required fields</p>
          </Typography>
          <Row>
            <Col xs="8" sm="8">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "left" }}>
                      Choose delivery of collection meethod
                    </CardTitle>
                    {saveAddress ? (
                      <Row>
                        <Col>
                          <Card style={{ textAlign: "left" }}>
                            <CardTitle tag="h5">Click & Collect</CardTitle>

                            <Row>
                              <Col>
                                <FormGroup check>
                                  <Label check style={{ fontSize: "12px" }}>
                                    <Input type="checkbox" />
                                    55 Bond St, Sydney NSW 2000
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col style={{ textAlign: "right" }}>
                                <Button
                                  style={{ textAlign: "right" }}
                                  color="light"
                                  size="sm"
                                >
                                  Instock
                                </Button>
                              </Col>
                            </Row>

                            <hr
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <Row>
                              <Col>
                                <FormGroup check>
                                  <Label check style={{ fontSize: "12px" }}>
                                    <Input type="checkbox" />0 Bond St, Sydney
                                    NSW 2000
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col style={{ textAlign: "right" }}>
                                <Button
                                  style={{ textAlign: "right" }}
                                  color="light"
                                  size="sm"
                                >
                                  Instock
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col>
                          <Card>
                            <CardTitle tag="h5" style={{ textAlign: "left" }}>
                              Delivery
                            </CardTitle>
                            <FormGroup check>
                              <Label
                                check
                                style={{ textAlign: "left", fontSize: "12px" }}
                              >
                                <Input type="checkbox" /> Mary McDonald 101/90
                                Mary St, Surry Hills New South Wales 2000
                                Australia
                              </Label>
                            </FormGroup>
                            <Button
                              style={{ textAlign: "right" }}
                              color="light"
                              size="sm"
                            >
                              Edit
                            </Button>
                            <br />
                            <Button
                              style={{ textAlign: "right" }}
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setModal(true);
                              }}
                            >
                              Add new address
                            </Button>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Card style={{ textAlign: "left" }}>
                            <CardTitle tag="h5">Delivery to:</CardTitle>

                            <Row>
                              <Col>
                                <FormGroup check>
                                  <Label check style={{ fontSize: "12px" }}>
                                    Mary McDonald 101/90 Mary St, Surry Hills
                                    New South Wales 2000 Australia
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col style={{ textAlign: "right" }}>
                                <Button
                                  style={{ textAlign: "right" }}
                                  color="light"
                                  size="sm"
                                >
                                  Change
                                </Button>
                              </Col>
                            </Row>
                          </Card>
                          <br />
                          <Card style={{ textAlign: "left" }}>
                            <CardTitle tag="h5">Delivery Options:</CardTitle>

                            <Table>
                              <tbody>
                                <tr>
                                  <td>AED12.99</td>
                                  <td>Delivery Today</td>
                                  <td>
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="radio" name="radio1" />{" "}
                                      </Label>
                                    </FormGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <td>AED7.99</td>
                                  <td>Delivery Next day</td>
                                  <td>
                                    {" "}
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="radio" name="radio1" />{" "}
                                      </Label>
                                    </FormGroup>
                                  </td>
                                </tr>
                                <tr>
                                  <td>AED5.99 </td>
                                  <td>
                                    Regular Shipping
                                    <Typography className="mb-2 text-muted">
                                      Delivered on or before Monday, 8 March
                                      2021 i No shipping on Public holiday
                                    </Typography>
                                  </td>
                                  <td>
                                    {" "}
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="radio" name="radio1" />{" "}
                                      </Label>
                                    </FormGroup>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col sm="4">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "left" }}>
                      Order Summary
                    </CardTitle>
                    <div style={{ padding: "" }}>
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Subtotal
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          76.40$
                        </p>
                      </div>
                      <br />
                      <br />
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Discount
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          36.12$
                        </p>
                      </div>

                      <br />
                      <br />

                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>Tax</p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          1.12$
                        </p>
                      </div>
                      <br />
                      <br />

                      <div>
                        <p style={{ textAlign: "left", fontWeight: "bold" }}>
                          Shipping
                        </p>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy A car parts
                        </Typography>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy B car parts
                        </Typography>
                      </div>
                      <br />
                      <div>
                        <FormGroup>
                          <Input
                            style={{ float: "left", width: "70%" }}
                            placeholder="Gift card/Discount code"
                            type="text"
                            name="text"
                            id="text"
                          />
                        </FormGroup>
                        <Button
                          style={{ float: "right" }}
                          color="primary"
                          size="sm"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <p style={{ float: "left", fontWeight: "bold" }}>Total</p>
                      <p style={{ float: "right", fontWeight: "bold" }}>
                        40.45$
                      </p>
                    </div>
                    <br />

                    <br />
                    <div>
                      <Button color="primary" style={{ width: "100%" }}>
                        Make a payment
                      </Button>
                    </div>
                  </CardBody>
                  <Alert
                    color="warning"
                    style={{
                      padding: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Free standard shipping to U.A.E. when you spend over AED100.00
                  </Alert>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(Shipping);
