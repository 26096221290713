const initState = {
    addresses: [],
    singleAddress: null,
    isSingleAddressLoading: false,
    isAddressLoading: false,
    isAddressUpdating: false,
    addressFee: {}
}

const AddressReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_ADDRESS_BEGIN':
            return {
                ...state,
                isAddressLoading: true,
            }
        case 'GET_ALL_ADDRESS_SUCCESS':
            return {
                ...state,
                addresses: action?.data,
                isAddressLoading: false,
            }
        case 'GET_ALL_ADDRESS_ERROR':
            return {
                ...state,
                isAddressLoading: false,
            }
        case 'GET_SINGLE_ADDRESS_BEGIN':
            return {
                ...state,
                isSingleAddressLoading: true
            }
        case 'GET_SINGLE_ADDRESS_SUCCESS':
            return {
                ...state,
                isSingleAddressLoading: false,
                singleAddress: action?.data
            }
        case 'GET_SINGLE_ADDRESS_ERROR':
            return {
                ...state,
                isSingleAddressLoading: false,
            }
        case 'UPDATING_ADDRESS_BEGIN':
            return {
                ...state,
                isAddressUpdating: true
            }
        case 'UPDATING_ADDRESS_SUCCESS':
            return {
                ...state,
                isAddressUpdating: false,
            }
        case 'UPDATING_ADDRESS_ERROR':
            return {
                ...state,
                isAddressUpdating: false,
            }
        case 'GET_FEE_ADDRESS_BEGIN':
            return {
                ...state,
                isAddressLoading: true,
            }
        case 'GET_FEE_ADDRESS_SUCCESS':
            return {
                ...state,
                addressFee: action?.data,
                isAddressLoading: false,
            }
        default:
            return state;
    }
};
export default AddressReducer;
