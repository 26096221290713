import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";

import SearchLocationInput from "../../../../common/SearchLocationInput";
import { updateUserProfile } from "../../../../../Store/action/userAction";
import { isValidObject, popUp, validateFile } from "../../../../../utils";
import { emiratesOptions } from "../../../../../constants";
import { uploadFileToAssets } from "../../../../../services/upload";
import UploadProfileImg from "./UploadProfileImg";

const SellerProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const validator = useRef(new SimpleReactValidator());

    const [avatar, setAvatar] = useState("");
    const [details, setDetails] = useState({});

    const [stateData, setStateData] = useState([]);

    const [submitLoading, setSubmitLoading] = useState(false);

    const [, forceUpdate] = useState();

    const { login } = useSelector((state) => {
        return {
            login: state.login
        }
    });

    //GETTING LIST OF REGIONS
    useEffect(() => {
        axios
            .get("/api/products/get-regions")
            .then((res) => {
                setStateData(res?.data?.regions);
            })
            .catch((e) =>
                popUp(e?.response?.data?.message || "Error while fetching regions"));
    }, []);

    //UPDATING THE FORM STATE
    useEffect(() => {
        let { avatar, details } = login;

        const truecheck = details?.company_address?.includes(",");
        const arr = details?.company_address?.split(",");

        const [area, ...companyAddress] = arr || [];
        const newaddres = companyAddress?.join();

        details = {
            ...details,
            company_address: newaddres,
            company_area: truecheck ? area : null
        }

        setAvatar(avatar);
        setDetails(details);
    }, [login]);

    //SETTING THE STATE ON SELECT INPUT
    const handleSelect = (selectedOption, fieldAttr) => {
        setDetails({
            ...details,
            [fieldAttr.name]: selectedOption.value
        })
    }

    // SETTING THE STATE ON INPUT CHANGE
    const handleChange = (e) => {
        e.preventDefault();

        setDetails({
            ...details,
            [e.target.name]: e.target.value
        });
    }

    //SETTING THE STATE ON ADDRESS FIELD CHANGE
    const setAddress = (address) => {
        setDetails({
            ...details,
            company_address: address
        });
    }

    //SETTING THE STATE ON FILE INPUT CHANGE
    const setSelectedFile = (file, name) => {
        setDetails({
            ...details,
            [name]: file
        });
    }

    //FUNCTION ON UPDATE SUCCESS
    const onSuccess = () => {
        popUp("Profile Updated Successfully !");
        setSubmitLoading(false);
        history.push('/my-dashboard');
    }

    //FUNCTION ON UPDATE FAIL
    const onFail = () => {
        setSubmitLoading(false);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (validator?.current?.allValid()) {
                setSubmitLoading(true);

                let formData = {};
                const { company_area, company_address, ...rest } = details;
                const fulladdress = `${company_area},${company_address}`;

                let detailData = {
                    ...rest,
                    company_address: fulladdress,
                };

                let fileArray = []
                if (isValidObject(avatar))
                    fileArray.push(uploadFileToAssets({ file: avatar, type: 'profile' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: avatar || "",
                    }))

                if (isValidObject(details?.trade_license_upload))
                    fileArray.push(uploadFileToAssets({ file: details?.trade_license_upload, type: 'user' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: details?.trade_license_upload || "",
                    }))

                const [avatarImg, trade_license_upload] = await Promise.all(fileArray);

                detailData = {
                    ...detailData,
                    trade_license_upload: trade_license_upload?._id,
                }

                formData = {
                    avatar: avatarImg?._id,
                    details: detailData
                }

                dispatch(updateUserProfile({
                    formData,
                    userId: login?._id,
                    onSuccess,
                    onFail
                }));
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (error) {
            popUp(error?.message);
            setSubmitLoading(false);
        }
    }

    return (
        <div className="row mt-2">
            <UploadProfileImg
                avatar={avatar}
                setAvatar={setAvatar}
            />

            <div className="col-lg-9 col-md-8">
                <div className="atbd_author_module">
                    <div className="atbd_content_module">
                        <div className="atbd_content_module__tittle_area">
                            <div className="atbd_area_title">
                                <h4>
                                    <span className="la la-user"></span>
                                    My Profile
                                </h4>
                            </div>
                        </div>
                        <div className="atbdb_content_module_contents">
                            <div className="user_info_wrap">
                                <div className="form-group">
                                    <label htmlFor="emirate" className="form-label">
                                        Emirate <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <Select
                                        className={`react-select`}
                                        classNamePrefix="react-select"
                                        name="emirate"
                                        id="emirate"
                                        placeholder="Choose Emirate..."
                                        options={emiratesOptions?.map((emirate) => {
                                            return {
                                                label: emirate,
                                                value: emirate,
                                            };
                                        })}
                                        onChange={handleSelect}
                                        value={{
                                            label: details?.emirate || "",
                                            value: details?.emirate || "",
                                        }}
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "emirate",
                                            details?.emirate,
                                            `required`
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="company-name" className="form-label">
                                        Company Name <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="company-name"
                                        disabled={true}
                                        placeholder="Enter Company Name"
                                        value={details?.company_name || ""}
                                        name="company_name"
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "company_name",
                                            details?.company_name,
                                            `required`
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="company-address" className="form-label">
                                        Company Address <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                name="company_area"
                                                id="company_area"
                                                placeholder="Choose company Area"
                                                options={stateData?.map((type) => {
                                                    return {
                                                        label: type.name,
                                                        value: type.name,
                                                    };
                                                })}
                                                onChange={handleSelect}
                                                value={{
                                                    label: details?.company_area || "",
                                                    value: details?.company_area || "",
                                                }}
                                            />
                                            <div className="text-danger">
                                                {validator.current.message(
                                                    "company_area",
                                                    details?.company_area,
                                                    `required`
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <SearchLocationInput
                                                setAddress={setAddress}
                                                databaseaddress={details?.company_address}
                                            />
                                            <div className="text-danger">
                                                {validator.current.message(
                                                    "company_address",
                                                    details?.company_address,
                                                    `required`
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="trade-license-no" className="form-label">
                                        Trade License No <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="trade-license-no"
                                        placeholder="Enter Trade Licnse No"
                                        value={details?.trade_license_no || ""}
                                        name="trade_license_no"
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "trade_license_no",
                                            details?.trade_license_no,
                                            `required`
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label
                                        htmlFor="trade-license-upload"
                                        className="form-label"
                                    >
                                        Trade License Upload
                                        <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {details?.trade_license_upload &&
                                        typeof details?.trade_license_upload !== "object" && (
                                            <p className="text-ellipse">
                                                <a
                                                    href={details?.trade_license_upload}
                                                    _target="blank"
                                                >
                                                    {details?.trade_license_upload}
                                                </a>
                                            </p>
                                        )}
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="trade-license-upload"
                                        style={{ padding: "10px" }}
                                        onChange={(e) => {
                                            let isLt5M = validateFile(e.target.files[0]);
                                            if (!isLt5M) {
                                                e.target.value = ''
                                                return;
                                            }
                                            setSelectedFile(
                                                e.target.files[0],
                                                "trade_license_upload"
                                            );
                                        }}
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "trade_license_upload",
                                            details?.trade_license_upload,
                                            `required`
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        disabled={
                                            submitLoading === false ? false : true
                                        }
                                        className="btn btn-sm btn-primary listing_submit_btn"
                                        onClick={handleSubmit}
                                    >
                                        {submitLoading && (
                                            <i className="las la-spinner la-spin mr-2"></i>
                                        )}
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SellerProfile