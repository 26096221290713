import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router";
import { getAllListingBrands } from "../../../Store/action/brandActions";
import { Autocomplete, Paper, TextField } from "@mui/material";

const AdvSearchTyreBattery = (props) => {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    const { showMobileSearch, handleBackFromSearch, loginToggle, widthData, heightData, rimSizeData,
        voltageData, ampData, brandData  } = props;
    const [tireKeyword, setTireKeyword] = useState('')
    const [batteryKeyword, setBatteryKeyword] = useState('')
    const [batteryYear, setBatteryYear] = useState('')
    const [tireYear, setTireYear] = useState('')
    const [width, setWidth] = useState('')
    const [rimSize, setRimSize] = useState('')
    const [selectedBrand, setSelectedBrand] = useState([])
    const [seletedBrandBatteries, setSelectedBrandBatteries] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [height, setHeight] = useState('')
    const [voltage, setVoltage] = useState('')
    const [amp, setAmp] = useState('')
    const [filteredCatalogBatteriesBrands, setFilteredCatalogBatteriesBrands] = useState([])

    let {
        brands: allBrands
    } = useSelector((state) => state.brand);

    const dispatch = useDispatch();

    const history = useHistory();

    const { batteriesBrands, tyreBrands } = useSelector(state => {
        return {
            batteriesBrands: state.brand.batteriesBrands,
            tyreBrands: state.brand.tyreBrands
        }
    })

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const batteriesBrandList = useMemo(() => {
        return filteredCatalogBatteriesBrands?.length ? filteredCatalogBatteriesBrands?.map(brand => ({
            label: capitalizeFirstLetter(brand.name),
            value: brand.name,
        })) : [];
    }, [filteredCatalogBatteriesBrands]);

    const tireBrandList = useMemo(() => {
        return tyreBrands?.length ? tyreBrands?.map(brand => ({
            label: brand,
            value: brand,
        })) : [];
    }, [tyreBrands]);

    const handleChangeBrand = (options) => {
        if (!options) {
            setMakes([])
            return;
        }
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrand(brands)
    }

    const handleChangeBrandBatteries = (options) => {
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrandBatteries(brands)
    }

    useEffect(() => {
        setFilteredCatalogBatteriesBrands(allBrands.filter(
            (brand) => brand.category.name === "Battery"
        ));
    }, [allBrands])

    const searchTires = async () => {
        let filterOptions = { type: "tires" };

        if (width) {
            filterOptions = { ...filterOptions, width }
        }
        if (height) {
            filterOptions = { ...filterOptions, height }
        }
        if (rimSize) {
            filterOptions = { ...filterOptions, rim_size: rimSize }
        }
        if (tireYear) {
            filterOptions = { ...filterOptions, tire_year: tireYear }
        }
        if (selectedBrand && selectedBrand.length > 0) {
            filterOptions = { ...filterOptions, brands: selectedBrand }
        }

        const encodedSearch = encodeURIComponent(tireKeyword?.trim())

        history.push({
            pathname: `/all-listings`,
            search: `?type=tires&width=${width}&height=${height}&rimSize=${rimSize}${encodedSearch ? `&search=${encodedSearch}` : ''}`,
            state: {
                search: encodedSearch,
                extraData: filterOptions
            }
        });
    }

    const searchBatteries = async () => {
        let filterOptions = { type: "batteries" }

        if (voltage) {
            filterOptions = { ...filterOptions, voltage }
        }
        if (amp) {
            filterOptions = { ...filterOptions, amp }
        }
        if (batteryYear) {
            filterOptions = { ...filterOptions, battery_year: batteryKeyword }
        }
        if (makes.length > 0) {
            filterOptions = { ...filterOptions, makes }
        }
        if (models.length > 0) {
            filterOptions = { ...filterOptions, models }
        }
        if (seletedBrandBatteries.length > 0) {
            filterOptions = { ...filterOptions, brands: seletedBrandBatteries }
        }
        const encodedSearch = encodeURIComponent(batteryKeyword?.trim())
        history.push({
            pathname: `/all-listings`,
            search: `?type=batteries?&voltage=${voltage}&amp=${amp}&brand=${encodedSearch}${encodedSearch ? `&search=${encodedSearch}` : ''}`,
            state: {
                brand: encodedSearch,
                search: encodedSearch,
                extraData: filterOptions
            }
        });
    }

    useEffect(() => {
        dispatch(getAllListingBrands({
            filter: "?type=tires",
            type: "GET_TIRE_BRAND_SUCCESS"
        }));

        dispatch(getAllListingBrands({
            filter: "?type=batteries",
            type: "GET_BATTERY_BRAND_SUCCESS"
        }));
    }, []);

    const handleSelection = (event, selectedValue) => {
        selectedValue && selectedValue.value ? setBatteryKeyword(selectedValue.value) : setBatteryKeyword('')
    };

    useEffect(() => {
        setWidth(widthData);
        setHeight(heightData);
        setRimSize(rimSizeData);
        setVoltage(voltageData);
        setAmp(ampData);
        console.log(brandData, '&&&&&&&&&&&&77')
        setSelectedBrand(brandData);
    }, [])

    return (
        <div className="directory_content_area">
            <div className="col-md-12" style={{ padding: 0 }} >
                {props.title === "Tires" && (
                    showMobileSearch
                        ?
                        <form className="search_img display-flex">
                            <div className="col-md-12 no-padding">
                                <select className="form-control"
                                    name="width"
                                    onChange={(e) => setWidth(e.target.value)}
                                    value={width}>
                                    <option value=''># Width</option>
                                    <option value={7}>7</option>
                                    <option value={7.5}>7.5</option>
                                    <option value={33}>33</option>
                                    <option value={35}>35</option>
                                    <option value={165}>165</option>
                                    <option value={175}>175</option>
                                    <option value={185}>185</option>
                                    <option value={195}>195</option>
                                    <option value={205}>205</option>
                                    <option value={215}>215</option>
                                    <option value={225}>225</option>
                                    <option value={235}>235</option>
                                    <option value={245}>245</option>
                                    <option value={255}>255</option>
                                    <option value={265}>265</option>
                                    <option value={275}>275</option>
                                    <option value={285}>285</option>
                                    <option value={295}>295</option>
                                    <option value={305}>305</option>
                                    <option value={315}>315</option>
                                    <option value={325}>325</option>
                                    <option value={385}>385</option>
                                </select>
                                <select className="form-control"
                                    name="height"
                                    onChange={(e) => setHeight(e.target.value)}
                                    value={height}>
                                    <option value=''># Height</option>
                                    <option value={12}>12</option>
                                    <option value={12.5}>12.5</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                </select>
                                <select className="form-control"
                                    name="rimSize"
                                    onChange={(e) => setRimSize(e.target.value)}
                                    value={rimSize}>
                                    <option value=''># Rim Size</option>
                                    <option value={14}>14</option>
                                    <option value={15}>15</option>
                                    <option value={16}>16</option>
                                    <option value={17}>17</option>
                                    <option value={17.5}>17.5</option>
                                    <option value={18}>18</option>
                                    <option value={19}>19</option>
                                    <option value={20}>20</option>
                                    <option value={21}>21</option>
                                    <option value={22}>22</option>
                                    <option value={22.5}>22.5</option>
                                    <option value={26}>26</option>
                                    <option value={160}>160</option>
                                </select>
                                {
                                    !currentUser
                                        ?
                                        <button
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={loginToggle}
                                        >
                                            SEARCH
                                        </button>
                                        :
                                        <button
                                            id="findTires"
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={searchTires}
                                        >
                                            SEARCH
                                        </button>
                                }
                            </div>
                        </form>
                        :
                        <form className="search_img display-flex">
                            <div className="search_form col-md-10 no-padding search-width">
                                <select className="form-control tyre-batery-input tyre-batery-dropdown"
                                    name="width"
                                    onChange={(e) => setWidth(e.target.value)}
                                    value={width}>
                                    <option value=''># Width</option>
                                    <option value={7}>7</option>
                                    <option value={7.5}>7.5</option>
                                    <option value={33}>33</option>
                                    <option value={35}>35</option>
                                    <option value={165}>165</option>
                                    <option value={175}>175</option>
                                    <option value={185}>185</option>
                                    <option value={195}>195</option>
                                    <option value={205}>205</option>
                                    <option value={215}>215</option>
                                    <option value={225}>225</option>
                                    <option value={235}>235</option>
                                    <option value={245}>245</option>
                                    <option value={255}>255</option>
                                    <option value={265}>265</option>
                                    <option value={275}>275</option>
                                    <option value={285}>285</option>
                                    <option value={295}>295</option>
                                    <option value={305}>305</option>
                                    <option value={315}>315</option>
                                    <option value={325}>325</option>
                                    <option value={385}>385</option>
                                </select>
                                <select className="form-control tyre-batery-input tyre-batery-dropdown"
                                    name="height"
                                    onChange={(e) => setHeight(e.target.value)}
                                    value={height}>
                                    <option value=''># Height</option>
                                    <option value={12}>12</option>
                                    <option value={12.5}>12.5</option>
                                    <option value={30}>30</option>
                                    <option value={35}>35</option>
                                    <option value={40}>40</option>
                                    <option value={45}>45</option>
                                    <option value={50}>50</option>
                                    <option value={55}>55</option>
                                    <option value={60}>60</option>
                                    <option value={65}>65</option>
                                    <option value={70}>70</option>
                                    <option value={75}>75</option>
                                    <option value={80}>80</option>
                                    <option value={85}>85</option>
                                </select>
                                <select className="form-control tyre-batery-input tyre-batery-dropdown bdright-0"
                                    name="rimSize"
                                    onChange={(e) => setRimSize(e.target.value)}
                                    value={rimSize}>
                                    <option value=''># Rim Size</option>
                                    <option value={14}>14</option>
                                    <option value={15}>15</option>
                                    <option value={16}>16</option>
                                    <option value={17}>17</option>
                                    <option value={17.5}>17.5</option>
                                    <option value={18}>18</option>
                                    <option value={19}>19</option>
                                    <option value={20}>20</option>
                                    <option value={21}>21</option>
                                    <option value={22}>22</option>
                                    <option value={22.5}>22.5</option>
                                    <option value={26}>26</option>
                                    <option value={160}>160</option>
                                </select>
                            </div>
                            <div className="col-md-2" style={{ paddingRight: '3px' }}>
                                {
                                    !currentUser
                                        ?
                                        <button
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={loginToggle}
                                        >
                                            SEARCH
                                        </button>
                                        :
                                        <button
                                            id="findTires"
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={searchTires}
                                        >
                                            SEARCH
                                        </button>
                                }
                            </div>
                        </form>


                )}
                {props.title === "battery" && (
                    showMobileSearch ?
                        <form className="search_img display-flex">
                            <div className="col-md-12 no-padding">
                                <Autocomplete
                                    sx={{ width: '100%', marginBottom: '1rem' }}
                                    options={batteriesBrandList}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ width: '80%', overflowX: 'hidden', paddingLeft: '10px', paddingBottom: '4px' }}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={params => (
                                        <div ref={params.InputProps.ref}>
                                            <input {...params.inputProps} className="form-control brand-autocomplete"
                                                placeholder="# Brand Name" />
                                        </div>
                                    )}
                                    onChange={handleSelection}
                                />
                                <select className="form-control" style={{ marginBottom: '1rem' }}
                                    name="voltage"
                                    onChange={(e) => setVoltage(e.target.value)}
                                    value={voltage}>
                                    <option value=''># Voltage</option>
                                    <option value={12}>12 Volt</option>
                                </select>
                                <select className="form-control" style={{ marginBottom: '1rem' }}
                                    name="amp"
                                    onChange={(e) => setAmp(e.target.value)}
                                    value={amp}>
                                    <option value=''># Amp.</option>
                                    <option value={12}>12 Amp.</option>
                                    <option value={33}>33 Amp.</option>
                                    <option value={34}>34 Amp.</option>
                                    <option value={35}>35 Amp.</option>
                                    <option value={36}>36 Amp.</option>
                                    <option value={40}>40 Amp.</option>
                                    <option value={43}>43 Amp.</option>
                                    <option value={44}>44 Amp.</option>
                                    <option value={45}>45 Amp.</option>
                                    <option value={50}>50 Amp.</option>
                                    <option value={53}>53 Amp.</option>
                                    <option value={55}>55 Amp.</option>
                                    <option value={60}>60 Amp.</option>
                                    <option value={62}>62 Amp.</option>
                                    <option value={63}>63 Amp.</option>
                                    <option value={65}>65 Amp.</option>
                                    <option value={66}>66 Amp.</option>
                                    <option value={68}>68 Amp.</option>
                                    <option value={70}>70 Amp.</option>
                                    <option value={71}>71 Amp.</option>
                                    <option value={72}>72 Amp.</option>
                                    <option value={74}>74 Amp.</option>
                                    <option value={75}>75 Amp.</option>
                                    <option value={80}>80 Amp.</option>
                                    <option value={85}>85 Amp.</option>
                                    <option value={88}>88 Amp.</option>
                                    <option value={90}>90 Amp.</option>
                                    <option value={95}>95 Amp.</option>
                                    <option value={96}>96 Amp.</option>
                                    <option value={100}>100 Amp.</option>
                                    <option value={105}>105 Amp.</option>
                                    <option value={110}>110 Amp.</option>
                                    <option value={115}>115 Amp.</option>
                                    <option value={120}>120 Amp.</option>
                                    <option value={140}>140 Amp.</option>
                                    <option value={145}>145 Amp.</option>
                                    <option value={150}>150 Amp.</option>
                                    <option value={155}>155 Amp.</option>
                                    <option value={170}>170 Amp.</option>
                                    <option value={180}>180 Amp.</option>
                                    <option value={200}>200 Amp.</option>
                                    <option value={225}>225 Amp.</option>
                                    <option value={230}>230 Amp.</option>



                                </select>
                                {
                                    !currentUser
                                        ?
                                        <button
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={loginToggle}
                                        >
                                            SEARCH
                                        </button>
                                        :
                                        <button id="findBatteries" type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={searchBatteries}
                                        >SEARCH
                                        </button>
                                }
                            </div>
                        </form>
                        :
                        <form className="search_img display-flex">
                            <div className="search_form col-md-10 no-padding search-width">
                                <Autocomplete
                                    sx={{ width: 900 }}
                                    options={batteriesBrandList}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ width: '100%', overflowX: 'hidden' }}>
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={params => (
                                        <div ref={params.InputProps.ref}>
                                            <input {...params.inputProps} className="form-control tyre-batery-input tyre-batery-input-no-border"
                                                placeholder="# Brand Name" />
                                        </div>
                                    )}
                                    onChange={handleSelection}
                                />
                                <select className="form-control tyre-batery-input tyre-batery-dropdown tyre-batery-dropdown-left-border"
                                    name="voltage"
                                    onChange={(e) => setVoltage(e.target.value)}
                                    value={voltage}>
                                    <option value=''># Voltage</option>
                                    <option value={12}>12 Volt</option>
                                </select>

                                <select className="form-control tyre-batery-input tyre-batery-dropdown bdright-0"
                                    name="amp"
                                    onChange={(e) => setAmp(e.target.value)}
                                    value={amp}>
                                    <option value=''># Amp.</option>
                                    <option value={12}>12 Amp.</option>
                                    <option value={33}>33 Amp.</option>
                                    <option value={34}>34 Amp.</option>
                                    <option value={35}>35 Amp.</option>
                                    <option value={36}>36 Amp.</option>
                                    <option value={40}>40 Amp.</option>
                                    <option value={43}>43 Amp.</option>
                                    <option value={44}>44 Amp.</option>
                                    <option value={45}>45 Amp.</option>
                                    <option value={50}>50 Amp.</option>
                                    <option value={53}>53 Amp.</option>
                                    <option value={55}>55 Amp.</option>
                                    <option value={60}>60 Amp.</option>
                                    <option value={62}>62 Amp.</option>
                                    <option value={63}>63 Amp.</option>
                                    <option value={65}>65 Amp.</option>
                                    <option value={66}>66 Amp.</option>
                                    <option value={68}>68 Amp.</option>
                                    <option value={70}>70 Amp.</option>
                                    <option value={71}>71 Amp.</option>
                                    <option value={72}>72 Amp.</option>
                                    <option value={74}>74 Amp.</option>
                                    <option value={75}>75 Amp.</option>
                                    <option value={80}>80 Amp.</option>
                                    <option value={85}>85 Amp.</option>
                                    <option value={88}>88 Amp.</option>
                                    <option value={90}>90 Amp.</option>
                                    <option value={95}>95 Amp.</option>
                                    <option value={96}>96 Amp.</option>
                                    <option value={100}>100 Amp.</option>
                                    <option value={105}>105 Amp.</option>
                                    <option value={110}>110 Amp.</option>
                                    <option value={115}>115 Amp.</option>
                                    <option value={120}>120 Amp.</option>
                                    <option value={140}>140 Amp.</option>
                                    <option value={145}>145 Amp.</option>
                                    <option value={150}>150 Amp.</option>
                                    <option value={155}>155 Amp.</option>
                                    <option value={170}>170 Amp.</option>
                                    <option value={180}>180 Amp.</option>
                                    <option value={200}>200 Amp.</option>
                                    <option value={225}>225 Amp.</option>
                                    <option value={230}>230 Amp.</option>
                                </select>
                            </div>
                            <div className="col-md-2" style={{ paddingRight: '3px' }}>
                                {
                                    !currentUser
                                        ?
                                        <button
                                            type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={loginToggle}
                                        >
                                            SEARCH
                                        </button>
                                        :
                                        <button id="findBatteries" type="button"
                                            className="btn btn-primary search-btn"
                                            onClick={searchBatteries}
                                        >SEARCH
                                        </button>
                                }
                            </div>
                        </form>
                )}
            </div>
        </div>
    )
}

export default AdvSearchTyreBattery
