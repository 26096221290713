import React, { Component, Fragment, useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { NavLink, Redirect, useHistory } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import Modal from 'react-awesome-modal'
import equal from 'fast-deep-equal'
import {
  AddToCart,
  SetFavouriteListing,
  visitor,
  Initialize,
  setPathname,
} from '../../../../Store/action/listingActions'
import {
  checkAndLogExceedLimit,
  countIdsWithQuantity,
  ga4Events,
  getListingDeliveryTime,
  numberWithCommas,
  popUp,
  ProductImage,
  stringToUrl,
} from '../../../../utils'
import axios from 'axios'
import _ from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import MinimizeIcon from '@material-ui/icons/Minimize'
import AddIcon from '@material-ui/icons/Add'
import NotLoggedInModal from '../modal/not-logged-modal'
import ShowDynamicCurrency from '../../../common/ShowDynamicCurrency'
import ShowDynamicPrice from '../../../common/ShowDynamicPrice'
import TextOverlay from '../../../common/TextOverlay'
import { SkeletonList } from '../../../container/skeleton-listing'
import { CircularProgress, Tooltip } from '@material-ui/core'
import {
  NewQuotation,
  UpdateQuotationItems,
} from '../../../../Store/action/quotationActions'
import { Skeleton } from '@material-ui/lab'

export const Listcard = (props) => {
  const { allCategories } = useSelector((state) => state.category)
  const { quotationItems } = useSelector((state) => state.quotation)
  const { collection, page, itemsInCart } = useSelector((state) => state.list)
  const history = useHistory()
  const [isLogin, setIsLogin] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [cardData, setCardData] = useState({})
  const [cardLoading, setCardLoading] = useState(true)
  const dispatch = useDispatch()

  const list = useSelector((state) => state.list)
  const {
    istecAll,
    user,
    pic,
    isForRequest,
    imageArray,
    partName,
    partSKU,
    partBrand,
    price,
    priceExclVat,
    _id,
    date,
    url,
    title,
    badge,
    link,
    api,
    onreceivestate,
    description,
    addToCart,
    setPathname,
    login,
    type,
    quantity,
    business_model,
    category,
    variant,
    deliveryTime,
    aliasName
  } = cardData

  useEffect(() => {
    setCardData(props)
  }, [props._id])

  useEffect(() => {
    setCardLoading(false)
  }, [cardData])

  const handleGa4Event = (action, categoryName) => {
    const findCategory =
      allCategories?.length !== 0 &&
      allCategories?.find((c) => c?._id === category)

    const product_sku =
      action ===
        `${page === 'collection' ? 'collection_plp' : 'plp'}_sku_copied`
        ? partSKU
        : undefined

    let gaEvent = {
      action,
      category: categoryName,
      product_id: _id,
      product_name: partName,
      product_category: findCategory?.name,
      product_brand: partBrand,
      product_variant: variant,
      product_sku,
    }

    if (collection?._id) {
      gaEvent = {
        ...gaEvent,
        collection_id: collection?._id,
        collection_name: collection?.title,
      }
    }

    ga4Events(gaEvent)
  }

  const currentUser = JSON.parse(localStorage.getItem('login'));
  const result = countIdsWithQuantity(itemsInCart);
  const handleAddToCart = (id, quantity) => {
    const itemInCart = result.find(item => item.id === id);
    const isAvailable = checkAndLogExceedLimit(id, result, quantity);

    if (currentUser) {
      const findCategory =
        allCategories?.length !== 0 &&
        allCategories?.find((c) => c?._id === category)

      let data = {
        action: `${page === 'collection' ? 'collection_plp' : 'plp'
          }_add_to_cart`,
        category: 'Cart',
        product_id: _id,
        product_name: partName,
        product_category: findCategory?.name,
        product_brand: partBrand,
        product_variant: variant,
      }

      if (collection?._id) {
        data = {
          ...data,
          collection_id: collection?._id,
          collection_name: collection?.title,
        }
      }
      if ((isAvailable)) {
        popUp("Your cart items quantity exceeded stock quantity.")
      } else {
        addToCart(id, data)
      }
      setPathname && setPathname(history.location.pathname)
      localStorage.setItem(
        'filterPath',
        JSON.stringify(history.location.pathname)
      )
    } else {
      popUp(
        'You need to register as a buyer to be able to make an order on BuyParts24'
      )
      setIsLogin(true)
    }
  }

  const [isCopied, setIsCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }, [isCopied])
  function handleNewQuotation() {
    if (login) {
      handRequestForPrice(
        quotationItems,
        { _id, partName, priceExclVat, qty: 1, pic },
        dispatch
      )
    } else {
      popUp('You need to register as a buyer to be able to request price')
    }
  }
  return (
    !cardLoading && (
      <div className='atbd_single_listing '>
        <NotLoggedInModal
          showModal={isLogin}
          onClose={setIsLogin}
          msg={errMsg}
        />
        <article className='atbd_single_listing_wrapper'>
          <figure className='atbd_listing_thumbnail_area'>
            <NavLink
              to={`/spare-part-details/${link}`}
              onClick={() =>
                handleGa4Event(
                  `${page === 'collection' ? 'collection_plp' : 'plp'
                  }_sku_image_cta`,
                  'Listing'
                )
              }>
              <div
                className='atbd_listing_image'
                style={{ display: 'flex', position: 'relative' }}>
                <ProductImage avatar={url} alt='Listing' />
                {
                  deliveryTime ?
                    <TextOverlay value={deliveryTime} /> : null
                }
              </div>
            </NavLink>

            <div className='atbd_thumbnail_overlay_content'>
              <ul className='atbd_upper_badge'>
                <li>
                  <span
                    className={
                      'text-capitalize atbd_badge atbd_badge_' +
                      badge?.replace(' ', '_')
                    }>
                    {badge}
                  </span>
                </li>
              </ul>
            </div>
          </figure>
          <div className='atbd_listing_info'>
            <h4 className='atbd_listing_title'>
              <NavLink
                to={`/spare-part-details/${link}`}
                onClick={() =>
                  handleGa4Event(
                    `${page === 'collection' ? 'collection_plp' : 'plp'
                    }_title_cta`,
                    'Listing'
                  )
                }>
                {title}
              </NavLink>
            </h4>
            <div className='rating-group'>
              <div className='d-flex '>
                <button className='btn part-brand'>
                  <NavLink
                    className={'cursur-pointer'}
                    style={{
                      color: 'black',
                    }}
                    to={`/all-listings?brand=${partBrand
                      .toLocaleLowerCase()
                      .replace(' ', '-')}`}
                    onClick={() => {
                      handleGa4Event(
                        `${page === 'collection' ? 'collection_plp' : 'plp'
                        }_brand_click`,
                        'Listing'
                      )
                    }}>
                    <a>{partBrand?.toUpperCase()}</a>
                  </NavLink>
                </button>
              </div>
              <span className='review-value'>{type}</span>
            </div>
            {!istecAll ? (
              <>
                <div className="d-flex justify-content-between align-items-center card__sku-container">
                    <button
                      className='btn part-sku'
                      title={partSKU}
                      onClick={() => {
                        setIsCopied(true)
                        navigator.clipboard.writeText(partSKU)
                        handleGa4Event(
                          `${
                            type === 'collection' ? 'collection_plp' : 'plp'
                          }_sku_copied`,
                          'Listing'
                        )
                      }}>
                      SKU{' '}
                      <span className='part' style={{ marginLeft: '2px' }}>
                        {partSKU}
                      </span>{' '}
                      <span className='ml-2'>|</span>
                      <Tooltip
                        className='ml-1 cursur-pointer'
                        title='SKU number copied to clipboard'
                        placement='top'
                        open={isCopied}
                        arrow
                        style={{minWidth: "24px"}}
                        disableHoverListener>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='18'
                          viewBox='0 0 24 24'
                          width='24'>
                          <path d='M0 0h24v24H0z' fill='none' />
                          <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' />
                        </svg>
                      </Tooltip>
                    </button>
                  {/* <span className="alias-name">{aliasName}</span> */}
                </div>
                <div className='pricing-group'>
                  {quantity && quantity > 0 ? (
                    login ? (
                      <p className='symbol'>
                        <ShowDynamicCurrency />
                        <span className='price'>
                          <ShowDynamicPrice price={priceExclVat} hideExc />
                        </span>
                      </p>
                    ) : (
                      <p className='symbol' style={{ padding: '11px' }}>
                        <span className='price'></span>
                      </p>
                    )
                  ) : (
                    <p></p>
                  )}
                  {quantity && quantity > 0 ? (
                    <button
                      className='btn cart-btn'
                      disabled={login?.role == 'SELLER' || list.isAddToCartLoading}
                      onClick={() => {
                        handleAddToCart(_id, quantity)
                      }}>
                      Add To Cart
                    </button>
                  ) : (
                    <div
                      style={{
                        marginTop: !login && '10px',
                      }}>
                      <button
                        onClick={handleNewQuotation}
                        className='btn cart-btn'
                        style={{
                          marginBottom: !login ? '10px' : '20px',
                          backgroundColor: '#1d71b8',
                          color: 'white',
                        }}>
                        {'Request For Price'}
                      </button>
                    </div>
                  )}
                </div>
                <span
                  style={{
                    fontSize: 10,
                    fontWeight: 600,
                    margin: 0,
                    padding: 0,
                  }}
                  className='text-muted'>
                  {quantity > 0 && login ? 'Exc.VAT' : ' '}
                </span>
              </>
            ) : (
              <>
                <p style={{ fontSize: 12, fontWeight: 600 }}>
                  SKU{' '}
                  <span
                    style={{
                      color: 'black',
                      fontSize: 12,
                      fontWeight: 600,
                    }}>
                    {partSKU}
                  </span>
                </p>
                <div
                  className='price-group'
                  style={{ justifyContent: 'flex-end' }}>
                  <button
                    className={
                      !isForRequest ? 'btn cart-btn' : 'btn cart-btn d-none'
                    }
                    disabled={login?.role == 'SELLER'}
                    onClick={() => {
                      if (login === null) {
                        return history.push('/register')
                      }
                      onreceivestate(
                        pic,
                        imageArray,
                        partName,
                        partSKU,
                        price,
                        _id,
                        date,
                        url,
                        title,
                        badge,
                        link,
                        api,
                        description
                      )
                    }}>
                    On Request
                  </button>
                </div>
              </>
            )}
          </div>
        </article>
      </div>
    )
  )
}

export const ListcardForAPI = (props) => {
  const history = useHistory()
  const [stars, setStars] = useState(0)
  const { quotationItems } = useSelector((state) => state.quotation)
  const [errMsg, setErrMsg] = useState('')
  const dispatch = useDispatch()
  const {
    pic,
    isForRequest,
    imageArray,
    partName,
    partSKU,
    price,
    priceExclVat,
    quantity,
    _id,
    date,
    url,
    title,
    badge,
    link,
    api,
    onreceivestate,
    description,
    addToCart,
    login,
    type,
    setPathname,
  } = props

  useEffect(() => {
    _id &&
      axios.get(`/api/review/${_id}`).then((data) => {
        let stars = data.data.reduce((total, { star }) => total + star, 0)
        setStars(data.data.length > 0 ? stars / data.data.length : 0)
      })
  }, [_id])
  function handleNewQuotation() {
    handRequestForPrice(
      quotationItems,
      { _id, partName, priceExclVat, qty: 1, pic },
      dispatch
    )
  }
  return (
    <div className='atbd_single_listing '>
      <article className='atbd_single_listing_wrapper'>
        <figure className='atbd_listing_thumbnail_area'>
          <div className='atbd_listing_image'>
            <NavLink to={`/spare-part-details/${link}`}>
              <img src={url || '/assets/img/byparts.jpg'} alt='Listing' />
            </NavLink>
          </div>
          <div className='atbd_thumbnail_overlay_content'>
            <ul className='atbd_upper_badge'>
              <li>
                <span
                  className={
                    'text-capitalize atbd_badge atbd_badge_' +
                    badge.replace(' ', '_')
                  }>
                  {badge}
                </span>
              </li>
            </ul>
          </div>
        </figure>
        <div className='atbd_listing_info'>
          <h4 className='atbd_listing_title'>
            <NavLink to={`/spare-part-details/${link}`}>{title}</NavLink>
          </h4>
          <div className='rating-group'>
            <div className='d-flex'>
              {stars < 1 ? (
                <span>No Reviews Yet</span>
              ) : (
                <>
                  <StarRatingComponent
                    name='rate2'
                    editing={false}
                    renderStarIcon={() => <i className='la la-star' />}
                    renderStarIconHalf={() => (
                      <i
                        className='la la-star-half-alt'
                        style={{ color: '#ffb400' }}
                      />
                    )}
                    starColor='#ffb400'
                    emptyStarColor={'#cecece'}
                    starCount={5}
                    value={parseInt(stars)}
                  />
                  <span className='rating-value'>
                    {parseFloat(stars).toFixed(1)}
                  </span>
                </>
              )}
            </div>
            <span className='review-value text-muted'>{type}</span>
          </div>
          {api == 'false' ? (
            <div className='price-group'>
              {quantity && quantity > 0 ? (
                <>
                  <p className='symbol mr-1'>
                    AED<span className='price'>{numberWithCommas(price)}</span>
                  </p>
                  <button
                    className='btn cart-btn'
                    onClick={() => {
                      addToCart(_id)
                      setPathname(history.location.pathname)
                    }}
                    disabled={login?.role == 'SELLER'}>
                    Add
                  </button>
                </>
              ) : (
                <p
                  onClick={handleNewQuotation}
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    margin: 2,
                    backgroundColor: '#1d71b8',
                    color: 'white',
                  }}>
                  Request For Price
                </p>
              )}
            </div>
          ) : (
            <div className='price-group' style={{ justifyContent: 'flex-end' }}>
              <button
                className={
                  !isForRequest ? 'btn cart-btn' : 'btn cart-btn d-none'
                }
                disabled={login?.role == 'SELLER'}
                onClick={() => {
                  if (login === null) {
                    return history.push('/register')
                  }
                  onreceivestate(
                    pic,
                    imageArray,
                    partName,
                    partSKU,
                    price,
                    _id,
                    date,
                    url,
                    title,
                    badge,
                    link,
                    api,
                    description
                  )
                }}>
                On Request
              </button>
            </div>
          )}
        </div>
      </article>
    </div>
  )
}

export const CardModal = (props) => {
  const {
    pic,
    imageArray,
    partName,
    partSKU,
    price,
    _id,
    date,
    url,
    title,
    badge,
    link,
    api,
    description,
    callbackClose,
    modalstatus,
    login,
    apiSendNo,
    apiopenmodal,
    apicloseModal,
    startChat,
    listing_user,
  } = props

  const submit = (SKU, user, quantity, partSKU, partName) => {
    setDisabled(true)
    let data = {
      partSKU: partSKU,
      quantity: quantity,
      user: user,
      endNo: SKU,
      partName: partName,
    }

    axios
      .post('/api/request/on-request', data)
      .then((res) => {
        popUp('Successfully Sent Request')
        callbackClose()
        setDisabled(false)
        setQuantity(1)
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        popUp('Failed To Sent Request')
        callbackClose()
        setDisabled(false)
      })
  }
  const handleClick = () => {
    callbackClose()
    apiopenmodal()
  }
  const [quantity, setQuantity] = useState(1)
  const [disabled, setDisabled] = useState(false)

  return (
    <Modal visible={modalstatus} width='600' height='440' effect='fadeInUp'>
      <div className='d-flex justify-content-start'>
        <button
          className='btn btn-default'
          style={{ fontSize: 18 }}
          onClick={callbackClose}>
          X
        </button>
      </div>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <div className='modal-vin'>
        <div className='row'>
          <div className='col-md-5'>
            <div style={{ height: 200, width: 250, padding: 10 }}>
              <ListcardForAPI
                api={api}
                login={login}
                isForRequest={true}
                url={url}
                title={title}
                badge={badge}
                link={link}
                pic={pic}
                imageArray={imageArray}
                partName={partName}
                partSKU={partSKU}
                price={price}
                _id={_id}
                date={date}
                description={description}
              />
            </div>
          </div>
          <div className='col-md-7'>
            <div className='mt-1'>
              <div className='atbdb_content_module_contents'>
                <h1>{partName}</h1>
              </div>
              <div className='right-modal mt-2 mb-2'>
                <label style={{ marginLeft: 4 }}>Add quantity</label>
                <div
                  className='d-flex justify-content-between'
                  style={{ marginTop: '-10px', marginLeft: '20px' }}>
                  <div className='w-50 d-flex justify-content-around align-items-center p-1'>
                    <button
                      disabled={quantity <= 1}
                      className='btn checkout-qty border'
                      onClick={() => setQuantity(quantity - 1)}>
                      <MinimizeIcon
                        style={{ fontSize: 18, marginBottom: 10 }}
                      />
                    </button>
                    <span
                      className='border h-100 w-500 justify-content-center d-flex align-items-center'
                      style={{ width: 300, paddingLeft: 30, paddingRight: 30 }}>
                      {quantity}
                    </span>
                    <button
                      className='btn checkout-qty border'
                      onClick={(e) => setQuantity(quantity + 1)}>
                      <AddIcon style={{ fontSize: 18, marginTop: '-3px' }} />
                    </button>
                  </div>
                </div>
              </div>
              <div className='d-flex ml-0'>
                <button
                  disabled={disabled}
                  onClick={() =>
                    submit(apiSendNo, login?._id, quantity, partSKU, partName)
                  }
                  className='btn btn-primary btn-xs'
                  style={{ marginTop: 10, marginLeft: 2 }}>
                  Send Request
                </button>
                {login && listing_user && (
                  <button
                    className='btn btn-secondary btn-xs'
                    style={{ marginTop: 10, marginLeft: 5 }}>
                    <NavLink to='/messages' style={{ color: '#fff' }}>
                      Send Message
                    </NavLink>
                  </button>
                )}

                {!login && (
                  <button
                    onClick={handleClick}
                    className='btn btn-secondary btn-xs'
                    style={{ marginTop: 10, marginLeft: 5 }}>
                    Send Messages
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

class CardListingGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalstatus: false,
      dataPerPage: 20,
      page: 1,
      pic: '',
      imageArray: '',
      partName: '',
      partSKU: '',
      price: '',
      _id: '',
      date: '',
      url: '',
      title: '',
      badge: '',
      link: '',
      api: '',
      quantity: 1,
      description: '',
      apiSendNo: '',
      listingItem: [],
    }
    this.handleOnReq = this.handleOnReq.bind(this)
    this.modalclose = this.modalclose.bind(this)
    this.handlePagination = this.handlePagination.bind(this)
  }

  handleOnReq = (
    pic,
    imageArray,
    partName,
    partSKU,
    price,
    _id,
    date,
    url,
    title,
    badge,
    link,
    api,
    description,
    partBrand
  ) => {
    if (this.props.login) {
      const info = partSKU.split('-')
      const carID = info[info.length - 1]

      this.setState({
        modalstatus: true,
        pic: pic,
        imageArray: imageArray,
        partName: partName,
        partSKU: partSKU,
        price: price,
        _id: _id,
        date: date,
        url: url,
        title: title,
        badge: badge,
        link: link,
        api: api,
        description: description,
        apiSendNo: carID,
      })
    } else {
      return <Redirect to='/register' />
    }
  }
  modalclose = () => {
    this.setState({
      modalstatus: false,
    })
  }

  componentDidMount() {
    this.setState({
      page: 1,
      searchQuery: this.props.searchQuery,
      listingItem: this.props.list.listing || [],
    })
  }

  handleSearch() {
    const currentUser = JSON.parse(localStorage.getItem('login'))
    // || (currentUser.status === "Pending" && currentUser.KYCStatus)
    if (!currentUser && this.props.list.count > 3) {
      return
    }
    const { dataPerPage, page } = this.state
    // this.props.initializeListing({...this.props?.list?.filterQuery,page, perPage:dataPerPage}, this.props?.list?.count);
    this.props.handleFilter(
      { ...this.props?._searchQuery, page, perPage: dataPerPage },
      'pagination'
    )
  }

  handlePagination(e, page) {
    this.setState({ page: page }, () => {
      this.handleSearch()
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {

    // if (!equal(this.props._searchQuery, prevProps.list.searchQuery)) {
    //   this.setState({page: 1})
    // }

    if (prevProps.list.listing !== this.props.list.listing) {
      const currentUser = JSON.parse(localStorage.getItem('login'))
      if (!currentUser && this.props.list.count > 3) {
        return
      }
      this.setState({ listingItem: this.props.list.listing }, () => {
        if (!currentUser) {
          this.props.visitor(this.props.list.count)
        }
      })
    }
  }

  render() {
    const { listingItem, dataPerPage, page } = this.state
    const { isLoading, listing, totalCount, isAfterloading, isAddToCartLoading } = this.props.list
    let mpu_sidebar_category_banner = this.props?.list.bannersList?.filter((banner) =>
      banner.category === 'mpu_sidebar_category_banner')
      ?.sort((a, b) => a.position - b.position)

    return (
      <Fragment>
        {(isLoading) ? (
          <SkeletonList isDiv />
        ) : listing?.length > 0 ? (
          <Fragment>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {!isLoading &&
                listingItem.map((value, index) => {
                  let {
                    istecAll,
                    user,
                    pic,
                    imageArray,
                    partName,
                    partSKU,
                    taImages,
                    price,
                    priceExclVat,
                    _id,
                    date,
                    description,
                    type,
                    quantity,
                    business_model,
                    oemNumbers,
                    partBrand,
                    category,
                    variant,
                    tradeSoftImages
                  } = value
                  // console.log(value, "value")
                  const title = `${partName}`
                  const badge = ''
                  if (!imageArray?.length) {
                    if (type === "Aftermarket") {
                      imageArray =
                        taImages
                          ?.map((obj) => obj['imageURL400'] || obj['imageURL800'])
                          .filter((i) => i) || []
                    }

                    if (type === "Genuine") {
                      imageArray = tradeSoftImages;
                    }

                  }
                  const link =
                    stringToUrl(partName) +
                    '--' +
                    stringToUrl(partSKU) +
                    (istecAll ? `?api=true` : `/${_id}`)

                  const deliveryTime = getListingDeliveryTime({
                    business_model,
                    quantity,
                    bmDTs: user?.bmDTs
                  })

                  const aliasName = user?.aliasName;

                  let url = imageArray?.[0] || 'assets/img/byparts.jpg'
                  pic = url
                  return (
                    <>
                      {index === 2 &&
                        mpu_sidebar_category_banner?.length > 0 && (
                          <>
                            {mpu_sidebar_category_banner?.map(
                              (banner, index) => {
                                return (
                                  <div
                                    className='col-md-12 hide-filter'
                                    onClick={() =>
                                      this.handleGaEvent(banner?._id)
                                    }
                                    style={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      backgroundSize: 'cover',
                                      objectPosition: 'center',
                                      objectFit: 'cover',
                                      justifyContent: 'center',
                                      borderRadius: '10px',
                                    }}>
                                    <a href={banner?.banner_link}>
                                      <img
                                        className='top-M-content'
                                        style={{
                                          width: '100%',
                                          borderRadius: '10px',
                                        }}
                                        src={banner?.banner_upload}
                                        alt='banner'
                                      />
                                    </a>
                                  </div>
                                )
                              }
                            )}
                          </>
                        )}
                      <div
                        className={'listing-card-grid'}
                        key={index}
                        style={{ minWidth: 160 }}>
                        <Listcard
                          onreceivestate={(
                            pic,
                            imageArray,
                            partName,
                            partSKU,
                            price,
                            priceExclVat,
                            _id,
                            date,
                            url,
                            title,
                            badge,
                            link,
                            api,
                            description,
                            partBrand
                          ) =>
                            this.handleOnReq(
                              pic,
                              imageArray,
                              partName,
                              partSKU,
                              price,
                              _id,
                              date,
                              url,
                              title,
                              badge,
                              link,
                              api,
                              description,
                              partBrand,
                              priceExclVat
                            )
                          }
                          api={this.props.api}
                          isForRequest={false}
                          url={url}
                          title={title}
                          badge={badge}
                          link={link}
                          pic={pic}
                          imageArray={imageArray}
                          partName={partName}
                          partSKU={partSKU}
                          price={price}
                          priceExclVat={priceExclVat}
                          _id={_id}
                          date={date}
                          istecAll={istecAll}
                          oemNumbers={oemNumbers}
                          user={user}
                          description={description}
                          login={this.props.login}
                          addToCart={this.props.addToCart}
                          type={type}
                          quantity={quantity}
                          business_model={business_model}
                          currencyObj={this.props.currency}
                          partBrand={partBrand}
                          category={category}
                          variant={variant}
                          handleFilter={this.props.handleFilter}
                          searchQuery={this.props._searchQuery}
                          handleFilteredData={this.props.handleFilteredData}
                          handleBrandclick={this.props.handleBrandclick}
                          index={index}
                          deliveryTime={deliveryTime}
                          aliasName={aliasName}
                          isAddToCartLoading={isAddToCartLoading}
                        />{' '}
                      </div>
                    </>
                  )
                })}

              {isAfterloading && <SkeletonList />}
            </div>
            <CardModal
              callbackClose={this.modalclose}
              modalstatus={this.state.modalstatus}
              apiSendNo={this.state.apiSendNo}
              api={this.props.api}
              isForRequest={false}
              url={this.state.url}
              title={this.state.title}
              badge={this.state.badge}
              link={this.state.link}
              pic={this.state.pic}
              imageArray={this.state.imageArray}
              partName={this.state.partName}
              partSKU={this.state.partSKU}
              price={this.state.price}
              _id={this.state._id}
              date={this.state.date}
              description={this.state.description}
              login={this.props.login}
            />
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='mt-2'>
                  <Pagination
                    count={Math.ceil(totalCount / dataPerPage)}
                    variant='outlined'
                    shape='rounded'
                    page={page}
                    onChange={this.handlePagination}
                    style={{ marginTop: '10px' }}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ) : isAfterloading ? (
          <SkeletonList isDiv />
        ) : (
          <Fragment>
            <div className='col-lg-12 text-center mt-5'>
              <div
                className='d-flex'
                style={{ width: '57%', margin: '0 auto' }}>
                <p
                  className='display-4'
                  style={{ margin: 'auto', marginRight: '2rem' }}>
                  <span
                    className=' display-3 font-weight-bolder'
                    style={{ color: '#1a0451', fontWeight: '800' }}>
                    Sorry
                  </span>
                  <br />{' '}
                  <span style={{ fontSize: '27px' }}>
                    We can't find any spare parts for your search
                  </span>
                </p>
                <img
                  src='/assets/img/no_results.png'
                  className='mt-4'
                  alt='empty'></img>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
    currency: state.currency,
    settings: state.settings,
    isLoad: state.list.isLoading,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    setFavouriteListing: (e, id) => dispatch(SetFavouriteListing(e, id)),
    initializeListing: (data, data1) => dispatch(Initialize(data, data1)),
    addToCart: (data, eventData) => dispatch(AddToCart(data, eventData)),
    visitor: (data) => dispatch(visitor(data)),
    setPathname: (pathname) => dispatch(setPathname(pathname)),
  }
}

export default connect(mapStateToProps, mapDispatchToProp)(CardListingGrid)

export const handRequestForPrice = (quotationItems, data, dispatch) => {
  const index = quotationItems?.findIndex((x) => x?.listingId === data?._id)
  if (index < 0) {
    quotationItems.push({
      listingId: data._id,
      name: data.partName,
      priceExclVat: data.priceExclVat,
      qty: 1,
      pic: data.pic,
    })
    dispatch(NewQuotation({ quotationItems }))
    popUp('You have request for price, view it now?', 'View', '/quotations')
  } else {
    quotationItems[index].qty = quotationItems[index].qty + 1
    popUp(' Quotation modified, view it now?', 'View', '/quotations')
  }
  dispatch(UpdateQuotationItems({ quotationItems }))
  return
}
