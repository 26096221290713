import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";


export default function PolicyAggremementModalOnProfile() {
  const [opt, setOpt] = useState({})
  const { login } = useSelector((state) => {
    return {
      login: state.login
    };
  });

  const getOpt = async (user_id) => {
    try {
      let res = await axios.get(`/api/opt/${user_id}`)
      setOpt(res.data?.opt)
    } catch (error) {
      console.log({ error })
    }
  }

  const toggleOpt = async () => {
    await axios.put(`/api/opt/${opt?._id}`);
    getOpt(login?._id)
  };

  return (
    <div>
      <div>
        By clicking button below, I agree to receive news, offers, updates and
        other promotional communication from and about Buyparts24.com on
        SMS, email phone and social media messaging apps.(eg:WhatsApp,
        telegram, etc.).This will keep you up-to-date with all the latest
        information form Buyparts24.com
      </div>
      Read our <Link to="/terms">Terms & condition</Link> and
      <Link to="/privacy">Privacy policy</Link> here.
      {login?._id && (
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <button disabled={opt?.optin ? true : false} onClick={toggleOpt} className="btn btn-primary text-center pt-3 mr-3">
            <p style={{ lineHeight: '10px' }}>Agree</p>
          </button>
          <button disabled={opt?.optout ? true : false} onClick={toggleOpt} className="btn btn-secondary text-center text-white pt-3">
            <p style={{ lineHeight: '10px' }}>Disagree</p>
          </button>
        </div>
      )}
    </div>
  );
}
