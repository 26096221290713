import React from "react";
import { ToWords } from "to-words";
import { formatPhoneNumber } from "../../../../utils";
import "./style.css";

const Index = ({ data, groupproducts: _groupproducts }) => {
  const [groupproducts, setGroupproducts] = React.useState([]);

  React.useEffect(() => {
    let filteredProducts = [];
    _groupproducts &&
      _groupproducts.length > 0 &&
      _groupproducts.forEach((p) => {
        p.data.forEach((item) => {
          if (item.status !== "cancelled") {
            filteredProducts.push(item);
          }
        });
      });
    setGroupproducts(filteredProducts);
  }, [_groupproducts]);

  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  const getTax = (amount, tax) => {
    return Math.ceil(amount - (amount/1.05));
  };

  const toWords = new ToWords();
  return (
    <div>
      <div className="invoice-tax-header">
        <div className="company_logo">
          <img
            src="/assets/img/logo.svg"
            alt="buyParts"
            style={{ marginRight: "2rem", height: "50px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div
          className="location-details"
          style={{ width: "42%", fontSize: "11px" }}
        >
          <div>Location</div>
          {data?.user?.details?.garage_address}
        </div>
        <div className="tax-title">Tax Invoice</div>
      </div>

      <div className="users-details">
        <div className="left">
          <div className="tax-invoice-to">
            <div className="tax-invoice-title">Invoice To</div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Buyer No.</div>
              <div>{data?.user?._id?.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Buyer Name</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Buyer Vat No.</div>
              <div>{data?.user?.details?.vat_registration_no}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Buyer Address</div>
              <div>{data?.user?.details?.garage_address}</div>
            </div>
          </div>

          <div className="deliver-to">
            <div className="tax-invoice-title">Deliver To</div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text"> Name</div>
              <div>{data?.user?.name}</div>
            </div>

            <div className="tax-info-detail">
              <div className="tax-invoice-text"> Contact</div>
              <div>{formatPhoneNumber(data?.user?.phone)}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text"> Address</div>
              <div>{data?.delivery_address?.address}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text"> Postcode</div>
              <div>{data?.delivery_address?.postcode}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text"> Location</div>
              <div>{data?.delivery_address?.location}</div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="invoice-details-container">
            <div className="tax-invoice-title">Invoice Details</div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Seller Name</div>
              <div>{data?.products[0]?.seller?.name}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Seller TRN</div>
              <div>{data?.products[0]?.seller?.details?.trade_license_no || 'n/a'}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Currency</div>
              <div>{data?.currency || "n/a"}</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Exchange Rate</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Customer No.</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Dcoument No./Date</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Shipment No.</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">So No. /Date</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Sales Person</div>
              <div>n/a</div>
            </div>
            <div className="tax-info-detail">
              <div className="tax-invoice-text">Terms of Payment</div>
              <div>Cash on Delivery</div>
            </div>
          </div>
        </div>
      </div>

      <div className="item-table-container">
        <table className="invoice-tax-table">
          <tr style={{ border: "1px solid black", backgroundColor: "#bdbdbd" }}>
            <th>Sr.No</th>
            <th>Item No.</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Total Price</th>
            <th>5% Tax</th>
            <th>Net Amount</th>
          </tr>
          {groupproducts &&
            groupproducts.length > 0 &&
            groupproducts.map((item, i) => {
              return (
                <tr>
                  <td>{++i}</td>
                  <td style={{whiteSpace: "pre-wrap"}}>{item?.delivery_option?.partSKU}</td>
                  <td>{item?.delivery_option?.partName}</td>
                  <td style={{ textAlign: "center" }}>{item?.quantity}</td>
                  <td style={{ textAlign: "center" }}>
                    {item?.delivery_option?.price}
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {item?.quantity * item?.delivery_option?.price +
                      item?.shipping_price_individual}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {getTax(
                      item?.quantity * item?.delivery_option?.price +
                        item?.shipping_price_individual,
                      0.05
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {item?.quantity * item?.delivery_option?.price +
                      item?.shipping_price_individual }
                  </td>
                </tr>
              );
            })}

          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="3">Total Invoice Value : </td>
            <td style={{ textAlign: "center" }}>
              {groupproducts
                ?.map((p) => p?.quantity)
                .reduce((acc, data) => acc + data, 0)}
            </td>
            <td></td>
            <td style={{ textAlign: "center" }}>
              AED{" "}
              {getPriceComma(
                groupproducts
                  ?.map(
                    (p) =>
                      p?.delivery_option?.price * p?.quantity +
                      p?.shipping_price_individual
                  )
                  .reduce((acc, data) => acc + data, 0)
              )}
            </td>
            <td></td>
            <td style={{ textAlign: "center" }}>
              AED{" "}
              {getPriceComma(
                groupproducts
                  .map(
                    (p) =>
                      p?.delivery_option?.price * p?.quantity +
                      p?.shipping_price_individual 
                  )
                  .reduce((acc, data) => acc + data, 0)
              )}
            </td>
          </tr>
          <tr style={{ border: "1px solid black", fontWeight: "600" }}>
            <td colSpan="2">Amount in Words :</td>
            <td>
              {toWords.convert(
                groupproducts
                  .map(
                    (p) =>
                      p?.delivery_option?.price * p?.quantity +
                      p?.shipping_price_individual 
                  )
                  .reduce((acc, data) => acc + data, 0)
              )}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Index;
