import * as yup from "yup";
import { string as YupString } from "yup";

export const emailValidationSchema = () => {
  let schema = {
    email: yup.string().required("Email is required").email('Invalid Email address, Please enter again'),
  };
  return yup.object().shape(schema);
};

export const phoneValidationSchema = () => {
  let schema = {
    newPassword: YupString()
      .required("New Password is Required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .mixed()
      .required("Confirm Password is Required")
      .test('password-match', 'Confirm Password must be same as New Password', function (value) {
        return value?.trim() === this.parent.newPassword?.trim();
      })
  };
  return yup.object().shape(schema);
};

export const otpValidationSchema = (isOtpByPhone) => {
  let schema = {
    otp: yup.mixed().required("OTP is required"),
    mobileOtp: yup.mixed().required("OTP is required"),
  };
  if (isOtpByPhone) {
    schema = {
      ...schema,
      otp: yup.mixed().nullable(),
    };
  } else {
    schema = {
      ...schema,
      mobileOtp: yup.mixed().nullable(),
    };
  }
  return yup.object().shape(schema);
};

export const otpValidation = () => {
  let schema = {
  };
  return yup.object().shape(schema);
};

