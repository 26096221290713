import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import NewsGrid4 from "../content/element/card/news-blog-grid4";
import { CircularProgress } from "@material-ui/core";

const News = () => {
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    async function fetchMyAPI() {
      let response = await axios.get("api/news/all");
      setNewsData(response?.data);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, []);

  return (
    <Fragment>
      <section
        className="blog-area blog-grid section-padding-strict section-bg"
        style={{ minHeight: "100vh", position: "relative" }}
      >
        <div className="container-fluid section-padding-all-listing">
          <div className="row">
            {!isLoading ? (
              newsData &&
              newsData.length > 0 &&
              newsData.map((item, index) => {
                return <NewsGrid4 blog={item} key={index} />;
              })
            ) : (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default News;
