import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowForward, Height } from "@material-ui/icons";
import { ga4Events } from "../../../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import CardContent from '@mui/material/CardContent';
import './style.css';
import PartsCategory from "./PartsCategory";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  squareTypo: {
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    size: "13px",
    fontWeight: "600",
  },
  customeItem: {
    flexBasic: '50%',
    [theme.breakpoints.up('md')]: {
      flexBasic: '33.33%'
    }
  }
}));

const ItemList = ({ category, subCategory, clickedUser }) => {
  const styles = useStyles();
  let {
    brands,
    lubsbrand,
    filteredCatalogMakeBrands,
    filteredCatalogBatteriesBrands,
    filteredCatalogTyreBrands,
    filteredCatalogLubricantBrands
  } = useSelector((state) => state.brand);
  //let {lubsbrand} = useSelector((state) => state.lubsbrand);
  //let lubsbrand = useSelector((state) => state.lubsbrand);
  //console.log('lubsbrand>>>>>>>>>>' + JSON.stringify(lubsbrand));
  const { allCategories } = useSelector((state) => state.category);
  const [itemToShow, setItemToShow] = useState([]);
  const [itemToShowParts, setItemToShowParts] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [viewAllShow, setViewAllShow] = useState(false);
 
  useEffect(() => {
    if (category === "Make") {
      if (filteredCatalogMakeBrands.length) {
        setItemToShow(filteredCatalogMakeBrands.slice(0, 16));
        filteredCatalogMakeBrands.length > 16
          ? setViewAll(true)
          : setViewAll(false);
        filteredCatalogMakeBrands.length > 16
          ? setViewAllShow(true)
          : setViewAllShow(false);
      }
    } else if (category === "Parts") {
      const partsData = allCategories.map((obj) => {
        return {
          brand_img: obj.imgUrl,
          subCategories: obj.subCategories,
          name: obj.name,
        };
      });
      setItemToShowParts(partsData.slice(0, 6));
      partsData.length > 6 ? setViewAll(true) : setViewAll(false);
      partsData.length > 6 ? setViewAllShow(true) : setViewAllShow(false);
    } else if (category === "Brands") {
       brands = brands.filter((brandVal) => (
        brandVal?.category?.slug !== 'battery' &&
        brandVal?.category?.slug !== 'lubricants'
      ))

      setViewAll(true);
      if (subCategory === "All") {
        setItemToShow(brands.slice(0, 16));
        brands.length > 16 ? setViewAllShow(true) : setViewAllShow(false);
      } else if (subCategory === "Genuine") {
        const genuineOEMFilteredData = brands.filter(
          (brand) => brand.category.name === "Genuine"
        );
        setItemToShow(genuineOEMFilteredData.slice(0, 16));
        genuineOEMFilteredData.length > 16
          ? setViewAllShow(true)
          : setViewAllShow(false);
      } else if (subCategory === "Aftermarket") {
        const afterMarketFilteredData = brands.filter(
          (brand) => brand.category.name === "Aftermarket"
        );
        setItemToShow(afterMarketFilteredData.slice(0, 16));
        afterMarketFilteredData.length > 16
          ? setViewAllShow(true)
          : setViewAllShow(false);
      }
      // https://assets.buyparts24.com/listing-1660976288686.jpg
    } else if (category === "Lubricants") {
      //console.log('lubsBrand Length >>>' +lubsbrand.length )
      // filteredCatalogLubricantBrands = brands.filter(
      //   (brand) => brand.category.name === "Lubricants"
      // );
      // if (filteredCatalogTyreBrands.length) {
      //   setItemToShow(filteredCatalogTyreBrands.slice(0, 16));
      //   filteredCatalogTyreBrands.length > 16
      //     ? setViewAllShow(true)
      //     : setViewAllShow(false);
      // }
      setItemToShow(lubsbrand?.slice(0, 16));
    
       lubsbrand?.length > 16
         ? setViewAllShow(true)
         : setViewAllShow(false);
    }
    // https://assets.buyparts24.com/listing-1660976288686.jpg
   else if (category === "Battery") {
    filteredCatalogBatteriesBrands = brands.filter(
      (brand) => brand.category.name === "Battery"
    );
      setItemToShow(filteredCatalogBatteriesBrands.slice(0, 16));
      filteredCatalogBatteriesBrands.length > 16
        ? setViewAllShow(true)
        : setViewAllShow(false);
    }
  }, [
    category,
    filteredCatalogMakeBrands,
    filteredCatalogTyreBrands,
    filteredCatalogBatteriesBrands,
    subCategory,
    lubsbrand
  ]);

  let updateItemList = () => {
    if (category === "Parts") {
      if (itemToShowParts.length == 6) {
        setItemToShowParts(
          allCategories.map((obj) => {
            return {
              brand_img: obj.imgUrl,
              subCategories: obj.subCategories,
              name: obj.name,
            };
          })
        );
        setViewAll(false);
      } else {
        setItemToShowParts(
          allCategories
            .map((obj) => {
              return {
                brand_img: obj.imgUrl,
                subCategories: obj.subCategories,
                name: obj.name,
              };
            }).slice(0, 6)
        );
        setViewAll(true);
      }
    } else if (itemToShow.length === 16) {
      if (category === "Make") {
        setItemToShow(filteredCatalogMakeBrands);
        setViewAll(false);
      } else if (category === "Brands") {
         brands = brands.filter((brandVal) => (
          brandVal?.category?.slug !== 'battery' &&
          brandVal?.category?.slug !== 'lubricants'

        ))
        if (subCategory === "All") {
          setItemToShow(brands);
        } else if (subCategory === "Genuine") {
          setItemToShow(
            brands.filter((brand) => brand.category.name === "Genuine")
          );
        } else if (subCategory === "Aftermarket") {
          setItemToShow(
            brands.filter((brand) => brand.category.name === "Aftermarket")
          );
        }
        setViewAll(false);
      } else if (category === "Lubricants" ) {
       // setItemToShow(filteredCatalogTyreBrands.slice(0, 16));
     setItemToShow(//lubsbrand.slice(0, 16)
       lubsbrand.filter((brand) => brand.category.name === "Lubricants")
     );
        setViewAll(false);
      } else if (category === "Battery" ) {
       // setItemToShow(filteredCatalogBatteriesBrands.slice(0, 16));
       setItemToShow(
        brands.filter((brand) => brand.category.name === "Battery")
      );
        setViewAll(false);
      }
    } else {
      if (category === "Make") {
        setItemToShow(filteredCatalogMakeBrands.slice(0, 16));
        setViewAll(true);
      } else if (category === "Brands") {
        if (subCategory === "All") {
          setItemToShow(brands.slice(0, 16));
        } else if (subCategory === "Genuine") {
          setItemToShow(
            brands
              .filter((brand) => brand.category.name === "Genuine")
              .slice(0, 16)
          );
        } else if (subCategory === "Aftermarket") {
          setItemToShow(
            brands
              .filter((brand) => brand.category.name === "Aftermarket")
              .slice(0, 16)
          );
        }
        setViewAll(true);
      } else if (category === "Lubricants" ) {
        // setItemToShow(filteredCatalogTyreBrands.slice(0, 16));
       setItemToShow(
      lubsbrand.filter((brand) => brand.category.name === "Lubricants").slice(0, 16)
       );
         setViewAll(true);
       } else if (category === "Battery") {
        // setItemToShow(filteredCatalogBatteriesBrands.slice(0, 16));
        setItemToShow(
         brands.filter((brand) => brand.category.name === "Battery").slice(0, 16)
       );
         setViewAll(true);
       }
    }
    ga4Events({
      action: `catalog_view_${itemToShow.length == 14 ? "all" : "less"}_click`,
      category: "Listing",
      subcategory_id: `${itemToShow.length == 14 ? "all" : "less"}`,
      subcategory_name: `view_${itemToShow.length == 14 ? "all" : "less"
        }_in_${category}`,
    });
  };
const replaceSlashes = (str) => {
    if(str.includes('/')) {
      console.log('str---' + str.replace(/\//g, '_'));
      return str.replace(/\//g, '_');
    } else {  
     
      return str;
    }
  }
  let handleGA = (item, e) => {
    ga4Events({
      action: `catalog_view_${item.name}_click`,
      category: "Listing",
      subcategory_id: item.sub_category[0]?._id,
      subcategory_name: item.sub_category[0]?.name,
    });
  };

  return (
    <>
      {category === "Parts" ?
        itemToShow?.length &&
        <PartsCategory itemToShow={itemToShowParts} clickedUser={clickedUser} />
        :
        <div className={"grid8"}>
          {itemToShow?.length &&
            itemToShow?.map((brand, index) => {
              return (
             
                  (
                    <div class="square">
                      <Paper variant="outlined">
                        <NavLink
                          to={
                            category === "Make"
                              ? `/car-parts/${brand.name}`
                              : category === "Brands"
                              ? `brand-list/${brand.name}/${brand?.category?.slug}`    
                            : category === "Parts"
                                  ? `/spare-parts/${brand.name}`
                                  : category === "Lubricants"
                                    ? `brand-list/${replaceSlashes(brand.name)}/${brand.category?.slug}`
                                    : category === "Battery"
                                      ? `brand-list/${brand.name}/${brand.category?.slug}`
                                      : ``
                          }
                          onClick={(e) => {
                            handleGA(brand, e);
                          }}
                        >
                          <img src={brand?.brand_img} />
                        </NavLink>
                      </Paper>
                    </div>
                  ))
            })}
        </div>
      }


      {/* <Grid container spacing={2} style={{ marginTop: '2px' }}>
        {itemToShow?.length &&
          itemToShow?.map((brand, index) => {
            return (
              <Grid item xs={category === "Parts" ? 4 : 3} md={category === "Parts" ? 4 : 2}
                className={styles.customeItem}>
                <Paper variant="outlined">
                  <Card >
                    <a
                      href={
                        category === "Make"
                          ? `/car-parts/${brand.name}`
                          : category === "Brands"
                            ? `brand-list/${brand.name}/${brand.category?.slug}`
                            : category === "Parts"
                              ? `/spare-parts/${brand.name}`
                              : category === "Lubricants"
                                ? `/all-listings?type=tires`
                                : category === "Battery"
                                  ? `/all-listings?type=batteries`
                                  : ``
                      }
                      onClick={(e) => {
                        handleGA(brand, e);
                      }}
                    >
                      <Box>
                        {category === "Battery" || category === "Lubricants" ? (
                          <Typography
                            variant="body1"
                            className={styles.squareTypo}
                          >
                            {brand?.name}
                          </Typography>
                        ) : (
                          <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>
                            <CardMedia
                              sx={{ height: 100, boxShadow: 'none' }}
                              image={brand?.brand_img}
                              title={brand.name}
                              onClick={clickedUser}
                            />
                          </Card>
                        )}
                        {category === "Parts" ? (
                          <Typography 
                          style={{ position: 'absolute', marginTop: '-2rem', marginLeft: '1rem', 
                          color: '#ffffff', fontWeight: 700 }}>
                            {brand.name}
                          </Typography>
                        ) : null}
                      </Box>
                    </a>
                  </Card>
                </Paper>
                <Grid item md={11} xs={11} sm={11}>
                  {brand?.subCategories && brand.subCategories.length
                    ? brand.subCategories.map((sub, ind) => {
                      let activeCategory = brand.name
                        ?.toLowerCase()
                        ?.replaceAll(" ", "-")
                        ?.replaceAll("/", "-");
                      let subCat = sub?.name
                        ?.toLowerCase()
                        ?.replaceAll(" ", "-")
                        ?.replaceAll("/", "-");
                      return (
                        <a
                          href={
                            category === "Parts"
                              ? `/spare-parts/${activeCategory}/${subCat}`
                              : ``
                          }
                          onClick={(e) => {
                            handleGA(brand, e);
                          }} style={{ color: '#333' }}
                        >
                          <Hidden xsDown={true}>
                            {category === "Parts" && 
                            <ListItem style={{ paddingLeft: '2px', paddingBottom: '2px', fontSize: '12px', fontWeight: 400 }}>{sub.name}</ListItem>}
                          </Hidden>
                        </a>
                      );
                    })
                    : null}
                </Grid>
              </Grid>
            );
          })}
      </Grid> */}
      {category && viewAllShow ? (
        <Stack>
          <Button
            variant="text"
            endIcon={<ArrowForward style={{height: '12px', width: '14px', marginTop: 0}}/>}
            onClick={updateItemList}
            style={{ backgroundColor: 'transparent', color: '#1D71B8', fontSize: '14px', fontWeight: 700 }}
          >
            {viewAll ? `VIEW ALL IN ${category.toUpperCase()}` : `VIEW LESS IN ${category.toUpperCase()}`}
          </Button>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

ItemList.propTypes = {};

export default ItemList;
