// eslint-disable-next-line
export default (cbm, status) => {
  let price = 0;
  if (cbm >= 16.76) {
    price = 693;
  }
  if (cbm <= 16.75) {
    price = 683;
  }
  if (cbm <= 16.5) {
    price = 672;
  }
  if (cbm <= 16.25) {
    price = 662;
  }
  if (cbm <= 16) {
    price = 652;
  }
  if (cbm <= 15.75) {
    price = 642;
  }
  if (cbm <= 15.5) {
    price = 632;
  }
  if (cbm <= 15.25) {
    price = 622;
  }
  if (cbm <= 15) {
    price = 612;
  }
  if (cbm <= 14.75) {
    price = 602;
  }
  if (cbm <= 14.5) {
    price = 592;
  }
  if (cbm <= 14.25) {
    price = 582;
  }
  if (cbm <= 14) {
    price = 571;
  }
  if (cbm <= 13.75) {
    price = 561;
  }
  if (cbm <= 13.5) {
    price = 551;
  }
  if (cbm <= 13.25) {
    price = 541;
  }
  if (cbm <= 13) {
    price = 531;
  }
  if (cbm <= 12.75) {
    price = 521;
  }
  if (cbm <= 12.5) {
    price = 511;
  }
  if (cbm <= 12.25) {
    price = 501;
  }
  if (cbm <= 12) {
    price = 491;
  }
  if (cbm <= 11.75) {
    price = 480;
  }
  if (cbm <= 11.5) {
    price = 470;
  }
  if (cbm <= 11.25) {
    price = 460;
  }
  if (cbm <= 11) {
    price = 450;
  }
  if (cbm <= 10.75) {
    price = 440;
  }
  if (cbm <= 10.5) {
    price = 430;
  }
  if (cbm <= 10.25) {
    price = 420;
  }
  if (cbm <= 10) {
    price = 410;
  }
  if (cbm <= 9.75) {
    price = 400;
  }
  if (cbm <= 9.5) {
    price = 389;
  }
  if (cbm <= 9.25) {
    price = 379;
  }
  if (cbm <= 9) {
    price = 369;
  }
  if (cbm <= 8.75) {
    price = 359;
  }
  if (cbm <= 8.5) {
    price = 349;
  }
  if (cbm <= 8.25) {
    price = 339;
  }
  if (cbm <= 8) {
    price = 329;
  }
  if (cbm <= 7.75) {
    price = 319;
  }
  if (cbm <= 7.5) {
    price = 309;
  }
  if (cbm <= 7.25) {
    price = 298;
  }
  if (cbm <= 7) {
    price = 288;
  }
  if (cbm <= 6.75) {
    price = 278;
  }
  if (cbm <= 6.5) {
    price = 268;
  }
  if (cbm <= 6.25) {
    price = 258;
  }
  if (cbm <= 6) {
    price = 248;
  }
  if (cbm <= 5.75) {
    price = 238;
  }
  if (cbm <= 5.5) {
    price = 228;
  }
  if (cbm <= 5.25) {
    price = 218;
  }
  if (cbm <= 5) {
    price = 208;
  }
  if (cbm <= 4.75) {
    price = 197;
  }
  if (cbm <= 4.5) {
    price = 187;
  }
  if (cbm <= 4.25) {
    price = 177;
  }
  if (cbm <= 4) {
    price = 167;
  }
  if (cbm <= 3.75) {
    price = 157;
  }
  if (cbm <= 3.5) {
    price = 147;
  }
  if (cbm <= 3.25) {
    price = 137;
  }
  if (cbm <= 3) {
    price = 127;
  }
  if (cbm <= 2.75) {
    price = 117;
  }
  if (cbm <= 2.5) {
    price = 106;
  }
  if (cbm <= 2.25) {
    price = 96;
  }
  if (cbm <= 2) {
    price = 86;
  }
  if (cbm <= 1.75) {
    price = 76;
  }
  if (cbm <= 1.5) {
    price = 66;
  }
  if (cbm <= 1.25) {
    price = 56;
  }
  if (cbm <= 1) {
    price = 46;
  }
  if (cbm <= 0.75) {
    price = 34;
  }
  if (cbm <= 0.5) {
    price = 23;
  }
  if (cbm <= 0.49) {
    price = 18;
  }
  

  return status === "express" ? price + 15 : price;
};
