import axios from "axios";
import { ga4Events, popUp } from "../../utils";

export const fetchAllAddress = _ => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_ALL_ADDRESS_BEGIN",
            })
            const currentUser = JSON.parse(localStorage.getItem("login"));

            let response = await axios.get(
                `/api/address?user_id=${currentUser?._id}`
            );

            dispatch({
                type: "GET_ALL_ADDRESS_SUCCESS",
                data: response?.data?.addresses.reverse()
            })
        } catch (err) {
            dispatch({
                type: "GET_ALL_ADDRESS_ERROR",
            });
            popUp(err?.response?.data?.message || "Error while fetching all address.");
        }
    };
};

export const fetchSingleAddress = id => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_SINGLE_ADDRESS_BEGIN",
            })

            let response = await axios.get(
                `/api/address/${id}`
            );

            dispatch({
                type: "GET_SINGLE_ADDRESS_SUCCESS",
                data: response?.data?.data
            })
        } catch (err) {
            dispatch({
                type: "GET_SINGLE_ADDRESS_ERROR",
            })
            popUp(err?.response?.data?.message || "Error while fetching single address.");
        }
    };
};

export const setDefaultAddress = ({ id, setDisableBtn }) => {
    return async dispatch => {
        let body = { id };
        axios.patch(`/api/address/default`, body)
            .then((res) => {
                dispatch(fetchAllAddress());
                setDisableBtn(false)
                popUp("Your address was successfully set as default");
            })
            .catch((e) => {
                setDisableBtn(false)
                popUp(e?.response?.data?.message || "Error while setting default address.");
            });
    };
};

export const addNewAddress = ({ data, resetFields }) => {
    return async dispatch => {
        dispatch({
            type: "UPDATING_ADDRESS_BEGIN",
        })

        axios
            .post("/api/address", data)
            .then((res) => {
                dispatch(fetchAllAddress());
                resetFields()
                ga4Events({
                    action: "cart_add_address",
                    added_address_id: res?.data?.newAddress?._id,
                    category: "Cart"
                })
                dispatch({
                    type: "UPDATING_ADDRESS_SUCCESS"
                })
                popUp("Your Address was added successfully.");
            })
            .catch((err) => {
                dispatch({
                    type: "UPDATING_ADDRESS_ERROR",
                })

                popUp(err?.response?.data?.message || "Error while adding new address.")
            });
    };
};

export const editAddress = ({ data, editedAddressId, resetFields }) => {
    return async dispatch => {
        dispatch({
            type: "UPDATING_ADDRESS_BEGIN",
        })

        axios
            .put(`/api/address/${editedAddressId}`, data)
            .then((res) => {
                if (res?.data) {
                    popUp("Your address was edited successfully.");
                    dispatch(fetchAllAddress());
                    resetFields(false);
                    ga4Events({
                        action: "cart_address_edit",
                        category: "User",
                        address_edited: editedAddressId
                    })

                    dispatch({
                        type: "UPDATING_ADDRESS_SUCCESS"
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: "UPDATING_ADDRESS_ERROR",
                })

                popUp(err?.response?.data?.message || "Error while editing the address")
            });
    };
};

export const deleteAddress = ({ selectedId, toggle }) => {
    return async dispatch => {
        axios
            .delete(`/api/address/${selectedId}`)
            .then((res) => {
                dispatch(fetchAllAddress());
                popUp("Your address was deleted successfully.");
                toggle();
            })
            .catch((err) => {
                popUp(err?.response?.data?.message || "Error while deleting the address");
            });
    };
};

export const feeAddress = ({ selectedId }) => {
    return async dispatch => {
        dispatch({
            type: "GET_FEE_ADDRESS_BEGIN",
        })
        axios
            .get(`/api/fees/address/${selectedId}`)
            .then((response) => {
                dispatch({
                    type: "GET_FEE_ADDRESS_SUCCESS",
                    data: response?.data
                })
            })
            .catch((err) => {
                popUp(err?.response?.data?.message || "Error while deleting the address");
            });
    };
};
