import React, { Component } from "react";
import { WebSocketContext } from "../../../WebSocket";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18next from "i18next";
import { Grid } from "@mui/material";
import {
  setCurrencyType,
  setCurrencyRate,
} from "../../../Store/action/currencyAction";
import Hidden from '@material-ui/core/Hidden';
import { Box, Typography } from "@material-ui/core";
import "./smartbanner.css";
import { ga4Events } from "../../../utils";
class PreHeader extends Component {
  static contextType = WebSocketContext;
  constructor(props) {
    super(props);
    this.state = { conversionRate: "", loading: false };
    this.changeLang = this.changeLang.bind(this);

  }

  changeLang(e, lang) {
    e.preventDefault();
    localStorage.setItem("lang", lang);
    i18next.changeLanguage(lang);
  }

  async componentDidUpdate(prevState) {
    const { currency } = this.props.currency;
    if (currency !== prevState?.currency?.currency) {
      this.setState({ loading: true });
      await fetch(
        `https://v6.exchangerate-api.com/v6/90eccd4a9aa0f4cd2409095c/pair/AED/${currency}`
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({ conversionRate: data?.conversion_rate });
          this.props.setCurrencyRate(data?.conversion_rate);
        });
      this.setState({ loading: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <><div className="pre-header-style container-fluid">
        <Grid container>
          <Grid item xs={12} md={5} sm={12} lg={5} style={{ paddingTop: 4, paddingBottom: 6, textAlign: 'left' }}>
            <Typography variant="paragraph">{t("top_bar_text")}</Typography>
          </Grid>
          <Grid item xs={12} md={7} sm={12} lg={7} style={{display: 'flex', justifyContent: 'right', paddingRight: '11px'}}>
            <div className="usp">
              <img src="/assets/img/USP.svg" className="padding-0-10" />
              <Typography variant="paragraph">Express Delivery</Typography>
            </div>
            <div className="usp">
              <img src="/assets/img/USP.svg" className="padding-0-10" />
              <Typography variant="paragraph">Genuine/Aftermarket Experts</Typography>
            </div>
            <div className="usp">
              <img src="/assets/img/USP.svg" className="padding-0-10" />
              <Typography variant="paragraph">Competitive Pricing</Typography>
            </div>

          </Grid>
          {/* <Grid item xs={2} md={2} sm={6} lg={3} className="mobile-hide" style={{textAlign: 'center'}}
          >
            <img src="/assets/img/USP.svg" className="padding-0-10" />
            <Typography variant="paragraph">Genuine/Aftermarket Experts</Typography>
          </Grid>            <img src="/assets/img/USP.svg" className="padding-0-10" />
            <Typography variant="paragraph">Competitive Pricing</Typography>
          <Grid item xs={2} md={2} sm={3} lg={2} className="mobile-hide" style={{textAlign: 'right'}}>

          </Grid> */}
        </Grid>
      </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currency: state.currency,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrencyType: (price) => dispatch(setCurrencyType(price)),
    setCurrencyRate: (rate) => dispatch(setCurrencyRate(rate)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PreHeader));