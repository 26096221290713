import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment-timezone";


const DetailsContent = (props) => {
  const { contents } = props;
  const { pic, title, subtitle, createdAt, category, description } = contents;

  const noAction = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className="post-details">
        <div className="post-head">
          <img src={pic || "/assets/img/product-default.jpg"} alt="" />
        </div>
        <div className="post-content">
          <div className="post-header">
            <h3>{title}</h3>
            <ul className="list-unstyled">
              <li>{moment(createdAt).tz("Asia/Dubai").format("DD/MM/YYYY")}</li>
              <li>
                In{" "}
                <NavLink to="/at_demo" onClick={noAction}>
                  {category}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="post-body">
            <div
              dangerouslySetInnerHTML={{
                __html: description?.replaceAll("\n", "<br/>"),
              }}
            />
            <div className="m-top-45 m-bottom-50">
              <blockquote className="blockquote blockquote1">
                <p>{subtitle}</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect({})(DetailsContent);
