import { LocationOn } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";

const PlacesAutoComplete = ({ 
    latitude, 
    longitude, 
    setLatitude, 
    setLongitude, 
    selectedAddress, 
    setAddress, 
    selectedLatitude, 
    selectedLongitude, 
    label
  }) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
      } = usePlacesAutocomplete({
        requestOptions: {
          /* Define search scope here */
          componentRestrictions: { country: "ae" },
          language: "en"
        },
        debounce: 300,
      });
    
    const [showSuggestion, setShowSuggestion] = useState(false);
    
    useEffect(() => {
        if (latitude && longitude) {
          getGeocode({ location: { lat: latitude, lng: longitude } }).then(
            (results) => {
              setValue(results[0].formatted_address);
              setShowSuggestion(false);
            }
          );
        }
      }, [latitude, longitude]);

    useEffect(() => {
      if(value) {
        setAddress(value)
      }
    }, [value])
    

    useEffect(() => {
      if(selectedLatitude !== 0 || selectedLongitude !== 0) {
        setLatitude(selectedLatitude);
        setLongitude(selectedLongitude);
        setShowSuggestion(false);
      }
      else if(selectedAddress) {
        setValue(selectedAddress);
        setShowSuggestion(false);

        // Get latitude and longitude via utility functions
        getGeocode({ address: selectedAddress }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            setLatitude(lat);
            setLongitude(lng);
        });
      } 
    }, [selectedAddress, selectedLatitude, selectedLongitude])
  
    const handleInput = (e) => {
      // Update the keyword of the input element
      const { value } = e.target;
      setValue(value);
      if(value) {
        setShowSuggestion(true);
      }
    };
  
    const handleSelect =
      ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
  
        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          setLatitude(lat);
          setLongitude(lng);
        });

        setShowSuggestion(false);
      };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
        return (
          <li className='location__suggestion-li' key={place_id} onClick={handleSelect(suggestion)}>
            <LocationOn className='location__suggestion-icon'/>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });
  
    return (
        <div style={{position: "relative"}} className="col-md-12 col-lg-4">
          <label htmlFor="location">
            {label || "Select Address"}
          </label>
          <input
            className='form-control'
            value={value}
            onChange={handleInput}
            disabled={!ready}
            onFocus={() => setShowSuggestion(true)}
            placeholder="Select your location"
            name='location'
            autoComplete="off"
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && showSuggestion && <ul className='location__suggestion-ul'>{renderSuggestions()}</ul>}
        </div>
    );
  };

export default PlacesAutoComplete