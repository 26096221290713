import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react'
import PlacesAutoComplete from './PlacesAutoComplete';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SelectLocationModal from './SelectLocationModal';
import { popUp } from '../../../utils';

import './style.css';

const GoogleMapLocation = (props) => {
    const {
        google,
        isModalOpen, 
        toggle,
        selectedLatitude,
        selectedLongitude,
        selectedAddress,
        updateLocation,
        label
    } = props;

    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    // select location modal
    const [isOpen, setIsOpen] = useState(false);

    //responsive
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [width, setWidth] = useState("700px");
    const [height, setHeight] = useState("400px");

    const zoom = 16.29;

    useEffect(() => {
        if(isModalOpen && (selectedLatitude === 0 || selectedLongitude === 0 || !selectedAddress)) {
            setIsOpen(true);
        }
    }, [isModalOpen])

    const handleConfirmSelection = (type) => {
        if(type === "current") {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    setIsOpen(prevState => !prevState);
                  },
                  (error) => {
                    popUp("Please allow Buyparts24 to access your location to continue.")
                  }
                );
              } else {
                console.log("Geolocation is not supported by this browser.");
              }
        } else {
            setLatitude(25.2048);
            setLongitude(55.2708);
            setIsOpen(prevState => !prevState);
        }
    }

    //responsive map
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
      useEffect(() => {
        if (windowWidth < 420) {
            setHeight("400px");
            setWidth("300px")
        } else if (windowWidth < 992) {
            setHeight("400px");
            setWidth("390px")
        } else if (windowWidth < 1200) {
            setHeight("400px");
            setWidth("500px")
        } else {
          setWidth("700px");
          setHeight("400px");
        }
      }, [windowWidth]);

    //function to set the longitude and latitude when marker is dragged
    const moveMarker = async coord => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
    };

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                toggle={(e) => {
                toggle(e);
                }}
                centered
                size='xl'
            >
                <ModalHeader>
                    <h5>Select your Location</h5>
                </ModalHeader>
                <ModalBody className='location__select-modal'>
                    <PlacesAutoComplete
                        latitude={latitude}
                        longitude={longitude}
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        selectedAddress={selectedAddress}
                        selectedLongitude={selectedLongitude}
                        selectedLatitude={selectedLatitude}
                        setAddress={setAddress}
                        label={label}
                    />
                    <div style={{ width: "800px", height: "400px", position: "relative" }}>  
                        <Map google={google} initialCenter={{ lat: latitude, lng: longitude }} center={{ lat: latitude, lng: longitude }}  containerStyle={{ width: width, height: height }} zoom={zoom} >
                            <Marker 
                                position={{ lat: latitude, lng: longitude }} 
                                draggable
                                onDragend={(t, map, coord) => moveMarker(coord)}
                            />
                        </Map>
                    </div>
                </ModalBody>
                
                <ModalFooter>
                    <Button
                        variant="contained"
                        onClick={(e) => toggle(e)}
                        className='btn btn-light'
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            updateLocation(
                            address, {
                                lat: latitude,
                                lng: longitude
                            },
                            );
                            toggle(e)
                        }}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            <SelectLocationModal
                isOpen={isOpen}
                handleConfirmSelection={handleConfirmSelection}
            />
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })(GoogleMapLocation);