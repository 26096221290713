import React from 'react';
import Carousel from "react-multi-carousel";
import ThumbnailCard from '../../card/ThumbnailCard';

const CollectionSlider = ({ section }) => {
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            containerClass="container-with-dots"
            className='mt-4 p-top-10 p-bottom-10'
            draggable
            focusOnSelect={false}
            infinite={false}
            keyBoardControl
            minimumTouchDrag={80}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                superLargeDesktop: {
                    breakpoint: {
                        max: 4000,
                        min: 1500
                    },
                    items: 6,
                    partialVisibilityGutter: 40
                },
                largeDesktop: {
                    breakpoint: {
                        max: 1500,
                        min: 1300
                    },
                    items: 6,
                    partialVisibilityGutter: 40
                },
                desktop: {
                    breakpoint: {
                        max: 1300,
                        min: 1119
                    },
                    items: 5,
                    partialVisibilityGutter: 30
                },
                tablet: {
                    breakpoint: {
                        max: 1119,
                        min: 991
                    },
                    items: 4,
                    partialVisibilityGutter: 30
                },
                mobile: {
                    breakpoint: {
                        max: 991,
                        min: 675
                    },
                    items: 3,
                    partialVisibilityGutter: 30
                },
                smallMobile: {
                    breakpoint: {
                        max: 675,
                        min: 475
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                },
                smallerMobile: {
                    breakpoint: {
                        max: 475,
                        min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {section.sectionData?.ids?.length && section.sectionData?.ids?.map((collection) => {
                return (
                    <div className='thumbnail__card-wrapper'>
                        <ThumbnailCard
                            id={collection?._id}
                            slug={collection?.slug}
                            thumbnail={collection?.thumbnail}
                            title={collection?.title}
                            section={section}
                        />
                    </div>
                );
            })}
        </Carousel>

    )
}

export default CollectionSlider