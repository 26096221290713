const initState = {
    regions: [],
    isRegionLoading: false,

    tradeNumbers: [],
    isTradeNumbersLoading: false,

    vatNumbers: [],
    isVatNumbersLoading: false,

    states: [],
    isStateLoading: false,
}

const DataReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_REGION_BEGIN':
            return {
                ...state,
                isRegionLoading: true,
            }
        case 'GET_REGION_SUCCESS':
            return {
                ...state,
                regions: action?.data,
                isRegionLoading: false,
            }
        case 'GET_REGION_ERROR':
            return {
                ...state,
                isRegionLoading: false,
            }

        case 'GET_ALL_TRADE_NO_BEGIN':
            return {
                ...state,
                isTradeNumbersLoading: true,
            }
        case 'GET_ALL_TRADE_NO_SUCCESS':
            return {
                ...state,
                tradeNumbers: action?.data,
                isTradeNumbersLoading: false,
            }
        case 'GET_ALL_TRADE_NO_ERROR':
            return {
                ...state,
                isTradeNumbersLoading: false,
            }

        case 'GET_ALL_VAT_NO_BEGIN':
            return {
                ...state,
                isVatNumbersLoading: true,
            }
        case 'GET_ALL_VAT_NO_SUCCESS':
            return {
                ...state,
                vatNumbers: action?.data,
                isVatNumbersLoading: false,
            }
        case 'GET_ALL_VAT_NO_ERROR':
            return {
                ...state,
                isVatNumbersLoading: false,
            }
        case 'GET_STATE_BEGIN':
            return {
                ...state,
                isStateLoading: true,
            }
        case 'GET_STATE_SUCCESS':
            return {
                ...state,
                states: action?.data,
                isStateLoading: false,
            }
        case 'GET_STATE_ERROR':
            return {
                ...state,
                isStateLoading: false,
            }
        default:
            return state;
    }
};
export default DataReducer;
