import React, { useState, useEffect } from "react";
import { PageBanner } from "../page-banner";
import moment from "moment-timezone";
import Pending from "../UserStatus/Pending";
import axios from "axios";
import { NavLink } from "react-router-dom";

const SellerDeliveries = () => {
	const [deliveries, setDeliveries] = useState([]);

	useEffect(() => {
		const currentUser = JSON.parse(localStorage.getItem("login"));
		async function fetchMyAPI() {
			let response = await axios.get(
				`api/order?buyer=${currentUser?._id}`
			);
			setDeliveries(response?.data?.orders);
		}
		fetchMyAPI();
	}, []);
	const filteredOrders =
		deliveries &&
		deliveries.length > 0 &&
		deliveries.filter((item) => {
			return item?.status === "delivered";
		});

	const currentUser = JSON.parse(localStorage.getItem("login"));

	if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;

	return (
		<>
			<PageBanner />
			<div className="container-fluid mt-5 mb-5 section-padding-all-listing">
				<div className="row">
					<div className="col-lg-12">
						<div className="order-list-wrapper">
							<table className="table bg-white table-responsive">
								<thead className="thead-light">
									<tr className="order-list-head">
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											S.No
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Order by
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Pick up or delivery
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Collection or shipping details
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Order date
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Item
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Cost
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
											className="head-item"
										>
											Status
										</th>
										<th
											style={{
												textAlign: "center",
												verticalAlign: "middle",
											}}
										>
											View
										</th>
										{/* <th
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle"
                      }}
                      className="head-item"
                    >
                      Action
                    </th> */}
									</tr>
								</thead>
								<tbody>
									{filteredOrders.length == 0 && (
										<td colSpan="9" style={{ textAlign: "center" }}>
											<img src="/assets/img/nodata.png" alt="no_data" />
										</td>
									)}

									{filteredOrders?.length > 0 &&
										filteredOrders?.map((order, orderIndex) => {
											return (
												<tr key={orderIndex}>
													<td style={{ textAlign: "center" }}>
														{orderIndex + 1}
													</td>
													<td>{order?.user?.name}</td>
													<td>
														{order?.delivery_option ? "Delivery" : "Pickup"}
													</td>
													<td>{order?.delivery_address?.address}</td>
													<td>
														{moment(order?.order_date)
															.tz("Asia/Dubai")
															.format("DD/MM/YYYY")}
													</td>
													<td className="d-flex order-listings-wrapper">
														{order.listings.map((listing, listingIndex) => {
															if (listingIndex < 1)
																return (
																	<>
																		<div
																			className="listing-item d-inline-block p-1 border rounded-circle"
																			key={listingIndex}
																			style={{ width: 40, height: 40 }}
																		>
																			<img
																				src={
																					listing.imageArray[0] ||
																					"/assets/img/product-default.jpg"
																				}
																				alt={listing.partName}
																				style={{
																					width: "100%",
																					height: "100%",
																					borderRadius: "50%",
																				}}
																			/>
																		</div>
																		{order.listings.length > 1 ? (
																			<span
																				className="badge badge-light rounded-circle border p-1"
																				key={listingIndex}
																				style={{
																					width: 40,
																					height: 40,
																					lineHeight: "30px",
																					textAlign: "center",
																				}}
																			>
																				+{order.listings.length}
																			</span>
																		) : (
																			<></>
																		)}
																	</>
																);
														})}
													</td>
													<td>
														AED{" "}
														{Math.round(order?.total_price).toLocaleString()}
													</td>
													<td style={{ textTransform: "uppercase" }}>
														{order.status}
													</td>
													<td>
														<NavLink to={`/orders/view/${order?._id}`}>
															<img
																src="https://img.icons8.com/small/16/000000/open-envelope.png"
																className="mr-3"
																style={{ cursor: "pointer" }}
															/>
														</NavLink>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SellerDeliveries;
