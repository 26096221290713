import React from 'react';
import { NavLink } from 'react-router-dom';
import { handleHomepageSectionEvents } from '../../../../utils';

const BannerSlider = ({ datas, section }) => {
    return (
        <div className="container carousel-item-image p-bottom-20">
            <div
                id={`banner-carousel-${section?._id}`}
                className="carousel carousel-div slide"
                data-ride="carousel"
            >
                <div className="carousel-inner">
                    {
                        datas?.length && datas?.map((data, index) => (
                            <div
                                className={
                                    index === 0
                                        ? `carousel-item active `
                                        : `carousel-item`
                                }
                            >
                                <NavLink to={`/collections/${data?.slug}`}
                                    style={{ textAlign: "center" }}
                                    onClick={() => handleHomepageSectionEvents(section, {
                                        collection_id: data?._id,
                                        collection_name: data?.title
                                    })}
                                >
                                    <img
                                        src={data?.banner}
                                        className="d-flex w-100"
                                        alt="..."
                                    />
                                </NavLink>
                            </div>
                        ))
                    }
                </div>

                {
                    datas?.length > 1 &&
                    <>
                        <a
                            className="carousel-control-prev"
                            href={`#banner-carousel-${section?._id}`}
                            role="button"
                            data-slide="prev"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                                style={{ height: 22, width: 22 }}
                            >
                                <path
                                    fill-rule="evenodd"
                                    fill="#000"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                            </svg>
                        </a>
                        <a
                            className="carousel-control-next"
                            href={`#banner-carousel-${section?._id}`}
                            role="button"
                            data-slide="next"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-right"
                                viewBox="0 0 16 16"
                                style={{ height: 22, width: 22 }}
                            >
                                <path
                                    fill="#000"
                                    fill-rule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                            </svg>
                        </a>
                    </>
                }
            </div>
        </div>
    )
}

export default BannerSlider