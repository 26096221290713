import React from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { ga4Events } from '../../../../utils';

const CatalogSubTypeSelect = ({ subCategory, setSubCategory }) => {
    const CatlogSubCategoryTypes = ['All', 'Genuine', 'Aftermarket'];
    const [values, setValues] = React.useState(0);

    const handleChangeCategory = (e, newValue) => {
        setSubCategory(CatlogSubCategoryTypes[newValue]);
        setValues(newValue);
        ga4Events({
            action: "catalog_subtype_click",
            category: "Listing",
            subcategory_id: newValue,
            subcategory_name: CatlogSubCategoryTypes[newValue]
        })
    }
    return (
        <>
            <Box sx={{ width: '100%' }} className="secondLevelTab">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs  value={values} onChange={handleChangeCategory} aria-label="basic tabs example" >
                        {CatlogSubCategoryTypes.map(cat => {
                            return (<Tab textColor='primary' label={cat} />)
                        })}
                    </Tabs>
                </Box>
            </Box>
        </>
    )
}

CatalogSubTypeSelect.propTypes = {
}

export default CatalogSubTypeSelect