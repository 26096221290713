import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdvanceSearch from '../../content/element/advance-search';
import AdvanceSearchVin from '../../content/element/advance-search-vin';
import AdvSearchTyreBattery from '../../content/element/search-tyre-batteries';
import { makeStyles } from '@material-ui/core';
import { ArrowBackIosIcon } from '../../common/MaterialIcons';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 'auto',
            padding: '13px',
            fontSize: '12px',
            fontWeight: 400,
            color: '#333333',
            border: '1px solid #CACACA',
            borderRadius: '10px 10px 0 0',
            marginRight: '3px',
            innerHeight: '10px',
            paddingTop: 0,
            textTransform:'capitalize'
        },
        '& .MuiTabs-indicator': {
            background: "#1a0451",
            innerHeight: 0
        },
        '& .Mui-selected': {
            background: "#1A0451",
            color: "#ffffff !important",
            borderRadius: "10px 10px 0 0",
            fontSize: '12px',
            fontWeight: 400,
        }
    },
    mobileTab: {
        '& .MuiTab-root': {
            borderRadius: '10px',
            marginRight: '10px',
            border: '1px solid',
            marginBottom: '1rem',
            minHeight: '40px'
        },
        '& .MuiTabs-indicator': {
            background: "#1a0451",
            innerHeight: 0
        },
        '& .Mui-selected': {

        }
    },
    indicator: {
        display: 'none'
    }
}))
const SearchTab = ({ showMobileSearch = false, handleBackFromSearch, loginToggle }) => {
    const location = useLocation();
    const params = queryString.parse(location.search);

    const [value, setValue] = React.useState(0);
    const [search, setSearch] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [rimSize, setRimSize] = React.useState(0);
    const [voltage, setVoltage] = React.useState(0);
    const [amp, setAmp] = React.useState(0);
    const [brand, setBrand] = React.useState(0);
    const classes = useStyles();

    useEffect(() => {
        if(Object.keys(params).length) {
        console.log(Object.keys(params).length, '#############', params.partname)
            if(params && params.partname) {
                setValue(0); 
                setSearch(params.partname);
            } 
            if(params && params.vin) {
                setValue(1);
                setSearch(params.vin);
            } 
            if(params && params.partnumber)  {
                setValue(2); 
                setSearch(params.partnumber);
            }
            if(params && params.type == 'tires')  {
                setValue(3);
                setWidth(params.width);
                setHeight(params.height);
                setRimSize(params.rimSize);
            }
            if(params && params.type == 'batteries?')  {
                setValue(4);
                setVoltage(params.voltage)
                setAmp(params.amp)
                setBrand(params.brand)
            }
         } else {
             setValue(0);
         }
    }, [])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderSwitch = (param) => {
        switch (param) {
            case 0:
                return <AdvanceSearch placeholder={"Enter Part Name"} name="partname" 
                showMobileSearch={showMobileSearch} searchData={search}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
            case 1:
                return <AdvanceSearchVin 
                showMobileSearch={showMobileSearch} searchData={search}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
            case 2:
                return <AdvanceSearch placeholder={"Enter Part Number"} name="partnumber" 
                showMobileSearch={showMobileSearch} searchData={search}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
            case 3:
                return <AdvSearchTyreBattery title="Tires" 
                showMobileSearch={showMobileSearch} widthData={width} heightData={height} rimSizeData={rimSize}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
            case 4:
                return <AdvSearchTyreBattery title="battery" 
                showMobileSearch={showMobileSearch} voltageData={voltage} ampData={amp} brandData={brand}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
            default:
                return <AdvanceSearchVin 
                showMobileSearch={showMobileSearch}
                handleBackFromSearch={handleBackFromSearch} loginToggle={loginToggle}/>;
        }
    }
    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            {
                showMobileSearch ?
                    <div style={{display: 'flex'}}>
                        <div className='mobile-back' onClick={() => handleBackFromSearch(false)}>
                            <ArrowBackIosIcon />
                        </div>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            className={classes.mobileTab}
                            classes={{ indicator: classes.indicator }}
                        >
                            <Tab label="Part Name" />
                            <Tab label="VIN" />
                            <Tab label="Part No." />
                            <Tab label="Tyre" />
                            <Tab label="Battery" />
                            {/* <Tab label="Upload Enquiry" /> */}
                        </Tabs>
                    </div>
                    :
                    <Tabs
                        className={classes.root}
                        value={value}
                        onChange={handleChange}
                        style={{ marginLeft: '18px', marginBottom: '-14px' }}
                        classes={{ indicator: classes.indicator }}
                    >
                        <Tab label="Part Name" />
                        <Tab label="VIN" />
                        <Tab label="Part No." />
                        <Tab label="Tyre" />
                        <Tab label="Battery" />
                        {/* <Tab label="Upload Enquiry" /> */}
                    </Tabs>
            }

            <>
                {
                    renderSwitch(value)
                }
            </>
        </Box>
    )
}

{/* <Tabs
value={value}
onChange={handleChange}
variant="scrollable"
scrollButtons="auto"
aria-label="scrollable auto tabs example">
<TabList>

    <Tab>VIN</Tab>
    <Tab>Part Name.</Tab>
    <Tab>Part No.</Tab>

    <Tab>Tyre</Tab>
    <Tab>Battery</Tab>
    <Tab>Upload Enquiry</Tab>
</TabList>

<TabPanel>
    <AdvanceSearchVin />
</TabPanel>
<TabPanel>
    <AdvanceSearch placeholder={"Search"} name="partname" />
</TabPanel>
<TabPanel>
    <AdvanceSearch placeholder={"Search"} name="partnumber" />
</TabPanel>
<TabPanel>
    <AdvSearchTyreBattery title="Tires" />
</TabPanel>
<TabPanel>
    <AdvSearchTyreBattery title="battery" />
</TabPanel>
</Tabs> */}
export default SearchTab;
