import React, {useState, useEffect} from 'react';
import { ProductImage } from '../../../utils';
const ReturnProductsList = ({ order_id,returnProduct:rP}) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [returnProduct, setReturnProduct] = useState([])

  useEffect(() => {
    if (currentUser.role === "SELLER") {   
      const _returnProduct = rP?.filter(r => {
        if (r?.list?.user?._id === currentUser._id || r?.list?.user === currentUser._id){//prev "listing" was used and user was not populate so user is seller id now delivery_option has used so lisitng user._id as user is populated in delivery_option
          return r
        }})
      setReturnProduct(_returnProduct)
    } else {
      setReturnProduct(rP)
    }
  }, [rP])
    return (

                  <table
                        id="directorist-checkout-table"
                        className="table bg-transparent checkout-table order-details-table"
                      >
                       
                        <tbody className="bg-white">
                          <tr>
                            <td
                              colSpan="2"
                              className="order-detail-table-title"
                            >
                              Name
                            </td>
                            <td
                              colSpan="2"
                              className="order-detail-table-title"
                            >
                            </td>
                           
                            <td
                              className="order-detail-table-title"
                              colSpan="3"
                            >
                              Return Collected By
                            </td>
                            <td
                              className="order-detail-table-title"
                              colSpan="2"
                            
                            >
                              Quantity
                            </td>

                          

                            <td
                              className="order-detail-table-title"
                              colSpan="3"

                            >
                              Reason
                            </td>
                             <td
                             className="order-detail-table-title"
                             colSpan="3"

                           >
                             Net Amount
                           </td>
                            
                            <td
                              colSpan="2"
                              className="order-detail-table-title"
                            >
                              Status
                            </td>
                          </tr>
                              {returnProduct?.map((item,i)=>{
                              const { list, assignedTo, quantity, reason,status,returnCollectedBy,_id,refund_amount} = item 
                                return (
                                  <tr key={i}>
                                <td colSpan="2">                          
                                <ProductImage
                                    avatar={list?.imageArray?.[0]||list?.pic}
                                    alt="p_name"
                                    style={{ width: "75px", height: "75px", objectFit: "cover" }}
                                />

                                 
                                </td>
                                <td colSpan="2">
                                  <span
                                  >
                                    {" "}
                                    {list?.partName}
                                  </span>
                                  <br/>
                                  <small
                                    style={{color:'GrayText'}}
                                  >
                                    {" "}
                                    {list?.partSKU}
                                  </small>
                                </td>
                                <td colSpan="3">
                                <span>{returnCollectedBy?<>{returnCollectedBy?.name}({returnCollectedBy?.email})</>:'Return not collected yet!'}</span>
                                </td>

                                <td style={{ textAlign: "start" }} colSpan="2">
                                  {quantity}
                                </td>

                                
                                <td style={{ textAlign: "start" }} colSpan="3">
                                  {reason}
                                </td>

                                 <td style={{ textAlign: "start" }} colSpan="3">
                                   {refund_amount?<>AED {refund_amount?.toFixed(2)}</>:'No Refunded yet!'}
                                </td>

                                <td style={{ textAlign: "start" }} colSpan="2">
                                  <strong>
                                  {status?.toUpperCase().replace('_', ' ')}

                                  </strong>
                                </td>
                                
                              </tr>
                                )
                              })}
                        </tbody>
                      </table>

    )
}

export default ReturnProductsList
