import React, { useState } from "react";
import "./index.css";
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Typography } from "@material-ui/core";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Hidden from '@material-ui/core/Hidden';

const Help = () => {
  const [showContact, setshowContact] = useState(false);

  let handleShowContact = (e) => {
    e.preventDefault();
    setshowContact(true);
    console.log("Show contact Clicked with: ", showContact)
  };

  return (
    <>
     <Hidden lgUp>
      {showContact === false ? (
        
        <div className="main-container">
          <div className="inner-container">
            <div>
              <h1>Help</h1>
            </div>
          </div>
          <div className="content-wrapper mt-5">
            <div className="inner-container">
              <div>
         
          <ul className="content" onClick={handleShowContact}>
                  <li className="inner-content">
                    <PhoneRoundedIcon
                      style={{ width: '16px', height: '16px' }} />
                  </li>
                  <li className="inner-content-text">
                    Contact Us
                  </li>
                  <li className="inner-content-arrow">
                    <ArrowForwardIosRoundedIcon
                      style={{ width: '20px', height: '20px', textAlign: 'right !important' }} />
                  </li>
                </ul>
                <ul className="content">
                  <li className="inner-content">
                    <HelpRoundedIcon
                      style={{ width: '16px', height: '16px' }} />
                  </li>
                  <li className="inner-content-text">
                    FAQ
                  </li>
                  <li className="inner-content-arrow">
                    <ArrowForwardIosRoundedIcon
                      style={{ width: '20px', height: '20px', textAlign: 'right !important' }} />
                  </li>
                </ul>
        
              </div>
            </div>
          </div>
        </div>
       
      ) : (
        <div className="main-container mt-5">
          <div className="inner-container" >
            <div className="contact-main">
              <h1>We're Always Here </h1>
              <h1>To Help</h1>
            </div>
          </div>
          <div className="inner-container contact-sub">
            <p>You can reach us through the following methods</p>
          </div>
          <div className="inner-container inner-content-container">
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <PhoneRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                CALL US <br />
                <li className="contact-text-sub">
                  800 666
                </li>
              </li>
            </ul>
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <MailOutlineRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                EMAIL <br />
                <li className="contact-text-sub">
                  help@buyparts.com
                </li>
              </li>
            </ul>
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <LocationOnRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                LOCATION <br />
                <li className="contact-text-sub">
                  Al Aweer Auto Market - Ras Al Khor <br />Industrial Area - Ras Al Khor Industrial <br />Area 3 - Dubai
                </li>
              </li>
            </ul>
          </div>
        </div>
      )
      }
       </Hidden>
       <Hidden mdDown>
       <div className="main-container mt-5">
          <div className="inner-container" >
            <div className="contact-main">
              <h1>We're Always Here </h1>
              <h1>To Help</h1>
            </div>
          </div>
          <div className="inner-container contact-sub">
            <p>You can reach us through the following methods</p>
          </div>
          <div className="inner-container inner-content-container">
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <PhoneRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                CALL US <br />
                <li className="contact-text-sub">
                  800 666
                </li>
              </li>
            </ul>
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <MailOutlineRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                EMAIL <br />
                <li className="contact-text-sub">
                  help@buyparts.com
                </li>
              </li>
            </ul>
            <ul className="contact-content">
              <li className="content-icon-cover">
                <div className="content-icon">
                  <LocationOnRoundedIcon
                    style={{ width: '16px', height: '16px' }} />
                </div>
              </li>
              <li className="contact-text">
                LOCATION <br />
                <li className="contact-text-sub">
                  Al Aweer Auto Market - Ras Al Khor <br />Industrial Area - Ras Al Khor Industrial <br />Area 3 - Dubai
                </li>
              </li>
            </ul>
          </div>
        </div>
       </Hidden>
    </>
  );
};

export default Help;
