import React, { Component } from "react";
import equal from "fast-deep-equal";
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { List, ListItem, ListItemText } from "@material-ui/core";
export class OptionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOptions: this.props.activeOptions,
      checked: [0]
    };
    this.handleClickOption = this.handleClickOption.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !equal(this.state.currentOptions, this.props.activeOptions) ||
      !equal(prevProps.options, this.props.options)
    ) {
      this.setState({ currentOptions: this.props.activeOptions });
    }
  }

  handleClickOption(e, option) {
    e.preventDefault();
    let { currentOptions } = this.state;

    if (currentOptions?.length && currentOptions?.find((e) => e?._id === (option?._id)))
      currentOptions = currentOptions?.filter((e) => e?._id !== option?._id);
    else {
      currentOptions?.push(option);
    }

    this.setState({ currentOptions });
    this.props.onChange(currentOptions, this.props.name);
  }

  render() {
    let { options, setpage, page, totalpage, show } = this.props;
    const { currentOptions } = this.state;

    return (
      <>
        {<List className=""
          style={{ padding: 0, margin: 0, color: "black", marginTop: "2px" }}
        >
          {options?.length &&
            options?.map((option, index) => {
              const foundOption =  currentOptions?.length && currentOptions?.find((e) => (e?._id).toLowerCase() === (option?._id).toLowerCase());
              const isChecked = foundOption ? true : false;
              const isDisabled = isChecked && options?.length === 1 && foundOption?.disable
              return (
                <ListItem
                  key={index}
                  style={{ padding: "2px 0", margin: 0, color: "black", cursor: "pointer" }}
                  onClick={(e) => {
                    if(!option.disable && !isDisabled) {
                      e.preventDefault();
                      this.handleClickOption(e, option);
                    }
                  }}
                  disabled={option.disable || isDisabled}
                >
                  <input
                    className="checked-item"
                    type="checkbox"
                    checked={isChecked}
                    style={{
                      color: "black",
                      borderRadius: "5px",
                      padding: 0, margin: 0,
                    }}
                  />
                  <span style={{ fontSize: "10px", marginLeft: "8px", marginTop: "2px" }}> <ListItemText
                    disableTypography
                    style={{
                      fontSize: "12px"
                    }}
                    primary={option?.name}

                  /></span>
                </ListItem>
              );
            })}
          <div style={{ display: 'block', width: '100%' }} >
            {show == true && <div style={{ width: '100%', justifyContent: 'space-between' }} className="d-flex">
              {page > 0 && <Button size="small" color="#000" onClick={(e) => setpage(page - 1)}><ArrowBackIosIcon style={{ fontSize: 14, marginBottom: 5 }} />Prev</Button>}
              {(totalpage - 1) !== page && <Button size="small" color="#000" onClick={(e) => setpage(page + 1)}>Next<NavigateNextIcon style={{ marginBottom: 5 }} /></Button>}
            </div>}
          </div>
        </List>}
      </>
    );
  }
}
export class ThumbOptionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOptions: [],
    };
    this.handleClickOption = this.handleClickOption.bind(this);
  }

  handleClickOption(e, option) {
    e.preventDefault();
    let { activeOptions, name } = this.props;
    if (activeOptions.includes(option))
      activeOptions = activeOptions.filter((e) => e !== option);
    else activeOptions.push(option);
    this.props.onChange(activeOptions, name);
  }

  render() {
    const { options, activeOptions } = this.props;
    return (
      <div className="atbd_selection__group">
        {options &&
          options.map((option, index) => {
            return (
              <div
                key={index}
                className={
                  activeOptions.includes(option.value)
                    ? "atbd_selection__thumb_item active"
                    : "atbd_selection__thumb_item"
                }
                onClick={(e) => this.handleClickOption(e, option.value)}
              >
                <img
                  src={`/assets/img/car-types/${option.value}.png`}
                  alt="cartype"
                />
                <span>{option.label}</span>
              </div>
            );
          })}
      </div>
    );
  }
}
