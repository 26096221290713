import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment'
import axios from 'axios';
import { popUp } from '../../../utils';

function UploadedInvoices({ orderDetail, currentUser, setShowModal, setPassId, setPassData }) {
    const { seller_uploads, products } = orderDetail || {}
    let uploads = []
    if (seller_uploads?.length > 0 && currentUser?.role === 'SELLER') {
        seller_uploads.forEach(data => {
            if (data.seller_id === currentUser._id) {
                uploads.push(data)
            }
        })
    }
    if (seller_uploads?.length > 0 && currentUser?.role === 'BUYER') {
        seller_uploads.forEach(data => {
            const total = orderDetail.products.filter(product => product.listing?.user === data.seller_id).length;
            const deliveredOrPending = orderDetail.products.filter(product => product.listing?.user === data.seller_id && (product.status === "delivered" || product.status === "cancelled")).length;
            if (total === deliveredOrPending)
                uploads.push(data);
        })
    }

    const downloadSellerInvoice = async (fileUrl) => {
        const fileName = (new URL(fileUrl)).pathname.split('/').pop();

        await axios({
            method: 'post',
            url: `/api/order/get-invoice`,
            responseType: 'blob',
            headers: {
                Accept: '*/*',
            },
            data: {
                url: fileUrl
            },
        }).then((res) => {
            const url = window.URL.createObjectURL(res.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url)
        }).catch(err => {
            popUp("Error while downloading invoice");
        });
    }

    {
        return uploads?.length > 0 ?
            <>
                <hr style={{ marginTop: "35px", marginBottom: "37px" }} />
                <div>

                    <h4>{currentUser?.role === 'BUYER' ? "Seller Invoices" : "My Invoices"}</h4>
                    <table className="table bg-transparent checkout-table order-details-table">
                        <tr>
                            <th style={{ textAlign: 'left' }}>
                                PartName
                            </th>
                            <th style={{ textAlign: 'left' }}>
                                PartSKU
                            </th>
                            <th style={{ textAlign: 'left' }}>
                                Date
                            </th>
                            <th style={{ textAlign: 'center' }}>
                                Actions
                            </th>
                        </tr>
                        {uploads?.map((data) => {
                            const placeholder = data.date ? `${moment(data.date).tz("Asia/Dubai").format("DD-MM-YYYY")}` : "INVOICE"

                            return <tr>
                                <td > {
                                    data?.list?.map((p) => {
                                        let part_name = "";
                                        if (!p?.partName) {
                                            const partSKU = p?.partSKU || p?.item_code;
                                            const foundSKU = products && products?.length !== 0 && products?.find(product => product?.delivery_option?.partSKU === partSKU?.toString());
                                            part_name = foundSKU?.delivery_option?.partName;
                                        }

                                        return (
                                            <div style={{ textAlign: 'left' }}>
                                                {p?.partName ?? part_name}
                                            </div>
                                        )
                                    })
                                } </td>
                                <td> {
                                    data?.list?.map((p) => {
                                        let partSKU = p?.partSKU || p?.item_code;

                                        const foundSKU = products && products?.length !== 0 && products?.find(product => product?.delivery_option?.partSKU === partSKU?.toString());

                                        if (foundSKU)
                                            return (
                                                <div style={{ textAlign: 'left' }}>
                                                    {partSKU}
                                                </div>
                                            )
                                    })
                                }</td>
                                <td style={{ textAlign: 'left' }}>{placeholder}</td>
                                <td style={{ paddingLeft: 10, display: "flex", justifyContent: 'center' }}>
                                    <div
                                        className="primary-btn"
                                        style={{ margin: 2 }}
                                        onClick={() => downloadSellerInvoice(data?.file_url)}
                                    >
                                        <span style={{ position: 'relative', top: "-2px" }}><GetAppIcon /></span>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </> : null
    }
}

export default UploadedInvoices
