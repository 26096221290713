import React, { useState } from 'react';
import ItemList from './ItemList';

import CatalogTypeSelect from './CatalogTypeSelect';
import CatalogSubTypeSelect from './CatalogSubTypeSelect';


const Catalog = ({clickedUser}) => {
    let [category, setCategory] = useState('Make')
    let [subCategory, setSubCategory] = useState('All')
    return (
        <>
            <CatalogTypeSelect category={category} setCategory={setCategory} />
            {category === 'Brands' ? <CatalogSubTypeSelect subCategory={subCategory} setSubCategory={setSubCategory} /> : null}
            <ItemList category={category} subCategory={subCategory} clickedUser={clickedUser}/>
        </>
    )
}

export default Catalog