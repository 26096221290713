import axios from "axios";

// ASSETS UPLOAD FILE
export const uploadFileToAssets = async ({ file, type="uploads", field = "file", multiple = false }) => {
  let data = new FormData();
  if(multiple) {
    file.forEach((f, index) => {
      data.append(field, f);
    });
  } else {
    data.append(field, file);
  }
  return await axios({
    method: "POST",
    baseURL: `${process.env.REACT_APP_ASSETS_URL}`,
    url: `/api/fileupload?type=${type}`,
    data
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      const errorMessage = error?.response?.data?.message || "Error while uploading image"
      throw new Error(errorMessage); 
    });
}
