import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { isValidObject, popUp } from "../../../../utils";
import { uploadFileToAssets } from "../../../../services/upload";

class ExcelQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      sellerId: null,
      seller: null,
      sellerList: null,
      quotationFile: null,
      quotationFileName: null,
      buyerFileName: null,
      sellerFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
      btndisable: false,
      quotationId: ""
    };

    this.validator = new SimpleReactValidator();
    this.myRef = React.createRef();
  }

  reloadState() {
    this.setState({
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { loadWithId } = nextProps;

    if (loadWithId) {
      this.loadData(loadWithId);
    }

    return true;
  }

  onSellerSelected = (seller) => {
    this.setState({ modalSellerOpen: false });

    this.setState({
      sellerId: seller._id,
      seller,
    });
  };

  loadData = (id) => {
    axios
      .get("/api/quotation/get?quotationId=" + id)
      .then((res) => {
        if (this.props.onDataLoaded) {
          this.props.onDataLoaded();
        }

        const { seller, number, buyerNotes, responseFileUrl, name } = res.data;

        this.setState({
          number,
          seller,
          sellerId: seller._id,
          buyerNotes: buyerNotes,
          quotationFileName: name,
          quotationId: id,
          quotationFile: responseFileUrl,
        });
      })
      .catch((err) => {
        popUp(err?.response?.data?.message);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.modalQuotation !== prevProps.modalQuotation) {
      this.reloadState();
      this.myRef.current.value = null;
    }
  }

  submit = async() => {
    const { quotationFile, buyerNotes } = this.state;
    const id = this.state.quotationId;

    const buyDate = moment.tz("Asia/Dubai").format("DD/MM/YYYY hh:mm:ss");
    let formData = {
      buyerNotes,
      date: buyDate
    }
    this.setState({ btndisable: true });

    if (this.props.editQuotation) {
      try {
        if(quotationFile && isValidObject(quotationFile)) {
          const file = await uploadFileToAssets({
            file: quotationFile,
            type: "quotation"
          })
          formData = {
            ...formData,
          file_id: file?._id
          }
        }
        axios
          .patch(
            "/api/products/edit-upload-order?id=" + id,
            formData
          )
          .then((res) => {
            this.reloadState();
  
            this.setState({ quotationFile: null })
            if (this.props.onFinished) this.props.onFinished();
            popUp("Update  Request");
            this.setState({
              quotationFile: "",
              sellerId: "",
              buyerNotes: "",
              buyDate: "",
              btndisable: false,
            });
  
          })
          .catch((err) => {
            if (this.props.onFinished) this.props.onFinished();
  
            popUp(err?.response?.data?.message);
            this.setState({
              quotationFile: "",
              sellerId: "",
              buyerNotes: "",
              buyDate: "",
              btndisable: false,
            });
          });
      } catch(error) {
        this.setState({
          btndisable: false,
        });
        popUp(error?.message || "Error while editing the quotation")
      }
    }
    else {
      if (!quotationFile) {
        alert("you must select quotation file");
        return;
      }


      uploadFileToAssets({
        file: quotationFile,
        type: "quotation"
      }).then(res => {
        formData = {
          ...formData,
          file_id: res?._id
        }

        axios
          .post(
            "/api/products/upload-order?user_id=" + this.props.login._id,
            formData
          )
          .then((res) => {
            this.reloadState();

            this.setState({ quotationFile: null })
            if (this.props.onFinished) this.props.onFinished();
            popUp("Successfully Sent Request");
            this.setState({
              quotationFile: "",
              sellerId: "",
              buyerNotes: "",
              buyDate: "",
              btndisable: false,
            });
            this.myRef.current.value = null;
          })
          .catch((err) => {
            if (this.props.onFinished) this.props.onFinished();

            popUp(err?.response?.data?.message);
            this.setState({
              quotationFile: "",
              sellerId: "",
              buyerNotes: "",
              buyDate: "",
              btndisable: false,
            });
          });
      }).catch(error => {
        popUp(error?.message);
        this.setState({
          quotationFile: "",
          sellerId: "",
          buyerNotes: "",
          buyDate: "",
          btndisable: false,
        });
      })

    }
  };

  componentDidMount() {
    axios
      .get("/api/users?role=SELLER&all=true&status=Approved")
      .then((res) => {
        this.setState({
          sellerList: res.data?.users,
          sellerId: res.data?.users?.find(
            (s) => s._id === process.env.REACT_APP_QUOTATION_SELLER_ID
          )?._id,
        });
      })
      .catch((err) => {
        popUp(err?.response?.data?.message);
      });
  }

  render() {
    return (
      <div className="p-3">
        <div className="d-flex mb-2 justify-content-between">
          <h4>
            {this.props.editQuotation
              ? "View Quotation Request by Excel file"
              : "New Quotation Request by Excel file"}
          </h4>
          <a
            href="/assets/buyparts24_rfq_template.xlsx"
            className="btn btn-default btn-xs"
          >
            Download Excel Template
          </a>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="quotationFile" className="form-label">
                Quotation File
              </label>
              {this.state.quotationFileName && (
                <p className="text-ellipse">
                  <a href={`${this.state.quotationFileName}`} _target="blank">
                    {this.state.quotationFileName}
                  </a>
                </p>
              )}
              <input
                type="file"
                className="form-control"
                id="quotationFile"
                accept=".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  this.setState({ quotationFile: e.target.files[0] });
                  this.setState({ btndisable: false });
                }}
                ref={this.myRef}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="buyerNotes" className="form-label">
                Buyer Notes
              </label>
              <textarea
                value={this.state.buyerNotes || ""}
                className="form-control"
                rows="3"
                onChange={(e) => {
                  this.setState({ buyerNotes: e.target.value });
                  this.setState({ btndisable: false });
                }}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button
              disabled={this.state.btndisable}
              className="btn btn-primary btn-xs"
              onClick={this.submit}
            >
               {this.state.btndisable && (
                <i className="las la-spinner la-spin mr-2"></i>
              )}
              {this.props.editQuotation ? "Update Request" : "Submit Request"}
            </button>
          </div>
        </div>
        <Modal
          visible={this.state.modalSellerOpen}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default"
              onClick={(e) => this.setState({ modalSellerOpen: false, quotationFile: null })}
            >
              X
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

ExcelQuotation.propTypes = {
  onFinished: PropTypes.func,
};

ExcelQuotation.propTypes = {
  loadWithId: PropTypes.string,
};

ExcelQuotation.propTypes = {
  onDataLoaded: PropTypes.func,
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  ExcelQuotation
);
