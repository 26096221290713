import React, { useState } from "react";
import "./search.css";
import {  Grid, Paper } from "@material-ui/core";
  
export const VinSearch = ({search}) => {  
  return (
      search && search.length > 0 && (
        <>
            <div style={{ display: "inherit", position: "absolute", top: "40px", width:"100%"}}>
                <Paper style={{ marginBottom: 105, width: "100%" }} className="home-search">
                    {/* Loop Grid */}
                    <Grid container style={{padding: "15px", borderTop: "1px solid #f7f3f3"}}>
                        <div className="product-detail">
                            <img src={'/assets/img/product-default.jpg'} />
                            <div className="vin-detail"> 
                                <div style={{fontSize:"11px"}}>VIN NUMBER HERE</div>
                                <div>BRAND</div>
                                <div style={{fontSize:"11px"}}>NUMBER</div>
                            </div>
                        </div>
                    </Grid>  
                </Paper>
            </div>
        </>
    )
  );
}; 

export const PartNoSearch = ({search}) => { 

    const highlight= (text, search) => {  
        // Split text on highlight term, include term itself into parts, ignore case
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return <span> { parts.map((part, i) => 
            <span key={i} style={part.toLowerCase() === search.toLowerCase() ? { fontWeight: 'bold'} : {} }>
                { part }
            </span>)
        } </span>;
    }

    return (
        <></>
    //     search && search.length > 2 && (
    //       <>
    //           <div style={{ display: "inherit", position: "absolute", top: "40px", width:"100%"}}>
    //               <Paper style={{ marginBottom: 105, width: "100%" }} className="home-search">  
    //                   {/* Loop Grid */}
    //                   <Grid container style={{padding: "15px", borderTop: "1px solid #f7f3f3"}}>
    //                       <div className="product-detail">
    //                           <img src={'/assets/img/product-default.jpg'} />
    //                           <div className="partno-detail"> 
    //                               <div style={{fontSize:"11px"}}>BRAND HERE</div>
    //                               <div style={{fontSize:"15px"}}>{ highlight( "fuck tae search", search) } </div> 
    //                               <div style={{fontSize:"11px"}}>NUMBER</div>
    //                           </div>
    //                       </div>
    //                   </Grid>  
    //               </Paper>
    //           </div>
    //       </>
    //   )
    );
  };