import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import ShowDynamicCurrency from '../../common/ShowDynamicCurrency';
import ShowDynamicPrice from '../../common/ShowDynamicPrice';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import { ga4Events } from '../../../utils';

export default function StepReview({
  step,
  setOrderNote,
  orderNote,
  login,
  formatPhoneNumber,
  selectedAddress,
  classes,
  newShippingPrice,
  getTaxAmount,
  getPriceTotal,
  flags,
  stepForward,
  disabler,
  placeOrder,
  couponCode,
  setCouponCode,
  setCouponShowModal,
  discount,
  coupon,
  walletAmount,
  subTotalExclVat,
  totalTax,
  setHasSelectedCoordinates,
  setTotalPayableAmount,
   lpoUsers,

  placeholder
}) {
  let exc_tax_total = subTotalExclVat;
  let discount1 = discount ? discount : 0;
  let tax = totalTax;

  let total =
    Number(exc_tax_total) -
    Number(discount1) +
    Number(newShippingPrice) +
    Number(tax);
  setTotalPayableAmount(total)

  disabler = login._id === '61b5a627e333e2a780ebc75a' ? true : disabler;

  const [dropNoteError, setDropNoteError] = useState('');

  const handleDropNoteChange = (e) => {
    const note = e.target.value;
    setOrderNote(note);
    if (note?.length > 35) {
      setDropNoteError(
        'The note you entered exceeds the maximum allowed character limit. Please shorten your input to 35 characters or less.'
      );
      return;
    }
    setDropNoteError('');
  };
  const checkUser = (e,total) => {
    if (lpoUsers.includes(login.ecvId) && orderNote.length === 0){
      setDropNoteError(
        'LPO number is required.'
      );
      return;
    }

    placeOrder(e, total);
  }

  return (
    <>
      <div className='col-lg-3' style={{ borderRadius: 10 }}>
        {step === 5 && (
          <>
            <div
              className='order-summary-part'
              style={{ marginBottom: '20px' }}
            >
              <div className='messageField' style={{ marginBottom: '10px' }}>
                <h3 className='mb-4'>Buyer Info</h3>
                <div className='subtotal-row d-flex justify-content-between mb-3'>
                  <span className='font-weight-bold'>Name</span>
                  <span></span>
                  <span>
                    <span className='text-secondary'>
                      {login.details?.garage_name}
                    </span>
                  </span>
                </div>
                <div className='shopping d-flex justify-content-between'>
                  <span className='font-weight-bold mb-3'>Contact</span>
                  <span className='order-summary-currency'>
                    <span className='text-secondary'>
                      {formatPhoneNumber(login.phone)}
                      <CallRoundedIcon
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginTop: '-3px',
                        }}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && !Object.keys(selectedAddress).length && (
          <div className={classes.alert}>
            <Alert severity='error'>
              <Typography color='error'>
                Please select one of your address for deliveries.
              </Typography>
            </Alert>
          </div>
        )}
        <div className='order-summary-part'>
          <h3 className='mb-4'>Order Summary</h3>

          <div className='subtotal-row d-flex justify-content-between mb-3'>
            <span className='font-weight-bold'>Subtotal (Excl. VAT)</span>
            <span></span>
            <span>
              <span className='checkout-currency'>
                <ShowDynamicCurrency />
              </span>

              <span className='text-secondary'>
                <ShowDynamicPrice price={exc_tax_total} hideExc />
              </span>
            </span>
          </div>

          <div className='discount-row d-flex justify-content-between mb-3'>
            <span className='font-weight-bold'>Discount</span>
            <span className='order-summary-currency'>
              <span className='checkout-currency'>
                <ShowDynamicCurrency />
              </span>
              <span className='text-secondary'>
                <ShowDynamicPrice price={discount1} hideExc />
              </span>
            </span>
          </div>

          <div className='shopping d-flex justify-content-between'>
            <span className='font-weight-bold mb-3'>Shipping</span>
            <span className='order-summary-currency'>
              <span className='checkout-currency'>
                {' '}
                <ShowDynamicCurrency />
              </span>
              <span className='text-secondary'>
                {' '}
                <ShowDynamicPrice price={newShippingPrice} hideExc />
              </span>
            </span>
          </div>

          <div className='tax-row d-flex justify-content-between mb-3'>
            <span className='font-weight-bold'>Tax</span>
            <span className='order-summary-currency'>
              <span className='checkout-currency'>
                <ShowDynamicCurrency />
              </span>
              <span className='text-secondary'>
                <ShowDynamicPrice price={tax} hideExc />
              </span>
            </span>
          </div>

          <div className='shopping d-flex justify-content-between'>
            <span className='font-weight-bold mb-3'>Total</span>
            <span className='order-summary-currency'>
              <span className='checkout-currency'>
                {' '}
                <ShowDynamicCurrency />
              </span>
              <span className='text-secondary'>
                {' '}
                <ShowDynamicPrice price={total} hideExc />
              </span>
            </span>
          </div>

          <div className='shopping d-flex justify-content-between'>
            <span className='font-weight-bold mb-3'>From Wallet</span>
            <span className='order-summary-currency'>
              <span className='checkout-currency'>
                {' '}
                <ShowDynamicCurrency />
              </span>
              <span className='text-secondary'>
                {' '}
                <ShowDynamicPrice price={walletAmount} hideExc />
              </span>
            </span>
          </div>

          <div className='total-row d-flex justify-content-between h4 py-4'>
            <span className='font-weight-bold' style={{ fontSize: '14px' }}>
              Total Amount:
            </span>
            <span style={{ fontSize: 15 }}>
              <span style={{ fontSize: '10px' }}>
                <ShowDynamicCurrency />
              </span>

              <span style={{ paddingLeft: 3 }}>
                <ShowDynamicPrice
                  price={Math.abs(
                    Number(total?.toFixed(2)) - Number(walletAmount?.toFixed(2))
                  )}
                  hideExc
                />
              </span>
            </span>
          </div>
          {step === 1 && (
            <>
              <button
                className='btn btn-primary w-100 text-center mt-2'
                type='button'
                disabled={flags.current}
                onClick={(e) =>
                  stepForward(e, 2, true, 'enter_shipping_detail_click')
                }
              >
                Enter shipping details
              </button>
              <div style={{ width: '100%' }}>
                {flags.current && (
                  <p
                    style={{
                      color: 'red',
                      fontStyle: 'italic',
                      lineHeight: '15px',
                      marginTop: 5,
                    }}
                  >
                    Your cart items quantity exceeded stock quantity.!{' '}
                  </p>
                )}
              </div>
            </>
          )}
          {step === 2 && (
            <button
              className='btn btn-primary w-100 text-center mt-2'
              type='button'
              onClick={(e) => {
                if (
                  selectedAddress?.deliveryLocation &&
                  selectedAddress?.latLong?.lat &&
                  selectedAddress?.latLong?.lng
                ) {
                  setHasSelectedCoordinates(true)
                  stepForward(e, 3, true, 'enter_shipping_address_click')
                  return
                }
                setHasSelectedCoordinates(false)
              }}
              disabled={!Object.keys(selectedAddress).length}
            >
              Enter shipping address
            </button>
          )}
          {step === 3 && (
            <button
              className='btn btn-primary w-100 text-center mt-2'
              type='button'
              onClick={(e) => stepForward(e, 4, true, 'make_a_payment_click')}
            >
              Make a payment
            </button>
          )}
          {step === 4 && (
            <button
              className='btn btn-primary w-100 text-center mt-2'
              type='button'
              onClick={(e) => stepForward(e, 5, true, 'review_order_click')}
            >
              Review Order
            </button>
          )}
          {step === 5 && (
            <>
              <div
                className='messageField'
                style={{
                  display: 'flex',
                  marginBottom: '30px',
                  color: '#4F4F4F',
                  marginTop: 20,
                }}
              >
                <BorderColorIcon
                  fontSize='medium'
                  style={{
                    color: 'rgb(243 147 44)',
                    marginRight: '5px',
                    marginTop: '10px',
                  }}
                />
                <TextField
                  fullWidth={true}
                  id='standard-textarea'
                  placeholder={placeholder}
                  onChange={handleDropNoteChange}
                  onBlur={() =>
                    ga4Events({
                      action: 'cart_enter_drop_note',
                      category: 'Cart',
                    })
                  }
                  value={orderNote}
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={dropNoteError || ''}
                  helperText={dropNoteError || ''}
                />
              </div>
              <button
                className='btn btn-primary w-100 text-center mt-2'
                type='button'
                disabled={disabler || dropNoteError}
                onClick={(e) => checkUser(e,total)}
              >
                {disabler ? 'Finalizing order...' : 'Finalize Order'}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
