import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { SetAccountType } from "../../../Store/action/signupActions";

class SellerRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setAccountType = this.setAccountType.bind(this);
  }

  setAccountType(e, type) {
    e.preventDefault();
    this.props.setAccountType(type);
    this.props.history.push("/register/verify-email");
  }

  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="registration-page">
                <section className="register-by-buyer">
                  <h2>REGISTER AS A SELLER</h2>
                  <p>
                    You are looking to sell car spare parts and get new buyers?
                    Please follow the steps after. One of our agent will get in
                    contact with you to process your registration.
                  </p>
                  <button
                    onClick={(e) => this.setAccountType(e, "SELLER")}
                    className="btn btn-outline-primary"
                  >
                    SELLER REGISTRATION
                  </button>
                </section>
                <div className="separator-line">
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    setAccountType: (data) => dispatch(SetAccountType(data)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(SellerRegister);
