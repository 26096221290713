import React, { Component, Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import LogIn from "../../content/element/modal/signIn";
import ScrollButton from "../../page/ScrollButton";
import { ga4Events } from "../../../utils";
// import ReactGA from "react-ga4";
import { Grid, Link, Tooltip } from "@mui/material";
import { LinkedIn, Twitter, Facebook, Instagram, Publish } from '@material-ui/icons'
import axios from "axios";
import { NotifyToastWithButtons } from "../../common/ToastNotifier/ToastNotifier";
import LoginModal from "../../content/Login/LoginModal";
import "./footer.css";
import { CallRoundedIcon, FileUploadIcon, WhatsAppIcon } from "../../common/MaterialIcons";

const allCatList = [
  "Service Parts",
  "Suspension Parts",
  "Drivetrain System",
  "Engine Parts",
  "Batteries & Electricals",
  "Lubricants & Fluids",
  "Engine Cooling System",
  "Body & Chassis Parts",
  "Air Conditioning",
  "Steering Parts",
  "Brake Parts",
  "Fuel & Engine Control",
  "Intake & Exhaust System",
  "Interior & Exterior Accessories",
  "Lighting",
  "Others",
  "Wheels & Tires",
  "Windshield Cleaning System"
]

let miscellaneousOptions = [
  { name: 'About Us', link: '/about-us' },
  { name: 'FAQ', link: '/faqs' },
  { name: 'Become a seller', link: '/become-seller' },
  { name: 'Terms and Conditions', link: '/terms' },
  { name: 'Become a buyer', link: '/become-buyer' },
  { name: 'Privacy Policy', link: '/privacy' },
  { name: 'Contact Us', link: '/contact' },
  { name: 'Return and Refund Policy', link: '/refundprivacy' },
  { name: 'News', link: '/news' },
]

const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [categories, setCategories] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [slicedCategories, setSlicedCategories] = useState([]);
  const [redirectURL, setRedirectURL] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isHelpModal, setIsHelpModal] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("login"));

  //toggle for login
  const toggle = (banner) => {
    setIsModalOpen(!isModalOpen);
    setRedirectURL(banner);
  }
  const toggleForLogin = () => {
    setIsModalOpen(false);
  }

  const getCategories = () => {
    let sortCategories = []
    for (let a of allCatList) {
      for (let b of props.allCategories) {
        if (a === b.name) {
          sortCategories.push(b)
        }
      }
    }
    setCategories(props.allCategories);
    setSortedCategories(sortCategories);
    setSlicedCategories(sortCategories.slice(0, 6));
  }

  useEffect(() => {
    getCategories();
  }, [props.allCategories])

  const handleNavClick = (e, section_name, section_link, isLink) => {
    ga4Events({ action: 'footer_section_click', category: 'Navigation', section_name, section_link: isLink ? section_link : `${window.location.origin}${section_link}` })
  }

  const alterCategoryCount = () => {
    if (slicedCategories.length === 6) {
      setSlicedCategories(sortedCategories);
    } else {
      setSlicedCategories(sortedCategories.slice(0, 6));
    }
    ga4Events({
      action: `footer_parts_view_${slicedCategories.length == 6 ? 'all' : 'less'}_click`,
      category: "Listing",
      subcategory_id: `${slicedCategories.length == 6 ? 'all' : 'less'}`,
      subcategory_name: `footer_parts_view_${slicedCategories.length == 6 ? 'all' : 'less'}`
    })
  }

  const validateEmail = (mail) => {
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!mail.match(pattern)) {
      setError("Please enter a valid email");
      setTimeout(function () {
        setError(null);
      }, 3000);
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const resp = await axios.post("/api/newsletter", { email: email });
      setEmail("");
      NotifyToastWithButtons(resp.data?.message);

      ga4Events({
        action: "newsletter_subscribe",
        category: "Support",
        email: email,
        subscribe_status: true,
      })
    }
  };

  const handleGaEvent = (args) => {
    const data = {
      category: 'Listing',
      ...args
    }
    ga4Events(data)
  }

  const handleGaEventWhatsApp = () => {
    ga4Events({
      action: 'whatsapp_icon_click',
      category: 'Chat'
    });
  }

  return (
    <Fragment>
      <LoginModal
        isModalOpen={isModalOpen}
        toggle={toggle}
        loginToggle={toggleForLogin}
        redirectURL={redirectURL}
      />
      {/* <footer className="footer-three footer-grey">
        <div className="container-fluid">
          <Grid container spacing={2} p={3} pb={1}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item>
                <Typography variant="h6" style={{ fontSize: 18, fontWeight: 700, color: "#333333", marginLeft: "12px" }}>Parts</Typography>
              </Grid>
              <Grid item>
                <Button variant="text" style={{ color: '#1D71B8', fontWeight: '700' }}
                  className="viewAllParts"
                  onClick={alterCategoryCount}>{slicedCategories.length === 6 ? 'View all' : 'View less'}</Button>
              </Grid>
            </Grid>
            {slicedCategories && slicedCategories.map((item, index) => {
              return (
                <Grid item xs={12} md={4}>
                  <Typography variant="h6"
                    style={{ fontSize: 13, fontWeight: 700, color: "#333333" }}>
                    {item.name}
                  </Typography>
                  {item.subCategories && item.subCategories.map((sub, ind) => {
                    let activeCategory = item?.name?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll("/", "-");
                    let subCat = sub?.name?.toLowerCase()?.replaceAll(" ", "-")?.replaceAll("/", "-");
                    return (
                      <Grid item
                        onClick={() => handleGaEvent({ action: 'top_subcategory_click', subcategory_id: sub?._id, subcategory_name: sub?.name })}
                      >
                        <Link href={currentUser ? `/spare-parts/${activeCategory}/${subCat}` : `#`} underline="none"
                          onClick={() => !currentUser && toggle(`/spare-parts/${activeCategory}/${subCat}`)}
                          style={{ color: '#7a82a6' }}>

                          <Typography variant="h6"
                            style={{ fontSize: 12, fontWeight: 400, color: "#333333", lineHeight: '25px' }}>
                            {sub.name}
                          </Typography>
                        </Link>
                      </Grid>
                    )
                  })}
                </Grid>)
            })}
          </Grid>
        </div>
        <Grid container spacing={4} p={5}>
          <Grid item sx={12} md={4}>
            <Grid container>
              <Grid item sx={12} md={12}>
                <div style={{ display: 'flex', alignItems: 'end' }}>
                  <NavLink to="/" className="footer-logo">
                    <ScrollButton />
                  </NavLink>
                </div>
              </Grid>
              <Grid item sx={12}>
                <div className="leading">UAE's leading spare part market</div>
                <div class="row-about">
                  {miscellaneousOptions && miscellaneousOptions?.map((item, index) => {
                    return (
                      <div className="column-about subleading">
                        <NavLink className="subleading" to={item.link} onClick={(e) => handleNavClick(e, item.link, `/${item.link}`)}>
                          {item.name}
                        </NavLink>
                      </div>
                    )
                  })}
                </div>
              </Grid>
              <Grid item sx={12} style={{ marginTop: '20px' }}>
                <Link href="https://www.linkedin.com/company/buyparts24/" target="_blank" className="social">
                  <LinkedIn style={{ scale: '1.4' }} />
                </Link>
                <Link href="https://twitter.com/buyparts24" target="_blank" className="social">
                  <Twitter style={{ scale: '1.4' }} />
                </Link>
                <Link href="https://www.facebook.com/BuyParts24" target="_blank" className="social">
                  <Facebook style={{ scale: '1.4' }} />
                </Link>
                <Link href="https://www.instagram.com/buyparts24" target="_blank" className="social">
                  <Instagram style={{ scale: '1.4' }} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={12} md={4}>
            <Grid container spacing={2}>
              <Grid item>
                <div className="leading">About</div>
              </Grid>
              <Grid item>
                <p className="subleading">
                  Find spare parts for any make, models and versions of cars in UAE. The leading multi vendor marketplace for car parts in the region.
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={12} md={4}>
            <Grid container>
              <Grid item sx={12} style={{ paddingBottom: '1rem' }}>
                <Typography variant='h6'>Have a query to upload</Typography>
                <Button style={{ backgroundColor: '#FF5733', color: '#fff', padding: '10px', borderRadius: '10px' }} variant="contained" startIcon={<Publish />}> UPLOAD ENQUIRY</Button>
              </Grid>
              <Grid item sx={12}>
                <Typography variant='h6'>Subscribe to our Newsletter</Typography>
                <Paper
                  component="form"
                  onSubmit={handleFormSubmit}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <InputBase
                    name="email"
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter email"
                    inputProps={{ 'aria-label': 'enter email' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <Button variant="outlined">SUBSCRIBE</Button>
                  </IconButton>
                </Paper>
                <p>You'll get an email weekly</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={12} md={4} pb={5} pl={5}>
            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item>
                <p className="m-0 copy-text">
                  © 2023 BuyParts24. All rights reserved.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer> */}
      <footer className="footer-view">
        <div className={`help-pop ${isHelpModal ? 'showModal' : 'hideModal'}`} >
          <ul >
            <a onClick={handleGaEventWhatsApp} href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}`} target='_blank'>
              <li className="help-list">
                <WhatsAppIcon className="help-svg" style={{ color: '#44c052' }} />
                WhatsApp
              </li>
            </a>
            <div onClick={(e) => {
              setIsHelpModal(false);
            }}>
              <NavLink
                to='/contact'>
                <li className="help-list">
                  <CallRoundedIcon className="help-svg" />
                  Contact Us
                </li>
              </NavLink>
            </div>
          </ul>
        </div>

        <button
          type="submit"
          className="btn btn-help-view"
          onClick={() => setIsHelpModal(!isHelpModal)}
        >
          <img style={{ marginLeft: '-1rem' }}
            src="/assets/img/help.svg"
            alt="Help"
          /> Help
        </button>

        <Grid container spacing={{ md: 4, xs: 2 }} pl={{ md: 8, xs: 2 }} pr={{ md: 8, xs: 2 }} pt={{ md: 4, xs: 2 }}
          mb={{ md: 8, xs: 2 }}>
          <Grid item sx={12} md={4} sm={6}>
            <div className="leading">About</div>
            <p className="subleading wd-80">
              Find spare parts for any make, models and versions of cars in UAE. The leading multi vendor marketplace for car parts in the region.
            </p>
          </Grid>
          <Grid item sx={12} md={4} sm={6}>
            <div className="leading">Region’s largest auto parts digital distributor</div>
            <div class="row-about">
              {miscellaneousOptions && miscellaneousOptions?.map((item, index) => {
                return (
                  <div className="column-about subleading">
                    <NavLink className="subleading" to={item.link} onClick={(e) => handleNavClick(e, item.link, `/${item.link}`)}>
                      {item.name}
                    </NavLink>
                  </div>
                )
              })}
            </div>
          </Grid>
          <Grid item sx={12} md={4} sm={12} >
            <Grid container >
              <Grid item sx={12} md={12} sm={6} px={{ sm: 2 }} width={'100%'}>
                <div style={{ justifyContent: 'end', display: 'flex' }}>
                  <Link href="https://www.linkedin.com/company/buyparts24/" target="_blank" className="social" style={{ marginTop: 'auto', marginBottom: "1px" }}>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.78255 2.16652C3.78233 2.60855 3.60652 3.03239 3.29381 3.34479C2.98109 3.6572 2.55708 3.83258 2.11505 3.83236C1.67302 3.83214 1.24919 3.65633 0.936785 3.34361C0.624381 3.0309 0.448998 2.60688 0.449219 2.16486C0.44944 1.72283 0.625247 1.29899 0.937964 0.98659C1.25068 0.674185 1.67469 0.498803 2.11672 0.499024C2.55875 0.499245 2.98258 0.675051 3.29499 0.987768C3.60739 1.30049 3.78277 1.7245 3.78255 2.16652ZM3.83255 5.06652H0.499219V15.4999H3.83255V5.06652ZM9.09922 5.06652H5.78255V15.4999H9.06589V10.0249C9.06589 6.97486 13.0409 6.69152 13.0409 10.0249V15.4999H16.3326V8.89152C16.3326 3.74986 10.4492 3.94152 9.06589 6.46652L9.09922 5.06652Z" fill="#1A0451" />
                    </svg>
                  </Link>
                  <Link href="https://twitter.com/buyparts24" target="_blank" className="social">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.52373 6.77566L15.4811 0H14.0699L8.89493 5.88201L4.7648 0H0L6.24693 8.89549L0 15.9999H1.4112L6.87253 9.78701L11.2352 15.9999H16M1.92053 1.04126H4.08853L14.0688 15.0098H11.9003" fill="#1A0451" />
                    </svg>
                  </Link>
                  <Link href="https://www.facebook.com/BuyParts24" target="_blank" className="social" style={{ marginTop: 'auto', marginBottom: "1px" }}>
                    <svg width="11px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                  </Link>
                  <Link href="https://www.instagram.com/buyparts24" target="_blank" className="social">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.5013 0.666992H12.5013C15.168 0.666992 17.3346 2.83366 17.3346 5.50033V12.5003C17.3346 13.7822 16.8254 15.0116 15.919 15.918C15.0126 16.8244 13.7832 17.3337 12.5013 17.3337H5.5013C2.83464 17.3337 0.667969 15.167 0.667969 12.5003V5.50033C0.667969 4.21845 1.17719 2.98907 2.08362 2.08264C2.99005 1.17622 4.21942 0.666992 5.5013 0.666992ZM5.33464 2.33366C4.53899 2.33366 3.77592 2.64973 3.21332 3.21234C2.65071 3.77495 2.33464 4.53801 2.33464 5.33366V12.667C2.33464 14.3253 3.6763 15.667 5.33464 15.667H12.668C13.4636 15.667 14.2267 15.3509 14.7893 14.7883C15.3519 14.2257 15.668 13.4626 15.668 12.667V5.33366C15.668 3.67533 14.3263 2.33366 12.668 2.33366H5.33464ZM13.3763 3.58366C13.6526 3.58366 13.9175 3.69341 14.1129 3.88876C14.3082 4.08411 14.418 4.34906 14.418 4.62533C14.418 4.90159 14.3082 5.16654 14.1129 5.3619C13.9175 5.55725 13.6526 5.66699 13.3763 5.66699C13.1 5.66699 12.8351 5.55725 12.6397 5.3619C12.4444 5.16654 12.3346 4.90159 12.3346 4.62533C12.3346 4.34906 12.4444 4.08411 12.6397 3.88876C12.8351 3.69341 13.1 3.58366 13.3763 3.58366ZM9.0013 4.83366C10.1064 4.83366 11.1662 5.27265 11.9476 6.05405C12.729 6.83545 13.168 7.89526 13.168 9.00033C13.168 10.1054 12.729 11.1652 11.9476 11.9466C11.1662 12.728 10.1064 13.167 9.0013 13.167C7.89623 13.167 6.83643 12.728 6.05502 11.9466C5.27362 11.1652 4.83464 10.1054 4.83464 9.00033C4.83464 7.89526 5.27362 6.83545 6.05502 6.05405C6.83643 5.27265 7.89623 4.83366 9.0013 4.83366ZM9.0013 6.50033C8.33826 6.50033 7.70238 6.76372 7.23353 7.23256C6.76469 7.7014 6.5013 8.33728 6.5013 9.00033C6.5013 9.66337 6.76469 10.2993 7.23353 10.7681C7.70238 11.2369 8.33826 11.5003 9.0013 11.5003C9.66434 11.5003 10.3002 11.2369 10.7691 10.7681C11.2379 10.2993 11.5013 9.66337 11.5013 9.00033C11.5013 8.33728 11.2379 7.7014 10.7691 7.23256C10.3002 6.76372 9.66434 6.50033 9.0013 6.50033Z" fill="#1A0451" />
                    </svg>
                  </Link>
                </div>
              </Grid>
              <Grid item sx={12} md={12} sm={6} mt={{ md: 3, xs: 2, sm: 0 }} px={{ sm: 2 }} width={'100%'}>
                <div className="leading">Have a enquiry to upload?</div>
                <NavLink
                  to={currentUser ? "/quotations" : ""}
                  className="btn search-btn upload"
                  onClick={() => !currentUser && toggle(`/quotations`)}
                >
                  <FileUploadIcon style={{ marginTop: '-3px' }} /> UPLOAD ENQUIRY
                </NavLink>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <div className='container-fluid copyright'>
          © 2023 BuyParts24 All Rights Reserved
        </div>
      </footer>

      {/* <footer style={{ backgroundColor: '#FFFCF7', boxShadow: "0 0px 3px 2px rgba(0, 0, 0, 0.2)" }}>
        <div className='container-fluid catalog-block' style={{ paddingTop: "3rem", paddingBottom: '3rem' }}>
          <div className="row">
            <div className="col-2">
              <NavLink to="/" className="footer-logo">
                <ScrollButton />
              </NavLink>
            </div>
            <div className="col-10" style={{ justifyContent: 'end', display: 'flex' }}>
              <Link href="https://www.linkedin.com/company/buyparts24/" target="_blank" className="social">
                <LinkedIn />
              </Link>
              <Link href="https://twitter.com/buyparts24" target="_blank" className="social">
                <Twitter />
              </Link>
              <Link href="https://www.facebook.com/BuyParts24" target="_blank" className="social">
                <Facebook />
              </Link>
              <Link href="https://www.instagram.com/buyparts24" target="_blank" className="social">
                <Instagram />
              </Link>
            </div>
            <div className="col-4 mt-5">
              <div className="leading">About</div>
              <p className="subleading wd-80">
                Find spare parts for any make, models and versions of cars in UAE. The leading multi vendor marketplace for car parts in the region.
              </p>
            </div>
            <div className="col-4 mt-5">
              <div className="leading">UAE's leading spare part market</div>
              <div class="row-about">
                {miscellaneousOptions && miscellaneousOptions?.map((item, index) => {
                  return (
                    <div className="column-about subleading">
                      <NavLink className="subleading" to={item.link} onClick={(e) => handleNavClick(e, item.link, `/${item.link}`)}>
                        {item.name}
                      </NavLink>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-4 mt-5">
              <div className="leading">Have a query to upload</div>
              <button
                type="submit"
                className="btn search-btn"

              >
                <FileUploadIcon style={{ marginTop: '-3px' }} /> UPLOAD ENQUIRY
              </button>
              <div className="leading" style={{ marginTop: '3rem' }}>Subscribe to our Newsletter</div>
              <div style={{ display: 'flex' }}>
                <input type="text" class="form-control" placeholder="Enter Email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  style={{marginRight: '1rem'}}
                />
                <button type="button" class="btn btn-outline-warning" style={{ backgroundColor: 'white' }}>SUBSCRIBE</button>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid copyright'>
          © 2023 BuyParts24 All Rights Reserved
        </div>
      </footer> */}

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        buttonClasses="accept-btn"
        containerClasses="cookie-message"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000", alignItems: "center" }}
        expires={150}
      >
        The cookie settings on this website are set to 'allow all cookies' to
        give you the very best experience. Please click Accept Cookies to
        continue to use the site
      </CookieConsent>
      <LogIn />
    </Fragment>
  );

}


const mapStateToProps = (state) => {
  return {
    login: state.login,
    allCategories: state.category.allCategories

  };
};
const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(Footer);

// export default withTranslation()(Footer);
