import React from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import qs from "query-string";

const ThankYou = () => {
    const history = useHistory()
    const orderId = qs.parse(history.location.search).order
    return (
        <>
            <div className="container mt-5 mb-5">
                <div className="thankyou-container">
                    <div className="thankyou-imageicon">
                        <img src="/assets/img/success.png" alt="success-png" />
                    </div>
                    <div className="thankyou-meta">
                        <h2>Thank you for joining the buyparts.</h2>
                    </div>
                    <div className="thankyou-btn-container ">
                        <NavLink to="/" className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png" alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            Back to home
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYou
