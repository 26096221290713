import React, { useState } from "react";
import LoginModal from "../../Login/LoginModal";

export default function VisitorModal({ showModal, onClose, msg }) {
  //const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [isModalOpen, setIsModalOpen] = useState(showModal ? showModal : false);
  const [isVisitor, setIsVisitor] = useState(true);

  const toggle = (e) => {
    e.preventDefault();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <LoginModal isModalOpen={isModalOpen} toggle={(e) => toggle(e)} isVisitor={isVisitor} />
    </div>
  );
}
