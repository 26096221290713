import React from "react";
import { NavLink } from "react-router-dom";
import { handleHomepageSectionEvents } from "../../../../utils";

const Section = ({ section, children }) => {
  return (
    <>
      <div style={{ marginTop: "4rem" }} className="section-title">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h1 className="mb-0 about_us_title">{section?.title}</h1>
          </div>

          {section?.sectionType === "product_slider" ? (
            <NavLink
              to={`/collections/${section?.sectionData?.ids?.[0]?.slug}`}
              onClick={() =>
                handleHomepageSectionEvents(section, {
                  collection_id: section?.sectionData?.ids?.[0]?._id,
                  collection_name: section?.sectionData?.ids?.[0]?.title,
                })
              }
              style={{ fontWeight: "700" }}
            >
              VIEW ALL
            </NavLink>
          ) : null}
        </div>
      </div>
      {children}
    </>
  );
};

export default Section;
