import React from "react";
import BuyerDashboard from "./buyerDashboard";
import SellerDashboard from "./SellerDashboard";
import { Redirect } from "react-router-dom";

const myDashboard = ({ currentUser }) => {
  if (!currentUser?.role) return <div>No Data Found</div>
  if (currentUser.role === "BUYER") return <BuyerDashboard />;
  if (currentUser.role === "SELLER") return <SellerDashboard />;

};

export default myDashboard;
