import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import { PageBanner } from "../content/element/page-banner";
import LastOrder from "../content/element/LastOrder/LastOrder";
import { Row, Col } from "reactstrap";
import PageTitle from "../content/element/PageTitle";
import Pending from "../content/element/UserStatus/Pending";

class BuyerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardFor: "SELLER",
      activeTab: "1",
      activeTabDate: "1",
      modal: false,
      messagesType: "AllMessages",
    };
  }

  render() {
    const { isLoading } = this.props.list;
    const { t } = this.props;
    const currentUser = JSON.parse(localStorage.getItem("login"));
    if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;

    return (
      <Fragment>
        {/* <LoadingOverlay active={isLoading} spinner text={t("loading_listing")}> */}
          <PageBanner title="Dashboard" />
          <section style={{ backgroundColor: "#F5F7FC" }} className="pt-2 pb-5">
            <div className="container">
              <PageTitle title="My Dashboard" />
              <Row className="mt-2">
                <Col sm="12">
                  <div className="mt-4">
                    <LastOrder />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        {/* </LoadingOverlay> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    dashboard: state.dashboard,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(BuyerDashboard);
