import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NavLink } from "react-router-dom";
import { popUp, stringToUrl } from "../../../../utils";
import { useHistory } from "react-router";

import { SetLoading } from "../../../../Store/action/listingActions";
import { uploadFileToAssets } from "../../../../services/upload";

export default function SearchImageSKU({ showModal, onClose }) {
  const [image, setImage] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory();
  const [isImgSearched, setIsImgSearched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [skus, setSkus] = useState([])

  const onChange = e => {
    setImage(e.target.files[0])
  }

  const handleCancle = () => {
    setImage(null);
    setIsImgSearched(false)
    SetLoading(false)
    setSkus([])
    onClose()
  }
  const searchByImage = async () => { 
    setIsLoading(true)
    dispatch(SetLoading(true))   
      uploadFileToAssets({
        file: image,
        type: "uploads"
      })
      .then((res) => {
        setIsLoading(false)
        dispatch(SetLoading(false))
        onClose()

        history.push({
          pathname: `/all-listings`,
          search: `?type=image`,
          state: {
              extraData: {file_id: res?._id}
          }
      });
      })
      .catch((error) => {
        setIsImgSearched(false)
        setIsLoading(false)
        dispatch(SetLoading(false))
        popUp(error?.message ||"Could not get any SKU")
      })
  }

  return (
    <div>
      <Dialog
        open={showModal}
        hideBackdrop={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{!isImgSearched ? "Search SKU By Image" : `${skus.length} SKUs found.`}</DialogTitle>
        <DialogContent>
          {!isImgSearched ? <DialogContentText id="alert-dialog-description">
            Upload Image
            <input
              type="file"
              name="vin_by_image"
              onChange={onChange}
            />
            <br />
          </DialogContentText> :
            <DialogContentText id="alert-dialog-description">
              {
                skus?.map(s => {
                  let link = stringToUrl(s?.partSKU)
                  return (
                    <div key={s}>
                      <NavLink to={`/spare-part-details/${link}/${s?._id}?img=true`}>
                        {s?.partSKU} of seller {s?.user?.details?.company_name || s?.user?.name}
                      </NavLink>
                      <br />
                    </div>
                  )
                })
              }
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: 'auto', marginLeft: 10 }} onClick={handleCancle} color="primary">
            Cancel
          </Button>

          <Button onClick={searchByImage} className="go-to-register">
            {isLoading && (
              <i className="las la-spinner la-spin mr-2"></i>
            )}
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
