import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ShowMessageModal = ({ isModalOpen, toggle, message, title }) => {
    return (
        <>
            <Modal
                isOpen={isModalOpen}
                toggle={() => {
                    toggle();
                }}
                centered
                size="lg"
                style={{ maxWidth: '500px' }}
            >
                <ModalBody>
                    <div className='mb-3 d-flex justify-content-between px-4 py-1'>
                        <div></div>
                        <h3>{title && title}</h3>
                        <button
                            type='button'
                            className='close mr-2'
                            data-dismiss='modal'
                            aria-label='Close'
                            style={{ outline: 'none' }}
                            onClick={toggle}
                        >
                            <img
                                src='/assets/img/close.png'
                                alt='close'
                                style={{ fontSize: 30, height: 19 }}
                            />
                        </button>

                    </div>
                    <div className='mb-3 d-flex justify-content-center px-4 py-1' style={{ color: "black", fontSize: "14px" }}>
                        {message && message}
                    </div>

                </ModalBody>
            </Modal>
        </>
    );
};

export default ShowMessageModal;
