import axios from "axios";

export const getDeliveryTime = ({ type = "default" }) => {
    return async (dispatch) => {
        dispatch({
            type: "GET_DELIVERY_TIME_BEGIN",
        })
        let url = `/api/delivery-times`;
        let actionType = ""

        switch (type) {
            case "default":
                url = url + '?defaultDT=true'
                actionType = "SET_DEFAULT_DELIVERY_TIME"
                break;
            case "all":
                url = url + '?getAll=true'
                actionType = "SET_DELIVERY_TIME"
        }

        axios
            .get(url)
            .then(res => {
                dispatch({
                    type: actionType,
                    data: res.data?.deliveryTimes
                })
            })
            .catch(err => {
                dispatch({
                    type: "GET_DELIVERY_TIME_ERROR",
                })
            });
    };
}

export const setMaintainceMode = (data) => {
    return (dispatch) => {
        dispatch({type: "SET_MAINTAINACE_MODE", data});
    }
}

export const setLoading = (data) => {
    return (dispatch) => {
        dispatch({type: "SET_PAGE_LOADING", data});
    }
}