const initState = {
    selectedCatalog: "Make",
    catalogs: ['Make','Parts','Brands','Tires','Battery'],
    isCatalogLoading: false,
}

const CatalogReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_ALL_CATALOG_BEGIN':
            return {
                ...state,
                isCatalogLoading: true,
            }
        case 'GET_ALL_CATALOG_SUCCESS':
            return {
                ...state,
                catalogs: action.data?.catalogs,
                isCatalogLoading: false,
            }
        case 'GET_ALL_CATALOG_ERROR':
            return {
                ...state,
                isCatalogLoading: false,
            }
        case 'FILTER_CATALOG':
            return {
                ...state,
                selectedCatalog: action?.data?.selectedCatalog,
            }
        default:
            return state;
    }
};
export default CatalogReducer;
