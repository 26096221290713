import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { SectionTitle } from "../content/element/section-title";
import CardOfWhatWeDo from "./we_do_card";
import SoloutionCard from "./solution-card";
import BenefitsCard from "./benefits-card";
import SellerBenefitsCard from "../content/element/SellerBenefitsCard";
import OneStopShop from "../page/BecomeBuyer/Onestop/OnestopContent";
import Payment from "../page/BecomeBuyer/Payment/PaymentContent";
import Savings from "../page/BecomeBuyer/Savings/SavingContent";
import Delivery from "../page/BecomeBuyer/Delivery/DeliveryContent";
import Digitization from "../page/BecomeBuyer/Digitization/DigitizationContent";
import AccessContent from "../page/BecomeSeller/Access/AccessContent";
import OperationContent from "../page/BecomeSeller/Operations/OperationContent";
import DigitizationContent from "../page/BecomeSeller/Digitization/DigitizationContent";
import Benefits from "../common/Benefits";
import { withRouter } from "react-router";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "One-stop-shop",
      state: "buyer",
    };
    this.handleCardClick = this.handleCardClick.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  handleCardClick(res) {
    this.setState({ active: res });
  }

  getContent(item) {
    switch (item) {
      case "One-stop-shop":
        return <OneStopShop />;
      case "Payment":
        return <Payment />;
      case "Digitization":
        return <Digitization />;
      case "Savings":
        return <Savings />;
      case "Access":
        return <AccessContent />;
      case "Operations":
        return <OperationContent />;
      case "Digitization":
        return <DigitizationContent />;
      case "Delivery":
        return <Delivery />;
      default:
        return null;
    }
  }

  render() {
    const data = [
      {
        productOffering: [
          "Wide range across brands & categories",
          "VIN parts search",
          "Verified authorized parts supplier",
          "On-demand product availability",
          "High quality parts portfolio",
        ],
      },
      {
        logisticDelivery: [
          "On-demand, express delivery",
          "Tracking and notification",
        ],
      },
      {
        valueAddedServices: [
          "Extended client reach",
          "Real-time customer support",
          "Increased brand visibility",
        ],
      },
      {
        price: [
          "Price comparison feature",
          "Competitive prices ",
          "Price ranges across all categories",
        ],
      },
      {
        payment: ["Multiple payment options"],
      },
    ];

    const benefits = [
      [
        "Widest range across brands & categories",
        "Widest range across brands & categories",
        "Instant parts availability",
        "Instant parts availability",
        "Ratings & Reviews",
      ],
      [
        " Price competitiveness",
        "Multiple payment options",
        "Improved margins",
        "Single invoicing across various vendors",
        "Price comparison feature",
      ],
      [
        "Procurement cycle digitization",
        "Easy procurement:e-payment,e-bill",
        "Hassle-free e-ordering",
        "Live chat",
        "Digital request for quotation",
      ],
      [
        "Reduced procurement lead times",
        "No lost revenues due to product unavailability",
        "Revenue growth through Business Intelligence",
      ],
      [
        "Call Centre Support",
        "Reliable, on-time delivery",
        "Express delivery option",
        "Delivery Status Tracking",
      ],
      [
        "Wildest range across brands and categories",
        "Instant parts availability",
        "Verified and trusted part sellers",
        "High quality products",
        "Rating & Reviews",
      ],
      [
        "Informed pricing strategy & improved margins",
        "Business insights & dashboards",
        "Manage promotions and offers",
        "Streamlined deliveries",
        "Seamless order management",
        "Returns management",
      ],
      [
        "Procurement cycle digitization",
        "Easy procurement:e-payment,e-bill",
        "Hassle-free e-ordering",
        "Live chat",
        "Digital request for quotation",
      ],
    ];

    return (
      <Fragment>
        {/* About us */}
        <div
          className="breadcrumb-wrapper cover"
          style={{
            backgroundImage: 'url("/assets/img/section-banner.jpg")',
            height: 200,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="inner-container">
            <div style={{ minWidth: "1024px" }}>
              <h1>About Us</h1>
            </div>
          </div>
        </div>
        {/*  end of About us */}

        {/* Who we are */}
        <div className="who-are-we mt-1" style={{ padding: "30px 0", backgroundColor: "#F5F5F7" }}>
          <div className="inner-container">
            <SectionTitle title=" Who Are We" color="rgb(26, 4, 81)" />

            <div className="row">
              <div className="col-md-6 ">
                <p className="text-meta">
                  BuyParts24 is your digital auto parts marketplace connecting
                  distributors to workshops, while lowering operational costs,
                  improving customer reach, expanding market penetration and
                  providing a seamless trading experience
                </p>
              </div>
              <div
                className="col-md-6 d-flex align-items-center about_logo flex-column about-us-image"
                style={{ marginTop: "-69px" }}
              >
                <img
                  src="/assets/img/logo.svg"
                  alt="logoImage"
                  className="img-fluid  title-div about-us-img"
                  width="230"
                  style={{ marginTop: 30 }}
                />

                <div className="whoarewe-title">
                  <h1
                    className="mb-0 about_us_title"
                    style={{ color: "#1A0451", fontSize: 36 }}
                  >
                    UAE's leading auto parts marketplace
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of Who we are */}

        {/**what we do */}
        <div style={{ backgroundColor: "#F39200", padding: "30px 0" }}>
          <div className="inner-container">
            <SectionTitle
              title="What we do"
              content="We do not simply aggregate the current spare parts market, we enhance it"
              color="#fff"
            />
            <div className="row mt-4">
              <div className="col-md-6">
                <CardOfWhatWeDo
                  imgSrc="./assets/img/about-us/onestop.jpg"
                  info="One-stop
                  shop offering"
                />
              </div>
              <div className="col-md-6">
                <CardOfWhatWeDo
                  imgSrc="./assets/img/about-us/pricecompetitive.jpg"
                  info="Price-competitive
                  quality auto parts"
                />
              </div>
              <div className="col-md-6">
                <CardOfWhatWeDo
                  imgSrc="./assets/img/about-us/widestproduct.jpg"
                  info="Widest product
                  portfolio across all
                  part categories"
                  titleColor="white"
                />
              </div>
              <div className="col-md-6">
                <CardOfWhatWeDo
                  imgSrc="./assets/img/about-us/omnichannel.jpg"
                  info="Omni-channel
                  customer journey"
                  titleColor="#1A0451"
                />
              </div>
            </div>
          </div>
        </div>
        {/** end of what we do */}

        {/**We are here to provide solutions */}
        <div
          className="we-are-here-to-provide-solution"
          style={{ padding: "30px 0",backgroundColor: "#F5F5F7" }}
        >
          <div className="inner-container">
            <div>
              <SectionTitle
                title="We are here to provide solutions"
                content=""
                color="rgb(26, 4, 81)"
              />
              <div className="row mt-4">
                <div className="col-md-6">
                  <SoloutionCard
                    title="PRODUCT OFFERING"
                    data={data[0].productOffering}
                    imgSrc="./assets/img/about-us/product-offering.png"
                  />
                </div>{" "}
                <div className="col-md-6">
                  <SoloutionCard
                    title="LOGISTICS & DELIVERY"
                    data={data[1].logisticDelivery}
                    imgSrc="./assets/img/about-us/logistic.png"
                  />
                </div>{" "}
                <div className="col-md-6">
                  <SoloutionCard
                    title="VALUE ADDED SERVICES"
                    data={data[2].valueAddedServices}
                    imgSrc="./assets/img/about-us/value-added.png"
                  />
                </div>{" "}
                <div className="col-md-6">
                  <SoloutionCard
                    title="PRICE"
                    data={data[3].price}
                    imgSrc="./assets/img/about-us/price.png"
                  />
                </div>
                <div className="col-md-6">
                  <SoloutionCard
                    title="PAYMENT"
                    data={data[4].payment}
                    imgSrc="./assets/img/about-us/payment.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** end of We are here to provide solutions*/}

        {/**Benefits for buyers */}
        <div style={{ backgroundColor: "rgb(26, 4, 81)", padding: "30px 0" }}>
          <div className="inner-container">
            <div>
              <div className="about-us-section-benefit-title">
                <span className="benefits-section-left-side">
                  Benefits for {this.state.state}
                </span>
                <span
                  className="benefits-section-right-side"
                  onClick={() => {
                    this.setState({
                      state: this.state.state === "buyer" ? "seller" : "buyer",
                      active:
                        this.state.state === "seller"
                          ? "One-stop-shop"
                          : "Access",
                    });
                  }}
                >
                  for {this.state.state === "buyer" ? "seller" : "buyer"}
                </span>{" "}
              </div>
            </div>
            {this.state.state === "buyer" ? (
              <>
                <div className="row justify-content-between mt-4   hide-mobile">
                  <BenefitsCard
                    title="One-stop-shop"
                    imgSrc="./assets/img/become-buyer/one-stop.png"
                    handleCardClick={this.handleCardClick}
                    active={this.state.active}
                  />
                  <BenefitsCard
                    title="Payment"
                    imgSrc="./assets/img/become-buyer/payment.png"
                    handleCardClick={this.handleCardClick}
                    active={this.state.active}
                  />
                  <BenefitsCard
                    title="Digitization"
                    imgSrc="./assets/img/become-buyer/digitization.png"
                    handleCardClick={this.handleCardClick}
                    active={this.state.active}
                  />
                  <BenefitsCard
                    title="Savings"
                    imgSrc="./assets/img/become-buyer/savings.png"
                    handleCardClick={this.handleCardClick}
                    active={this.state.active}
                  />
                  <BenefitsCard
                    title="Delivery"
                    imgSrc="./assets/img/become-buyer/delivery.png"
                    handleCardClick={this.handleCardClick}
                    active={this.state.active}
                  />
                </div>
                <div
                  className="hide-mobile"
                  style={{ borderTop: "2px solid white", marginTop: "20px" }}
                />
              </>
            ) : (
              <>
                <div className="row  mt-4   hide-mobile">
                  <div className="col-md-4">
                    <SellerBenefitsCard
                      title="Access"
                      imgSrc="./assets/img/become-seller/access.png"
                      handleCardClick={this.handleCardClick}
                      active={this.state.active}
                    />
                  </div>
                  <div className="col-md-4">
                    <SellerBenefitsCard
                      title="Operations"
                      imgSrc="./assets/img/become-seller/operations.png"
                      handleCardClick={this.handleCardClick}
                      active={this.state.active}
                    />
                  </div>
                  <div className="col-md-4">
                    <SellerBenefitsCard
                      title="Digitization"
                      imgSrc="./assets/img/become-seller/digitization.png"
                      handleCardClick={this.handleCardClick}
                      active={this.state.active}
                    />
                  </div>
                </div>
                <div className="benefits-seller-line hide-mobile" />
              </>
            )}

            <div className="row d-flex benefits hide-mobile">
              {this.getContent(this.state.active)}
            </div>

            {/* for mobile view */}
            <div className="mobile only-mobile">
              <div className="row">
                {this.state.state === "buyer" ? (
                  <>
                    <div className="col-sm-12">
                      <Benefits
                        title="One-stop-shop"
                        data={benefits[0]}
                        imgSrc="./assets/img/become-buyer/one-stop.png"
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Payment"
                        data={benefits[1]}
                        imgSrc="./assets/img/become-buyer/payment.png"
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Digitization"
                        data={benefits[2]}
                        imgSrc="./assets/img/become-buyer/digitization.png"
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Savings"
                        data={benefits[3]}
                        imgSrc="./assets/img/become-buyer/savings.png"
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Delivery"
                        data={benefits[4]}
                        imgSrc="./assets/img/become-buyer/delivery.png"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-sm-12">
                      <Benefits
                        title="Access"
                        imgSrc="./assets/img/become-seller/access.png"
                        data={benefits[5]}
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Operations"
                        imgSrc="./assets/img/become-seller/operations.png"
                        data={benefits[6]}
                      />
                    </div>
                    <div className="col-sm-12">
                      <Benefits
                        title="Digitization"
                        imgSrc="./assets/img/become-seller/digitization.png"
                        data={benefits[7]}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* end */}

            <div className="row">
              <div className="col-lg-12">
                <button
                  onClick={() => this.props.history.push("/register")}
                  type="button"
                  className="btn benefits-button btn-outline-none"
                >
                  {this.state.state === "buyer"
                    ? "BECOME A BUYER"
                    : "BECOME A SELLER"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/** end of Benefits for buyers*/}

      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(AboutUs));
