import React, { useState } from "react";
import Header from "../../layout/header";
import PreHeader from "../../layout/pre-header";
import Footer from "../../layout/footer";
import data from "./data.json";
import "./index.css";

const FAQ = () => {
  let indexPlus;

  const [active, setActive] = useState(0);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };
  return (
    <>
    <div style={{background: '#F5F5F7'}}>
      <div
        className="breadcrumb-wrapper cover"
        style={{
          backgroundImage: 'url("/assets/img/section-banner.jpg")',
          height: 200,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="inner-container">
          <div style={{ minWidth: "1024px" }}>
            <h1>FAQs</h1>
          </div>
        </div>
      </div>
      <div className="content-wrapper mt-5" style={{background: '#F5F5F7'}}>
        <div className="inner-container">
          <div>
            <div className="pending-content">
              We are currently building this page
            </div>
            <div className="pending-content-meta">Come back soon.</div>
            <div className="pending-image-container mt-5">
              <img src="/assets/img/pending.png" alt="pending-image" />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default FAQ;
