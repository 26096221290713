import React from 'react'
import { Input, Label } from 'reactstrap';

import './style.css';
import { LocationOn } from '@material-ui/icons';

const SelectionInput = ({ label, name, input, setInput, btnLabel, eventFn, required = true }) => {
  return (
    <>
      <Label for={name}>{label} 
      {
        required &&
        <span style={{ color: 'red' }}>*</span>
      }
      </Label>
      <div className="row">
        <div className="col-md-12">
          <div className='selection__input-container' onClick={eventFn}>
            {
              input && <LocationOn className='selection__input-icon'/>
            }

            <Input
              type="text"
              className={`selection__input-input ${input ? "input-padding" : ""}`}
              name={name}
              id={name}
              placeholder={label}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              readOnly
            />

            <p className='text-button'>{btnLabel}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectionInput