import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { Button } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { popUp } from "../../../../../utils";
import GoogleMapLocation from "../../../../common/GoogleMapLocation";
import SelectionInput from "../../../../common/SelectionInput/index";
import { addAddressValidator } from "./validation";
import { phoneNoValidator } from "../../../../../services/numValidator";
import { addNewAddress } from "../../../../../Store/action/addressAction";

const Index = (props) => {
  const { isOpenAddModal, handleAddFormModal, stateData, locationData, regionData } = props;
  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [ship_to_site_code, SetShipToSideCode] = useState("");
  const [postCode, setPostCode] = useState("");
  const [contact, setContact] = useState("");
  const [emirate, setEmirates] = useState({ label: "", value: "" });
  const [region, setRegion] = useState({ label: "", value: "" });
  const [location, setLocation] = useState({ label: "", value: "" });
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [latLong, setLatLong] = useState({
    lat: 0,
    lng: 0
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  const { isSingleAddressLoading, isAddressUpdating } = useSelector(state => {
    return {
      isSingleAddressLoading: state.address?.isSingleAddressLoading,
      isAddressUpdating: state.address?.isAddressUpdating
    }
  })

  useEffect(() => {
    setInvalidPhoneNumber(false);
  }, [isOpenAddModal])

  const toggle = () => {
    setIsModalOpen(prev => !prev);
  }

  const update = (address, coordinate) => {
    setDeliveryLocation(address);
    setLatLong(coordinate);
  }

  const handleChangeState = (e) => {
    setEmirates({ label: e.value, value: e.value });
    setRegion({ label: "", value: "" });
    setLocation({ label: "", value: "" });
  };

  const handleChangeRegion = (e) => {
    setRegion({ label: e.value, value: e.value });
    setLocation({ label: "", value: "" });
  };

  const filteredRegions =
    regionData &&
    regionData.length &&
    regionData.filter((item) => {
      return item?.state?.name === emirate.value;
    });

  const stateOption =
    stateData?.state &&
    stateData.state.length &&
    stateData.state.map((state) => {
      return { label: state?.name, value: state?.name };
    });

  const regionOption =
    filteredRegions &&
    filteredRegions.length &&
    filteredRegions.map((region) => {
      return { label: region.name, value: region.name };
    });

  const filteredCities =
    locationData &&
    locationData.length &&
    locationData.filter((item) => {
      return item?.region?.name === region.value;
    });
  const sortedCities =
    filteredCities &&
    filteredCities.length > 0 &&
    filteredCities.sort((a, b) => a.name.localeCompare(b.name));

  const formattedCities =
    sortedCities &&
    sortedCities.length > 0 &&
    sortedCities.map((item) => {
      return { label: item?.name, value: item?.name };
    });

  const validator = React.useRef(addAddressValidator);

  const hasSelectedCoordinates = () => {
    if (currentUser?.role !== "BUYER") return true;

    if (latLong?.lat === 0 || latLong?.lng === 0) {
      popUp("Please select your exact location from map");
      return false;
    }
    return true;
  }

  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setAddress("");
    SetShipToSideCode("");
    setDeliveryLocation("");
    setEmirates({ label: "", value: "" });
    setRegion({ label: "", value: "" });
    setLocation({ label: "", value: "" });
    setPostCode("");
    setContact("");
    setLatLong({
      lat: 0,
      lng: 0
    })
    handleAddFormModal(false);
  }

  const submitAddress = () => {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (!invalidPhoneNumber && validator.current.allValid() && hasSelectedCoordinates()) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        address: address,
        ship_to_site_code: ship_to_site_code,
        state: emirate.value,
        region: region.value,
        location: location?.value,
        deliveryLocation: deliveryLocation,
        latLong: latLong,
        postcode: postCode,
        contact: contact,
        user: currentUser?._id,
      };

      dispatch(addNewAddress({ data, resetFields }))
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }

  };

  const handleBlur = async () => {
    try {
      const res = await phoneNoValidator({ contact });
      if (res?.valid === false) {
        setInvalidPhoneNumber(true);
      } else {
        setInvalidPhoneNumber(false);
      }
      setIsDisable(false);
    } catch (error) {
      popUp(error?.message)
    }
  };

  useEffect(() => {
    if (!isOpenAddModal) {
      validator.current.hideMessages();
    }
  }, [isOpenAddModal]);

  return (
    <>
      <Modal
        isOpen={isOpenAddModal}
        toggle={() => handleAddFormModal(false)}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader
          style={{ position: "relative", height: 50 }}
          toggle={() => handleAddFormModal(false)}
        >
          <div style={{ position: "absolute", right: 20 }}>Add address</div>
        </ModalHeader>
        <ModalBody style={{ padding: "20px" }}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  required={true}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />

                {validator.current.message(
                  "firstName",
                  firstName,
                  "required|firstName"
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">Last Name <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                {validator.current.message(
                  "lastName",
                  lastName,
                  "required|lastName"
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col>
              <FormGroup>
                <Label for="address">Address <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
                {validator.current.message("address", address, "required")}
              </FormGroup>
            </Col>
            {/* 
            <Col>
              <FormGroup>
                <Label for="ship_to_site_code">Site Code</Label>
                <Input
                  type="text"
                  name="ship_to_site_code"
                  id="ship_to_site_code"
                  placeholder="Site Code"
                  value={ship_to_site_code}
                  onChange={(e) => {
                    SetShipToSideCode(e.target.value);
                  }}
                />
                {validator.current.message(
                  "ship_to_site_code",
                  ship_to_site_code,
                  "siteCodeLimit"
                )}
              </FormGroup>
            </Col> */}
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="emirate">Emirates <span style={{ color: 'red' }}>*</span></Label>
                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={stateOption ? stateOption : []}
                  value={emirate}
                  onChange={(e) => {
                    handleChangeState(e);
                  }}
                />
                {validator.current.message("emirate", emirate?.value, "required")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="region">Regions <span style={{ color: 'red' }}>*</span></Label>
                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={regionOption ? regionOption : []}
                  value={region}
                  onChange={(e) => {
                    handleChangeRegion(e);
                  }}
                />
                {validator.current.message("region", region?.value, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col>
              <FormGroup>
                <Label for="location">Location<span style={{ color: 'red' }}>*</span></Label>
                <Select
                  className={`react-select`}
                  classNamePrefix="react-select"
                  options={formattedCities ? formattedCities : []}
                  value={location}
                  onChange={(e) => {
                    setLocation({ label: e.value, value: e.value });
                  }}
                />
                {validator.current.message("location", location?.value, "required")}
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for="postCode">PO Box <span style={{ color: 'red' }}>*</span></Label>
                <Input
                  type="text"
                  name="postCode"
                  id="postCode"
                  placeholder="Post Code"
                  value={postCode}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                />
                {validator.current.message("postCode", postCode, "required")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="contact">Contact No <span style={{ color: 'red' }}>*</span></Label>
                <PhoneInput
                  name="contact"
                  country={'ae'}
                  onlyCountries={['ae']}
                  value={contact}
                  placeholder="+971 123456789"
                  onBlur={handleBlur}
                  onFocus={() => setIsDisable(true)}
                  onChange={(contact) => setContact(contact)}
                />

                {validator.current.message(
                  "contact",
                  contact,
                  "required"
                )}
                {invalidPhoneNumber && (
                  <div className="text-danger">Invalid Contact Number.</div>
                )}
              </FormGroup>
            </Col>

            {
              currentUser?.role === "BUYER" &&
              <Col>
                <FormGroup>
                  <SelectionInput
                    input={deliveryLocation}
                    setInput={setDeliveryLocation}
                    label={"Delivery Location"}
                    name="deliveryLocation"
                    btnLabel="Change"
                    eventFn={toggle}
                  />
                  {validator.current.message("deliveryLocation", deliveryLocation, "required")}
                </FormGroup>
              </Col>
            }
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => handleAddFormModal(false)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={submitAddress} disabled={isDisable || isAddressUpdating}>
            {
              (isSingleAddressLoading || isAddressUpdating) &&
              <i className='las la-spinner la-spin mr-2'></i>
            }
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <GoogleMapLocation
        selectedAddress={deliveryLocation}
        selectedLatitude={latLong.lat}
        selectedLongitude={latLong.lng}
        isModalOpen={isModalOpen}
        toggle={toggle}
        updateLocation={update}
        label="Shipping Address"
      />
    </>
  );
};

export default Index;
