import React from "react";
import { useSelector} from "react-redux";
const ShowDynamicCurrency = (props) => {
  const { currency } = useSelector((state) => {
    return {
      currency: state.currency.currency
    };
  });
  return (
    <span style={{fontSize: '11px', color: '#333333', fontWeight: 400}}>
      {currency}
    </span>
  );
};

export default ShowDynamicCurrency;
