import SimpleReactValidator from "simple-react-validator";

export const addAddressValidator =
    new SimpleReactValidator({
        className: "text-danger",
        validators: {
            firstName: {
                message: "Name must be greater than 3 characters",
                rule: (val) => {
                    return val.length >= 3;
                },
                required: true,
            },
            lastName: {
                message: "Name must be greater than 3 characters",
                rule: (val) => {
                    return val.length >= 3;
                },
                required: true,
            },
            siteCodeLimit: {
                message: "Site Code should not be greater than 10 characters",
                rule: (val) => {
                    return val.length <= 10;
                },
                required: true,
            }
        },
    });