import axios from "axios";

export const getCategories = _ => {
    return async dispatch => {
        try {
            axios
                .get(`/api/categories`)
                .then(res => {
                    dispatch({
                        type: "GET_CATEGORIES",
                        data: res.data
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        } catch (err) {
            console.log(err)
        }
    };
};
export const getAllCategories = _ => {
    return async dispatch => {
        dispatch({
            type: "GET_ALL_CATEGORIES_LOADING", 
        });
        try {
            axios
                .get(`/api/categories/category-subcategory`)
                .then(res => {
                    const allCategories = res?.data;

                    const allSubCategories = allCategories?.length ? 
                                                allCategories?.reduce((a, b) => {
                                                    a = [...a, ...b?.subCategories];
                                                    return a;
                                                }, []) 
                                                    : [];

                    dispatch({
                        type: "GET_ALL_CATEGORIES",
                        allCategories: allCategories,
                        allSubCategories: allSubCategories
                    });
                })
                .catch(err => {
                    console.log(err)
                });
        } catch (err) {
            dispatch({
                type: "GET_ALL_CATEGORIES_ERROR", 
            })
            console.log(err)
        }
    };
};

export const getSubCategories = _ => {
    return async dispatch => {
        try {
            axios
                .get(`/api/subcategories/all`)
                .then(res => {
                    dispatch({
                        type: "GET_SUB_CATEGORIES",
                        data: res.data?.data
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        } catch (err) {
            console.log(err)
        }
    };
};