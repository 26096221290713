import React, { Component } from "react";
import Stepper from "./../common/Stepper";

class shopping extends Component {
  render() {
    return (
      <div>
        <Stepper />
      </div>
    );
  }
}

export default shopping;
