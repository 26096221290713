import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import { NavLink } from 'react-router-dom'
import MaterialTable from 'material-table'
import NoData from '../Nodata'
import CircularProgress from '@material-ui/core/CircularProgress'
import { popUp, ProductImage } from '../../../../utils'
import { Button, TablePagination, Tooltip } from '@material-ui/core'
import CssTextField from '../SearchField'
import Select from 'react-select'
import PageTitle from '../PageTitle'
import ShowDynamicCurrency from '../../../common/ShowDynamicCurrency'
import ShowDynamicPrice from '../../../common/ShowDynamicPrice'

class SellerOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: [],
      totalOrders: 0,
      selectedTabs: 'all',
      loader: false,
      searchField: '',
      openRemarkModal: false,
      actionState: {},
      perPage: 20,
      page: 0,
    }
  }

  handleChangepagination = (event, value) => {
    window.scrollTo(0, 0)
  }

  handleSearchChange = (e) => {
    const { value } = e.target
    this.setState({ searchField: value, page: 0 })
  }

  handleChangePage = (e, page) => {
    this.setState({
      page: page,
    })
  }

  handleChangeRowPerPage = (rowsPerPage) => {
    this.setState({
      perPage: rowsPerPage,
    })
  }

  async componentDidMount() {
    const { searchField, perPage, page } = this.state

    this.setState({ isLoading: true })
    await axios
      .get(
        `/api/order?buyer=${this.props.login._id}&size=${perPage}&page=${page + 1}&search=${searchField}`
      )
      .then((res) => {
        this.setState({
          orders: res?.data?.orders,
          totalOrders: res?.data?.total,
        })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
    this.setState({ isLoading: false })
  }

  async componentDidUpdate(prevProps, prevState) {
    const { searchField, perPage, page } = this.state
    if (
      prevState.searchField !== searchField ||
      prevState.perPage !== perPage ||
      prevState.page !== page
    ) {
      this.setState({ loader: true })
      await axios
        .get(
          `/api/order?buyer=${this.props.login._id}&size=${perPage}&page=${page + 1}&search=${searchField}`
        )
        .then((res) => {
          this.setState({
            orders: res?.data?.orders,
            totalOrders: res?.data?.total,
          })
          window.scrollTo(0, 0)
        })
        .catch((err) => {
          popUp('There was a problem with server. Please try again.')
        })
      this.setState({ loader: false })
    }
  }

  getItems(order) {
    return (
      <div className='d-flex order-listings-wrapper'>
        <>
          <div
            className='listing-item d-inline-block p-1 border rounded-circle'
            style={{ width: 40, height: 40 }}
          >
            <ProductImage
              avatar={
                order?.products[0]?.listing?.imageArray?.length > 0
                  ? order?.products[0]?.listing?.imageArray[0]
                  : '/assets/img/product-default.jpg'
              }
              className='item-image'
              alt={order?.products[0]?.listing?.partName}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'contain',
              }}
            />
            {/* <img
							src={
								order?.products[0]?.listing?.imageArray?.length > 0
									? order?.products[0]?.listing?.imageArray[0]
									: "/assets/img/product-default.jpg"
							}
							
							alt={order?.products[0]?.listing?.partName}
							style={{
								width: "100%",
								height: "100%",
								borderRadius: "50%",
							}}
						/> */}
          </div>
          {order.products.length > 1 ? (
            <span
              className='badge badge-light rounded-circle border p-1'
              style={{
                width: 40,
                height: 40,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              +{order.products.length - 1}
            </span>
          ) : (
            <></>
          )}
        </>
      </div>
    )
  }

  getPriceComma(str) {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  render() {
    const { orders, searchField, perPage, page, totalOrders } = this.state
    const columns = [
      {
        title: <span className='tryit'>Order Id</span>,
        field: '',
        render: (orders) =>
          orders?.order_id
            ? orders?.order_id
            : orders?._id.slice(0, 8).toUpperCase(),
        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
      },
      {
        title: <span className='tryit'>Order Date</span>,
        field: '',
        render: (orders) => (
          <>
            {moment(orders?.order_date).tz('Asia/Dubai').format('DD/MM/YYYY')}
            <br />
            {moment(orders?.order_date).tz('Asia/Dubai').format('hh:mm A')}
          </>
        ),
        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
      },
      {
        title: <span className='tryit'>Items</span>,
        field: '',
        render: (orders) => this.getItems(orders),
        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
      },
      {
        title: <span className='tryit'>Price</span>,
        field: 'total_price',
        render: (order) => (
          <>
            <ShowDynamicCurrency />
            &nbsp;
            <ShowDynamicPrice price={order?.total_price} hideExc />
          </>
        ),
        // render: (order) => `AED ${this.getPriceComma(Math.round((order?.total_price)?.toFixed(2)) )} `,
        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
      },
      {
        title: <span className='tryit'>Address</span>,
        field: '',
        render: (order) => <>{order?.delivery_address?.address}</>,

        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
      },
      {
        title: <span className='tryit'>View</span>,
        field: '',
        headerStyle: {
          backgroundColor: '#1a0451',
          color: '#fff',
          fontSize: '14px',
          fontWeight: 800,
          fontFamily: 'Montserrat',
        },
        render: (orders) => (
          <NavLink to={`/orders/view/${orders?._id}`}>
            <div className='primary-btn'>View Order</div>
          </NavLink>
        ),
      },
    ]

    const filteredOrders =
      orders &&
      orders.filter((item) => {
        return item?.order_id
          ?.toLowerCase()
          .includes(this.state?.searchField?.toLowerCase())
      })

    if (this.state.isLoading)
      return (
        <div
          style={{
            height: '90vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )
    if (
      !this.state.orders &&
      !this.state.searchField &&
      !this.state.loader
    )
      return (
        <NoData text='You have not made any sales yet. They will show here as soon as you make your first sale.' />
      )
    return (
      <section className='mt-2 mb-5 bgcolor-white '>
        <div className='container'>
          <PageTitle title='My Order' />
          <div className='dashboard-search-box'>
            <div className='ml-auto'>
              <CssTextField
                id='standard-basic'
                value={this.state.searchField}
                onChange={this.handleSearchChange}
                label='Search by ID'
              />
            </div>
            <div>
              <i className='las la-search la-search search-icon-datatable'></i>
            </div>
          </div>
          <div className='row  '>
            <div className='col-lg-12 '>
              <MaterialTable
                onChangeRowsPerPage={this.handleChangeRowPerPage}
                style={{ zIndex: 0 }}
                key={totalOrders}
                title={null}
                data={orders}
                columns={columns}
                isLoading={this.state.loader}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <img
                        src='/assets/img/nodata.png'
                        className='datatableimage'
                      />
                    ),
                  },
                }}
                options={{
                  search: false,
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return { backgroundColor: '#f2f2f2' }
                    }
                  },
                  paging: true,
                  pageSize: perPage,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [20, 50, 100],
                }}
                components={{
                  Pagination: (props) => (
                    <TablePagination
                      {...props}
                      count={totalOrders}
                      page={page}
                      rowsPerPage={perPage}
                      onChangePage={this.handleChangePage}
                      component='div'
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProp)(SellerOrder)