import { CircularProgress } from '@material-ui/core'
import axios from 'axios'
import React, { useState, useEffect } from 'react'

const ThreeDsPayment = () => {
  const threeDs = async () => {
    const resp = await window.NI.handlePaymentResponse(window.paymentRes, {
      mountId: '3ds_iframe',
      style: { width: 500, height: 500 },
    })
    const { status, error } = resp
    window.close()
  }
  useEffect(() => {
    threeDs()
  }, [window])

  return (
    <>
      <div id='3ds_iframe'></div>
    </>
  )
}

export default function ThreeDsPaymentModal() {
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  return (
    <div>
      <div
        style={{
          width: '200px',
          margin: '0 auto',
        }}>
        <img
          src='/assets/img/logo-footer.svg'
          alt='logoImage'
          style={{ width: '100%' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ThreeDsPayment />
      </div>
    </div>
  )
}
