import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import SellerListingCard from "../../content/element/card/seller-listing-card";
import Pagination from "@material-ui/lab/Pagination";
import { popUp } from "../../../utils";
import { emiratesOptions } from "../../../constants.js";
import NodataCard from "../../content/element/card/Nodatacard";

const Index = () => {
  const [sellerList, setSellerList] = useState([]);
  const [sellerListCount, setSellerListCount] = useState(0);
  const [sellerListFilter, setSellerListFilter] = useState([]);
  const [starts, setStarts] = useState("");
  const [emirate, setEmirate] = useState("");
  const [seller, setSeller] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10)

  useEffect(() => {
    axios
      .get("/api/users?role=SELLER&status=Approved&all=true")
      .then((res) => {
        setSellerListFilter(res.data?.users);
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.")
      });
  }, []);

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`/api/users?role=SELLER&status=Approved&search=${seller}&emirate=${emirate}&rating=${starts ? starts : ""}&page=${page}&limit=${limit}`)
      .then((res) => {
        setSellerList(res.data?.users);
        setSellerListCount(res.data?.total)
        setIsLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        popUp("There was a problem with server. Please try again.")
      }

      );
  }, [seller, emirate, starts, page, limit]);


  const [checked, setChecked] = useState(true);

  const handleChange = (event, emirate) => {
    setChecked(event.target.checked);
    setEmirate(emirate);
    setSeller("");
    setStarts(0);
    setPage(1);
  };
  const handleChange1 = (event, seller) => {
    setChecked(event.target.checked);
    setSeller(seller);
    setEmirate("");
    setStarts(0);
    setPage(1);
  };
  const handleStar = (val) => {
    setEmirate("");
    setSeller("");
    setStarts(val);
    setPage(1);
  };


  const handlePagination = (e, page) => {
    setPage(page);
  }

  return (
    <>
      <section className="all-listing-wrapper section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="atbd_generic_header bgcolor-white"
                style={{ background: "white", marginBottom: "1rem" }}
              >
                <div className="atbd_generic_header_title ">
                  <h4>Car parts sellers in the UAE ({sellerListCount})</h4>
                  <p className="m-0">
                    Selling their products on{" "}
                    <NavLink to="/"> BuyParts24.com</NavLink>{" "}
                  </p>
                </div>
              </div>
            </div>{" "}
            {/*<!-- ends: .col-lg-12 -->*/}
            <div className="col-lg-12 listing-items">
              <div className="row">
                <div className="col-lg-4 order-lg-0 order-0  mt-lg-0 mb-4">
                  <div className="listings-sidebar">
                    <div
                      className="search-area default-ad-search"
                      style={{ padding: "1rem" }}
                    >
                      <div className="mb-3">
                        <h5 className="mb-2">Filter by Sellers</h5>

                        {sellerListFilter?.map((sel) => {
                          return (
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary mb-1">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={sel?.details?.company_name}
                                name={sel?.details?.company_name}
                                checked={seller === sel?.details?.company_name}
                                onChange={(e) => handleChange1(e, sel?.details?.company_name)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={sel?.details?.company_name}
                              >
                                {sel?.details?.company_name}
                              </label>
                            </div>
                          );
                        })}
                      </div>

                      <div className="mt-3 mb-3">
                        <h5 className="mb-2">Filter by Emirates</h5>

                        {emiratesOptions?.map((emi) => {
                          return (
                            <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary mb-1">
                              <input
                                type="checkbox"
                                id={emi}
                                name={emi}
                                className="custom-control-input"
                                checked={emirate === emi}
                                onChange={(e) => handleChange(e, emi)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={emi}
                              >
                                {emi}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <div className="filter-checklist">
                        <h5>Filter by Ratings</h5>
                        <div className="sort-rating">
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck8"
                              checked={starts === 4}
                              onChange={(e) => handleStar(4)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck8"
                            >
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                            </label>
                            <span style={{ fontWeight: starts === 4 ? "bold" : "normal" }}> & Up</span>
                          </div>
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck9"
                              checked={starts === 3}
                              onChange={(e) => handleStar(3)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck9"
                            >
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                            </label>
                            <span style={{ fontWeight: starts === 3 ? "bold" : "normal" }}> & Up</span>
                          </div>
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck10"
                              checked={starts === 2}
                              onChange={(e) => handleStar(2)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck10"
                            >
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                            </label>
                            <span style={{ fontWeight: starts === 2 ? "bold" : "normal" }}> & Up</span>
                          </div>
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck11"
                              checked={starts === 1}
                              onChange={(e) => handleStar(1)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck11"
                            >
                              <span className="active">
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                            </label>
                            <span style={{ fontWeight: starts === 1 ? "bold" : "normal" }}> & Up</span>
                          </div>
                          <div className="custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck12"
                              checked={starts === 0}
                              onChange={(e) => handleStar(0)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck12"
                            >
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span>
                                <i className="la la-star"></i>
                              </span>
                            </label>
                            <span style={{ fontWeight: starts === 0 ? "bold" : "normal" }}> & Up</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-lg-8 order-lg-1 order-0" style={{ position: "relative" }}>
                  <div>
                    {
                      !isLoading ? <div>
                        {sellerList && sellerList.length !== 0 &&
                          sellerList.map((data) => {
                            return (
                              <div>
                                {data ? (
                                  <SellerListingCard
                                    data={data}
                                    selectedRating={starts}
                                  />
                                ) : (
                                  <NodataCard />
                                )}
                              </div>
                            );
                          })
                        }
                      </div> : (
                        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                          <CircularProgress />
                        </div>
                      )
                    }

                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="mt-2">
                          <Pagination
                            count={Math.ceil(sellerListCount / limit)}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={handlePagination}
                            style={{ marginTop: "10px" }}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
