import React, { Component, Fragment } from 'react'
import axios from 'axios'
import VehicleLinkageModal from './modal/vehicle-linkages'
import { ga4Events } from '../../../utils'

export class ListingManufacture extends Component {
  constructor(props) {
    super(props)
    this.state = {
      manus: [],
      loading: false,
      showModal: false,
      vehicleLinkages: [],
    }
  }
  componentDidMount() {
    const { listing } = this.props
    this.setState({ loading: true })
    if (listing.type === 'Genuine') {
      axios
        .get(
          `/api/listings/vehicle-linkage?partSKU=${listing?.partSKU}&partBrand=${listing?.partBrand}`
        )
        .then((res) => {
          this.setState({ manus: res.data })
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    } else {
      axios
        .get(`/api/listings/vehicle-linkage?partSKU=${listing?.partSKU}`)
        .then((res) => {
          this.setState({ manus: res.data })
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    }
  }

  openModal = (manuId) => {
    this.setState({ showModal: true })
    let linkages =
      this.state.manus.filter((m) => m.manuId === manuId)?.[0]
        ?.vehicleLinkages || []
    this.setState({ vehicleLinkages: linkages })

    ga4Events({
      action: 'pdp_vehicle_make_model_cta',
      category: 'Listing',
      product_id: this.props.listing?._id,
      linkage: linkages?.length,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { manus, loading, showModal, vehicleLinkages } = this.state

    if (loading) {
      return <div>Loading...</div>
    }

    return (
      <Fragment>
        <VehicleLinkageModal
          vehicleLinkages={vehicleLinkages}
          showModal={showModal}
          onClose={this.closeModal}
        />
        <div className='atbd_content_module atbd_listing_features'>
          <div className='atbd_content_module__tittle_area'>
            <div className='atbd_area_title'>
              <h4>
                <span className='la la-list-alt'></span>
                Vehicle Make/Model
              </h4>
            </div>
          </div>
          <div className='atbdb_content_module_contents'>
            <ul className='atbd_custom_fields features-table'>
              {manus.map((m, i) => (
                <li>
                  <div key={i} className='atbd_custom_field_title '>
                    <p>Name: </p>
                  </div>
                  <div className='atbd_custom_field_content'>
                    <p>
                      {m?.manuName}{' '}
                      <a href={'#'} onClick={() => this.openModal(m.manuId)}>
                        ({m?.vehicleLinkages?.length || 0} vehicle linkages)
                      </a>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    )
  }
}
