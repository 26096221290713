import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { hostedAPI, outletRefID } from '../../constants'

const OnlinePayment = ({ setLoading, setIsButtonDisabled }) => {
  const style = {
    main: {
      margin: 0,
      height: 'fit-content',
    } /* the style for the main wrapper div around the card input*/,
    base: {
      height: 'fit-content',
    } /* this is the default styles that comes with it */,
    input: {
      padding: '5px',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgb(227, 230, 239)',
      color: '#000',
      boxShadow: ' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
    } /* custom style options for the input fields */,
    invalid: {
      padding: '5px',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#f00',
      color: '#f00',
    } /* custom input invalid styles */,
  }

  const onSuccess = () => {
    setLoading(false)
  }
  const onFail = () => {
    setLoading(false)
  }
  const apiKey = hostedAPI
  const outletRef = outletRefID
  useEffect(() => {
    setLoading(true)
    setIsButtonDisabled(true)
    window.paymentWinOpen = true
    window.NI.mountCardInput('mount-id' /* the mount id*/, {
      style, // Style configuration you can pass to customize the UI
      apiKey, // API Key for WEB SDK from the portal
      outletRef, // outlet reference from the portal
      onSuccess, // Success callback if apiKey validation succeeds
      onFail, // Fail callback if apiKey validation fails
      onChangeValidStatus: ({
        isCVVValid,
        isExpiryValid,
        isNameValid,
        isPanValid,
        ...rest
      }) => {
        if (isCVVValid && isExpiryValid && isNameValid && isPanValid) {
          setIsButtonDisabled(false)
        } else {
          setIsButtonDisabled(true)
        }
        console.log({
          isCVVValid,
          isExpiryValid,
          isNameValid,
          isPanValid,
          rest,
        })
      },
    })
  }, [window])

  return (
    <>
      <div id='mount-id' style={{ height: '300px', margin: '0 auto' }}></div>
    </>
  )
}

export default function PaymentModal() {
  const [loading, setLoading] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  async function createSession() {
    try {
      const response = await window.NI.generateSessionId()
      if (window.opener != null && !window.opener.closed) {
        window.opener.isSessionExpired = false
        window.opener.paymentSessionId = response.session_id
      }
    } catch (err) {
      window.opener.paymentSessionId = null
      window.opener.isSessionExpired = true
    } finally {
      setLoading(false)
      window.close()
    }
  }

  const payNow = () => {
    setLoading(true)
    createSession()
  }

  return (
    <>
      {loading && (
        <div
          style={{
            padding: '0px 20px',
            marginTop: '100px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          width: '500px',
          margin: 'auto',
        }}>
        {!loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
            }}>
            <img
              src='/assets/img/logo-footer.svg'
              alt='logoImage'
              width='200'
              style={{ margin: '20px  auto' }}
            />
          </div>
        )}
        <OnlinePayment
          setLoading={setLoading}
          setIsButtonDisabled={setIsButtonDisabled}
        />
        {!loading && (
          <Button
            autoFocus
            disabled={isButtonDisabled}
            onClick={payNow}
            variant='contained'
            color='primary'
            style={{
              width: '100%',
              margin: 'auto',
              marginTop: '-200px',
            }}>
            Check out
          </Button>
        )}
      </div>
    </>
  )
}
