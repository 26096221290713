import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ProductImage } from '../../../../utils'
import ShowDynamicCurrency from '../../../common/ShowDynamicCurrency'
import ShowDynamicPrice from '../../../common/ShowDynamicPrice'

const dataPerRow = 8

const UnAvailableProductsList = () => {
  const { unAvailableProducts } = useSelector((state) => state.list)

  const [next, setNext] = useState(dataPerRow)

  const handleMoreData = () => {
    setNext(next + dataPerRow)
  }

  return (
    <div className='checkout-table table-responsive order-review-step'>
      <table
        id='directorist-checkout-table'
        className='table bg-transparent checkout-table'
      >
        <>
          <thead>
            <tr>
              <th className='bg-danger' colSpan={6}>
                Unavailable/Invalid Products
              </th>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ backgroundColor: '#f7f7f7', width: '20%' }}
              >
                Name
              </td>
              <td style={{ backgroundColor: '#f7f7f7', width: '15%' }}>Qty</td>
              <td style={{ backgroundColor: '#f7f7f7', width: '15%' }}>
                Price(Excl. Tax)
              </td>
              <td style={{ backgroundColor: '#f7f7f7', width: '50%' }}>
                Reason
              </td>
            </tr>
          </thead>
          {unAvailableProducts?.slice(0, next)?.map((listing, i) => {
            return (
              <tbody className='bg-white' key={i}>
                <tr>
                  <td
                    width='75'
                    style={{
                      position: 'relative',
                    }}
                  >
                    <ProductImage
                      avatar={''}
                      className='item-image'
                      alt={listing?.partName}
                      style={{
                        height: '50px',
                        width: '50px',
                        objectFit: 'contain',
                      }}
                    />
                  </td>
                  <td>
                    <h4>{listing?.partName}</h4>
                    <p className='text-muted' style={{ marginBottom: 0 }}>
                      <strong>{listing?.partSKU}</strong>
                      <br />
                    </p>
                  </td>
                  <td>
                    <div className='d-flex'>{listing?.orderQty}</div>
                  </td>
                  <td>
                    <span className='country-currency'>
                      {' '}
                      <ShowDynamicCurrency />
                    </span>
                    {''}
                    <span
                      style={{
                        color: 'black',
                        fontSize: '17px',
                        fontWeight: 'bold',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      <ShowDynamicPrice price={listing?.priceExclVat} />
                    </span>
                  </td>

                  <td>
                    <p className='text-muted' style={{ marginBottom: 0 }}>
                      {listing?.reason}
                    </p>
                  </td>
                </tr>
              </tbody>
            )
          })}
        </>

        <tbody className='bg-white'>
          {next < unAvailableProducts?.length && (
            <tr>
              <td style={{ width: '100%' }} align='center' colSpan={6}>
                <button
                  className='btn btn-primary text-center mt-4'
                  onClick={handleMoreData}
                >
                  Load more
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default UnAvailableProductsList
