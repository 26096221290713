import React, { useState } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { popUp } from '../../utils'
import { Box, Typography } from '@material-ui/core'
import { uploadFileToAssets } from '../../services/upload'

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}))

const UploadProduct = ({ showModal, closeModal, url, template }) => {
  const [file, setFile] = useState(null)
  const [isUploding, setIsUploading] = useState(false)
  const handleChange = (e) => {
    setFile(e.target.files[0])
  }
  const handleSubmit = async(e) => {
    e.preventDefault()
    if (!file) {
      alert('Please upload excel file')
    }
    const currentUser = JSON.parse(localStorage.getItem('login'))

    const fileData = await uploadFileToAssets({
      file,
      type: "listing"
    })
    const data = {
      file_id: fileData?._id
    }
    setIsUploading(true)
    axios
      .post(`/api/products/${url}/${currentUser?._id}`, data)
      .then((res) => {
        if (res?.status === 201) {
          closeModal()
          popUp(res?.data?.message || "Listing has been uploaded successfully")
        }
        if (res?.status === 200) {
          closeModal()
          popUp(res?.data?.message || "Listing has been uploaded successfully")
        }
      })
      .catch((err) => {
        popUp(err?.response?.data?.message)
      })
      .finally(() => setIsUploading(false))
  }
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const handleClose = () => {
    closeModal()
  }
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className='upload-product-wrapper'>
        <div className='container'>
          <div className='upload-product-content'>
            {!isUploding ? (
              <form onSubmit={handleSubmit}>
                <label>
                  Please select excel file or{' '}
                  <a download href={`/${template}`}>
                    Download our template
                  </a>
                </label>
                <input
                  type='file'
                  onChange={handleChange}
                  name='file'
                  accept='.xlsx, .xls, .csv'
                  style={{
                    border: '1px solid #ddd',
                    height: 45,
                    lineHeight: '45px',
                    width: '100%',
                  }}
                />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    className='btn btn-color-blue file_upload_btn'
                    type='submit'
                    style={{ marginTop: 20, width: '48%' }}
                  >
                    Upload Product
                  </button>
                  <button
                    className='btn'
                    type='button'
                    onClick={handleClose}
                    style={{
                      marginTop: 20,
                      width: '48%',
                      background: '#f5f7fc',
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : (
              <Box mt='1rem'>
                <Typography align='center' color='white'>
                  Uploading your Listings. Please wait...
                </Typography>
                <BouncingDotsLoader />
                <Typography align='center' variant='body2' color='error'>
                  Please don't refresh the page.
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {body}
    </Modal>
  )
}

const BouncingDotsLoader = () => {
  return (
    <div className='bouncing-dot-loader'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default UploadProduct
