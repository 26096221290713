import React, { Component } from "react";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { popUp } from "../../../../utils";
import { uploadFileToAssets } from "../../../../services/upload";

class ResponseQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerFileName: null,
      sellerFileName: null,
      sellerNotes: null,
      modalSellerOpen: false,
    };

    this.validator = new SimpleReactValidator();
  }

  reloadState() {
    this.setState({
      sellerId: null,
      seller: null,
      quotationFile: null,
      quotationFileName: null,
      buyerNotes: null,
      modalSellerOpen: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { loadWithId } = nextProps;

    if (loadWithId) {
      this.loadData(loadWithId);
    }

    return true;
  }

  onSellerSelected = (seller) => {
    this.setState({ modalSellerOpen: false });

    this.setState({
      sellerId: seller._id,
      seller,
    });
  };

  loadData = (id) => {
    axios
      .get("/api/quotation/get?quotationId=" + id)
      .then((res) => {
        if (this.props.onDataLoaded) this.props.onDataLoaded();

        const { seller, number, details } = res.data;

        this.setState({
          number,
          seller,
          sellerId: seller._id,
          buyerNotes: details[0].buyerNotes,
          quotationFileName: details[0].buyerFileName,
        });
      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  };

  submit = async() => {
    const { quotationFile, sellerNotes } = this.state;

    if (!quotationFile) {
      alert("you must select quotation file");
      return;
    }

    if (!sellerNotes) {
      alert("please fill notes");
      return;
    }

    try {
      const file = await uploadFileToAssets({
        file: quotationFile,
        type: "quotation"
      })

      const formData = {
        _id: this.props?._id,
        sellerNotes: sellerNotes,
        buyer_id: this.props.buyerId,
        file_id: file?._id
      };
  
      axios
        .post(
          "/api/products/upload-quotation?user_id=" + this.props.login._id,
          formData
        )
        .then((res) => {
          this.reloadState();
          if (this.props.onFinished) this.props.onFinished();
          popUp("Successfully Sent RFQ Response");
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
        });
    } catch(error) {
      popUp(error?.message || "Error while sending REF Response")
    }
  };

  render() {
    return (
      <div className="p-3">
        <div className="d-flex mb-2 justify-content-between">
          <h4>
            {this.state.number
              ? "View Quotation Response by Excel file"
              : "New Quotation Response by Excel file"}
          </h4>
          <a
            href="/assets/buyparts24_rfq_template.xlsx"
            className="btn btn-default btn-xs"
          >
            Download Excel Template
          </a>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="quotationFile" className="form-label">
                Quotation Response File
              </label>
              {this.state.quotationFileName ? (
                <p className="text-ellipse">
                  <a href={this.state.quotationFileName} _target="blank">
                    {this.state.quotationFileName}
                  </a>
                </p>
              ) : (
                <input
                  type="file"
                  className="form-control"
                  id="quotationFile"
                  onChange={(e) =>
                    this.setState({ quotationFile: e.target.files[0] })
                  }
                />
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="sellerNotes" className="form-label">
                Seller Notes
              </label>
              <textarea
                value={this.state.sellerNotes || ""}
                className="form-control"
                rows="3"
                onChange={(e) => this.setState({ sellerNotes: e.target.value })}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            <button className="btn btn-primary btn-xs" onClick={this.submit}>
              {this.state.number ? "Update Request" : "Submit Quotations"}
            </button>
          </div>
        </div>
        <Modal
          visible={this.state.modalSellerOpen}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default"
              onClick={() => this.props.closemodal}
            >
              X
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

ResponseQuotation.propTypes = {
  onFinished: PropTypes.func,
};

ResponseQuotation.propTypes = {
  loadWithId: PropTypes.string,
};

ResponseQuotation.propTypes = {
  onDataLoaded: PropTypes.func,
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  ResponseQuotation
);
