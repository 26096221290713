import React from "react";
import { PageBanner } from "../content/element/page-banner";
import MyReviewCard from "../content/element/card/my-review-card";
import Pending from "../content/element/UserStatus/Pending";

const MyReview = () => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;
  return (
    <>
      <PageBanner />
      <div className="container mt-2 mb-5 bgcolor-white">
        <MyReviewCard />
      </div>
    </>
  );
};

export default MyReview;
