import React, { Component, Fragment } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { PageBanner } from "../content/element/page-banner";
import { connect } from "react-redux";
import { compose } from "redux";
import { popUp, stringToUrl } from "../../utils";
import MaterialTable from "material-table";
import TextField from '@material-ui/core/TextField';
import CssTextField from "../content/element/SearchField";
import PageTitle from "./../content/element/PageTitle/index";

class MyRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mylist: [],
      close: false,
      quantity: 1,
      show: false,
      searchField: '',
      currentEditId: ''
    };
    this.submitChange = this.submitChange.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  showHide = (index) => {
    this.setState({
      close: true,
    })
  }


  componentDidMount() {
    this.getData();
  }
  getData() {
    axios.get("/api/request/" + this.props.login._id)
      .then((res) => {
        if (res.data)
          this.setState({ mylist: res.data });

      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");;
      });
  }

  submitChange = (id, quantity) => {
    axios.put(`/api/request/${id}`, { quantity })
      .then((res) => {
        popUp("Your request was successfully updated.");
        this.getData();

      })
      .catch((err) => {
        popUp("There was a problem with server. Please try again.");
      });
  }

  handleEdit = (id, qty) => {
    this.setState({
      currentEditId: id,
      show: true,
      quantity: qty
    })
  }

  render() {
    const { mylist } = this.state;
    const columns = [
      {
        title: <span className="tryit">S.N</span>,
        field: "",
        render: rowData => (<p>{rowData.tableData.id + 1}</p>),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },
      {
        title: <span className="tryit">Part Name</span>,
        field: "",
        render: (mylist) => mylist?.partName,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },
      {
        title: <span className="tryit">PartSKU</span>,
        field: "",
        render: (mylist) => mylist?.partSKU,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },

      {
        title: <span className="tryit">Quantity</span>,
        field: "",
        render: (mylist) => {
          return (
            this.state.show == true && this.state.currentEditId == mylist?._id ?
              <div>
                <div className="d-flex justify-content-between">
                  <div className="w-50 d-flex justify-content-around align-items-center p-1">
                    <button disabled={this.state.quantity <= 1} className="btn checkout-qty border" onClick={() => this.setState({ quantity: this.state.quantity - 1 })}>-</button>
                    <span className="border h-100 w-500 justify-content-center d-flex align-items-center" style={{ width: 200, paddingLeft: 20, paddingRight: 20 }}>
                      {this.state.quantity}
                    </span>
                    <button className="btn checkout-qty border" onClick={() => this.setState({ quantity: this.state.quantity + 1 })}>+</button>
                  </div>
                </div>
              </div> : <p>{mylist?.quantity}</p>
          )
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },
      {
        title: <span className="tryit"></span>,
        field: "",
        render: (mylist) => {
          return (
            (this.state.show == true) && (this.state.currentEditId == mylist?._id) ?
              (

                <div className="d-flex" style={{ height: 'auto' }}>
                  <button style={{ textAlign: 'center' }} onClick={() => this.setState({ show: false })} className="primary-btn">
                    Cancel
                  </button>
                  <div onClick={() => {
                    this.submitChange(mylist?._id, this.state.quantity)
                    this.setState({ show: false });
                  }} className="primary-btn" style={{ textAlign: 'center', backgroundColor: 'rgb(243, 146, 0)' }}>
                    Save Changes
                  </div>

                </div>
              )
              :
              (
                <div onClick={() => this.handleEdit(mylist?._id, mylist?.quantity)} className="primary-btn">
                  Edit
                </div>
              )
          )

        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },
      {
        title: <span className="tryit"></span>,
        field: "",
        render: (mylist) => {
          const carId = mylist?.partSKU?.split("-")?.pop()
          const id = mylist?.partSKU?.replace(`-${carId}`, "")
          const link = `${stringToUrl(mylist?.partName)}-${stringToUrl(mylist?.partSKU)}/${id}?api=true`
          const _link =
            stringToUrl(mylist?.partName) +
            "-" +
            stringToUrl(mylist?.partSKU) + "?api=true";

          return (
            <div className="primary-btn">
              <NavLink to={`/spare-part-details/${link}`} style={{ color: 'white' }}>View Item </NavLink>
            </div>
          )
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: 'Montserrat'
        },
      },
    ];

    const filteredOrders = mylist && mylist.filter((item) => {
      return (
        item?.partName?.toLowerCase().includes(this.state?.searchField?.toLowerCase()) ||
        item?._id?.toLowerCase().includes(this.state?.searchField?.toLowerCase()) ||
        item?.partSKU?.toLowerCase().includes(this.state?.searchField?.toLowerCase())
      )
    })

    return (
      <Fragment>
        <PageBanner />

        <div className="container-fluid section-padding-all-listing mt-4">
          <PageTitle title="My Requests" />

          <div style={{ marginRight: 2, position: 'relative', display: 'flex', marginBottom: 10 }}>
            <div className="ml-auto">
              <CssTextField
                id="standard-basic"
                value={this.state.searchField}
                onChange={(e) => this.setState({ searchField: e.target.value })}
                label="Search Field" />
            </div>
            <div><i className="las la-search la-search search-icon-datatable"></i></div>
          </div>

          <MaterialTable

            title={null}
            style={{ zIndex: 0, marginBottom: 20 }}
            data={filteredOrders}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <img src="/assets/img/nodata.png" className="datatableimage" />
                ),
              },
            }}
            columns={columns}
            isLoading={this.state.loader}
            cellDataGetter={() => this.getSerial()}
            rowGetter={({ index }) => this.state.mylist[index]}
            rowCount={this.state.mylist.length}
            options={{
              search: false,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#f2f2f2" };
                }
              },
              paging: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 50, 100],
            }}
          />
        </div>

      </Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProp)
)(MyRequest);
