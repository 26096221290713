import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardContent, CardMedia, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import NotLoggedInModal from "../modal/not-logged-modal";
import { checkAndLogExceedLimit, countIdsWithQuantity, ga4Events, popUp, ProductImage } from "../../../../utils";
import ShowDynamicCurrency from "../../../common/ShowDynamicCurrency";
import ShowDynamicPrice from "../../../common/ShowDynamicPrice";
import { AddToCart } from "../../../../Store/action/listingActions";
import { handRequestForPrice } from "./card-listing-grid";
import TextOverlay from "../../../common/TextOverlay";
import "./SKUCard.css";

const useStyle = makeStyles((theme) => ({
	overlay: {
		top: '4px',
		position: 'absolute',
		color: 'white',
		padding: '3px 10px',
		fontSize: '12px',
		borderRadius: '0px 0px 12px 0px'
	},
	overlayGenuine: {
		backgroundColor: '#333333',
	},
	overlayAfterMarket: {
		backgroundColor: '#485696',
	},

}))

export default ({
	link,
	url,
	badge,
	title,
	type,
	_id,
	quantity,
	pic,
	partSKU,
	partBrand,
	category,
	partName,
	variant,
	priceExclVat,
	eventAction,
	collectionEventData,
	deliveryTime,
	aliasName
}) => {
	const classes = useStyle();
	const [showModal, setShowModal] = useState(false)
	const [isCopied, setIsCopied] = useState(false);
	const [msg, setErrMsg] = useState("");
	const [imageError, setImageError] = useState(false);

	const handleImageError = () => {
		setImageError(true);
	}

	const currentUser = JSON.parse(localStorage.getItem("login"));

	const { allCategories, quotationItems, login, isAddToCartLoading } = useSelector(state => {
		return {
			allCategories: state.category.allCategories,
			quotationItems: state.quotation.quotationItems,
			login: state.login,
			isAddToCartLoading: state.list.isAddToCartLoading
		}
	});
	const { itemsInCart } = useSelector((state) => state.list)
	const dispatch = useDispatch();

	const handleGa4Event = (action) => {
		const findCategory = allCategories?.length !== 0 && allCategories?.find(c => c?._id === category);
		ga4Events({
			action,
			category: "Listing",
			product_id: _id,
			product_name: partName,
			product_category: findCategory?.name,
			product_brand: partBrand,
			product_variant: variant,
			product_sku: partSKU,
			...collectionEventData
		});
	}

	const handleCloseModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);

	}, [isCopied]);

	const handleAddToCart = () => {
		if (currentUser) {
			const eventData = {
				action: `${eventAction}_add_to_cart`,
				category: "Cart",
				product_id: _id,
				...collectionEventData
			}
			dispatch(AddToCart(_id, eventData));
		} else {
			setErrMsg(
				"You need to register as a buyer to be able to make an order on BuyParts24"
			);
			setShowModal(true);
		}
	}

	const handleNewQuotation = () => {
		if (login) {
			handRequestForPrice(
				quotationItems,
				{ _id, partName, priceExclVat, qty: 1, pic },
				dispatch
			)
		} else {
			popUp('You need to register as a buyer to be able to request price')
		}
	}

	const result = countIdsWithQuantity(itemsInCart);
	const isAvailable = checkAndLogExceedLimit(_id, result, quantity);

	return (
		<>
			<NotLoggedInModal
				showModal={showModal}
				onClose={handleCloseModal}
				msg={msg}
			/>
			<Card sx={{ maxWidth: 345 }} className="product-slider-card">
				<NavLink to={`/spare-part-details/${link}`} onClick={() => handleGa4Event(`${eventAction}_sku_image_cta`)}>

					<CardMedia
						component="img"
						height="194"
						src={imageError ? "/assets/img/byparts.jpg" : url}
						onError={handleImageError}
					/>
					{
						deliveryTime ?
							<TextOverlay value={deliveryTime} />
							:
							null
					}
					<Typography variant="body1" className={`${classes.overlay} ${type == 'Genuine' ? classes.overlayGenuine : classes.overlayAfterMarket}`}>
						{type}
					</Typography>
				</NavLink>

				<CardContent>
					<NavLink className={"cursur-pointer"}
						style={{
							color: "black"
						}}
						to={`/all-listings?brand=${partBrand
							?.toLocaleLowerCase()
							?.replace(" ", "-")}`}
						onClick={() => {
							handleGa4Event(`${eventAction}_brand_click`)
						}
						}
					>
						<span className="sku-subhead">
							<span style={{ marginRight: '5px' }}>{partBrand?.toUpperCase()}</span>
							<span className="dot"></span>
							<span style={{ marginLeft: '5px' }}>{partSKU}</span>
						</span>
					</NavLink>
					<NavLink to={`/spare-part-details/${link}`} onClick={() => handleGa4Event(`${eventAction}_sku_image_cta`)}>
						<div className="sku-heading title-limit" onClick={() => handleGa4Event(`${eventAction}_title_cta`)}>
							{title}
						</div>
					</NavLink>
					{quantity && quantity > 0 ?
						<>
							<ShowDynamicCurrency />
							<ShowDynamicPrice price={priceExclVat} hideExc />
						</>
						: (
							<div style={{ height: '23px' }}></div>
						)}
					{/* <p className="sku-warranty">3 years warranty</p> */}
					<div className="sku-cart">
						{quantity && quantity > 0 ? (
							<button
								type="button"
								className="btn search-btn request-cart-btn"
								style={{height: '32px'}}
								disabled={currentUser?.role == "SELLER" || isAddToCartLoading}
								onClick={() => {
									if (isAvailable) {
										popUp("Your cart items quantity exceeded stock quantity!")
									} else {
										handleAddToCart();
									}
								}}
							>
								ADD TO CART
							</button>)
							:
							<button
								type="button"
								onClick={handleNewQuotation}
								className="btn search-btn request-cart-btn"
								style={{height: '32px'}}
							>
								REQUEST FOR PRICE
							</button>
						}
					</div>
				</CardContent>
			</Card>
			{/* <div className="listing-card-grid">
				<div className="atbd_single_listing ">

					<article className="atbd_single_listing_wrapper">
						<figure className="atbd_listing_thumbnail_area">
							<NavLink to={`/spare-part-details/${link}`} onClick={() => handleGa4Event(`${eventAction}_sku_image_cta`)}>
								<div
									className="atbd_listing_image"
									style={{ display: "flex", position: "relative" }}
								>
									<ProductImage avatar={url} alt="Listing" />
									{
										deliveryTime ?
											<TextOverlay value={deliveryTime} />
											:
											null
									}
								</div>
							</NavLink>

							<div className="atbd_thumbnail_overlay_content">
								<ul className="atbd_upper_badge">
									<li>
										<span
											className={
												"text-capitalize atbd_badge atbd_badge_" +
												badge?.replace(" ", "_")
											}
										>
											{badge}
										</span>
									</li>
								</ul>
							</div>
						</figure>
						<div className="atbd_listing_info">
							<h4 className="atbd_listing_title">
								<NavLink to={`/spare-part-details/${link}`}
									onClick={() => handleGa4Event(`${eventAction}_title_cta`)}>{title}</NavLink>

							</h4>
							<div className="rating-group">
								<div className="d-flex ">
									<button className="btn part-brand">
										<NavLink className={"cursur-pointer"}
											style={{
												color: "black"
											}}
											to={`/all-listings?brand=${partBrand
												?.toLocaleLowerCase()
												?.replace(" ", "-")}`}
											onClick={() => {
												handleGa4Event(`${eventAction}_brand_click`)
											}
											}
										><a>{partBrand?.toUpperCase()}</a></NavLink>
									</button>

								</div>
								<span className="review-value">{type}</span>
							</div>
							<div className="d-flex justify-content-between align-items-center card__sku-container">
								<button
									style={{ maxWidth: aliasName ? "150px" : "" }}
									className="btn part-sku"
									onClick={() => {
										setIsCopied(true)
										navigator.clipboard.writeText(partSKU);
										handleGa4Event(`${eventAction}_sku_copied`)
									}}
								>
									SKU{" "}
									<span className='part' style={{ marginLeft: '2px' }}>
										{partSKU}
									</span>{' '}
									<span className="ml-2">|</span>
									<Tooltip
										className="ml-1 cursur-pointer"
										title="SKU number copied to clipboard"
										placement="top"
										open={isCopied}
										arrow
										disableHoverListener>
										<svg xmlns="http://www.w3.org/2000/svg"
											height="18" viewBox="0 0 24 24" width="24">
											<path d="M0 0h24v24H0z" fill="none" />
											<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg>
									</Tooltip>
								</button>
								<span className="alias-name">{aliasName}</span>
							</div>
							<div className="pricing-group">
								{quantity && quantity > 0 ? (
									login ? (
										<p className='symbol'>
											<ShowDynamicCurrency />
											<span className='price'>
												<ShowDynamicPrice price={priceExclVat} hideExc />
											</span>
										</p>
									) : (
										<p className='symbol' style={{ padding: '11px' }}>
											<span className='price'></span>
										</p>
									)
								) : (
									<p></p>
								)}
								{quantity && quantity > 0 ? (
									<button
										className="btn cart-btn"
										disabled={currentUser?.role == "SELLER" || isAddToCartLoading}
										onClick={() => {
											if (isAvailable) {
												popUp("Your cart items quantity exceeded stock quantity!")
											} else {
												handleAddToCart();
											}
										}}
									>
										ADD TO CART
									</button>
								) : (
									<div
										style={{
											marginTop: !login && '10px',
										}}>
										<button
											onClick={handleNewQuotation}
											className='btn cart-btn'
											style={{
												marginBottom: !login ? '10px' : '20px',
												backgroundColor: '#1d71b8',
												color: 'white',
											}}>
											{'Request For Price'}
										</button>
									</div>
								)}
							</div>
							<span style={{ fontSize: 10, fontWeight: 600, margin: 0, padding: 0 }} className="text-muted">{(quantity > 0 && currentUser) ? "Exc.VAT" : " "}</span>

						</div>
					</article>
				</div>
			</div> */}
		</>

	);
};
