import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { PageBanner } from "../../content/element/page-banner";
import PageTitle from "../../content/element/PageTitle";
import Pending from "../../content/element/UserStatus/Pending";
import AddForm from "../../content/element/Address/AddAddress";
import EditForm from "../../content/element/Address/EditAddress";
import RemoveAddress from "../../content/element/Address/RemoveAddress";
import AddressBox from "../../content/element/Address/AddressBox";
import { fetchAllAddress, setDefaultAddress } from "../../../Store/action/addressAction";
import AddressSkeletonLoader from "../../common/Loader/AddressSkeletonLoader";

const YourAddress = ({ history }) => {
  const [modalEditNewAddress, setModalEditNewAddress] = useState(false);
  const [modalRemoveNewAddress, setModalRemoveNewAddress] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [disabledBtn, setDisableBtn] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const dispatch = useDispatch();

  const { userAddress, isAddressLoading } = useSelector(state => {
    return {
      userAddress: state.address.addresses,
      isAddressLoading: state.address.isAddressLoading
    }
  });

  useEffect(() => {
    axios
      .get("/api/products/get-state")
      .then((res) => {
        setStateData(res.data);
      })

    axios
      .get("/api/products/get-regions")
      .then((res) => {
        setRegionData(res?.data?.regions);
      })

    axios
      .get("/api/products/a-city")
      .then((res) => {
        setLocationData(res.data);
      })
  }, []);

  useEffect(() => {
    dispatch(fetchAllAddress());
  }, []);

  const handleAddFormModal = (res) => {
    setIsOpenAddModal(res);
  };

  const handleClicked = (id) => {
    setDisableBtn(true);
    dispatch(setDefaultAddress({ id, setDisableBtn }))
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setModalEditNewAddress(prevVal => !prevVal);
  };

  const handleRemove = (item) => {
    setSelectedItem(item);
    setModalRemoveNewAddress(prev => !prev);
  }

  const currentUser = JSON.parse(localStorage.getItem("login"));

  if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;

  return (
    <Fragment>

      <PageBanner />

      <AddForm
        handleAddFormModal={handleAddFormModal}
        isOpenAddModal={isOpenAddModal}
        stateData={stateData}
        locationData={locationData}
        regionData={regionData}
      />

      <EditForm
        stateData={stateData}
        locationData={locationData}
        regionData={regionData}
        isOpenEditModal={modalEditNewAddress}
        handleEditFormModal={() => setModalEditNewAddress(false)}
        editedAddressId={selectedItem?._id}
        hasSelectedCoordinates={true}
        isOnlyEdit={true}
      />

      <RemoveAddress
        modalRemoveNewAddress={modalRemoveNewAddress}
        selectedAddress={selectedItem}
        toggle={() => setModalRemoveNewAddress(false)}
      />

      <section className="pt-2 pb-5" style={{ backgroundColor: "#F6F7FC" }}>
        <div className="container">
          <PageTitle title="My Address" />

          <Row className="mt-2">
            {
              isAddressLoading && !userAddress?.length ?
                Array(3).fill(null).map((_, i) => (
                  <Col xl="4" lg="6" md="6" key={i}>
                    <AddressSkeletonLoader />
                  </Col>
                )) :
                <>
                  {userAddress &&
                    userAddress.length > 0 &&
                    userAddress.map((item, index) => {
                      return (
                        <Col xl="4" lg="6" md="6" key={index}>
                          <AddressBox
                            item={item}
                            disabledBtn={disabledBtn}
                            setDefaultAddress={handleClicked}
                            handleEdit={handleEdit}
                            handleRemove={handleRemove}
                          />
                        </Col>
                      );
                    })}
                </>
            }
            <Col xl="4" lg="6" md="6">
              <div
                className="add-address-container"
                onClick={() => {
                  handleAddFormModal(true);
                }}
              >
                <div className="add-image-icon">
                  {" "}
                  <img src="/assets/img/add-icon.png" alt="add_address_icon" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
};

export default YourAddress;
