
import React, { useEffect, useRef } from 'react';

import { handleBannerEvents } from '../../../utils';
import { useState } from 'react';
import LoginModal from '../Login/LoginModal';

const Leaderboard = ({ banner, listing, position = "" }) => {
  const elementRef = useRef(null);
  const handleGaEvent = () => {
    const action = banner?.collectionId?._id ? `collection_${position}_lb_banner_click` : `${position}_lb_banner_click`
    handleBannerEvents(action, banner)
  }

  const currentUser = JSON.parse(localStorage.getItem("login"));
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const toggleLogin = (e) => {
    e.preventDefault();
    setIsModalOpen(!isModalOpen);
    setRedirectURL(banner?.banner_link);
  }

  const toggleForLogin = () => {
    setIsModalOpen(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageXOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHight = document.documentElement.scrollHeight;

      if(documentHight - (scrollTop + windowHeight) <= 300 && documentHight - (scrollTop + windowHeight) > 200) {
        elementRef.current && elementRef.current.classList.remove('banner-bottom-scroll300')
        elementRef.current && elementRef.current.classList.add('banner-bottom-scroll200')
      } else if(documentHight - (scrollTop + windowHeight) <= 200) {

        elementRef.current && elementRef.current.classList.remove('banner-bottom-scroll200')
        elementRef.current && elementRef.current.classList.add('banner-bottom-scroll300')
      } else {
        elementRef.current && elementRef.current.classList.remove('banner-bottom-scroll300')
        elementRef.current && elementRef.current.classList.remove('banner-bottom-scroll200')
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className="top-leaderboard hide-banner-height">
      <div className="container" style={{ marginBottom: listing ? "20px" : "25px", marginTop: listing ? "20px" : "0px" }}>
        <div className="row">
          <div className="col-lg-12" 
          // style={{
          //   display: 'flex',
          //   alignContent: "center",
          //   justifyContent: "center",
          //   width: "900px",
          //   backgroundSize: "cover",
          //   objectPosition: "center",
          //   objectFit: 'cover',

          // }}
          >
            {
              banner && (banner.category == "left_leaderboard_home_page" || banner.category == "right_leaderboard_home_page") ?
                <div className={`top-leaderboard-content ${position == 'right' ? 'rightView' : ''}`} >
                  <a onClick={(e) => {
                    handleGaEvent();
                    !currentUser && toggleLogin(e);

                  }}
                    href={banner?.banner_link}>
                    <img ref={elementRef}
                      className={`${position == 'right' ? 'banner-right' : ''} banner-view-fixed`}
                      src={banner?.banner_upload} alt="banner" />
                  </a>
                </div>
                :
                <div className={`top-leaderboard-content ${position == 'right' ? 'rightView' : ''}`} >
                  <a onClick={(e) => {
                    handleGaEvent();
                    !currentUser && toggleLogin(e);

                  }}
                    href={banner?.banner_link}>
                    <img style={{
                      width: "65%",
                      borderRadius: "10px",
                      maxHeight: "80px",
                      minHeight: "40px",
                      marginTop: "1rem"
                    }}
                      src={banner?.banner_upload} alt="banner" />
                  </a>
                </div>
            }
          </div>
        </div>
      </div>

      <LoginModal
        isModalOpen={isModalOpen}
        toggle={toggleLogin}
        loginToggle={toggleForLogin}
        redirectURL={banner?.banner_link}
      />
    </section>
  )
}

export default Leaderboard
