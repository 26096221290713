import React from "react";
import PageTitle from "../../content/element/PageTitle";
export default ({ step }) => (
  <div className="cart-top-head container" style={{ textAlign: "left" }}>
    {(step === 1 && (
      <PageTitle title="Your Cart" />
    )) ||
      (step === 2 && (
        <PageTitle title="Shipping Options" />
      )) ||
      (step === 3 && (
        <PageTitle title="Your Address" />
      )) ||
      (step === 4 && (
        <PageTitle title="Your Payments"
         />
      )) ||
      (step === 5 && (
        <PageTitle title="Order Confirmation" />
      ))}
  </div>
);
