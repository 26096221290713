import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	chipStyle: {
		backgroundColor: "#f7af30",
	},
	imgStyle: {
		height: '20px!important',
		width: '20px!important',
		objectFit: "contain",
		objectPosition: "center",

	},
	imgStyleDiv: {
		height: '30px!important',
		width: '30px!important',
		borderRadius: 20,
		border: '2px solid #ddd',
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "#FFF",
		zIndex: 1
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	textStyle: {
		marginLeft: -15,
		fontSize: 10,
		fontWeight: 500,
		color: '#FFF',
		padding: '3px 10px',
		paddingLeft: 18,
		borderRadius: 15,
		// color: (props) => props.color || "#00FFFF",
	},
	divStyle: {
		position: 'absolute',
		display: 'flex',
		top: 0,
		right: 20,
		alignItems: 'center',
		// display: 'flex',
	}
}));

const TextOverlay = (props) => {
	const { value } = props;
	const classes = useStyles(props);

	let _color = "#32cc6f";

	return (
		<div className={classes.divStyle} style={{ ...props?.style }}>
			<div className={classes.imgStyleDiv} style={{ borderColor: _color, marginLeft: '4px' }}>
				<img
					className={classes.imgStyle}
					src={"/assets/img/express-delivery.png"}
					alt="express"
				/>
			</div>
			<h3
				className={classes.textStyle}
				style={{
					backgroundColor: _color,
				}}
			>
				{" "}
				{value}
			</h3>
		</div>

	);
};

export default TextOverlay;
