import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import qs from "query-string";
import { useSelector } from 'react-redux';
import { ga4Events } from '../../../utils';
import ShowMessageModal from '../../common/ShowMessageModal';

const ThankYou = () => {
    const history = useHistory()
    const orderId = qs.parse(history.location.search).order
    const { pathname } = useSelector(state => state.list);
    const login = useSelector((state) => state.login)

    const filteredPath = localStorage.getItem("filterPath");
    const handleGa4Event = (action) => {
        ga4Events({
            action,
            category: "Navigation"
        })
    }
    //toggle for order place message
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [msg, setMessage] = useState("");
    const toggle = () => {
        setIsModalOpen(!isModalOpen)
    };

    useEffect(() => {
        if (!login?.KYCStatus) {
            setIsModalOpen(true);
            setMessage("Thank you for your Order. We will contact you soon to confirm your details.")
        }
        return () => {
            setIsModalOpen(false);
        }
    }, [])

    return (

        <>
            <div className="container mt-5 mb-5">
                <div className="thankyou-container">
                    <div className="thankyou-imageicon">
                        <img src="/assets/img/success.png" alt="success-png" />
                    </div>
                    <div className="thankyou-meta">
                        <h2>Purchase Success! Thanks for your order.</h2>
                        <p>BuyParts24 is coordinating with the sellers to make your order processed as soon as possible. Your order number is <b>{orderId}.</b>
                            <br />, you will be receiving an message shortly about it. You can track the progress of your order in My Orders page. </p>

                    </div>
                    <div className="thankyou-btn-container ">

                        <NavLink to="/" onClick={() => handleGa4Event("back_to_home_cta")} className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png" alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            Back to home
                        </NavLink>
                        <NavLink to="/my-order" onClick={() => handleGa4Event("my_orders_cta")} className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png" alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            My Orders
                        </NavLink>
                        <NavLink to={pathname ? pathname : filteredPath ? filteredPath?.slice(1, -1) : '/'} onClick={() => handleGa4Event("continue_shopping_cta")} className="thankyou-back-to-home-btn" >
                            <img src="/assets/img/path.png"
                                alt="path_icon" style={{ marginRight: '12px', verticalAlign: "initial" }} />
                            Continue Shopping
                        </NavLink>
                    </div>
                </div>

            </div>

            <ShowMessageModal
                isModalOpen={isModalOpen}
                toggle={toggle}
                message={msg}
            />


        </>
    )
}

export default ThankYou
