import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { SetPhoneNumber } from "../../../Store/action/signupActions";
import { ga4Events, popUp } from "../../../utils";
import { phoneNoValidator } from "../../../services/numValidator";
import { getOTP, verifyOTP } from "../../../Store/action/userAction";
import { useHistory } from "react-router-dom";

const VerifyPhone = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [phone, setPhone] = useState("");
    const [otp, setOTP] = useState("");

    const validator = useRef(new SimpleReactValidator());

    const [verifyLoading, setVerifyLoading] = useState(false);
    const [otpLoading, setOTPLoading] = useState(false);

    const [codeSent, setCodeSent] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);

    const [, forceUpdate] = useState();

    const { t } = useTranslation();

    const signup = useSelector(state => state.signup);

    useLayoutEffect(() => {
        if (!signup.accountType) {
            history.push("/register");
            return;
        }

        if (!signup.email) {
            history.push("/register/verify-email");
            return;
        }
    }, []);

    const verifyPhoneNumber = async () => {
        try {
            const res = await phoneNoValidator({ contact: phone?.trim() });
            setPhoneValid(res?.valid ?? false);
        } catch (error) {
            popUp(error?.message || "Error while validating phone number")
        }
    }

    useEffect(() => {
        if(phone) {
            const timeout = setTimeout(() => {
                verifyPhoneNumber();
            }, 1000);
    
            return () => clearTimeout(timeout);
        } else {
            setPhoneValid(false)
        }
    }, [phone])

    const handleChangePhone = (phone) => {
        setCodeSent(false);
        setPhone(phone?.trim());
        setPhoneValid(false);
    }

    const handleChangeInput = (e) => {
        e.preventDefault();
        setOTP(e.target.value);
    }

    const handleGetOTP = (e) => {
        e.preventDefault();
        setOTPLoading(true);
        setCodeSent(false);

        const postData = {
            value: `+${phone?.trim()}`,
            by: "sms",
            forRegister: true
        }

        getOTP(postData)
            .then((res) => {
                setOTPLoading(false);
                setCodeSent(true);
            })
            .catch((err) => {
                popUp(err?.message);
                setOTPLoading(false);
                setCodeSent(false);
            });
    }

    const handleVerifyOTP = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            setVerifyLoading(true);

            const identify = "+" + phone?.trim();

            const postData = {
                identify,
                code: otp,
                forRegister: true
            }

            verifyOTP(postData)
                .then((res) => {
                    setVerifyLoading(false);
                    ga4Events({
                        action: 'register_phone_otp_verified',
                        category: 'User',
                        phone: identify
                    });
                    dispatch(SetPhoneNumber(identify));
                    history.push("/register/password");
                })
                .catch((err) => {
                    popUp(err?.message);
                    setCodeSent(false);
                    setVerifyLoading(false);
                });
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }

    return (
        <section className="register-verification section-padding-strict mb-5">
            <div className="container">
                <p className="text-center">
                    Please verify your phone number with One Time Password
                </p>
                <form action="/">
                    <div className="form-group  opt-password-wrapper">
                        <PhoneInput
                            placeholder={t("auth_phone_placeholder")}
                            name="Phone"
                            value={phone || ""}
                            onChange={(phone) => handleChangePhone(phone)}
                            inputStyle={{ border: "none" }}
                        />
                        <button
                            type="button"
                            disabled={!phoneValid || otpLoading}
                            onClick={handleGetOTP}
                            className="btn get-otp-btn"
                            id="get-opt-btn"
                        >
                            {otpLoading && (
                                <i className="las la-spinner la-spin mr-2"></i>
                            )}
                            {t("auth_get_otp")}
                        </button>
                        <div className="text-danger">
                            {validator.current.message("Phone", phone, "required")}
                        </div>
                    </div>
                </form>
                <div className="opt-password-wrapper form-group">
                    <input
                        type="text"
                        name="otpCode"
                        className="form-control"
                        placeholder="OTP"
                        onChange={handleChangeInput}
                    />
                    <button
                        type="submit"
                        disabled={!phoneValid || verifyLoading || !otp}
                        onClick={handleVerifyOTP}
                        className="btn btn-primary"
                    >
                        {verifyLoading && (
                            <i className="las la-spinner la-spin mr-2"></i>
                        )}
                        {t("auth_continue")}
                    </button>
                </div>
                <div className="text-danger">
                    {validator.current.message("otp code", otp, "required|string")}
                </div>

                {codeSent === true && (
                    <p className="text-info text-center">
                        We have sent code to {phone}.{" "}
                        <a href=" " onClick={handleGetOTP}>
                            {t("auth_send_again")}
                        </a>
                        ?
                    </p>
                )}
            </div>
        </section>
    )
}

export default VerifyPhone