import React, { Fragment, Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Select from 'react-select'
import axios from 'axios'
import LoadingOverlay from 'react-loading-overlay'
import { PageBanner } from '../content/element/page-banner'
import {
  SetListings,
  VinSearchDetails,
  SetTradesoftSKUs,
  SetLoading,
} from '../../Store/action/listingActions'
import qs from 'query-string'
import SearchIcon from "@material-ui/icons/Search";
import { ga4Events, getCurrentTime, popUp } from '../../utils'
class SearchVin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      makes: [],
      models: [],
      engines: [],
      categories: [],
      categoriesVIN: [],
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      tradesoftSKUs: [],
      selectedMake: null,
      selectedModel: null,
      selectedEngine: null,
      carId: null,
      catalogId: null,
      isSearchByMake: false,
      criteria: null,
      isLoading: false,
      partIdentified: false,
      vinCode: '',
      partSKU: '',
      carIdentified: false,
      isSearchByVin: false,
      makeFromVIN: null,
      modelFromVIN: null,
      categoryFromVIN: null,
      engineFromVIN: null,
      isLoadingSpareParts: false,
      error: false,
      search: '',
      vinSearch: false,
      currentTime: '',
      searchStartTime: new Date(),
      searchDuration: 0,
      startTime: Date.now(),
      endTime: null,
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      selectedOption: '',
      query: '',
      isSearchloading: false,
      groupSuggestOption: [],
      menuIsOpen: false,
      groupSuggestSelectOption: null,
      criteria:null
    }
    this.handleChangeMake = this.handleChangeMake.bind(this)
    this.handleChangeModel = this.handleChangeModel.bind(this)
    this.handleChangeEngine = this.handleChangeEngine.bind(this)
    this.handleChangeCategoriesVin = this.handleChangeCategoriesVin.bind(this)
    this.handleChangeVINCode = this.handleChangeVINCode.bind(this)
    this.getVehiclesByVIN = this.getVehiclesByVIN.bind(this)
    this.calculateDuration = this.calculateDuration.bind(this)
  }

  customFilterOption = (option, rawInput) => true;

  handleChange(e) {
    e.preventDefault()
    this.setState({ error: false })
    const { value, name } = e.target || {}
    // eslint-disable-next-line
    if (value.match(/^[\w\s\.\/\,\"\'\-]+$/) || value === '') {
      this.setState({ [name]: value })
    }
  }

  stopTimer = () => {
    this.setState({ endTime: Date.now() })
  }

  calculateDuration = () => {
    const { startTime, endTime } = this.state
    if (startTime) {
      const duration = Date.now() - startTime
      return duration
    }
    return 0
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    axios
      .get('/api/info/get-catalogs-tradesoft')
      .then((res) => {
        const makes = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d }
        })
        this.setState({ makes, isLoading: false })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        popUp('There was a problem with server. Please try again.')
      })

    if (this.props.location.search) {
      let { vin } = qs.parse(this.props.location.search)
      this.setState({ vinCode: vin }, () => {
        this.getVehiclesByVIN()
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      let { vin } = qs.parse(this.props.location.search)
      this.setState({ vinCode: vin }, () => {
        this.getVehiclesByVIN()
      })
    }
  }

  handleChangeVINCode(e) {
    this.setState({ vinCode: e.target.value })
  }

  handleGetGroupSuggest = async () => {
    this.setState({ isSearchloading: true })
    if (this.state.query && this.state.action === 'input-change') {
      try {
        const res = await axios.get(`/api/info/group-suggest?catalogId=${this.state.catalogId}&q=${this.state.query}`);

        if (res?.data?.data?.length === 0) throw 'No results found !'
        else {
           let isDup = new Set()
        let options  = res.data.data.filter(el=>{
          const dups = isDup.has(el.value)
          isDup.add(el.value)
          return !dups
        })
          this.setState({  groupSuggestOption: options, menuIsOpen: true, })
        }
      } catch (error) {
        this.setState({ menuIsOpen: false })
        if (typeof error === 'string') popUp(error)
        else popUp(error.response.data.message)
      }
      finally {
        this.setState({ ...this.state, isSearchloading: false, action: '' })
      }
    }
    else {
      this.setState({
        ...this.state,
        menuIsOpen: true,
        isSearchloading: false,
      })
    }
  }

  getGroupIdBySid = async (option, query) => {
    this.setState({
      groupSuggestSelectOption: option,
      menuIsOpen: false,
      isLoading: true,
      partname: query || this.state.partname,

    })
    let params = {
      catalogId: this.state.catalogId,
      carId: this.state.carId,
      sid: option?.value,
      criteria:this.state.criteria,
      text:option?.label,
      partname:query|| this.state.partname
    }

    try {
      const { data } = await axios.get('/api/info/get-parts-by-sid', {
        params
      })
      this.getTradesoftSKUs(data.data)
      this.setState({
        isLoading: false
      })
      this.showSparePartsTradeSoft(null, true)
    }
    catch (er) {
     popUp(er?.response?.data?.message ||"No results found please select other option")
      this.setState({
        ...this.state,
       isLoading: false,
      partname: query || this.state.partname,
     
      })
     setTimeout(()=>{
      this.setState({
        menuIsOpen:true,
      })
     },100)
    }

  }


  handleChangeMake(option) {
    this.setState({
      selectedMake: option,
      isLoading: true,
      vinCode: null,
      isSearchByMake: true,
      categoriesVIN: [],
      categoryFromVIN: null,
      models: [],
      selectedModel: null,
      engines: [],
      selectedEngine: null,
      subGroupsTradeSoft: [],
      selectedsubGroupsTradeSoft: null,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      categories: [],
      partsError: false,
      carIdentified: false,
      partIdentified: false,
      isSearchByVin: false,
      makeFromVIN: null,
      modelFromVIN: null,
      carId: null,
      catalogId: null,
      criteria: null,
    })
    
    axios
      .get('/api/info/get-models-tradesoft?catalogId=' + option.value)
      .then((res) => {
        const models = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d }
        })
        this.setState({ models, isLoading: false })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        this.setState({ isLoading: false })
      })
  }

  handleChangeModel(option) {
    const { selectedMake } = this.state
    this.setState({
      selectedModel: option,
      engines: [],
      selectedEngine: null,
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      categories: [],
      categoriesVIN: [],
      categoryFromVIN: null,
      carId: null,
      catalogId: null,
      partsError: false,
      criteria: null,
      isLoading: true,
    })
    axios
      .post(`/api/info/get-trim-tradesoft`, {
        catalogId: selectedMake.value,
        modelId: option.value,
      })
      .then(async (res) => {
        const engines = res.data.map((d) => {
          return { label: d.name, value: d.id, ...d }
        })
        this.setState({ engines, isLoading: false })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
        this.setState({ isLoading: false })
      })
  }

  handleChangeEngine(option) {
    this.setState({
      selectedEngine: option,
      isLoading: true,
      carId: option.id,
      catalogId: option.catalogId,
      criteria: option.criteria,
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      partsError: false,
      tradesoftSKUs: [],
      categories: [],
    })
    axios
      .post(`/api/info/get-cats-tradesoft`, {
        carId: option.id,
        catalogId: option.catalogId,
      })
      .then((res) => {
        this.setState({ categories: res.data.group2 })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  handleChangeCategoriesVin(option) {
    this.setState({
      categoryFromVIN: option,
      isLoading: true,
      subGroupsTradeSoft: [],
      selectedsubGroupsTradeSoft: null,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      tradesoftSKUs: [],
      partsError: false,
    })
    if (option.hasParts === false) {
      axios
        .post(`/api/info/get-sub-groups-tradesoft`, {
          carId: this.state.carId,
          catalogId: this.state.catalogId,
          criteria: this.state.criteria,
          groupId: option.value,
        })
        .then((res) => {
          this.setState({ subGroupsTradeSoft: res.data })
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  getTradesoftSKUs = (data) => {
    let skus = []
    data.forEach((ele) => {
      ele.parts.forEach((e) => {
        if (e.number) {
          skus.push(e.number)
        }
      })
    })
    this.setState({ tradesoftSKUs: skus, partIdentified: true })
  }

  handleChangeSubGroupsTradeSoft = (option) => {
    this.setState({
      isLoading: true,
      selectedsubGroupsTradeSoft: option,
      subGroupsTradeSoft1: [],
      selectedsubGroupsTradeSoft1: null,
      partsError: false,
      partIdentified: false,
      tradesoftSKUs: [],
    })
    if (option.hasParts === true) {
      axios
        .post(`/api/info/get-spareparts-tradesoft`, {
          carId: this.state.carId,
          catalogId: this.state.catalogId,
          criteria: this.state.criteria,
          groupId: option.value,
        })
        .then((res) => {
          this.getTradesoftSKUs(res.data.partGroups)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    }
    if (option.hasParts === false) {
      axios
        .post(`/api/info/get-sub-groups-tradesoft`, {
          carId: this.state.carId,
          catalogId: this.state.catalogId,
          criteria: this.state.criteria,
          groupId: option.value,
        })
        .then((res) => {
          this.setState({ subGroupsTradeSoft1: res.data })
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    }
  }

  handleChangeSubGroupsTradeSoft1 = (option) => {
    this.setState({
      isLoading: true,
      selectedsubGroupsTradeSoft1: option,
      partsError: false,
      partIdentified: false,
      tradesoftSKUs: [],
    })
    if (option.hasParts === true) {
      axios
        .post(`/api/info/get-spareparts-tradesoft`, {
          carId: this.state.carId,
          catalogId: this.state.catalogId,
          criteria: this.state.criteria,
          groupId: option.value,
        })
        .then((res) => {
          this.getTradesoftSKUs(res.data.partGroups)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      this.setState({ partIdentified: false, partsError: true })
      this.setState({ isLoading: false })
    }
  }

  showSparePartsTradeSoft = (e, vinSearch) => {
    if (vinSearch) {
      this.setState({ isLoadingSpareParts: true })
      const extraData = {
        vinSearch: vinSearch,
        make: this.state.makeFromVIN,
        model: this.state.modelFromVIN,
        category: this.state.categoryFromVIN,
        subcategory: this.state.selectedsubGroupsTradeSoft,
        subcategory2: this.state.selectedsubGroupsTradeSoft1,
        skus: [...new Set(this.state.tradesoftSKUs)],
        startTime: this.state.startTime,
        endTime: Date.now(),
      }
      let encoded_key = ''
      let encodedSearch = ''
      if (this.state.partname) {
        encoded_key = 'partname'
        encodedSearch = encodeURIComponent(this.state.partname.toString())
      }
      if (this.state.partnumber) {
        encoded_key = 'partnumber'
        encodedSearch = encodeURIComponent(this.state.partnumber.toString())
      }


      this.props.history.push({
        pathname: `/all-listings`,
        search: `?type=vin-search${encodedSearch ? `&${encoded_key}=${encodedSearch}` : ''
          }`,
        state: { type: 'vin-search', extraData },
      })

      ga4Events({
        action: 'parts_searched_from_VIN',
        category: 'Listing',
        query: encodedSearch,
        user_id: this.props.login?._id,
        currentTime: getCurrentTime(),
        platform: 'web',
      })
    } else {
      this.setState({ isLoadingSpareParts: true })
      let emirate =
        this.props.login?.role === 'BUYER'
          ? this.props.login?.details?.emirate
          : ''
      let user_id =
        this.props.login?.role === 'BUYER' ? this.props.login?._id : ''
      if (this.state.tradesoftSKUs.length) {
        axios
          .post(
            `/api/info/tradesoft-skus?emirate=${emirate}&user_id=${user_id}`,
            { skus: [...new Set(this.state.tradesoftSKUs)] }
          )
          .then((res) => {
            let bp24 = res.data

            let _bp24 = bp24.map((b) => {
              return {
                ...b,
                date: new Date(),
                pic: b.imageArray[0],
                istecAll: false,
              }
            })

            this.props.setListings({ listings: [..._bp24] })
            this.props.SetTradesoftSKUs([...new Set(this.state.tradesoftSKUs)])
            this.props.vinSearchDetails({
              make: this.state.makeFromVIN,
              model: this.state.modelFromVIN,
              vin: this.state.vinCode,
            })

            ga4Events({
              action: 'parts_searched_from_VIN',
              category: 'Search',
              user_id: this.props.login?._id,
              currentTime: getCurrentTime(),
              platform: 'web',
            })

            this.setState({ isLoadingSpareParts: false })
            this.props.history.push({
              pathname: `/all-listings`,
              search: `?api=${true}`,
              state: {
                skus: [...new Set(this.state.tradesoftSKUs)],
                isSearchByCatSubCat: true,
                startTime: this.state.startTime,
                endTime: Date.now(),
              },
            })
          })
          .catch((err) => {
            this.setState({ isLoadingSpareParts: false })
            console.log(err)
            popUp('There was a problem with server. Please try again.')
          })
      }
    }
  }

  getVehiclesByVIN() {
    if (this.state.vinCode) {
      this.setState({
        isLoading: true,
        isSearchByMake: false,
        partIdentified: false,
        partsError: false,
        tradesoftSKUs: [],
        selectedMake: null,
        categoriesVIN: [],
        categoryFromVIN: null,
        models: [],
        selectedModel: null,
        engines: [],
        selectedEngine: null,
        subGroupsTradeSoft: [],
        selectedsubGroupsTradeSoft: null,
        subGroupsTradeSoft1: [],
        selectedsubGroupsTradeSoft1: null,
        tradesoftSKUs: [],
        categories: [],
        partsError: false,
        carId: null,
        catalogId: null,
        criteria: null,
        carIdentified: false,
        makeFromVIN: null,
        modelFromVIN: null,
        carId: null,
        catalogId: null,
        criteria: null,
        startTime: Date.now(),
        groupSuggestOption: [],

      })
      axios
        .get(
          `/api/info/get-vehicles-by-vin-tradesoft?vinCode=${this.state.vinCode}&userId=${this.props.login?._id}`
        )
        .then((res) => {
          if (res.data) {
            console.log('vechical--' + res.data);
            this.setState({ categoriesVIN: res.data.group2,criteria:res.data.criteria })
            this.setState({ carIdentified: true, isSearchByVin: true })
            this.setState({
              makeFromVIN: res.data.make,
            })
            this.setState({
              modelFromVIN: res.data.model,
            })
            this.setState({
              carId: res.data.carId,
              catalogId: res.data.catalogId,
              criteria: res.data.criteria,
            })

            ga4Events({
              action: 'vin_search_result',
              category: 'Listing',
              vin_number: this.state.vinCode,
              status: 'result_found',
            })
          } else {
            this.setState({ carIdentified: false, isSearchByVin: true })
          }
          this.setState({ isLoading: false })
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            carIdentified: false,
            isSearchByVin: false,
          })

          if (err?.response?.status === 404) {
            popUp('Cannot find the result for given VIN number.')
            ga4Events({
              action: 'vin_search_result',
              category: 'Listing',
              vin_number: this.state.vinCode,
              status: 'no_result_found',
              reason: 'Wrong Vin Number',
            })
            return
          }
          popUp('There was a problem with server. Please try again.')
        })
    }
  }

  handleOptionChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    })
    this.setState({
      search: '',
      categoryFromVIN: null,
      selectedsubGroupsTradeSoft: null,
      selectedsubGroupsTradeSoft1: null,
      subGroupsTradeSoft: [],
      subGroupsTradeSoft1: [],
    })
  }

  render() {
    const {
      makes,
      models,
      engines,
      categories,
      isLoading,
      makeFromVIN,
      modelFromVIN,
      isLoadingSpareParts,
      carIdentified,
      isSearchByVin,
      categoryFromVIN,
      selectedsubGroupsTradeSoft,
      selectedsubGroupsTradeSoft1,
      partsError,
      selectedEngine,
      selectedModel,
      selectedMake,
      partIdentified,
      isSearchByMake,
      vinCode,
      categoriesVIN,
      subGroupsTradeSoft,
      subGroupsTradeSoft1,
      menuIsOpen,
      query,
      isSearchloading,
      groupSuggestOption,
      partnumber
    } = this.state

    return (
      <Fragment>
        <LoadingOverlay active={isLoading} spinner text={`Loading...`}>
          <PageBanner title='Parts Search Vin' />
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='searh-vin-page'>
                  <section className='search-vin-col'>
                    <h2>Find the model by VIN number</h2>
                    <p>
                      Vehicle VIN is the most reliable identifier. If you are
                      looking for a Japanese car, enter FRAME
                    </p>
                    <div className='vin-search-wrapper'>
                      <i style={{ cursor: 'pointer' }} onClick={this.getVehiclesByVIN} className='la la-search'></i>
                      <input
                        type='text'
                        name='search'
                        className='form-control'
                        autoComplete='off'
                        placeholder='VIN or FRAME'
                        onChange={this.handleChangeVINCode}
                        value={vinCode}
                      />
                    </div>
                    <p className='ex-text'>
                      For example: VIN XW8AN2NE3JH035743 or FRAME KZN185-9023353
                    </p>
                    <button
                      id='findCarVin'
                      style={{ fontSize: '1rem' }}
                      disabled={isLoading}
                      type='button'
                      className='btn btn-block btn-primary'
                      onClick={this.getVehiclesByVIN}>
                      Find Car with VIN
                    </button>
                    {isSearchByVin && carIdentified ? (
                      <div className='car-identified-wrap'>
                        <div className='p-3 bg-success w-100 text-light text-center h2 my-4 border-top'>
                          <i className='las la-check-square mr-1'></i>
                          Car identified!
                        </div>
                        <div className='form-group row'>
                          <label className='control-label col-sm-3'>
                            Make:
                          </label>
                          <div className='col-sm-9'>
                            <h3>{makeFromVIN}</h3>
                          </div>
                        </div>
                        <div className='form-group row'>
                          <label className='control-label col-sm-3'>
                            Model:
                          </label>
                          <div className='col-sm-9'>
                            <h3>{modelFromVIN}</h3>
                          </div>
                        </div>

                        <div className='form-group row'>
                          <label className='col-sm-12 text-dark ml-2 h5'>
                            Search Via:
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            paddingRight: '1px',
                            justifyContent: 'space-around',
                            marginBottom: '14px',
                          }}>
                          <label className=' text-dark h5'>
                            <input
                              type='radio'
                              value='partName'
                              name='options'
                              checked={this.state.selectedOption === 'partName'}
                              onChange={this.handleOptionChange}
                              className='mr-2'
                            />
                            Part Name
                          </label>
                          <label className=' text-dark h5'>
                            <input
                              type='radio'
                              value='partNumber'
                              name='options'
                              checked={
                                this.state.selectedOption === 'partNumber'
                              }
                              onChange={this.handleOptionChange}
                              className='mr-2'
                            />
                            Part Number
                          </label>
                          <label className=' text-dark h5'>
                            <input
                              type='radio'
                              value='category'
                              name='options'
                              checked={this.state.selectedOption === 'category'}
                              onChange={this.handleOptionChange}
                              className='mr-2'
                            />
                            Category
                          </label>
                        </div>

                        {this.state.selectedOption === 'partName' && (
                          <div className='form-group row'>
                            <div className='col-sm-9'>
                              <Select
                                className={`react-select`}
                                classNamePrefix='react-select'
                                placeholder='Search....'
                                menuIsOpen={menuIsOpen}
                                onKeyDown={(e) => {
                                  let { key } = e
                                  if (key === 'Enter') {
                                    this.handleGetGroupSuggest()
                                    e.preventDefault()
                                  }
                                }}
                                components={{
                                  DropdownIndicator: () => <SearchIcon onClick={this.handleGetGroupSuggest} style={{ cursor: 'pointer', fontSize: 24, marginTop: 0, marginRight: '12px' }} />
                                  , IndicatorSeparator: () => null
                                }}
                                isLoading={isSearchloading}
                                filterOption={this.customFilterOption}
                                inputValue={query}
                                onFocus={() => {
                                  if (this.state?.groupSuggestOption?.length) {
                                    this.setState({
                                      menuIsOpen: true
                                    })
                                  }
                                }}
                                onBlur={() => {
                                  this.setState({
                                    menuIsOpen: false
                                  })
                                }}
                                onChange={(sel, action) => {
                                  this.getGroupIdBySid(sel, this.state.query)
                                }}

                                onInputChange={
                                  (option, {
                                    action
                                  }) => {
                                    if (action === 'input-change') {
                                      this.setState({
                                        query: option,
                                        action,
                                      })
                                      if (option === '') {
                                        this.setState({
                                          menuIsOpen: false
                                        })
                                      }
                                      else {
                                        if (this.state.groupSuggestOption.length) {
                                          this.setState({
                                            menuIsOpen: true
                                          })
                                        }
                                      }
                                    }
                                    else if (action === 'set-value') {
                                      this.setState({
                                        query: option,
                                        action,
                                      })
                                    }
                                  }
                                }
                                options={groupSuggestOption}
                              />
                            </div>

                          </div>
                        )}
                        {this.state.selectedOption === 'partNumber' && (
                          <div className='form-group row'>
                            <div className='col-sm-12'>
                              <input
                                type='text'
                                value={this.state.partnumber || ''}
                                name='partnumber'
                                onChange={(e) => this.handleChange(e)}
                                placeholder={'Enter Part Number'}
                                className='form-control'
                              />
                            </div>
                          </div>
                        )}

                        {this.state.selectedOption === 'category' && (
                          <>
                            <div className='form-group row'>
                              <label className='control-label col-sm-3'>
                                Categories:
                              </label>
                              <div className='col-sm-9'>
                                <Select
                                  className={`react-select`}
                                  classNamePrefix='react-select'
                                  placeholder='Choose Category'
                                  name='categories'
                                  value={categoryFromVIN}
                                  onChange={this.handleChangeCategoriesVin}
                                  options={categoriesVIN.map((value) => {
                                    return {
                                      label: value.name,
                                      value: value.id,
                                      ...value,
                                    }
                                  })}
                                />
                              </div>
                            </div>

                            {subGroupsTradeSoft.length > 0 && isSearchByVin && (
                              <div className='form-group row'>
                                <label className='control-label col-sm-3'>
                                  Sub-Categories:
                                </label>
                                <div className='col-sm-9'>
                                  <Select
                                    className={`react-select`}
                                    classNamePrefix='react-select'
                                    placeholder='Choose Categories...'
                                    name='categories'
                                    value={selectedsubGroupsTradeSoft}
                                    onChange={
                                      this.handleChangeSubGroupsTradeSoft
                                    }
                                    options={subGroupsTradeSoft.map((value) => {
                                      return {
                                        label: value.name,
                                        value: value.id,
                                        ...value,
                                      }
                                    })}
                                  />
                                </div>
                              </div>
                            )}
                            {subGroupsTradeSoft1.length > 0 &&
                              isSearchByVin && (
                                <div className='form-group row'>
                                  <label className='control-label col-sm-3'>
                                    Sub-Categories 2:
                                  </label>
                                  <div className='col-sm-9'>
                                    <Select
                                      className={`react-select`}
                                      classNamePrefix='react-select'
                                      placeholder='Choose Categories...'
                                      name='categories'
                                      value={selectedsubGroupsTradeSoft1}
                                      onChange={
                                        this.handleChangeSubGroupsTradeSoft1
                                      }
                                      options={subGroupsTradeSoft1.map(
                                        (value) => {
                                          return {
                                            label: value.name,
                                            value: value.id,
                                            ...value,
                                          }
                                        }
                                      )}
                                    />
                                  </div>
                                </div>
                              )}
                          </>
                        )}
                        {((
                          this.state.selectedOption === 'partNumber') ||
                          (this.state.selectedOption === 'category' &&
                            partIdentified)) && (
                            <button
                              style={{ fontSize: '1rem' }}
                              type='button'
                              className='btn btn-block btn-primary'
                              disabled={
                                (this.state.selectedOption === 'partNumber' && this.state.partnumber) &&
                                  !categoryFromVIN &&
                                  (!selectedsubGroupsTradeSoft ||
                                    !selectedsubGroupsTradeSoft1)
                                  ? false
                                  : isSearchByVin &&
                                    !isSearchByMake &&
                                    (isLoadingSpareParts || !partIdentified)
                                    ? true
                                    : false
                              }
                              onClick={(e) => {
                                this.showSparePartsTradeSoft(e, true)
                              }}>
                              {this.state.selectedOption === 'partName' || this.state.selectedOption === 'partNumber'
                                ? 'Search Part'
                                :
                                "Search Category"
                              }
                              {isLoadingSpareParts && (
                                <i className='las la-spinner la-spin mr-2'></i>
                              )}
                            </button>
                          )}

                        {isSearchByVin && partsError ? (
                          <div className='car-identified-wrap'>
                            <div className='p-3 bg-danger w-100 text-light text-center h2 my-4 border-top'>
                              Parts not found!
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : isSearchByVin && !carIdentified ? (
                      <div className='car-identified-wrap'>
                        <div className='p-3 bg-danger w-100 text-light text-center h2 my-4 border-top'>
                          Car not found!
                        </div>
                      </div>
                    ) : null}
                  </section>
                  <div className='separator-line'>
                    <span>or</span>
                  </div>
                  <section className='search-vin-col'>
                    <h2
                      onClick={() => this.setState({ startTime: Date.now() })}>
                      Find car parts for your car
                    </h2>
                    <p>
                      If you do not have or can't find the VIN number, please
                      fill the details below
                    </p>
                    <form className='form-horizontal'>
                      <div className='form-group row'>
                        <label className='control-label col-sm-3'>Make:</label>
                        <div className='col-sm-9'>
                          <Select
                            className={`react-select`}
                            classNamePrefix='react-select'
                            placeholder='Choose...'
                            name='make'
                            value={selectedMake}
                            onChange={this.handleChangeMake}
                            options={makes}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label className='control-label col-sm-3'>Model:</label>
                        <div className='col-sm-9'>
                          <Select
                            className={`react-select`}
                            classNamePrefix='react-select'
                            placeholder='Choose...'
                            name='model'
                            value={selectedModel}
                            onChange={this.handleChangeModel}
                            options={models}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label className='control-label col-sm-3'>Trim:</label>
                        <div className='col-sm-9'>
                          <Select
                            className={`react-select`}
                            classNamePrefix='react-select'
                            placeholder='Choose...'
                            name='engine'
                            value={selectedEngine}
                            onChange={this.handleChangeEngine}
                            options={engines}
                          />
                        </div>
                      </div>

                      {isSearchByMake && (
                        <>
                          <div className='form-group row'>
                            <label className='control-label col-sm-3'>
                              Categories:
                            </label>
                            <div className='col-sm-9'>
                              <Select
                                className={`react-select`}
                                classNamePrefix='react-select'
                                placeholder='Choose Categories...'
                                name='categories'
                                value={categoryFromVIN}
                                onChange={this.handleChangeCategoriesVin}
                                options={categories.map((value) => {
                                  return {
                                    label: value.name,
                                    value: value.id,
                                    ...value,
                                  }
                                })}
                              />
                            </div>
                          </div>
                          {subGroupsTradeSoft.length > 0 && (
                            <div className='form-group row'>
                              <label className='control-label col-sm-3'>
                                Sub-Categories:
                              </label>
                              <div className='col-sm-9'>
                                <Select
                                  className={`react-select`}
                                  classNamePrefix='react-select'
                                  placeholder='Choose Categories...'
                                  name='categories'
                                  onChange={this.handleChangeSubGroupsTradeSoft}
                                  options={subGroupsTradeSoft.map((value) => {
                                    return {
                                      label: value.name,
                                      value: value.id,
                                      ...value,
                                    }
                                  })}
                                />
                              </div>
                            </div>
                          )}

                          {subGroupsTradeSoft1.length > 0 && (
                            <div className='form-group row'>
                              <label className='control-label col-sm-3'>
                                Sub-Categories 2:
                              </label>
                              <div className='col-sm-9'>
                                <Select
                                  className={`react-select`}
                                  classNamePrefix='react-select'
                                  placeholder='Choose Categories...'
                                  name='categories'
                                  onChange={
                                    this.handleChangeSubGroupsTradeSoft1
                                  }
                                  options={subGroupsTradeSoft1.map((value) => {
                                    return {
                                      label: value.name,
                                      value: value.id,
                                      ...value,
                                    }
                                  })}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {isSearchByMake && partsError ? (
                        <div className='car-identified-wrap'>
                          <div className='p-3 bg-danger w-100 text-light text-center h2 my-4 border-top'>
                            Parts not found!
                          </div>
                        </div>
                      ) : null}
                    </form>
                    <fieldset>
                      {selectedMake && (
                        <button
                          className='btn btn-primary btn-block'
                          onClick={(e) => {
                            this.setState({ endTime: Date.now() })
                            this.showSparePartsTradeSoft(e, false)
                          }}
                          style={{ fontSize: '1rem' }}
                          disabled={
                            !isSearchByVin &&
                            isSearchByMake &&
                            (isLoadingSpareParts || !partIdentified)
                          }>
                          {isLoadingSpareParts && (
                            <i className='las la-spinner la-spin mr-2'></i>
                          )}
                          Show Spare Parts
                        </button>
                      )}
                    </fieldset>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  }
}
const mapDispatchToProp = (dispatch) => {
  return {
    setListings: (data) => dispatch(SetListings(data)),
    SetTradesoftSKUs: (data) => dispatch(SetTradesoftSKUs(data)),
    vinSearchDetails: (data) => dispatch(VinSearchDetails(data)),
    SetLoading: (data) => dispatch(SetLoading(data)),
  }
}
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(SearchVin)
