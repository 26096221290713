import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import MaterialTable from "material-table";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { TablePagination } from '@material-ui/core'

import { PageBanner } from "../page-banner";
import { ProductImage, popUp } from "../../../../utils";
import CssTextField from "../SearchField";
import PageTitle from "../PageTitle";
import useDebounce from "../../../../hook/useDebounce";

const SellerDeliveries = () => {
  const currentUser = JSON.parse(localStorage.getItem("login"));

  const [deliveries, setDeliveries] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [searchField, setSearchField] = useState("");

  const debouncedValue = useDebounce(searchField, 500)

  async function fetchMyAPI() {
    setLoader(true);
    try {
      let response = await axios.get(
        `api/order?seller=${currentUser?._id}&status=delivered&size=${perPage}&page=${page + 1}&search=${searchField}`
      );
      setDeliveries(response?.data?.orders);
      setTotalOrders(response?.data?.total)
    } catch (err) {
      popUp(err?.response?.data?.message || "There was a problem with server. Please try again.");
    }
    setLoader(false);
  }

  useEffect(() => {
    fetchMyAPI();
  }, [debouncedValue, page, perPage]);

  const getItems = (order) => {
    let images = []
    order.products.forEach((p) => {
      if (p.delivery_option?.user?._id == currentUser._id) {
        p.delivery_option?.imageArray?.forEach((img) => images.push(img))
      }
    })

    return (
      <div className='d-flex order-listings-wrapper'>
        <>
          <div
            className='listing-item d-inline-block p-1 border rounded-circle'
            style={{ width: 40, height: 40 }}
          >
            <ProductImage
              avatar={images[0]}
              alt={'order'}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
              }}
              width={17}
              height={17}
            />
          </div>
          {images.length > 1 ? (
            <span
              className='badge badge-light rounded-circle border p-1'
              style={{
                width: 40,
                height: 40,
                lineHeight: '30px',
                textAlign: 'center',
              }}
            >
              +{images.length - 1}
            </span>
          ) : (
            <></>
          )}
        </>
      </div>
    )
  }

  const handleSearchChange = (e) => {
    setSearchField(e.target.value);
    setPage(0);
  }

  const handleChangePage = (e, page) => {
    setPage(page);
  }

  const handleChangeRowPerPage = (rowsPerPage) => {
    setPerPage(rowsPerPage)
  }

  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = [
    {
      title: <span className="tryit">Order Id</span>,
      field: "",
      render: (deliveries) => deliveries?.order_id,
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Order Date</span>,
      field: "",
      render: (deliveries) =>
        moment(deliveries?.order_date).tz("Asia/Dubai").format("DD/MM/YYYY"),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Order By</span>,
      field: "user.name",
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Item</span>,
      field: "",
      render: (deliveries) => getItems(deliveries),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">Pick up or Delivery</span>,
      field: "",
      render: (deliveries) =>
        deliveries?.delivery_option === 0 ? "Pick up" : "Delivery",
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },

    {
      title: <span className="tryit">Cost</span>,
      field: "total_price",
      render: (order) => `AED ${getPriceComma(Math.round(order?.total_price?.toFixed(2)))} `,
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
    {
      title: <span className="tryit">View</span>,
      field: "",
      render: (deliveries) => (
        <NavLink to={`/orders/view/${deliveries?._id}`}>
          <div className="primary-btn">View Order</div>
        </NavLink>
      ),
      headerStyle: {
        backgroundColor: "#1a0451",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 800,
        fontFamily: "Montserrat",
      },
    },
  ];

  return (
    <>

      <PageBanner />
      <section className="mt-2  mb-5">
        <div className="container">
          <PageTitle title="My Deliveries" />
          <div className="dashboard-search-box">
            <div className="ml-auto">
              <CssTextField
                id="standard-basic"
                value={searchField}
                onChange={(e) => handleSearchChange(e)}
                label="Search by ID"
              />
            </div>
            <div>
              <i className="las la-search la-search search-icon-datatable"></i>
            </div>
          </div>

          <MaterialTable
            onChangeRowsPerPage={handleChangeRowPerPage}
            style={{ zIndex: 0 }}
            key={totalOrders}
            title={null}
            data={deliveries}
            columns={columns}
            isLoading={loader}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <img
                    src='/assets/img/nodata.png'
                    className='datatableimage'
                  />
                ),
              },
            }}
            options={{
              search: false,
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: '#f2f2f2' }
                }
              },
              paging: true,
              pageSize: perPage,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 50, 100],
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  count={totalOrders}
                  page={page}
                  rowsPerPage={perPage}
                  onChangePage={handleChangePage}
                  component='div'
                />
              ),
            }}
          />
        </div>
      </section>
    </>
  );
};

export default SellerDeliveries;
