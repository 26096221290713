import React, { Fragment } from "react";

export default () => {
  return (
    <Fragment>
      <iframe src="http://52.65.208.255/" name="iframe_all"
        frameBorder="0" style={{ height: 'calc(100vh - 200px)', width: '100vw' }} ></iframe>
    </Fragment>
  );
}
