
import React from 'react'
import { NavLink } from 'react-router-dom';
import { ga4Events } from '../../../utils';
import Address from "../../content/element/Address";

export default function Step2({ 
    addresses, 
    setSelectedAddress, 
    selectedAddress, 
    setIsOpenEditModal, 
    setEditedAddressId, 
    handleAddFormModal 
}) {
    const exactLocation = 
    selectedAddress?.latLong?.lat && 
    selectedAddress?.latLong?.lng ? 
    `${selectedAddress?.latLong?.lat},${selectedAddress?.latLong?.lng}` : selectedAddress?.address ? selectedAddress?.address : ""

    return (
        <div className="shipping-step">
            <div
                style={{
                    display: "flex",
                    backgroundColor: "transparent",
                    flexWrap: "wrap",
                    padding: "10px 0",
                }}
            >
                {addresses &&
                    addresses.map((address, i) => {
                        return (
                            <Address
                                data={address}
                                getShippingAddress={(add) =>{
                                        setSelectedAddress(add);
                                        ga4Events({
                                            action: "cart_address_selection",
                                            category: "User",
                                            address_selected_id: add?._id,
                                            address_selected: add?.address
                                        })
                                    }
                                }
                                key={i}
                                selectedAddress={selectedAddress}
                                setIsOpenEditModal={setIsOpenEditModal}
                                setEditedAddressId={setEditedAddressId}
                            />
                        );
                    })}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "300px",
                }}
                className="checkout-location"
            >
                <div style={{ width: "60%", padding: "20px 0" }}>
                    <h3>Delivery Detail</h3>
                    <div style={{ marginTop: "10px" }}>
                        <b>{selectedAddress?.address}</b>
                    </div>
                    <p style={{ marginTop: "10px" }}>
                        {selectedAddress?.country} {selectedAddress?.state}{" "}
                        {selectedAddress?.suburb}{" "}
                        {selectedAddress?.postcode}{" "}
                        {selectedAddress?.ship_to_site_code}{" "}
                    </p>
                    <NavLink
                        to="#"
                        className="btn btn-primary"
                        onClick={() => { handleAddFormModal(true); }}
                    >
                        Add Address
                    </NavLink>
                </div>
                {
                    exactLocation &&
                    <div style={{ marginTop: "10px" }}>
                        <div className="mapouter">
                            <div className="gmap_canvas" onMouseOver={() => ga4Events({ action: "cart_address_map", category: "Cart" })}>
                                <iframe
                                    width="500"
                                    height="300"
                                    id="gmap_canvas"
                                    src={`https://maps.google.com/maps?q=${exactLocation}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
