import React from 'react'
import { ToWords } from 'to-words'
import moment from 'moment-timezone'
import _ from 'lodash'
import './style.css'
import { formatPhoneNumber, getDiscountAmount } from '../../../../utils'

const Index = ({
  data,
  groupproducts: _groupproducts,
  returnProduct,
  calculatedData
}) => {
  const [groupproducts, setGroupproducts] = React.useState([])
  const [cancelledProducts, setCancelledProducts] = React.useState([])
  const [allProducts, setAllProducts] = React.useState([])
  React.useEffect(() => {
    let filteredProducts = []
    let cancelledProducts = []
    let allProducts = []
    _groupproducts &&
      _groupproducts.length > 0 &&
      _groupproducts.forEach((p) => {
        p.data.forEach((item) => {
          allProducts.push(item)
          if (item.status !== 'cancelled') {
            filteredProducts.push(item)
          } else {
            cancelledProducts.push(item)
          }
        })
      })

    setGroupproducts(filteredProducts)
    setCancelledProducts(cancelledProducts)
    setAllProducts(allProducts)
  }, [_groupproducts])
  const getPriceComma = (str) => {
    return str?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const toWords = new ToWords()

  let returnAmount = 0
  returnAmount = returnProduct.reduce((a, b) => {
    if (b.status === 'quality_check_pass') {
      return a + b.list?.priceExclVat * b.quantity
    } else {
      return a
    }
  }, 0)

  let taxAmount = 0
  let discountAmtOfReturn = returnProduct
    .map((o, i) => {
      let exclPrice = 0
      let totalPrice = 0
      let discountAmount = 0
      let newTotalAfterDiscount = 0
      if (o?.status === 'quality_check_pass') {
        exclPrice = o?.list?.priceExclVat
        totalPrice = exclPrice * o?.quantity
        discountAmount = getDiscountAmount(
          exclPrice,
          o?.quantity,
          o?.list,
          calculatedData?.discountPerUnit,
          data?.coupon
        )
        newTotalAfterDiscount = totalPrice - discountAmount
        taxAmount =
          taxAmount + newTotalAfterDiscount * (o?.list.vatPercent / 100)
      }
      return discountAmount
    })
    .reduce((a, b) => {
      return a + b
    }, 0)
  let afterDiscountOfReturn = returnAmount - discountAmtOfReturn
  let netTotalofReturn = afterDiscountOfReturn + taxAmount

  // calculating total new amount for products without status cancelation
  let total = groupproducts
    ?.map((item, i) => {
      let exclPrice = item?.delivery_option?.priceExclVat
      let totalUnitPrice =
        exclPrice * item?.quantity + item?.shipping_price_individual
      let discountAmount = getDiscountAmount(
        exclPrice,
        item?.quantity,
        item?.delivery_option,
        calculatedData?.discountPerUnit,
        data?.coupon
      )
      // newTaxAMount
      let newTotalAfterDiscount = totalUnitPrice - discountAmount
      let taxAmount =
        newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)

      let newTotalCost = taxAmount + newTotalAfterDiscount
      let netAmount = totalUnitPrice - discountAmount + taxAmount
      return netAmount
    })
    .reduce((a, b) => a + b, 0)

  // calculating total new amount for all products
  let cancelledProductsSum = cancelledProducts.reduce(
    (a, b) => a + b?.delivery_option?.priceExclVat * b?.quantity,
    0
  )

  let totalOrderAmount = allProducts
    ?.map((item, i) => {
      let exclPrice = item?.delivery_option?.priceExclVat
      let totalUnitPrice =
        exclPrice * item?.quantity + item?.shipping_price_individual
      let discountAmount = getDiscountAmount(
        exclPrice,
        item?.quantity,
        item?.delivery_option,
        calculatedData?.initalDiscountPerUnit,
        data?.coupon
      )
      // newTaxAMount
      let newTotalAfterDiscount = totalUnitPrice - discountAmount
      let taxAmount =
        newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)

      let newTotalCost = taxAmount + newTotalAfterDiscount
      let netAmount = totalUnitPrice - discountAmount + taxAmount
      return netAmount
    })
    .reduce((a, b) => a + b, 0)

  let remainingOrderAmt = total - netTotalofReturn

  let refundAmt = totalOrderAmount - remainingOrderAmt - cancelledProductsSum
  const showRefundAmount = returnProduct.some(
    (item) => item.status === 'quality_check_pass'
  )

  return (
    <>
      <div className='invoice-tax-header'>
        <div className='company_logo'>
          <img
            src='/assets/img/logo.svg'
            alt='buyParts'
            style={{ marginRight: '2rem', height: '50px' }}
          />
        </div>
      </div>

      <div className='tax-title'>E-Invoice</div>

      <div className='users-details'>
        <div className='left'>
          <div className='tax-invoice-to'>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>E-Invoice No.</div>
              <div>{data?._id.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>E-invoice Date</div>
              <div>n/a</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>BuyParts24 Order No.</div>
              <div>{data?.order_id}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>BuyParts 24 Order Date</div>
              <div>
                {moment(data?.order_date)
                  .tz('Asia/Dubai')
                  .format('DD/MM/YYYY hh:mm A')}
              </div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Seller Tax Invoice No.</div>
              <div>n/a</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Payment Terms</div>
              <div>Cash on delivery</div>
            </div>
          </div>

          <div className='deliver-to'>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Bill to:</div>
              <div>{data?.user?.name}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Vat Registration Number</div>
              <div>
                {data?.user?.details?.vat_registration_no
                  ? data?.user?.details?.vat_registration_no
                  : 'n/a'}
              </div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Address</div>
              <div>{data?.user?.details?.emirate}</div>
            </div>
          </div>
        </div>
        <div className='right'>
          <div className='tax-invoice-to'>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Seller No.</div>
              <div>
                {_groupproducts[0]?.sellerId.slice(0, 8)?.toUpperCase()}
              </div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Seller Code</div>
              <div>{_groupproducts[0]?.data[0]?.seller?.seller_code}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Seller Address</div>
              <div>{_groupproducts[0]?.data[0]?.seller?.details?.emirate}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Buyer No.</div>
              <div>{data?.user?._id?.slice(0, 8)?.toUpperCase()}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Buyer Name</div>
              <div>{data?.user?.details?.garage_name}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='e-invoice-text'>Buyer Address</div>
              <div>{data?.user?.details?.garage_address}</div>
            </div>
          </div>

          <div className='deliver-to'>
            <div className='tax-info-detail'>
              <div className='tax-invoice-text'>Ship to:</div>
              <div>{data?.user?.details?.garage_name}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='tax-invoice-text'>Contact</div>
              <div>{formatPhoneNumber(data?.user?.phone)}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='tax-invoice-text'>Address</div>
              <div>{data?.delivery_address?.address}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='tax-invoice-text'>Postcode</div>
              <div>{data?.delivery_address?.postcode}</div>
            </div>
            <div className='tax-info-detail'>
              <div className='tax-invoice-text'>Location</div>
              <div>{data?.delivery_address?.location}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='item-table-container'>
        <table className='invoice-tax-table'>
          <tr style={{ border: '1px solid black', backgroundColor: '#bdbdbd' }}>
            <th>#</th>
            <th>Item</th>
            <th style={{ textAlign: 'center' }}>Unit Price</th>
            <th style={{ textAlign: 'center' }}>Qty</th>
            <th>Total Price</th>
            <th>Discount</th>
            <th style={{ textAlign: 'center' }}>Tax</th>
            <th>Net Amt</th>
          </tr>
          {groupproducts &&
            groupproducts.length > 0 &&
            groupproducts.map((item, i) => {
              let exclPrice = item?.delivery_option?.priceExclVat
              let totalUnitPrice =
                exclPrice * item?.quantity + item?.shipping_price_individual
              let discountAmount = getDiscountAmount(
                exclPrice,
                item?.quantity,
                item?.delivery_option,
                calculatedData?.discountPerUnit,
                data?.coupon
              )
              // newTaxAMount
              let newTotalAfterDiscount = totalUnitPrice - discountAmount
              let taxAmount =
                newTotalAfterDiscount * (item?.delivery_option.vatPercent / 100)

              let newTotalCost = taxAmount + newTotalAfterDiscount
              let netAmount = totalUnitPrice - discountAmount + taxAmount
              return (
                <tr>
                  <td style={{ paddingLeft: '5px' }}>{++i}</td>
                  <td>
                    {item?.delivery_option?.partName}
                    <br />
                    <small>
                      <strong>{item?.delivery_option?.partSKU}</strong>
                    </small>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {exclPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: 'center' }}>{item?.quantity}</td>
                  <td style={{ textAlign: 'center' }}>
                    {totalUnitPrice.toFixed(2)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {discountAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {taxAmount.toFixed(2)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {netAmount.toFixed(2)}
                  </td>
                </tr>
              )
            })}
        <tr style={{ border: '1px solid black', fontWeight: '600' }}>
            <td colSpan='3'>Shipping(Inc. VAT) : </td>

            <td style={{ textAlign: 'center' }}></td>
            <td></td>
            <td></td>
            <td></td>

            <td style={{ textAlign: 'center' }}>
              AED  {data?.shipping_price}
            </td>
          </tr>
          <tr style={{ border: '1px solid black', fontWeight: '600' }}>
            <td colSpan='3'>Total Invoice Value: </td>

            <td style={{ textAlign: 'center' }}></td>
            <td></td>
            <td></td>
            <td></td>

            <td style={{ textAlign: 'center' }}>
              AED {getPriceComma((total + data?.shipping_price).toFixed(2))}
            </td>
          </tr>
  
          <tr style={{ border: '1px solid black', fontWeight: '600' }}>
            <td colSpan='2'>Amount in Words :</td>
            <td>{toWords.convert((total+ data?.shipping_price).toFixed(2) * 1)}</td>
          </tr>
        </table>
      </div>

      {returnProduct?.length ? (
        <div className='item-table-container'>
          <div>
            <div
              style={{ fontWeight: '600', textAlign: 'center', color: 'black' }}
            >
              {' '}
              Return Sales{' '}
            </div>
          </div>
          <table className='invoice-tax-table'>
            <thead>
              <tr
                style={{
                  border: '1px solid black',
                  backgroundColor: '#BDBDBD',
                }}
              >
                <th style={{ textAlign: 'center' }}>#</th>
                <th style={{ textAlign: 'left' }}>Item</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center' }}>Unit Price</th>
                <th style={{ textAlign: 'center' }}>Qty</th>
                <th style={{ textAlign: 'center' }}>Total Price</th>
                <th style={{ textAlign: 'center' }}>Discount</th>
                <th style={{ textAlign: 'center' }}>Tax</th>
                <th>Net Amt</th>
              </tr>
            </thead>
            <tbody>
              {returnProduct?.length > 0 &&
                returnProduct.map((o, i) => {
                  let exclPrice = 0
                  let totalPrice = 0
                  let discountAmount = 0
                  let taxAmount = 0
                  let netAmount = 0
                  if (o?.status === 'quality_check_pass') {
                    exclPrice = o?.list?.priceExclVat
                    totalPrice = exclPrice * o?.quantity
                    discountAmount = getDiscountAmount(
                      exclPrice,
                      o?.quantity,
                      o?.list,
                      calculatedData?.discountPerUnit,
                      data?.coupon
                    )
                    let newTotalAfterDiscount = totalPrice - discountAmount
                    taxAmount =
                      newTotalAfterDiscount * (o?.list.vatPercent / 100)

                    let newTotalCost = taxAmount + newTotalAfterDiscount
                    // let netAmount = totalUnitPrice - discountAmount + taxAmount
                    // taxAmount = o?.list?.vatAmount
                    netAmount = totalPrice - discountAmount + taxAmount
                  }
                  return (
                    <tr
                      style={{ border: '1px solid black', textAlign: 'center' }}
                    >
                      <td style={{ paddingRight: '5px', textAlign: 'center' }}>
                        {++i}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {o?.list?.partName}
                        <br />
                        <small>
                          <strong style={{whiteSpace: "pre-wrap"}}>{o?.list?.partSKU}</strong>
                        </small>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {o?.status.toUpperCase().replace('_', ' ')}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {exclPrice?.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'center' }}>{o?.quantity}</td>
                      <td style={{ textAlign: 'center' }}>
                        {totalPrice.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {discountAmount.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {taxAmount.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {netAmount.toFixed(2)}
                      </td>
                    </tr>
                  )
                })}
              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Total Return Sales Value: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: 'center' }}>
                  AED {getPriceComma(netTotalofReturn.toFixed(2))}
                </td>
              </tr>

              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Net Total Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: 'center' }}>
                  AED {getPriceComma(calculatedData?.remainingOrderAmount ? calculatedData?.remainingOrderAmount.toFixed(2) : 0)}
                </td>
              </tr>

              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Amount in Words : </td>

                <td style={{ textAlign: 'center' }}>
                  {toWords.convert((calculatedData?.remainingOrderAmount ? calculatedData?.remainingOrderAmount.toFixed(2) : 0) * 1)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

      {showRefundAmount ? (
        <div className='item-table-container'>
          <div>
            <div
              style={{ fontWeight: '600', textAlign: 'center', color: 'black' }}
            >
              {' '}
              Refundable Amount{' '}
            </div>
          </div>
          <table className='invoice-tax-table'>
            <thead></thead>
            <tbody>
              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Total Order Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: 'center' }}>
                  AED {getPriceComma(calculatedData?.totalOrderAmount ? calculatedData?.totalOrderAmount.toFixed(2) : 0)}
                </td>
              </tr>

              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Remaining Order Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: 'center' }}>
                  AED {getPriceComma(calculatedData?.remainingOrderAmount ? calculatedData?.remainingOrderAmount.toFixed(2) : 0)}
                </td>
              </tr>
              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Refundable Amount: </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: 'center' }}>
                  AED {getPriceComma(calculatedData?.netPayable ? calculatedData?.netPayable.toFixed(2) : 0)}
                </td>
              </tr>

              <tr style={{ border: '1px solid black', fontWeight: '600' }}>
                <td colSpan='3'>Amount in Words : </td>

                <td style={{ textAlign: 'center' }}>
                  {toWords.convert((calculatedData?.netPayable ? calculatedData?.netPayable.toFixed(2) : 0) * 1)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
      <div className='einvoice-note'>
        <b>Note:</b>
        <ul>
          <li>
            This is an e-invoice from BuyParts24. The Tax invoice will be
            generated by the seller.{' '}
          </li>
          <li>
            It is system-generated document and does not require signature.
          </li>
          <li>
            BuyParts24 is not liable for payment made to any party based on this
            invoice. BuyParts24 accepts the payment through its authorized
            channels only.
          </li>
          <li>
            For any queries related to this invoice, please get in touch to our
            customer service.
          </li>
        </ul>
      </div>
    </>
  )
}

export default Index
