import axios from "axios";

import { NotifyToastWithButtons } from "../../components/common/ToastNotifier/ToastNotifier";
import { LogOut } from "./logoutAction";
import { popUp } from "../../utils";
import { UpdateUserInfo } from "./loginActions";

export const getUserDetail = (id) => {
    return (dispatch) => {
        axios
            .get(`/api/users/${id}`)
            .then((res) => {
                if (res.data.status === 'Blocked') {
                    dispatch(LogOut(null));
                    NotifyToastWithButtons(
                        'Your account has been suspended.Please contact buyparts admin'
                    )
                }
                if (res.data.isDeleted) {
                    dispatch(LogOut(null));
                    NotifyToastWithButtons(
                        'Your account has been deleted.Please contact buyparts admin'
                    )
                }
                dispatch(UpdateUserInfo(res.data));
            })
            .catch((_) => {
            })
    };
};

export const updateUserProfile = ({ formData, userId, onSuccess, onFail }) => {
    return dispatch => {
        axios
            .put(`/api/users/${userId}`, formData)
            .then((res) => {
                dispatch(UpdateUserInfo(res?.data?.user));
                onSuccess();
            })
            .catch((err) => {
                console.log(err)
                popUp(err?.response?.data?.message || "Error while updating details. Please try again.");
                onFail();
            });
    }
}

export const getOTP = (postData) => {
    return axios.post(`/api/users/get-otp`, postData)
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            throw new Error(err?.response?.data?.message || "Error while sending the OTP. Please try again.")
        });

}

export const verifyOTP = (postData) => {
    return axios.post(`/api/users/verify-otp`, postData)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw new Error(err?.response?.data?.message || "Error while sending the OTP. Please try again.")
        });

}

export const signUpUser = (postData) => {
    return axios.post(`/api/users/signup`, postData)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        throw new Error(err?.response?.data?.message || "Error while sending the OTP. Please try again.")
    });
}