import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import ReactQuill from "react-quill";
import axios from "axios";
import "react-quill/dist/quill.snow.css";

import { PageBanner } from "../content/element/page-banner";
import moment from "moment-timezone";
import { popUp } from "../../utils";
import Select from "react-select";
import Pending from "../content/element/UserStatus/Pending";
import PageTitle from "../content/element/PageTitle";

class MyStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      description: null,
      socialLinks: [{ name: "", link: "", label: "" }],
      openingHours: {
        saturday: { start_time: null, close_time: null, clsoed: false },
        sunday: { start_time: null, close_time: null, clsoed: false },
        monday: { start_time: null, close_time: null, clsoed: false },
        tuesday: { start_time: null, close_time: null, clsoed: false },
        wednesday: { start_time: null, close_time: null, clsoed: false },
        thursday: { start_time: null, close_time: null, clsoed: false },
        friday: { start_time: null, close_time: null, clsoed: false },
      },
      validateTime0: "",
      validateTime1: "",
      validateTime2: "",
      validateTime3: "",
      validateTime4: "",
      validateTime5: "",
      validateTime6: "",
    };
    this.validator = new SimpleReactValidator();
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.openFileDlg = this.openFileDlg.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.addSocialLink = this.addSocialLink.bind(this);
    this.removeSocialLink = this.removeSocialLink.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangeLink = this.handleChangeLink.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.onMarkClosed = this.onMarkClosed.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/store/get-my-store/?id=" + this.props.login._id)
      .then((res) => {
        this.setState({
          ...this.state,
          description: res.data.description,
          socialLinks: res.data.social_links,
          openingHours: res.data.opening_hours,
        });
      })
      .catch((err) => {
        popUp(err.response.data.message);
      });
  }
  uploadAvatar(e) {
    e.preventDefault();
    let file = e.target.files[0];
    let avatar = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    this.setState({ avatar });
  }

  openFileDlg(e) {
    e.preventDefault();
    this.upload.click();
  }

  handleDescriptionChange(val) {
    this.setState({ description: val });
  }

  addSocialLink(e) {
    e.preventDefault();
    let { socialLinks } = this.state;
    socialLinks.push({ name: "", link: "" });
    this.setState({ socialLinks });
  }

  removeSocialLink(e, index) {
    e.preventDefault();
    if (index === 0) return;
    let { socialLinks } = this.state;
    socialLinks.splice(index, 1);
    this.setState({ socialLinks });
  }

  handleSelect(selectedOption, index) {
    let { socialLinks } = this.state;
    socialLinks[index]["name"] = selectedOption.value;
    socialLinks[index]["label"] = selectedOption.label;
    this.setState({ socialLinks });
  }

  handleChangeLink(e, index) {
    let { socialLinks } = this.state;
    socialLinks[index]["link"] = e.target.value;
    this.setState({ socialLinks });
  }

  handleChangeTime(e, week, type) {
    e.preventDefault();
    let { openingHours } = this.state;
    openingHours[week][type] = e.target.value;
    this.setState({ openingHours });
    if (
      openingHours.saturday.close_time - openingHours.saturday.start_time <
      0
    ) {
      this.setState({
        validateTime: "Please make sure time is validate !",
      });
    }

    var start0 = moment(openingHours.saturday.start_time, "HH:mm");
    var end0 = moment(openingHours.saturday.close_time, "HH:mm");

    var start1 = moment(openingHours.sunday.start_time, "HH:mm");
    var end1 = moment(openingHours.sunday.close_time, "HH:mm");

    var start2 = moment(openingHours.monday.start_time, "HH:mm");
    var end2 = moment(openingHours.monday.close_time, "HH:mm");

    var start3 = moment(openingHours.tuesday.start_time, "HH:mm");
    var end3 = moment(openingHours.tuesday.close_time, "HH:mm");

    var start4 = moment(openingHours.wednesday.start_time, "HH:mm");
    var end4 = moment(openingHours.wednesday.close_time, "HH:mm");

    var start5 = moment(openingHours.thursday.start_time, "HH:mm");
    var end5 = moment(openingHours.thursday.close_time, "HH:mm");

    var start6 = moment(openingHours.friday.start_time, "HH:mm");
    var end6 = moment(openingHours.friday.close_time, "HH:mm");

    if (start0 >= end0) {
      this.setState({
        validateTime0: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime0: "",
      });
    }
    if (start1 >= end1) {
      this.setState({
        validateTime1: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime1: "",
      });
    }
    if (start2 >= end2) {
      this.setState({
        validateTime2: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime2: "",
      });
    }
    if (start3 >= end3) {
      this.setState({
        validateTime3: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime3: "",
      });
    }
    if (start4 >= end4) {
      this.setState({
        validateTime4: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime4: "",
      });
    }
    if (start5 >= end5) {
      this.setState({
        validateTime5: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime5: "",
      });
    }
    if (start6 >= end6) {
      this.setState({
        validateTime6: "Invalid Time: Please Enter Valid Time!",
      });
    } else {
      this.setState({
        validateTime6: "",
      });
    }
  }

  onMarkClosed(e, week) {
    {
    }
    let { openingHours } = this.state;
    openingHours[week]["clsoed"] = e.target.checked;
    this.setState({ openingHours });
  }

  updateProfile(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ submitting: true });
      let formData = new FormData();
      formData.append("social_links", JSON.stringify(this.state.socialLinks));
      formData.append("opening_hours", JSON.stringify(this.state.openingHours));
      formData.append("description", this.state.description);
      formData.append("seller", this.props.login._id);

      if (
        this.state.validateTime0 === "" &&
        this.state.validateTime1 === "" &&
        this.state.validateTime2 === "" &&
        this.state.validateTime3 === "" &&
        this.state.validateTime4 === "" &&
        this.state.validateTime5 === "" &&
        this.state.validateTime6 === ""
      ) {
        axios
          .post("/api/store/new", {
            social_links: JSON.stringify(this.state.socialLinks),
            opening_hours: JSON.stringify(this.state.openingHours),
            description: this.state.description,
            seller: this.props.login._id,
          })
          .then((res) => {
            this.setState({ submitting: false });
            popUp("Successfully Updated");
          })
          .catch((err) => {
            this.setState({ submitting: false });
            popUp("Failed to Update");
          });
      } else {
        alert("Please Enter the valid Time !");
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const { socialLinks, submitting, openingHours } = this.state;
    const socialMedia = [
      { label: "Choose social media...", value: "" },
      { label: "Facebook", value: "facebook" },
      { label: "Google+", value: "google-plus" },
      { label: "Instagram", value: "instagram" },
      { label: "LinkedIn", value: "linkedin" },
      { label: "Twitter", value: "twitter" },
      { label: "Youtube", value: "youtube" },
    ];

    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />;
    return (
      <Fragment>
        <PageBanner title="My Store" />
        <section className="section-bg pt-2 pb-5">
          <div className="container ">
            <PageTitle title="My Store" />
            <div className="row mt-2">
              <div className="col-lg-12">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>General Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents">
                    <form action="/">
                      <div className="form-group">
                        <label htmlFor="title" className="form-label">
                          Description
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={this.state.description}
                          onChange={this.handleDescriptionChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Social Information</label>
                        <div id="social_info_sortable_container">
                          <div className="directorist atbdp_social_field_wrapper">
                            {socialLinks.map((social, index) => {
                              return (
                                <div
                                  className="row m-bottom-20"
                                  id={"social-form-fields" + index}
                                  key={`${social.name}${index}`}
                                >
                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <Select
                                        className={`react-select`}
                                        classNamePrefix="react-select"
                                        placeholder="Choose Social Media..."
                                        options={socialMedia?.map((media) => {
                                          return {
                                            label: media.label,
                                            value: media.value,
                                          };
                                        })}
                                        onChange={(selectedOption) =>
                                          this.handleSelect(
                                            selectedOption,
                                            index
                                          )
                                        }
                                        defaultValue={
                                          social.label && social.name
                                            ? {
                                              label: social.label,
                                              value: social.name,
                                            }
                                            : null
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="form-group">
                                      <input
                                        value={social.link}
                                        type="url"
                                        className="form-control directory_field atbdp_social_input"
                                        placeholder="eg. http://example.com"
                                        required=""
                                        onChange={(e) =>
                                          this.handleChangeLink(e, index)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2">
                                    <span
                                      className="removeSocialField btn-danger"
                                      id={"removeSocial" + index}
                                      title="Remove this item"
                                      onClick={(e) =>
                                        this.removeSocialLink(e, index)
                                      }
                                      style={{
                                        backgroundColor: "rgb(243, 146, 0)",
                                      }}
                                    >
                                      <i className="la la-times"></i>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <button
                          className="copy-btn btn btn-sm btn-secondary"
                          onClick={this.addSocialLink}
                        >
                          <i className="la la-plus"></i> Add New
                        </button>
                      </div>

                      {/*<!-- ends: .form-group -->*/}
                    </form>
                  </div>
                  {/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>
                {/*<!-- ends: .atbd_content_module -->*/}
              </div>

              {/*<!-- ends: .col-lg-10 -->*/}
              <div className="col-lg-12" style={{ marginTop: 30 }}>
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-calendar-check-o"></span>{" "}
                        Opening/Business Hour Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents">
                    <div className="business-hour">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_saturday"
                              className="atbd_day_label form-label"
                            >
                              Saturday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_saturday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.saturday.start_time}
                                  type="time"
                                  id="bdbh_saturday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "saturday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Saturday Start Time",
                                    openingHours?.saturday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_saturday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.saturday.close_time}
                                  type="time"
                                  id="bdbh_saturday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "saturday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Saturday Close Time",
                                    openingHours?.saturday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.saturday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                id="sat_cls"
                                onChange={(e) =>
                                  this.onMarkClosed(e, "saturday")
                                }
                              />
                              <label
                                htmlFor="sat_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime0}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_sunday"
                              className="atbd_day_label form-label"
                            >
                              Sunday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_sunday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.sunday.start_time}
                                  type="time"
                                  id="bdbh_sunday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "sunday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Sunday Start Time",
                                    openingHours.sunday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_sunday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.sunday.close_time}
                                  type="time"
                                  id="bdbh_sunday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "sunday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Sunday Close Time",
                                    openingHours.sunday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.sunday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="sun_cls"
                                onChange={(e) => this.onMarkClosed(e, "sunday")}
                              />
                              <label
                                htmlFor="sun_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime1}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_monday"
                              className="atbd_day_label form-label"
                            >
                              Monday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_monday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.monday.start_time}
                                  type="time"
                                  id="bdbh_monday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "monday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Monday Start Time",
                                    openingHours.monday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_monday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.monday.close_time}
                                  type="time"
                                  id="bdbh_monday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "monday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Monday Close Time",
                                    openingHours.monday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.monday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="mon_cls"
                                onChange={(e) => this.onMarkClosed(e, "monday")}
                              />
                              <label
                                htmlFor="mon_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime2}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_tuesday"
                              className="atbd_day_label form-label"
                            >
                              Tuesday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_tuesday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.tuesday.start_time}
                                  type="time"
                                  id="bdbh_tuesday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "tuesday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Tuesday Start Time",
                                    openingHours.tuesday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_tuesday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.tuesday.close_time}
                                  type="time"
                                  id="bdbh_tuesday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "tuesday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Tuesday Close Time",
                                    openingHours.tuesday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.tuesday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="tue_cls"
                                onChange={(e) =>
                                  this.onMarkClosed(e, "tuesday")
                                }
                              />
                              <label
                                htmlFor="tue_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime3}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_wednesday"
                              className="atbd_day_label form-label"
                            >
                              Wednesday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_wednesday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.wednesday.start_time}
                                  type="time"
                                  id="bdbh_wednesday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "wednesday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Wednesday Start Time",
                                    openingHours.wednesday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_wednesday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.wednesday.close_time}
                                  type="time"
                                  id="bdbh_wednesday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "wednesday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Wednesday Close Time",
                                    openingHours.wednesday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.wednesday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="wed_cls"
                                onChange={(e) =>
                                  this.onMarkClosed(e, "wednesday")
                                }
                              />
                              <label
                                htmlFor="wed_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime4}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_thursday"
                              className="atbd_day_label form-label"
                            >
                              Thursday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_thursday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.thursday.start_time}
                                  type="time"
                                  id="bdbh_thursday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "thursday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Thursday Start Time",
                                    openingHours.thursday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_thursday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.thursday.close_time}
                                  type="time"
                                  id="bdbh_thursday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "thursday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Thursday Close Time",
                                    openingHours.thursday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.thursday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="thu_cls"
                                onChange={(e) =>
                                  this.onMarkClosed(e, "thursday")
                                }
                              />
                              <label
                                htmlFor="thu_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime5}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                          <div className="form-group">
                            <label
                              htmlFor="bdbh_friday"
                              className="atbd_day_label form-label"
                            >
                              Friday
                            </label>
                            <div className="row atbd_row_bg">
                              <div className="col-sm-6">
                                <label
                                  htmlFor="bdbh_friday"
                                  className="not_empty"
                                >
                                  Start time
                                </label>
                                <input
                                  value={openingHours?.friday.start_time}
                                  type="time"
                                  id="bdbh_friday"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "friday",
                                      "start_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Friday Start Time",
                                    openingHours.friday.start_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <label
                                  htmlFor="bdbh_friday_cls"
                                  className="not_empty"
                                >
                                  Close time
                                </label>
                                <input
                                  value={openingHours?.friday.close_time}
                                  type="time"
                                  id="bdbh_friday_cls"
                                  className="form-control directory_field"
                                  onChange={(e) =>
                                    this.handleChangeTime(
                                      e,
                                      "friday",
                                      "close_time"
                                    )
                                  }
                                />
                                <div className="text-danger">
                                  {this.validator.message(
                                    "Friday Close Time",
                                    openingHours.friday.close_time,
                                    `required`
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="atbd_mark_as_closed custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                              <input
                                checked={!!openingHours?.friday.clsoed}
                                type="checkbox"
                                className="custom-control-input"
                                name="enable247hour"
                                value="1"
                                id="fri_cls"
                                onChange={(e) => this.onMarkClosed(e, "friday")}
                              />
                              <label
                                htmlFor="fri_cls"
                                className="not_empty custom-control-label"
                              >
                                {" "}
                                Mark as Closed{" "}
                              </label>
                              <div
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                  float: "right",
                                }}
                              >
                                <span>{this.state.validateTime6}</span>
                              </div>
                            </div>
                          </div>
                          {/*<!-- ends: .form-group -->*/}
                        </div>
                        {/*<!--ends col-md-6 col-sm-12-->*/}
                      </div>
                      {/*<!--ends .row-->*/}
                    </div>
                  </div>
                  {/*<!-- ends: .atbdb_content_module_contents -->*/}
                </div>
                {/*<!-- ends: .atbd_content_module -->*/}
              </div>
              {/*<!-- ends: .col-lg-10 -->*/}
              <div className="col-lg-12 text-center">
                <div className="btn_wrap list_submit m-top-25">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg listing_submit_btn"
                    disabled={submitting}
                    onClick={this.updateProfile}
                  >
                    {submitting && (
                      <i className="las la-spinner la-spin mr-2"></i>
                    )}
                    Update Profile
                  </button>
                </div>
              </div>
              {/*<!-- ends: .col-lg-10 -->*/}
            </div>
          </div>
        </section>
        {/*<!-- ends: .add-listing-wrapper -->*/}

      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(MyStore);
