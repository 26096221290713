import React from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ga4Events } from '../../../utils';
import {
    AddLocationOutlinedIcon, ContactSupportOutlinedIcon, DashboardOutlinedIcon,
    FavoriteBorderOutlinedIcon, InsertDriveFileOutlinedIcon, ListAltOutlinedIcon,
    LocalMallOutlinedIcon, LogoutOutlinedIcon, PersonOutlineOutlinedIcon, UploadFileOutlinedIcon
} from '../../common/MaterialIcons';

export const ProfileMenu = ({t}) => {
    const history = useHistory();
    const logOut = (e) => {
        e.preventDefault();
        // this.props.logOutdata(null);
        // this.setState({ showModal: false });
        ga4Events({ action: "profile_menu_logout", category: "User" });
        localStorage.removeItem("user");
        localStorage.removeItem("filterPath");
        localStorage.removeItem("login");
        localStorage.removeItem("access_token");
        localStorage.removeItem("visitors_count");
        // localStorage.clear();

        window.location.href = "/";
    };
    const closeMenu = (route, label) => {
        ga4Events({
            action: "profile_menu_click",
            category: "User",
            menu_item_name: label,
        })
        history.push(route)
    }
    return (
        <ul className="list-unstyled">
            {/* <li className='group-title'>Your Account</li> */}
            <li className="close-menu-modal">
                <NavLink
                    to="/my-dashboard"
                    onClick={() =>
                        closeMenu("/my-dashboard", "Dashboard")
                    }
                >
                    <DashboardOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Dashboard
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/my-profile"
                    onClick={() =>
                        closeMenu("/my-profile", "Profile")
                    }
                >
                    <PersonOutlineOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Profile
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/credits"
                    onClick={() =>
                        closeMenu("/credits", "Your Credits")
                    }
                >
                    {" "}
                    <UploadFileOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Wallet
                </NavLink>
            </li>

            <li>
                <NavLink
                    to="/quotations"
                    onClick={() =>
                        closeMenu("/quotations", "Quotations")
                    }
                >
                    {" "}
                    <InsertDriveFileOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Quotations
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/your-address"
                    onClick={() =>
                        closeMenu("/your-address", "Addresses")
                    }
                >
                    {" "}
                    <AddLocationOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Addresses
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/my-order"
                    onClick={() =>
                        closeMenu("/my-order", "Orders")
                    }
                >
                    {" "}
                    <ListAltOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Orders
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/coming-soon"
                    onClick={() =>
                        closeMenu("/coming-soon", "Orders")
                    }
                >
                    {" "}
                    <LocalMallOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Purchased Items
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink
                    to="/coming-soon"
                    onClick={() =>
                        closeMenu("/coming-soon", "Orders")
                    }
                >
                    {" "}
                    <FavoriteBorderOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    My Wishlist
                </NavLink>
            </li>
            <li className="close-menu-modal">
                <NavLink to="/help"
                    onClick={() =>
                        closeMenu("/help", "Help")
                    }
                >
                    {" "}
                    <ContactSupportOutlinedIcon
                        style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "-1px",
                            cursor: "pointer",
                            color: "#333333",
                            top: 0,
                            marginRight: "5px",
                        }}
                    />
                    Help
                </NavLink>
            </li>
            <li className="log-out">
                <NavLink to="#">
                    <div onClick={logOut}>
                        <LogoutOutlinedIcon
                            style={{
                                height: "18px",
                                width: "18px",
                                marginTop: "-1px",
                                cursor: "pointer",
                                color: "#333333",
                                top: 0,
                                marginRight: "5px",
                            }}
                        />
                        {t("menu_signout")}
                    </div>
                </NavLink>
            </li>
        </ul>
    )
}
