import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { LogOut } from "../../../Store/action/logoutAction";
import { LogInAc } from "../../../Store/action/loginActions";
import ConfirmModalLogout from "../../content/element/modal/logout";
import NotificationModal from "../../content/element/modal/NotificationModal";
import { ga4Events, popUp } from "../../../utils";
import LoginModal from "../../content/Login/LoginModal";
import { Grid, Box } from "@mui/material";
import "react-sticky-header/styles.css";
import "react-tabs/style/react-tabs.css";
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import axios from "axios";
import {
  PersonOutlineOutlinedIcon,
  Search, ShoppingCartOutlinedIcon
} from "../../common/MaterialIcons";
import "./header.css";
import SearchTab from "./SearchTab";
import { ProfileMenu } from "./ProfileMenu";
import { SellerMenu } from "./SellerMenu";


class Header extends Component {
  constructor(props) {
    super(props);
    const { variable = 0 } = this.props;
    this.state = {
      activeCategory: "",
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: false,
      showModal: false,
      passevent: "",
      unreadCount: [],
      anchorlEl: null,
      isModalOpen: false,
      isHovered: false,
      isRegisterModalOpen: false,
      lastBoughtBrands: [],
      lastBoughtProducts: [],
      tabIndex: variable,
      prevScrollpos: window.pageYOffset,
      visible: true,
    };

    this.authorArea = React.createRef();
    this.sidebarRef = React.createRef();
    this.handleCategoryHover = this.handleCategoryHover.bind(this);
    this.handleDisplayOthersCategoryDiv =
      this.handleDisplayOthersCategoryDiv.bind(this);
    this.handleDisplayAllCategoryDiv =
      this.handleDisplayAllCategoryDiv.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.toggleRegister = this.toggleRegister.bind(this);
    this.toggleForLogin = this.toggleForLogin.bind(this);
  }

  handleGaEvent(args) {
    const data = {
      category: "User",
      ...args,
    };
    ga4Events(data);
  }

  componentDidMount() {
    this.setState({
      unreadCount: this.props?.chat?.rooms.filter(
        (room) => room.unreadMsgCnt === 1
      ),
    });
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("scroll", this.handleScroll);
    // Attach the event listener for the 'storage' event
    window.addEventListener("storage", this.handleStorageChange);
    // Attach the event listener for the 'beforeunload' event
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleMouseLeave = (e) => {
    e.preventDefault()
    this.setState({ isHovered: false })
    this?.authorArea?.current?.classList?.toggle('no-active')
  }

  handleMouseEnter = (e) => {
    const currentPathname = this.props.location.pathname
    e.preventDefault()
    this.setState({ isHovered: true })
    this.handleGaEvent({
      action: "login_or_register_pop_up_page_name",
      currentPathname: currentPathname,
    });
    this.authorArea?.current?.classList?.toggle("active");
  };

  handleClick = () => {
    this?.authorArea?.current?.classList?.toggle("active");
  };

  handleCategoryHover(e, category) {
    e.preventDefault();
    this.setState({ activeCategory: category });
  }

  handleReset(e) {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: false,
    });
  }

  handleDisplayOthersCategoryDiv(
    e,
    bool,
    categoryValue = "Transmission Parts"
  ) {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: bool,
      displayAllCategoryDiv: false,
    });
    this.setState({ activeCategory: categoryValue });
  }

  handleDisplayAllCategoryDiv(e, bool, categoryValue = "") {
    e.preventDefault();
    this.setState({
      displayOthersCategoryDiv: false,
      displayAllCategoryDiv: bool,
    });
    this.setState({ activeCategory: categoryValue });
  }

  handleCloseModal = () => {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  };

  handleConfirm = (e) => {
    this.setState({
      showModal: true,
      passevent: e,
    });
  };
  handleCloseNotification = (e) => {
    this.setState({ anchorEl: null });
  };
  handleClickNotification = (event) => {
    if (this.state.anchorlEl == null) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
    this.handleGaEvent({ action: "login_or_register_button_clicked" });
  };

  toggleRegister = (e) => {
    e.preventDefault();
    this.setState({
      isRegisterModalOpen: !this.state.isRegisterModalOpen,
    });
    this.handleGaEvent({ action: "sign_up_btn_click" });
  };

  toggleForLogin = () => {
    this.setState({
      isModalOpen: false,
    });
    this.setState({
      isRegisterModalOpen: false,
    });
  };

  settingtActiveCategory = () => {
    this.setState({ activeCategory: "" });
  };

  handleSearchContainer = () => {
    this.setState({
      openSelectSearchOption: !this.state.openSelectSearchOption,
    });
    this.setState({ searchByParts: false });
    this.setState({ searchByVin: false });
  };

  closeMenu = (route, label) => {
    ga4Events({
      action: "profile_menu_click",
      category: "User",
      menu_item_name: label,
    })
    this.props.history.push(route)
  }

  componentWillMount() {
    if (this.props.login?._id) {
      axios
        .get(`/api/categories/most-viewed-brand/${this.props.login?._id}`)
        .then((res) => {
          this.setState({
            lastBoughtBrands: res.data.data?.map((data) => {
              return Object.entries(data).map(([name, product]) => ({
                name,
                product,
              }));
            }),
          });
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
        });

      axios
        .get(`/api/categories/most-viewed-product/${this.props.login?._id}`)
        .then((res) => {
          this.setState({
            lastBoughtProducts: res.data.data?.map((data) => {
              return Object.entries(data).map(([name, product]) => ({
                name,
                product,
              }));
            }),
          });
        })
        .catch((err) => {
          popUp("There was a problem with server. Please try again.");
        });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.sidebarRef.current &&
      !this.sidebarRef.current.contains(event.target)
    ) {
      const sidebarElement = document.getElementsByClassName(
        "offcanvas-menu__contents"
      )[0];
      if (sidebarElement && !sidebarElement.contains(event.target)) {
        sidebarElement.classList.remove("active");
      }
    }
  };


  componentWillUnmount() {
    // Remove the event listeners when the component is unmounted to avoid memory leaks
    window.removeEventListener("storage", this.handleStorageChange);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleStorageChange = (event) => {
    // Check if the 'localStorage' has been cleared by the network application
    if (event.key === null) {
      // Perform the logout process when the 'localStorage' is cleared
      this.handleLogout();
    }
  };

  handleBeforeUnload = () => {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    if (!currentUser) {
      // localStorage.clear();
    }
  };

  handleLogout() {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    if (!currentUser) {
      window.location.href = "/";
    }
  }
  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;
    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  render() {
    const logOut = (e) => {
      e.preventDefault();
      this.props.logOutdata(null);
      this.setState({ showModal: false });
      ga4Events({ action: "profile_menu_logout", category: "User" });
      localStorage.removeItem("user");
      localStorage.clear();

      window.location.href = "/";
    };

    const { t, quotation } = this.props;

    return (
      <>
        <Grid
          container paddingX={{ md: 7.5, xs: 2 }} paddingY={{ md: 1, xs: 2 }}
          onMouseEnter={(e) => this.handleReset(e)}
        >
          <Grid item md={2} sm={3} xs={6} className="center-content">
            <a href="/" style={{ marginTop: '26px' }}>
              <img src="/assets/img/logo1.svg" alt="logoImage" className="img-logo" />
            </a>
          </Grid>
          <Grid item md={8} sm={9} xs={8} p={2} className="web-container" style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Grid container>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <SearchTab loginToggle={this.toggle} />
              </Grid>
            </Grid>

          </Grid>

          <Grid item md={2} sm={2} className="web-container" style={{ paddingTop: '2%' }}>
            {this.props.login === null && (
              <>
                <div className="user-login">
                  <PersonOutlineOutlinedIcon className="svg_icons" style={{ marginTop: '15px', marginRight: '9px' }} />
                  <div>
                    <span style={{ fontSize: '10px', fontWeight: 400, color: '#333333' }}>Hi, User</span>

                    <a
                      href=" "
                      className="access-link"
                      style={{
                        fontWeight: 600,
                        color: "#F39200",
                        display: "block",
                        fontSize: '12px'
                      }}
                    >
                      <span onClick={this.toggle}>Login / Register </span>
                    </a>

                  </div>
                </div>

              </>

            )}
            <div className="menu-right"
              ref={this.sidebarRef}>
              <div className="search-view">
                <div className=" d-inline-flex">
                  <div style={{ color: "#fff", marginLeft: "15px" }}>
                    <NotificationModal />
                  </div>
                  {/* <div className="search-container">
                        <SearchIcon
                          style={{
                            fontSize: 30,
                            cursor: "pointer",
                            top: 0,
                          }}
                          onClick={this.handleSearchContainer}
                        />
                      </div> */}
                </div>
              </div>


              {this.props.login !== null && (
                <div className="header-user">
                  <div
                    className="dropdown-log"
                    style={{ display: 'flex' }}
                  >
                    <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <img src="/assets/img/user.svg" alt="user" style={{marginTop: '12px'}}/>
                    </div>
                    <div
                      style={{ fontSize: '12px' }}
                      onClick={() =>
                        ga4Events({
                          action: "profile_menu_icon_click",
                          category: "User",
                        })
                      }
                    >
                      <span
                        className="w-100"
                        style={{ display: "inline-block", color: "#333333" }}
                      >
                        <span className="userName">
                          {this.props.login &&
                            this.props.login?.name || 'User'}{" "}
                        </span>
                        {/* <i
                              className="la la-angle-down"
                              style={{ fontSize: "12px" }}
                            ></i> */}
                      </span>
                      <span
                        style={{ display: "inline-block", color: "#F39200", }}
                        className="font-weight-bold"
                      >

                        My Account {" "}<img src="/assets/img/down-arrow.svg" alt="menu" style={{ paddingLeft: "5px" }} />
                      </span>
                      <span style={{ display: "inline-block" }}></span>
                    </div>
                    <div className="dropdown-content ">
                      {
                        this.props.login.role == "BUYER" && <ProfileMenu t={t} /> 
                      }  
                      {
                        this.props.login.role == "SELLER" && <SellerMenu t={t} />
                      }  
                    </div>
                  </div>
                  <div className={"offcanvas-menu-resp"}>
                    {this.props.login && (
                      <div
                        className="notification-view-icons"
                      >
                        <NotificationModal />
                      </div>
                    )}
                    {this.props.login.role == "BUYER" && (
                      <>
                        <div style={{ marginLeft: '10px', marginTop: '7px' }}>
                          {this.props.list.itemsInCart.length === 0 && (
                            <img src="/assets/img/Cart.svg" alt="Cart" />
                          )}

                          {((this.props.list.itemsInCart &&
                            this.props.list.itemsInCart.length > 0) ||
                            this.props.list.bulkCartListCount !== 0) && (
                              <NavLink
                                to="/checkout"
                                onClick={() =>
                                  ga4Events({
                                    action: "cart_icon_click",
                                    category: "Cart",
                                  })
                                }
                              >
                                <img src="/assets/img/Cart.svg" alt="Cart" />{" "}
                                <span className="cart-count">  {this.props.list.itemsInCart.length ||
                                  this.props.list.bulkCartListCount}</span>
                              </NavLink>
                            )}
                        </div>
                        {quotation?.quotationItems?.length > 0 && (
                          <NavLink
                            to="/quotations"
                            className="offcanvas-menu__cart"
                            onClick={() =>
                              ga4Events({
                                action: "cart_icon_click",
                                category: "Cart",
                              })
                            }
                          >
                            {/* <img
                                    src='/assets/img/paper.png'
                                    alt='Cart'
                                    style={{color: "white"}}
                                  />{' '} */}
                            {/* <span
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  right: "-29px",
                                  position: "absolute",
                                  marginTop: "2px",
                                }}
                              >
                                <AddToQueueIcon style={{color: "#333333"}} />
                              </span> 
                              <span className="queue-count">  
                              {quotation?.quotationItems?.length > 0 &&
                                quotation?.quotationItems?.length}
                                </span>*/}
                          </NavLink>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item sm={9} xs={6} className="mobile-container">
            <div className="iconView">
              <Search className="svg_icons" onClick={() => this.props.handleFromSearch(true)} />
              {/* {
                this.props.login != null && (
                  <div className="dropdown-log tab-container" style={{ marginTop: '1px' }}>
                    <PersonOutlineOutlinedIcon className="svg_icons" />
                    <div className="dropdown-content ">
                      <ProfileMenu t={t} />
                    </div>
                  </div>)
              } */}
              {this.props.login === null && (
                <PersonOutlineOutlinedIcon className="svg_icons" onClick={this.toggle} />
              )}
              {this.props.login != null && (
                <NotificationModal />
              )}
              {this.props.login != null && (
                <NavLink
                  to="/checkout"
                  onClick={() =>
                    ga4Events({
                      action: "cart_icon_click",
                      category: "Cart",
                    })
                  }
                  style={{ color: '#333333' }}
                >
                  <ShoppingCartOutlinedIcon className="svg_icons" />
                </NavLink>
              )}
              {this.props.list.itemsInCart.length || this.props.list.bulkCartListCount ?
                <span className="cart-count" style={{ position: 'absolute', right: '16px' }}>
                  {this.props.list.itemsInCart.length ||
                    this.props.list.bulkCartListCount}
                </span> : <></>
              }
            </div>
          </Grid>
        </Grid>
        <ConfirmModalLogout
          showModal={this.state.showModal}
          onClose={this.handleCloseModal}
          eventFunc={logOut}
          e={this.state.passevent}
        />
        <LoginModal
          isModalOpen={this.state.isModalOpen}
          toggle={this.toggle}
          loginToggle={this.toggleForLogin}
        />
      </>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    chat: state.chat,
    list: state.list,
    cart: state.cart,
    quotation: state.quotation,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    logOutdata: (login) => dispatch(LogOut(login)),
    loginAction: (login) => dispatch(LogInAc(login)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(withRouter(Header));
