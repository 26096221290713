import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MobileSearch.css";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider, Grid, makeStyles } from "@material-ui/core";
import { Button, ButtonGroup } from "reactstrap";
import {
  GridViewIcon, ArrowForwardIosIcon, ListItemIcon, Search, Person2Icon, ShoppingCartOutlinedIcon,
  PersonOutlineOutlinedIcon,
  NoteAddOutlinedIcon,
  InsertDriveFileOutlinedIcon,
  FmdGoodOutlinedIcon,
  ListAltOutlinedIcon,
  ShoppingBagOutlinedIcon,
  FavoriteBorderOutlinedIcon,
  EmailOutlinedIcon,
  LockOutlinedIcon,
  TextSnippetOutlinedIcon,
  LogoutOutlinedIcon
} from "../../common/MaterialIcons";
import { ga4Events } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../../../Store/action/logoutAction";
import SearchTab from "../header/SearchTab";

const useStyles = makeStyles((theme) => ({
  headerBanner: {
    backgroundColor: '#F0F0F0',
    height: '32px',
    textAlign: 'center'
  },
  headerSubBanner: {
    backgroundColor: '#F4F4F4',
    height: '32px',
    textAlign: 'center'
  },
  bannerText: {
    paddingTop: 6,
    color: '#1D71B8',
    fontSize: '12px',
    fontWeight: 700
  },
  bannerAnchor: {
    fontWeight: 800,
    textDecoration: 'underline !important',
    paddingLeft: 10
  }
}));
const MobileSearch = ({ showMobileSearch, handleBackFromSearch }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <div className="mobile-menu-container">
      {showMobileSearch && (
        <>
          <div className="mobile-menu" style={{ marginBottom: 105 }}>
            <Grid container>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <SearchTab showMobileSearch={showMobileSearch} 
                handleBackFromSearch={handleBackFromSearch}/>
              </Grid>
            </Grid>
          </div>
        </>

      )}
    </div>
  );
};

export default MobileSearch;
