import React, { useEffect, useState } from 'react'
import Header from './index';
import PreHeader from '../pre-header';
import { AppBar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'sticky',
        top: 0
    },
    showBanner: {
        display: 'block'
    },
    hideBanner: {
        display: 'none'
    },
}))

const HeaderWrapper = ({ currentPath, handleFromSearch }) => {
    const classes = useStyles();
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
            setShow(false);
        } else { // if scroll up show the navbar
            setShow(true);
        }

        // remember current page location to use in the next move
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);
    return (
        <AppBar component="nav" style={{ backgroundColor: '#fff', boxShadow: 'none', zIndex: 99 }}>
            <div className={`${show ? classes.showBanner : classes.hideBanner}`}>
                <PreHeader />
            </div>
            {!['/payment', '/3ds-payment'].includes(currentPath) && <Header
                handleFromSearch={handleFromSearch} />}
        </AppBar>
    )
}

export default HeaderWrapper;