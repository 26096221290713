import React from "react";
import { Row, Col, Button } from "reactstrap";

const Index = ({
  data,
  getShippingAddress,
  selectedAddress,
  setIsOpenEditModal,
  setEditedAddressId,
}) => {

  const handleEdit = (id) => {
    setIsOpenEditModal(true);
    setEditedAddressId(id);
  };

  return (
    <>
      <Col
        sm={12}
        md={6}
        lg={6}
        style={{
          position: "relative",
          cursor: "pointer",
        }}
        className={`card-address-main`}
        onClick={() => getShippingAddress(data)}
      >
        <Row
          style={{
            margin: "2px",
            marginBottom: "0px",
            paddingTop: "10px",
            backgroundColor: "#f6f7fc",
            borderRadius: "5px",
            position: "relative",
            minHeight: "120px",
          }}
          className={`${selectedAddress?._id === data?._id && "selected-address-card"
            }`}
        >
          <>
            <Col sm="2">
              <img
                src="/assets/img/avatar.png"
                alt="avatar"
                style={{ width: "60px" }}
              />
            </Col>

            <Col sm="10">
              <h6 style={{ marginLeft: "30px", marginTop: "0px" }}>
                {data?.first_name} {data?.last_name}
              </h6>
              <p style={{ marginLeft: "30px" }}>
                {data?.postcode} {data?.suburb} {data?.address} {data?.state}{data?.ship_to_site_code}
                <br /> {data?.contact}
              </p>
            </Col>
          </>

          <hr className="address-hr" />
          <div className="user-details-footer" style={{ marginLeft: "auto" }}>
            <Button
              size="sm"
              color="#FFF"
              onClick={() => {
                handleEdit(data?._id);
              }}
              className="btn-address"
            >
              Edit
            </Button>
          </div>

          <div
            color="primary"
            className={data.default_address ? "address-true" : "d-none"}
          >
            <p style={{ color: "#fff" }}>Default</p>
          </div>
        </Row>
      </Col>
    </>
  );
};

export default Index;
