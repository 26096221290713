import React, { useState } from 'react'
import { getDiscountAmount, ProductImage } from '../../../../utils'
import ShowDynamicCurrency from '../../../common/ShowDynamicCurrency'
import ShowDynamicPrice from '../../../common/ShowDynamicPrice'
import QtyInput from '../QtyInput'

const dataPerRow = 8

const ListCard = ({
  data,
  list,
  getCartLength,
  qtyIncrement,
  qtyDecrement,
  handleConfirm,
  coupon,
  discountPerUnit,
  changeCartQty,
}) => {
  const [next, setNext] = useState(dataPerRow)

  const handleMoreData = () => {
    setNext(next + dataPerRow)
  }
  const aliasName = data?.data?.[0]?.user?.aliasName || ""

  return (
    <>
      <>
        <thead>
          <tr>
            <th colSpan={6}>
              {aliasName}
            </th>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{ backgroundColor: '#f7f7f7', width: '60%' }}
            >
              Name
            </td>
            <td style={{ backgroundColor: '#f7f7f7', width: '15%' }}>Qty</td>
            <td style={{ backgroundColor: '#f7f7f7', width: '15%' }}>
              Price(Excl. Tax)
            </td>
            <td style={{ backgroundColor: '#f7f7f7', width: '15%' }}>
              Discount
            </td>
            <td style={{ backgroundColor: '#f7f7f7', width: '10%' }}>Delete</td>
          </tr>
        </thead>
        {data?.data?.slice(0, next)?.map((userListings, i) => {
          return (
            <tbody className='bg-white' key={i}>
              {userListings?.listings?.length > 0 &&
                userListings?.listings.map((listing, index) => {
                  if (getCartLength(list.itemsInCart, listing) === 0) {
                    return false
                  }
                  const cartItemPrice = listing.priceExclVat
                  const originalItemPrice = Number(listing.originalPrice?.toFixed(2))

                  let url = listing.pic || listing.imageArray?.[0]
                  return (
                    <tr
                      className={`${listing?.quantity <
                          getCartLength(list.itemsInCart, listing)
                          ? 'bg-warning-light'
                          : ''
                        }`}
                      key={index}
                    >
                      <td
                        width='75'
                        style={{
                          position: 'relative',
                        }}
                      >
                        <ProductImage
                          avatar={url}
                          className='item-image'
                          alt={listing.partName}
                          style={{
                            height: '50px',
                            width: '50px',
                            objectFit: 'contain',
                          }}
                        />
                      </td>
                      <td>
                        <h4>{listing.partName}</h4>
                        <p className='text-muted' style={{ marginBottom: 0 }}>
                          <strong>{listing.partSKU}</strong>
                          <br />
                          Avail.Qty({listing?.quantity})
                        </p>
                      </td>
                      <td>
                        <div className='d-flex'>
                          <button
                            disabled={
                              getCartLength(list.itemsInCart, listing) < 2 ||
                              listing?.orderQty - 1 > listing?.quantity
                            }
                            className='btn checkout-qty'
                            onClick={(e) => qtyDecrement(e, listing._id)}
                            style={{
                              marginRight: '12px',
                            }}
                          >
                            <img src='/assets/img/left-arrow.png' />
                          </button>
                          <QtyInput
                            listing={listing}
                            changeCartQty={changeCartQty}
                            value={getCartLength(list.itemsInCart, listing)}
                          />
                          <button
                            disabled={
                              getCartLength(list.itemsInCart, listing) >
                              listing?.quantity - 1
                            }
                            className='btn checkout-qty'
                            onClick={(e) => qtyIncrement(e, listing._id)}
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            <img src='/assets/img/right-arrow.png' />
                          </button>
                        </div>
                      </td>
                      <td>
                        <span className='country-currency'>
                          {' '}
                          <ShowDynamicCurrency />
                        </span>
                        {''}
                        <span
                          style={{
                            color: 'black',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          <ShowDynamicPrice
                            price={cartItemPrice}
                            isHighlighted={
                              originalItemPrice !== cartItemPrice
                            }
                          />
                        </span>
                        {originalItemPrice !== Number(listing?.priceExclVat?.toFixed(2)) ? (
                          <div
                            style={{ textDecoration: 'line-through' }}
                            className='text-dark'
                          >
                            AED{' '}
                            {originalItemPrice
                              ?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <span className='country-currency'>
                          {' '}
                          <ShowDynamicCurrency />
                        </span>
                        {''}
                        <span
                          style={{
                            color: 'black',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat',
                          }}
                        >
                          <ShowDynamicPrice
                            price={getDiscountAmount(
                              cartItemPrice,
                              getCartLength(list.itemsInCart, listing),
                              listing,
                              discountPerUnit,
                              coupon
                            )}
                          />
                        </span>
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <a
                          href='#!'
                          onClick={(e) =>
                            handleConfirm(e, listing._id, listing)
                          }
                        >
                          <i className='las la-times mr-1'></i>
                        </a>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          )
        })}
      </>

      <tbody className='bg-white'>
        {next < data?.data?.length && (
          <tr>
            <td style={{ width: '100%' }} align='center' colSpan={6}>
              <button
                className='btn btn-primary text-center mt-4'
                onClick={handleMoreData}
              >
                Load more
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </>
  )
}

export default ListCard
