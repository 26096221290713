const initState = {
   isHomepageSectionLoading: false,
   homepageSections: []
}

const CollectionReducer = (state = initState, action) => {
    switch (action.type) {
        case 'GET_HOMEPAGE_SECTION_BEGIN':
            return {
                ...state,
                isHomepageSectionLoading: true,
                homepageSections: []
            }
        case 'GET_HOMEPAGE_SECTION_SUCCESS':
            return {
                ...state,
                homepageSections: action?.data,
                isHomepageSectionLoading: false,
            }
        case 'GET_HOMEPAGE_SECTION_ERROR':
            return {
                ...state,
                isHomepageSectionLoading: false,
                homepageSections: []
            }
        default:
            return state;
    }
};
export default CollectionReducer;
