import React from "react";
import { useSelector } from "react-redux";

import { PageBanner } from "../content/element/page-banner";
import PageTitle from "../content/element/PageTitle";
import BuyerProfile from "../content/element/my-profile/UserProfile/BuyerProfile";
import SellerProfile from "../content/element/my-profile/UserProfile/SellerProfile";
import Pending from "../content/element/UserStatus/Pending";
import UpdatePhone from "../content/element/my-profile/UpdatePhone";
import PolicyAggremementModalOnProfile from "../common/PolicyAggremementOnProfile";
import UpdatePassword from "../content/element/my-profile/UpdatePassword";

const AuthProfile = () => {
  const { login } = useSelector(state => {
    return {
      login: state.login
    }
  })

  return (
    <>
      {
        login && login?.status === "Approved" && login?.KYCStatus && login?.details?.emirate === null ?
          <Pending /> :
          <>
            <PageBanner title="My Profile" />
            <section className="author-info-area section-padding-strict section-bg pb-5 pt-2">
              <div className="container">
                <PageTitle title="My Profile" />

                {
                  login?.role === "BUYER" ? (
                    <BuyerProfile />
                  ) : login?.role === "SELLER" ? (
                    <SellerProfile />
                  ) : null
                }

                <div className="row">
                  {/* Password Update */}
                  <div className="col-lg-3 col-md-4 mb-5 mb-lg-0 ">
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="atbd_content_module mt-3">
                      <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                          <h4>
                            <span className="la la-lock"></span>
                            Change Password
                          </h4>
                        </div>
                      </div>
                      <div className="atbdb_content_module_contents">
                        <div className="user_info_wrap">
                          <UpdatePassword />
                        </div>
                      </div>
                    </div>

                    {/* Phone Update */}
                    <div className="atbd_content_module mt-3">
                      <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                          <h4>
                            <span className="la la-phone"></span>
                            Change Phone
                          </h4>
                        </div>
                      </div>
                      <div className="atbdb_content_module_contents">
                        <div className="user_info_wrap">
                          <UpdatePhone />
                        </div>
                      </div>
                    </div>

                    {/* opt */}
                    <div className="atbd_content_module mt-3">
                      <div className="atbd_content_module__tittle_area">
                        <div className="atbd_area_title">
                          <h4>
                            <span className="la la-newspaper"></span>
                            OPT
                          </h4>
                        </div>
                      </div>
                      <div className="atbdb_content_module_contents">
                        <div className="user_info_wrap">
                          <PolicyAggremementModalOnProfile />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
      }
    </>
  )
}

export default AuthProfile;
