import axios from "axios";

// ASSETS UPLOAD FILE
export const phoneNoValidator = async ({ contact = "" }) => {
    return await axios({
        method: "GET",
        url: `https://apilayer.net/api/validate?access_key=${process.env.REACT_APP_NUM_VERIFY_API_KEY}&number=${contact?.trim()}`
    })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            const errorMessage = error?.response?.data?.message
            throw new Error(errorMessage);
        });
}
