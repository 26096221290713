import moment from "moment"

const initState = {
  homeListing: [],
  listing: [],
  tradesoftSKUs: [],
  isInitalRender: false,
  sameNameItemList: [],
  totalCount: 0,
  maxPrice: 0,
  minPrice: 0,
  makeList: [],
  modelList: [],
  brandList: [],
  bannersList: [],
  categoryList: [],
  subCategoryList: [],
  isLoading: true,
  hasError: false,
  filterQuery: {},
  itemsInCart: [],
  searchQuery: {
    string: '',
    priceRange: null,
    category: null,
    makes: null,
    models: null,
    year: null,
    brand: null,
    sortBy: {},
  },
  count: localStorage.getItem('visitors_count') * 1 || 0,
  listing_count_pagination: 1,
  sparePartSearchDetails: {},
  loading_count: false,
  tradesoftSKUs: [],
  pathname: '',
  isBulkListLoading: false,
  isBulkUpload: false,
  bulkCartList: [],
  bulkCartListCount: 0,
  bulkUploadedList: [],
  unAvailableProducts: [],
  isVinHistoryLoading: false,
  vinSearchHistory: [],
  businessModelList: [],
  emirateLists: [],
  businessModelData: [],
  emirateData: [],
  categoryList: [],
  subCategoriesList: [],
  makeListData: [],
  brandListData: [],
  page: 'listing',
  collection: null,
  isAfterloading: false,
  isAddToCartLoading: false
}

function searchSort(list = [], sortKey = '', sort) {
  let sortingType = ''
  return (list
    .reduce((acc = [], current, index, array) => {
      if (current.partSKU === sortKey) {
        return [current, ...acc]
      }

      return [...acc, current]
    }, [])
    .sort((a, b) => {
      if (sort && sort !== "recommended") {
        if (sort.price == 1) {
          return a.priceExclVat - b.priceExclVat
        } else if (sort.price == -1) {
          return b.priceExclVat - a.priceExclVat
        } else if (sort.date === 1) {
          return moment(a.date).diff(moment(b.date));
        } else if (sort.date === -1) {
          return moment(b.date).diff(moment(a.date));
        }
      } 
      // else {
      
      //   let sk = a?.partSKU === sortKey
      //   let bk = b?.partSKU === sortKey
      //   if (sk) {
      //     sortingType = a.type
      //   }
      //   if (bk) {
      //     sortingType = b.type
      //   }
      //   if (sortingType === 'Aftermarket') {
      //     if (a.type === 'Aftermarket') return 1
      //     else if (b.type === 'Genuine') return -1
      //     else return 0
      //   } else if (sortingType === 'Genuine') {
      //     if (a.type === 'Genuine') return 1
      //     else if (b.type === 'Aftermarket') return -1
      //     else return 0
      //   } else {
      //     if (a.type === 'Genuine') return -1
      //     else if (b.type === 'Aftermarket') return 1
      //     else return 0
      //   }
      // }
    }))?.sort((a, b) => {
      return b.qGt0 - a.qGt0
    })
}
const listingReducer = (state = initState, action) => {
  let sortingType = ''
  switch (action.type) {
    case 'IMAGE_SEARCH_SKUS':
      return {
        ...state,
        // isLoading: false,
        hasError: false,
        listing: action.listings,
      }
    case 'SET_FILTER': {
      return {
        ...state,
        filterQuery: action.filterQuery,
      }
    }
    case 'SET_PATHNAME': {
      return {
        ...state,
        pathname: action.pathname,
      }
    }
    case 'INITIALIZE_SUCCESS':
      return {
        ...state,
        searchQuery: { ...initState.searchQuery, ...action.searchQuery },
        listing: searchSort(action.listings, action.filterQuery.search, action.filterQuery.sortBy),
        filterQuery: action.filterQuery,
        totalCount: action.totalCount || 0,
        isLoading: false,
        hasError: action.listings > 1 ? false : true,
      }
    case 'GET_FILTER':
      return {
        ...state,
        maxPrice: action.maxPrice,
        minPrice: action.minPrice,
        makeList: action.makeList,
        modelList: action.modelList,
        brandList: action.brandList,
        categoryList: action.categoryList,
        subCategoriesList: action.subCategoriesList,
        businessModelList: action.businessModelList,
        emirateLists: action.emirateLists,
      }
    case 'GET_FILTER_AFTERMARKET':
      return {
        ...state,
        makeList: [...new Set([...state.makeList, ...action.makeList])],
        modelList: [...new Set([...state.modelList, ...action.modelList])],
        brandList: [...new Set([...state.brandList, ...action.brandList])],
      }
    case 'GET_UPDATED_FILTER':
      return {
        ...state,
        maxPrice: action.maxPrice,
        minPrice: action.minPrice,
        makeListData: action.makeListData,
        brandListData: action.brandListData,
        businessModelData: action.businessModelData,
        emirateData: action.emirateData,
      }
    case 'INITIALIZE_FAILED':
      return {
        ...initState,
      }
    case 'GET_BANNER_LIST':
      return {
        ...state,
        bannersList: action.data,
        // isLoading: false,
      }
    case 'SET_TRADESOFT_SKUS':
      return {
        ...state,
        tradesoftSKUs: action.data,
      }
    case 'GET_HOME_LISTING_SUCCESS':
      return {
        ...state,
        homeListing: action.listing,
        isLoading: false,
      }
    case 'GET_HOME_LISTING_FAILED':
      return {
        ...state,
        homeListing: [],
      }
    case 'GET_MY_LISTING_BEGIN':
      return {
        ...state,
        listing: [],
        listing_count_pagination: 0,
        isLoading: true,
      }
    case 'GET_MY_LISTING_SUCCESS':
      return {
        ...state,
        listing: action.listings?.listings,
        listing_count_pagination: action.listings?.totalCount,
        isLoading: false,
      }
    case 'GET_MY_LISTING_FAILED':
      return {
        ...initState,
      }
    case 'SET_ITEMS_IN_CART':
      return {
        ...state,
        itemsInCart: action.data,
      }
    case 'SET_IS_ADD_TO_CART_LOADING':
      return {
        ...state,
        isAddToCartLoading: action.data
      }
    case 'ADD_TO_CART':
      const { itemsInCart } = state
      itemsInCart.push(action.data)
      localStorage.itemsInCart = JSON.stringify(itemsInCart)
      return {
        ...state,
        itemsInCart,
      }
    case 'UPDATE_CART':
      localStorage.itemsInCart = JSON.stringify(action.data)
      return {
        ...state,
        itemsInCart: action.data,
      }
    case 'CLEAR_CART':
      return {
        ...state,
        itemsInCart: [],
      }
    case 'SET_VISIBILITY_SUCCESS':
      const { listing_id, visibility } = action
      return {
        ...state,
        listing: state.listing.map((item) => {
          if (item?._id === listing_id) {
            return { ...item, hide: visibility }
          } else {
            return item
          }
        }),
      }

    case 'GET_MY_FAVORITES_SUCCESS':
      return {
        ...state,
        favoriteListing: action.listings,
        isLoading: false,
      }
    case 'GET_MY_FAVORITES_FAILED':
      return {
        ...state,
        favoriteListing: [],
        isLoading: false,
      }
    case 'GET_ALL_LISTING_SUCCESS':
      return {
        ...state,
        listing: action.listing,
        isLoading: false,
      }
    case 'FILTER_LISTING_SUCCESS':
      return {
        ...state,
        listing: searchSort(action.listings, action.searchKey, action.sortBy),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case 'COLLECTION_LISTING_SUCCESS':
      return {
        ...state,
        listing: action.listings,
        totalCount: action.totalCount,
        collection: action?.collection,
        isLoading: false,
      }
    case 'FILTER_LISTING_FAILED':
      return {
        ...state,
        listing: [],
        isLoading: false,
      }
    case 'SET_SIMILAR_LISTING':
      return {
        ...state,
        similarListing: action.data,
      }
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.data,
      }
    case 'SET_LISTINGS':
      return {
        ...state,
        listing: action.data?.listings,
        maxPrice: action.data?.maxPrice,
        minPrice: action.data?.minPrice,
        makeList: action.data?.makeList,
        modelList: action.data?.modelList,
        brandList: action.data?.brandList,
        categoryList: action.data?.categoryList,
        subCategoryList: action.data?.subCategoryList,
        isLoading: false,
      }
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.data,
        hasError: false,
        page: action?.page ?? 'listing',
      }
    case 'SET_LOADING_AFTER_MARKET':
      return {
        ...state,
        isAfterloading: action?.data,
        ...(action?.listings
          ? {
            listing: searchSort(
              [
                ...state.listing,
                ...action.listings.filter(
                  (i) => !state.listing.find(({ _id }) => i._id == _id)
                ),
              ],
              action.searchKey,
              action.sortBy
            ),
          }
          : {}),
      }
    case 'SAME_NAME_ITEM_LIST':
      return {
        ...state,
        sameNameItemList: action.data,
      }
    case 'BULK_PRODUCT_DELETE':
      return {
        ...state,
        isLoading: true,
      }
    case 'INCREASE_VISITORS_VISIT_COUNT':
      localStorage.setItem('visitors_count', action.count)
      return {
        ...state,
        count: action.count,
      }
    case 'SET_SPAREPART_SEARCHDETAILS':
      return {
        ...state,
        sparePartSearchDetails: action.data,
      }
    case 'LOADING_COUNT':
      return {
        ...state,
        loading_count: true,
      }
    case 'GET_MY_LISTING_COUNT_SUCCESS':
      return {
        ...state,
        loading_count: false,
        listing_count_pagination: action.count,
        isInitalRender: action.isInitalRender,
      }
    case 'GET_MY_LISTING_COUNT_FAILED':
      return {
        ...state,
        loading_count: false,
        listing_count_pagination: 1,
      }
    case 'SET_BULK_UPLOAD_LIST':
      return {
        ...state,
        isBulkUpload: true,
        bulkUploadedList: action.data,
      }
    case 'GET_BULK_CART_LIST_BEGIN':
      return {
        ...state,
        isBulkListLoading: true,
        bulkCartListCount: 0,
      }
    case 'GET_BULK_CART_LIST_SUCCESS':
      return {
        ...state,
        bulkCartList: action.data?.products,
        unAvailableProducts: action.data?.unAvailableProducts,
        isBulkListLoading: false,
      }
    case 'GET_BULK_CART_LIST_ERROR':
      return {
        ...state,
        isBulkListLoading: false,
      }
    case 'SET_BULK_CART_LIST_COUNT':
      return {
        ...state,
        bulkCartListCount: action.data,
      }
    case 'UPDATE_BULK_CART':
      return {
        ...state,
        bulkCartList: action.data,
      }
    case 'CLEAR_BULK_CART':
      return {
        ...state,
        bulkCartList: [],
        bulkCartListCount: 0,
        bulkUploadedList: [],
        unAvailableProducts: [],
        isBulkUpload: false,
      }
    case 'GET_VIN_HISTORY_BEGIN':
      return {
        ...state,
        isVinHistoryLoading: true,
      }
    case 'GET_VIN_HISTORY_SUCCESS':
      return {
        ...state,
        isVinHistoryLoading: false,
        vinSearchHistory: action.data,
      }
    case 'GET_VIN_HISTORY_ERROR':
      return {
        ...state,
        isVinHistoryLoading: false,
      }
    default:
      return state
  }
}
export default listingReducer
export { searchSort }
