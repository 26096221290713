import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import axios from 'axios'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { ga4Events, popUp } from "../../../../utils";
import { uploadFileToAssets } from "../../../../services/upload";

export default function SearchImage({ showModal, onClose }) {
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false); 
  const history = useHistory();

  const currentUser = JSON.parse(localStorage.getItem("login"));

  const onChange = e => {
    setImage(e.target.files[0])
  }
 const searchByImage = async() => {
    setIsLoading(true)
    uploadFileToAssets({
      file: image,
      type: "uploads"
    })
     .then((res) => {
       axios.get(`/api/listings/recognize-details?file_id=${res?._id}`).then(resp => {
        let vin = resp.data?.details?.vin
        if (vin) {
          ga4Events({
            action: 'search_parts_by_vin_image', 
            category: 'Search', 
            image_source: `${res?.serverUrl}${res?.relativePath}`,
            result_vin_number: vin
          })
          onClose();
          history.push(`/parts-search-vin?vin=${vin}`)
        } else {
          setIsLoading(true)
          popUp("Could not get any vin")
        }
       }).catch(err => {
          setIsLoading(true);
          popUp(err?.response?.data?.message || "Could not get any vin")
       })      
     })
     .catch((error) => {   
       setIsLoading(true)   
       popUp(error?.message || "Could not get any vin")
       console.error(error);
     });
 }

  return (
    <div>
      <Dialog
        open={showModal}
        hideBackdrop={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Search VIN By Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Upload Image
            <input
              type="file"
              name="vin_by_image"
              onChange={onChange}
            />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ marginRight: 'auto', marginLeft: 10 }} onClick={onClose} color="primary">
            Cancel
          </Button>

          <div onClick={searchByImage} className="go-to-register">
            {isLoading && (
              <i className="las la-spinner la-spin mr-2"></i>
            )}
            Search
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
