const initState = {
  noOfUnseen: 0,
  notifications: [],
}

const NotificationReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ADD_UNSEEN_NOTIFICATION_COUNT':
      return {
        ...state,
        noOfUnseen: action.data,
      }
    case 'GET_NOTIFICATION':
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
}
export default NotificationReducer
