import React, { useEffect, useState, useRef } from 'react'
import ConfirmModal from '../../content/element/modal/confirmation'
import CouponConfirmModal from '../../content/element/modal/coupon-confirmation'
import { PageBanner } from '../../content/element/page-banner'
import AddForm from '../../content/element/Address/AddAddress'
import EditForm from '../../content/element/Address/EditAddress'
import { useDispatch, useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'

import {
  getCartLength,
  popUp,
  changeToGroup,
  getTaxAmount,
  ga4Events,
  calcInclVatFromExcl,
  getDiscountAmount,
} from '../../../utils'
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import {
  getBulkCartListing,
  updateBulkCartListing,
  UpdateCart as updateCart,
} from '../../../Store/action/listingActions'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import InfoIcon from '@material-ui/icons/Info'
import Steps from './Steps'
import StepButton from './StepButton'
import StepDetailText from './StepDetailText'
import PaymentModal from './PaymentModal'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import StepReview from './StepReview'
import useStyles from './Styles'
import WalletPaymentModal from './WalletPayment'
import CheckoutEmpty from './CheckoutEmpty'
import BulkOrderStep1 from './BulkUpload/Step1'
import { RemoveShoppingCart, CloseOutlined } from '@material-ui/icons'
import myListing from '../my-listing'
import { parse, stringify } from 'query-string'
import ShowMessageModal from '../../common/ShowMessageModal'
import { feeAddress, fetchAllAddress } from '../../../Store/action/addressAction'
import './onlinepayment.css'
import payment from '../stepper/payment'
import { event } from 'react-ga'
//import CustomerId from '../../common/CustomerId'

export function formatPhoneNumber(phone) {
  if (phone)
    return [
      phone.slice(0, 4),
      ' ',
      phone.slice(4, 6),
      ' ',
      phone.slice(6, 9),
      ' ',
      phone.slice(9, phone.length),
    ].join('')
}

let REACT_APP_CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;
let CustomerId = REACT_APP_CUSTOMER_ID.split(",");
CustomerId = CustomerId.map(x => +x);

export default (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  // reducers
  const list = useSelector((state) => state.list)

  const loginDetails = localStorage.getItem('login')
  const user = JSON.parse(loginDetails)
  const login = useSelector((state) => state.login)
  const currency = useSelector((state) => state.currency)

  const { allCategories } = useSelector((state) => state.category)

  const { userAddress, addressFee } = useSelector((state) => {
    return {
      userAddress: state.address.addresses,
      addressFee: state.address.addressFee,
    }
  })

  // confirm Modal
  const [showModal, setShowModal] = useState(false)
  const [iframPreview, setIframeModal] = useState(null)
  const [couponShowModal, setCouponShowModal] = useState(false)
  const [walletShowModal, setWalletShowModal] = useState(false)
  const [wallet, setWallet] = useState({})
  const [walletAmount, setWalletAmount] = useState(0)
  const [partialPaymentType, setPartialPaymentType] = useState('')

  const [passId, setPassId] = useState('')
  const [passevent, setPassevent] = useState('')
  const [passName, setPassName] = useState('')

  // step
  const [step, setStep] = useState(1)
  const [deliveryAddress, setdeliveryAddress] = useState(null)
  const [discountPerUnit, setDiscountPerUnit] = useState(0)
  const [discount, setDiscount] = useState(0)

  // amount
  const [tempsubTotal, setTempsubTotal] = useState(0)
  let totalAmount = 0
  const [price, setPrice] = useState(0)
  const [couponCode, setCouponCode] = useState('')
  const [coupon, setCoupon] = useState({})

  // listings
  const [listings, setListings] = useState([])
  const [orderNote, setOrderNote] = useState('')
  const [delivery_option, setDelivery_option] = useState([])

  const [addresses, setAddresses] = useState([])
  const [defaultAddress, setDefaultAddress] = useState({})
  const [selectedAddress, setSelectedAddress] = useState({})
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)

  // payment
  const [payOnCard, setpayOnCard] = useState(false)
  const [payByWallet, setPayByWallet] = useState(false)
  const [selectedDelivery, setSelectedDelivery] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [selectedCreditCard, setSelectedCreditCard] = useState(false)
  const [paymentSessionId, setPaymentSessionId] = useState('')
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  //warehouse
  const [warehouse, setWarehouse] = useState(null)
  const [disabler, setdisabler] = useState(false)

  // funcs
  const flags = useRef(false)
  const [loader, setLoader] = useState(false)

  //edit address
  const [editedAddressId, setEditedAddressId] = useState(null)

  //address data
  const [stateData, setStateData] = useState([])
  const [regionData, setRegionData] = useState([])
  const [locationData, setLocationData] = useState([])

  // Total Tax
  const [totalTax, setTotalTax] = useState(0)
  const [subTotalExclVat, setSubTotalExclVat] = useState(0)

  const [hasSelectedCoordinates, setHasSelectedCoordinates] = useState(true)

  // ==================collecting Card info ====================

  const [cardInfo, setCardInfo] = useState(false)

  //For Default Payment
  const [userDetails, setUserDetails] = useState(user?.defaultPaymentMethod)
  const [totalPayableAmount, setTotalPayableAmount] = useState(0)

  //For express delivery option show
  const [showExpressDelivery, setExpressDelivery] = useState(false)

  useEffect(() => {
    if (!hasSelectedCoordinates) {
      setEditedAddressId(selectedAddress?._id)
      setIsOpenEditModal(true)
    }
  }, [hasSelectedCoordinates])

  useEffect(async () => {
    try {
      let state = await axios.get('/api/products/get-state')
      let location = await axios.get('/api/products/get-regions')
      let city = await axios.get('/api/products/a-city')
      let warehouse = await axios.get('/api/warehouse')
      let wallet = await axios.get('/api/wallet/wallet-user/' + login._id)
      let showExpress = await axios.get('/api/settings')
      setWarehouse(warehouse?.data?.warehouse)
      setStateData(state.data)
      setRegionData(location?.data?.regions)
      setLocationData(city.data)
      setWallet(wallet?.data)
      setExpressDelivery(showExpress?.data?.setting?.enabledExpressDelivery)
    } catch (error) {
      popUp('There was a problem with server. Please try again.')
    }
  }, [])
  useEffect(() => {
    if (!payOnCard || !cardInfo) {
      setCardInfo(null)
    }
  }, [payOnCard, cardInfo])

  useEffect(() => {
    if (list.itemsInCart.length !== 0) {
      getListings()
    }
  }, [list.itemsInCart])

  useEffect(() => {
    if (
      list?.bulkUploadedList.length !== 0 &&
      list?.bulkCartList.length === 0
    ) {
      dispatch(getBulkCartListing())
    }
  }, [list?.bulkUploadedList])

  useEffect(() => {
    if (list?.bulkCartList && list?.bulkCartList.length !== 0) {
      const newListings = []
      let total_price = 0
      let initalDeliveryOptions = []
      let isFlagged = false
      setLoader(false)
      list?.bulkCartList.forEach((item, i) => {
        newListings.push({ user: item.user, listings: [item] })

        total_price += item.priceExclVat * item?.orderQty

        if (item?.orderQty > item?.quantity) {
          isFlagged = true
        }
        flags.current = isFlagged

        return true
      })

      setSubTotalExclVat(total_price) // it's used

      newListings.map((item) => {
        return item.listings.forEach((list) => {
          initalDeliveryOptions.push({
            ...list,
            item_id: list._id,
            delivery_option: 'next_day',
          })
        })
      })
      setPrice(total_price)
      setTempsubTotal(total_price)
      setListings(changeToGroup(newListings))
      setDelivery_option(initalDeliveryOptions)

      dispatch(fetchAllAddress())
    }
  }, [list?.bulkCartList])

  //Setting the default payment method
  useEffect(() => {
    setUserDetails(user?.defaultPaymentMethod) 
  }, []);
const [lpoUsers, setLpoUsers] = useState([]);


  useEffect(() => { 
   // console.log("CustomerId",CustomerId);

    setLpoUsers(CustomerId);     
    if (lpoUsers.includes(login.ecvId)){      
      setPlaceholder("* Add LPO number");
    }
  }, [lpoUsers]);

  //Function that sets the payment method for first time
  const setPaymentMethod = () => {
    if(wallet && wallet?.amount > 0) {
     setPayByWallet(true)
      setSelectedCreditCard(false)
      setSelectedDelivery(false)
      setpayOnCard(false) 
    } else {
      switch(userDetails) {
        case "credit-card": 
          setSelectedCreditCard(true)
          setpayOnCard(true)
          setSelectedDelivery(false)
          setPayByWallet(false)
          break;
        case "cash" : 
          setpayOnCard(false)
          setPayByWallet(false)
          setSelectedCreditCard(false)
          setSelectedDelivery(true)
          handleCash()
          break;
        default:
          setpayOnCard(false)
          setPayByWallet(false)
          setSelectedCreditCard(false)
          setSelectedDelivery(true)
          handleCash()
      }
    }
  }

  useEffect(() => {
    setPaymentMethod();
  }, [userDetails, wallet?.amount])

  const handleCloseModal = () => setShowModal(false)

  const getEventData = (id, items, type) => {
    let eventData = null
    const myListing =
      delivery_option && delivery_option?.find((d) => d?._id === id)
    const category =
      allCategories &&
      allCategories?.length !== 0 &&
      allCategories?.find((c) => c?._id === myListing?.category)

    if (myListing) {
      eventData = {
        category: 'Cart',
        product_id: myListing?._id,
        product_name: myListing?.partName,
        product_category: category?.name || myListing?.category,
        product_brand: myListing?.partBrand,
        product_variant: myListing?.variant,
        product_price: myListing?.price,
        cart_value: items.length,
      }
      switch (type) {
        case 'delete':
          eventData = { ...eventData, action: 'cart_delete_item' }
          break
        case 'increment':
          eventData = {
            ...eventData,
            action: 'cart_increment_quantity',
            quantity: getCartLength(items, myListing),
          }
          break
        case 'decrement':
          eventData = {
            ...eventData,
            action: 'cart_decrement_quantity',
            quantity: getCartLength(items, myListing),
          }
          break
        case 'update':
          eventData = {
            ...eventData,
            action: 'cart_update_quantity',
            quantity: getCartLength(items, myListing),
          }
          break
        default:
      }
    }

    return eventData
  }

  const resetPaymentOnCartChange = () => {
    setWalletAmount(0)
    setPartialPaymentType("");

    if(payByWallet) {
      setSelectedCreditCard(false);
      setSelectedDelivery(false);
      setpayOnCard(false);
    }
  }

  function removeCartItem(e, id) {
    e.preventDefault()
    flags.current = false
    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList]

      let filteredProducts = products.filter((product) => product?._id !== id)

      dispatch(updateBulkCartListing(filteredProducts, 'delete'))
    } else {
      let items = list.itemsInCart
      items = items.filter((e) => e !== id)

      const eventData = getEventData(id, items, 'delete')

      dispatch(updateCart(items, eventData))
    }
    setShowModal(false)
    resetPaymentOnCartChange();
    popUp('Item removed from cart successfully!')
  }

  function stepForward(e, i, rightStep = false, action) {
    if (deliveryAddress && step === 2 && i > step) return false
    if (selectedCreditCard && !cardInfo && step === 4) {
      setStep(i)
    }

    if (
      payByWallet &&
      (walletAmount === 0 ||
        walletAmount > Number(totalPayableAmount?.toFixed(2)) ||
         (walletAmount < Number(totalPayableAmount?.toFixed(2)) && !partialPaymentType)
      ) &&
      step === 4
    ) {
      handleWallet()
      return false
    }

    if (rightStep || step > i) setStep(i)

    let eventData = {
      action,
      category: 'Cart',
    }
    let payment_method = []

    if (action === 'review_order_click') {
      selectedDelivery && payment_method.push('Cash On Delivery')
      selectedCreditCard && payment_method.push('Card Payment')
      payByWallet && payment_method.push('Wallet Payment')

      eventData = { ...eventData, payment_method: payment_method.join(',') }
    }

    if (action) {
      ga4Events(eventData)
    }
  }

  function qtyIncrement(e, id) {
    checkQuantity(id, true)
    e.preventDefault()

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList]

      let product = products.find((product) => product?._id === id)
      if (product) product.orderQty = parseInt(product.orderQty) + 1

      dispatch(updateBulkCartListing(products, 'increment'))
    } else {
      const items = list.itemsInCart
      items.push(id)

      const eventData = getEventData(id, items, 'increment')

      dispatch(updateCart(items, eventData))
    }
    resetPaymentOnCartChange();
  }

  const checkQuantity = (id, shoudlIncrement) => {
    listings.map((listing) => {
      listing.data?.map((d) => {
        const { _id, quantity } = d.listings[0]
        if (_id?.toString() == id) {
          let total = getCartLength(list.itemsInCart, id)
          if (shoudlIncrement == true) total = total + 1
          else total = total - 1
          if (total > quantity) flags.current = true
          else flags.current = false
        }
      })
    })
  }

  const checkTotal = () => {
    listings.map((listing) => {
      listing.data?.map((d) => {
        const { _id, quantity } = d.listings[0]
        let total = getCartLength(list.itemsInCart, _id)
        if (total > quantity) flags.current = true
        else flags.current = false
      })
    })
  }

  function changeCartQty(qty, id) {
    if (qty < 1) qty = 1

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList]

      let product = products.find((product) => product?._id === id)
      if (product) product.orderQty = qty

      dispatch(updateBulkCartListing(products, 'update'))
    } else {
      let newItems = new Array(qty).fill(id)
      const items = list.itemsInCart.filter((e) => e !== id).concat(newItems)
      const eventData = getEventData(id, items, 'update')
      dispatch(updateCart(items, eventData))
      resetPaymentOnCartChange();
    }
  }

  function qtyDecrement(e, id) {
    e.preventDefault()

    if (list?.isBulkUpload) {
      const products = [...list?.bulkCartList]

      let product = products.find((product) => product?._id === id)
      if (product) product.orderQty = parseInt(product.orderQty) - 1

      dispatch(updateBulkCartListing(products, 'decrement'))
    } else {
      checkQuantity(id, false)
      const items = list.itemsInCart
      const index = items.indexOf(id)
      if (index >= 0) items.splice(index, 1)

      const eventData = getEventData(id, items, 'decrement')
      dispatch(updateCart(items, eventData))
    }
    resetPaymentOnCartChange();
  }

  useEffect(() => {
    if (userAddress?.length) {
      setAddresses(userAddress)

      const DefaultAddress =
        userAddress.find((address) => address.default_address == true) || {}
      setDefaultAddress(DefaultAddress)
      if (!Object.keys(selectedAddress).length > 0) {
        setSelectedAddress(DefaultAddress)
      } else {
        const editedAddress =
          userAddress.find((address) => address._id === selectedAddress._id) ||
          {}
        setSelectedAddress(editedAddress)
      }
    }
  }, [userAddress])

  useEffect(() => {
    if(selectedAddress._id){
        dispatch(feeAddress({selectedId: selectedAddress._id}))
    }
}, [selectedAddress])

  function handleDeliveryOption(val, id) {
    let del = [...delivery_option]
    let i = del.findIndex((obj) => obj._id === id)
    del[i].delivery_option = val
    setDelivery_option(del)
  }

  function getPriceTotal() {
    let price = delivery_option.reduce((total, obj) => {
      if (obj.delivery_option === 'express') {
        return total + 20
      } else {
        return total + 0
      }
    }, 0)
    return price >= 20 ? 20 : 0
  }

  const clearCoupon = () => {
    setCouponCode('')
    setCoupon({})
    setDiscount(0)
    setDiscountPerUnit(0)
  }

  const calcDiscountPerUnit = (listings, coupon, payment_method, cart) => {
    let newTaxAmount = 0
    if (coupon) {
      let _filteredListings = listings.filter((l) => {
        if (
          coupon.parameters.label === 'Category' &&
          coupon.parameters.value?.includes(l.category)
        ) {
          return l
        }
        if (
          coupon.parameters.label === 'Brand' &&
          coupon.parameters.value?.includes(l.partBrand)
        ) {
          return l
        }
        if (
          coupon.parameters.label === 'Sub-Category' &&
          coupon.parameters.value?.includes(l.subCategory)
        ) {
          return l
        }
        if (
          coupon.parameters.label === 'SKU' &&
          !coupon.parameters.selectedAll &&
          coupon.parameters.value?.includes(l._id)
        ) {
          return l
        }
        if (
          coupon.parameters.label === 'SKU' &&
          coupon?.parameters?.selectedAll &&
          !coupon?.excludedSKUs?.includes(l._id)
        ) {
          return l
        }
        if (
          coupon.parameters.label === 'Seller' &&
          coupon.parameters.value?.includes(l?.user?._id)
        ) {
          return l
        }
      })

      let filteredListings = listings
      let totalVal = _filteredListings.reduce((total, obj) => {
        return total + obj.priceExclVat * getCartLength(list.itemsInCart, obj)
      }, 0)

      if (totalVal == 0) {
        return {
          discount: 0,
          discountPerUnit: 0,
          newTaxAmount: listings.reduce((total, obj) => {
            return total + (obj.priceExclVat * obj.vatPercent / 100) * getCartLength(list.itemsInCart, obj)
          }, 0),
        }
      }

      let discountPerUnit = 0

      if (
        totalVal >= coupon?.min_cart_value &&
        coupon?.discount_type?.label === 'by_percent'
      ) {
        discountPerUnit = coupon?.discount_type?.value / 100

        let cap_discountAmt = totalVal * discountPerUnit
        if (
          coupon?.discount_cap_amount &&
          cap_discountAmt > coupon?.discount_cap_amount
        ) {
          discountPerUnit = coupon?.discount_cap_amount / totalVal
        }
      }
      if (
        totalVal >= coupon?.min_cart_value &&
        coupon?.discount_type?.label === 'by_value'
      ) {
        discountPerUnit = coupon?.discount_type?.value / totalVal
      }

      if (totalVal < coupon?.min_cart_value) {
        discountPerUnit = 0
      }

      if (
        coupon.payment_method !== 'All' &&
        coupon.payment_method !== payment_method
      ) {
        discountPerUnit = 0
      }

      const newTaxAmountForDiscountListing = _filteredListings.reduce(
        (total, obj) => {
          const discountAmount = discountPerUnit * obj.priceExclVat
          return (
            total +
            calcInclVatFromExcl({
              exclPrice: obj.priceExclVat - discountAmount,
              vatPercent: obj.vatPercent,
            }).taxAmount *
            getCartLength(list.itemsInCart, obj)
          )
        },
        0
      )

      const remainingListing = filteredListings.filter(
        (x) => !_filteredListings.includes(x)
      )
      const newTaxAmountForRemainingListing = remainingListing.reduce(
        (total, obj) => {
          return total + (obj.priceExclVat * obj.vatPercent / 100) * getCartLength(list.itemsInCart, obj)
        },
        0
      )

      newTaxAmount =
        newTaxAmountForDiscountListing + newTaxAmountForRemainingListing

      return {
        discount: discountPerUnit * totalVal,
        discountPerUnit,
        newTaxAmount,
      }
    } else {
      return {
        discount: 0,
        discountPerUnit: 0,
        newTaxAmount: listings.reduce((total, obj) => {
          return total + (obj.priceExclVat * obj.vatPercent / 100) * getCartLength(list.itemsInCart, obj)
        }, 0),
      }
    }
  }

  const getFlattenListings = (listings) => {
    let listingsArray = []
    listings.forEach((l) => {
      l.data.forEach((d) => {
        listingsArray.push(d.listings[0])
      })
    })
    return listingsArray
  }
  useEffect(() => {
    let { totalVat, totalAmountExclVat } = getTotalAmounts(listings)
    if (coupon?._id) {
      const paymentMethod = payByWallet
        ? 'Wallet Payment'
        : selectedCreditCard
          ? 'Online Payment'
          : 'Cash on delivery'

      let { discount, discountPerUnit, newTaxAmount } = calcDiscountPerUnit(
        getFlattenListings(listings),
        coupon,
        paymentMethod,
        list
      )
      setDiscount(discount)
      setDiscountPerUnit(discountPerUnit)
      newTaxAmount && setTotalTax(newTaxAmount)
    } else {
      setTotalTax(totalVat)
    }
    setSubTotalExclVat(totalAmountExclVat)
  }, [list, coupon, payOnCard, listings])

  const verifyCoupon = async () => {
    try {
      if (couponCode) {
        const res = await axios.post('/api/coupon/verify-coupon', {
          coupon_code: couponCode,
          user_id: login._id,
          listings: getFlattenListings(listings)?.map((l) => ({
            ...l,
            qty: getCartLength(list.itemsInCart, l),
          })),
          payment_method: payByWallet
            ? 'Wallet Payment'
            : selectedCreditCard
              ? 'Online Payment'
              : 'Cash on delivery',
        })
        let _coupon = { ...res.data.data }
        if(payByWallet) {
          setPartialPaymentType("");
          setSelectedCreditCard(false);
          setSelectedDelivery(false);
          setpayOnCard(false);
        }
        setCoupon(_coupon)
        setCouponShowModal(false)
        ga4Events({
          action: 'cart_apply_coupon',
          category: 'Cart',
          coupon_code: couponCode,
        })

        popUp(res.data.message || 'Coupon applied successfully.')
      }
    } catch (error) {
      popUp(error.response?.data?.message || 'Invalid coupon code.')
    }
  }

  const handleAddFormModal = (res) => {
    setIsOpenAddModal(res)
  }

  useEffect(() => {
    const handler = (event) => {
      setCardInfo(event.data.data)
    }
    window.addEventListener('message', handler)

    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  const handleEditFormModal = (res) => setIsOpenEditModal(res)

  useEffect(() => {
    if (partialPaymentType === 'cash_on_delivery') {
      handleCash()
    }
    if (partialPaymentType === 'online_payment') {
      handleCard()
    }
  }, [partialPaymentType])

  const _handleCash = () => {
    setpayOnCard(false)
    setSelectedDelivery(true)
    setSelectedCreditCard(false)
    setWalletShowModal(false)
    window.paymentSessionId = undefined
    window.isSessionExpired = undefined
  }

  const handleCash = () => {
    if (disabled) {
      return
    }
    ga4Events({
      action: 'cart_select_payment_method',
      category: 'Cart',
      payment_method: 'Cash On Delivery',
    })
    if (
      coupon &&
      Object.keys(coupon).length !== 0 &&
      coupon?.payment_method === 'Online Payment'
    ) {
      clearCoupon()
    }
    _handleCash()
  }

  const handleCard = async () => {
    if (disabled) {
      return
    }

    ga4Events({
      action: 'cart_select_payment_method',
      category: 'Cart',
      payment_method: 'Credit Card',
    })

    if (
      coupon &&
      Object.keys(coupon).length !== 0 &&
      coupon?.payment_method === 'Cash on delivery'
    ) {
      clearCoupon()
    }
    setpayOnCard(true)
    setSelectedDelivery(false)
    setSelectedCreditCard(true)
    setCardInfo(true)
  }
  function CardDetailInputWin() {
    return new Promise((resolve, reject) => {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=400,left=500,top=150`
      let paymentWin = window.open('/payment', 'online_payment', params)
      paymentWin.focus()
      window.pageloaded = false
      //on open
      paymentWin.onload = function () {
        window.pageloaded = true
        window.paymentSessionId = undefined
        window.isSessionExpired = undefined
        paymentWin.onunload = function () {
          // called when user close window from x button
          if (!window.isonbeforeunloadCalled) {
            return reject('')
          } else {
            delete window.isonbeforeunloadCalled
          }
        }
      }

      //on close
      paymentWin.onbeforeunload = function () {
        window.isonbeforeunloadCalled = true
        if (window.paymentSessionId && !window.isSessionExpired) {
          return resolve({
            paySessionId: window.paymentSessionId,
          })
        } else if (!window.paymentSessionId && window.isSessionExpired) {
          handleCash()
          return
        } else {
          // delete created order
          return reject({
            message:
              'Your session has expire or have provided invalid data. Please try again.',
          })
        }
      }
    })
  }

  function ThreeDSChallengePage(paymentRes) {
    return new Promise((resolve, reject) => {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=400,left=500,top=150`
      let paymentWin = window.open('/3ds-payment', '3ds-payment', params)
      if (!paymentWin) {
        setdisabler(false)
        alert('You have a popup blocker enabled. Please allow popups')
        return resolve()
      }
      paymentWin.focus()
      paymentWin.paymentRes = paymentRes

      //on open
      paymentWin.onload = function () {
        window.isPaymentSuccess = undefined
        window.errorMsg = undefined
        paymentWin.onunload = function () {
          if (!window.isonbeforeunloadCalled) {
            setdisabler(false)
            return resolve()
          } else {
            delete window.isonbeforeunloadCalled
          }
        }
      }

      //on close
      paymentWin.onbeforeunload = function () {
        window.isonbeforeunloadCalled = true
        return resolve()
      }
    })
  }

  const handleWallet = () => {
    setPayByWallet(true)
    setSelectedDelivery(false)
    setSelectedCreditCard(false)
    setWalletShowModal(true)

    ga4Events({
      action: 'cart_select_payment_method',
      category: 'Cart',
      payment_method: 'Wallet',
    })
  }

  const updateOrderProducts = (products, order) => {
    const noOfChunks = Math.ceil(products?.length / 20)
    let promises = []

    for (let i = 0; i < noOfChunks; i++) {
      const postData = {
        order_id: order?.order_id,
        products: products?.splice(0, 20),
      }

      promises.push(axios.put('/api/order/update-order-products', postData))
    }

    Promise.all(promises)
      .then((res) => {
        axios.get(`/api/order/send-order-notification/${order?.order_id}`)

        popUp('Your order was placed successfully!')
        window.paymentSessionId = undefined
        window.isSessionExpired = undefined

        if (list?.isBulkUpload) {
          dispatch({ type: 'CLEAR_BULK_CART' })
        } else {
          dispatch(updateCart([]));
        }
        setListings([])
        setdisabler(false)
        history.push('/thankyou?order=' + order?.order_id)
      })
      .catch(async (err) => {
        await axios.delete(`/api/order/${order?.order_id}`)
        window.paymentSessionId = undefined
        window.isSessionExpired = undefined
        window.isPaymentSuccess = undefined
        popUp("Your order placement wasn't successful!")
        setdisabler(false)
      })
  }
  function getTotalAmounts(listings) {
    let arr = listings
      .reduce((acc, curr) => {
        return [...acc, ...curr.data.map((l, i) => l.listings)].filter((i) => i)
      }, [])
      .flatMap((i) => i)
    return arr.reduce(
      (res, c) => {
        let len = getCartLength(list.itemsInCart, c)
        res.totalAmountExclVat += c.priceExclVat * len
        res.totalVat += (c.vatPercent / 100) * (c.priceExclVat * len)
        return res
      },
      { totalAmountExclVat: 0, totalVat: 0 }
    )
  }

  //toggle for order place message
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [msg, setMessage] = useState('')
  const toggle = () => {
    setIsModalOpen(!isModalOpen)
  }

  async function placeOrder(e, actualTotalPrice) {
    if (!payOnCard) {
      setdisabler(true)
    }
    const buss_model = ['Consolidation', 'Fulfilment']
    const defaultWarehouse = null
    const order_id = uuidv4().replace('-', '').slice(0, 8).toUpperCase()
    const today = moment().tz('Asia/Dubai').unix() * 1000
    const stockinfo = []
    let orderTaxAmount = 0
    let express_count = 0

    let products = delivery_option.map((res) => {
      const qty = getCartLength(list.itemsInCart, res)
      stockinfo.push({
        listing_id: res._id,
        quantity: qty,
      })
      // for unit_discount quantity is one
      let discountAmount = getDiscountAmount(
        res.priceExclVat,
        qty,
        res,
        discountPerUnit,
        coupon
      ),
        unit_discount = discountAmount / qty

      // newTaxAMount
      let newTotalAfterDiscount = res.priceExclVat * qty - discountAmount
      let taxAmount = newTotalAfterDiscount * (res.vatPercent / 100)
      orderTaxAmount = orderTaxAmount + taxAmount

      // + taxAmount

      res = {
        ...res,
        unit_discount,
        netamount_excl_vat: newTotalAfterDiscount,
        item_vat_amount: taxAmount,
      }

      if (res?.delivery_option === 'express') {
        express_count = express_count + 1
      }

      return {
        listing: res._id,
        quantity: qty,
        seller: res?.user?._id,
        status: 'PENDING',
        delivery_option: res,
        shipping_price_individual: 0,
        warehouse: buss_model.includes(res.business_model)
          ? defaultWarehouse
          : null,
      }
    })

    const totalProducts = [...products]
    let totalPrice =
      Number(subTotalExclVat) + Number(newShippingPrice) + Number(totalTax)

    let order = {
      currency: currency?.currency,
      products:
        totalProducts?.length <= 20 || !list?.isBulkUpload
          ? products
          : products?.splice(0, 20),
      delivery_address: selectedAddress || defaultAddress,
      pay_on_card: false,
      discount: discount,
      coupon: coupon?._id && discount > 0 ? { _id: coupon?._id } : {},
      total_price: Number(actualTotalPrice?.toFixed(2)),
      orderTaxAmount,
      unchanged_total_price: Number(actualTotalPrice?.toFixed(2)),
      // shipping_price: getPriceTotal(),
      shipping_price: newShippingPrice,

      user: login._id,
      walletAmount: +walletAmount,
      order_date: today,
      order_id,
      express_count,
      order_note: orderNote,
      priceExclVat: Number(subTotalExclVat.toFixed(2)),
      isPaidOnline: false,
      isProductsRemaining: totalProducts?.length > 20 && list?.isBulkUpload,
      MOVShippingFee: addressFee 
    }
    let paymentSessionId
    function deleteOrder(id) {
      axios
        .delete(`api/order/${id}`)
    }
    if (payOnCard) {
     
      try {
        const { paySessionId } = await CardDetailInputWin()
        paymentSessionId = paySessionId
        setdisabler(true)
      } catch (error) {
        return
      }
    }
    axios
      .post(
        '/api/order',
        { ...order, paymentSessionId },
        {
          headers: {
            platform: 'web',
          },
        }
      )
      .then(async (res) => {
        const orderedListing = res.data?.order
        let order_id = orderedListing?.order_id

        const orderNumber = res.data?.from
        if (res.data.status === 'challenge') {
          try {
            order_id = res.data.order.order_id
            await ThreeDSChallengePage(res.data.data)
            const { data } = await axios.post('/api/order/verify-payment', {
              order_id: res.data?.order._id,
              isPaidOnline: true,
              payOnCard,
              details: CryptoJS.AES.encrypt(
                JSON.stringify({ orderRef: res.data.data?.orderReference }),
                process.env.REACT_APP_OBJECT_ENCREPTION_KEY
              ).toString(),
            })
            if (data !== 'PAY_SUCCESS') {
              throw { verifyMsg: data }
            }
          } catch (err) {
            throw {
              message: err,
            }
          }
        }
        if (totalProducts?.length > 20 && list?.isBulkUpload) {
          await updateOrderProducts(products, orderedListing)
        } else {
          if (login?.KYCStatus) {
            popUp('Your order was placed successfully!')
          }
          window.paymentSessionId = undefined
          window.isSessionExpired = undefined
          if (list?.isBulkUpload) {
            setTimeout(() => {
              dispatch({ type: 'CLEAR_BULK_CART' })
            }, 0)
          } else {
            dispatch(updateCart([]));
          }
          setListings([])
          setdisabler(false)
          ga4Events({
            action: 'order_created',
            category: 'Cart',
            order_id: order_id,
          })
          history.push('/thankyou?order=' + order_id)
        }
      })
      .catch(async (err) => {
        if (Boolean(err?.message.verifyMsg)) popUp(err.message.verifyMsg)

        if (err?.order_id) deleteOrder(err.order_id)
        if (Boolean(err?.message?.message)) popUp(err.message.message)
        if (Boolean(err?.response?.data?.message))
          popUp(err?.response?.data?.message)
      })
      .finally(() => setdisabler(false))
  }

  function handleConfirm(e, id, list) {
    setShowModal(true)
    setPassId(id)
    setPassevent(e)
    setPassName(
      list?.partName || list?.partSKU
        ? `${list.partName ? list.partName : list.partSKU}`
        : null
    )
  }

  function getListings() {
    axios
      .post('/api/listings/cart-listings', {
        listings: list.itemsInCart,
        user: login._id,
      })
      .then((res) => {
        const newListings = []
        let total_price = 0
        let initalDeliveryOptions = []
        setLoader(false)
        res.data.forEach((item, i) => {
          newListings.push({ user: item.user, listings: [item] })

          total_price += item.price * getCartLength(list.itemsInCart, item)

          return true
        })
        newListings.map((item) => {
          return item.listings.forEach((list) => {
            initalDeliveryOptions.push({
              ...list,
              item_id: list._id,
              delivery_option: 'next_day',
            })
          })
        })
        setPrice(total_price)
        setTempsubTotal(total_price)
        setListings(changeToGroup(newListings))
        setDelivery_option(initalDeliveryOptions)
      })
      .catch((err) => {
        setLoader(false)
        popUp('There was a problem with server. Please try again.')
      })
    dispatch(fetchAllAddress())
  }
  let shippingTaxAmt = getPriceTotal() - (getPriceTotal() / 1.05)?.toFixed(2) ?? 0
  // let newShippingPrice = (getPriceTotal() - shippingTaxAmt)?.toFixed(2)
  let newShippingPrice = getPriceTotal()
  
  if(subTotalExclVat && addressFee && addressFee.MOV && addressFee.shipping){
    let subTotalExclVatAfterDiscount = subTotalExclVat
    if(discount){
      subTotalExclVatAfterDiscount = parseFloat(subTotalExclVat) - parseFloat(discount)
    }
    if(subTotalExclVatAfterDiscount < addressFee.MOV){
      newShippingPrice = parseFloat(newShippingPrice) + parseFloat(addressFee.shipping)
    }
  }

  newShippingPrice = newShippingPrice * 1.05

  const updatestockflag = (flag) => {
    flags.current = flag
    return <InfoIcon style={{ color: 'orange', fontSize: 24 }} />
  }

  const handleClearCart = () => {
    dispatch({ type: 'CLEAR_BULK_CART' })
    setShowModal(false)
  }

  let s1 = {
    listings,
    totalAmount,
    getCartLength,
    list,
    updatestockflag,
    qtyDecrement,
    qtyIncrement,
    handleConfirm,
    getTaxAmount,
    discount,
    coupon,
    discountPerUnit,
    changeCartQty,
  }
  let s1Bulk = {
    listings,
    getCartLength,
    list,
    updatestockflag,
    qtyDecrement,
    qtyIncrement,
    handleConfirm,
    coupon,
    discountPerUnit,
    changeCartQty,
  }
  let s2 = {
    addresses,
    setSelectedAddress,
    selectedAddress,
    setIsOpenEditModal,
    setEditedAddressId,
    handleAddFormModal,
  }
  let s3 = {
    delivery_option,
    selectedAddress,
    defaultAddress,
    handleDeliveryOption,
    getCartLength,
    list,
    showExpressDelivery,
  }
  let s4 = {
    setCardInfo,
    setpayOnCard,
    setDisabled,
    setSelectedDelivery,
    setSelectedCreditCard,
    setPayByWallet,
    payOnCard,
    selectedCreditCard,
    selectedDelivery,
    setShowPaymentModal,
    setCouponShowModal,
    payByWallet,
    setCouponCode,
    wallet,
    handleCash,
    handleCard,
    handleWallet,
    setUserDetails,
    totalPayableAmount,
    setWalletAmount,
    partialPaymentType
  }
  let s5 = {
    selectedAddress,
    login,
    formatPhoneNumber,
    payOnCard,
    stepForward,
    list,
    totalAmount,
    getCartLength,
    discountPerUnit,
    coupon,
    delivery_option,
    payByWallet,
    partialPaymentType,
  }

  // List of customer's that needs to add LPO number on the checkout note


  const [placeholder, setPlaceholder] = useState('Drop note for your order...'); 
  let sReview = {
    totalTax: totalTax + shippingTaxAmt,
    subTotalExclVat,
    step,
    orderNote,
    login,
    formatPhoneNumber,
    selectedAddress,
    classes,
    getTaxAmount,
    newShippingPrice,
    getPriceTotal,
    flags,
    stepForward,
    disabler,
    placeOrder,
    couponCode,
    setCouponCode,
    setCouponShowModal,
    discount,
    coupon,
    setOrderNote,
    walletAmount,
    setHasSelectedCoordinates,
    setTotalPayableAmount,
    lpoUsers,
    placeholder
  }

  let aForm = { stateData, regionData, locationData }
  let exc_tax_total =
    parseFloat(localStorage.getItem('totalAmountInCart')) / 1.05
  let discount1 = discount ? discount : 0

  let tax = (exc_tax_total - discount1 + Number(newShippingPrice)) * 0.05

  let total = exc_tax_total - discount1 + Number(newShippingPrice) + tax

  if (loader || list?.isBulkListLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )

  if (
    !list.itemsInCart.length &&
    !list.bulkCartList.length &&
    !list.unAvailableProducts.length
  ) {
    return <CheckoutEmpty />
  }

  return (
    <>
      <PageBanner />
      <ConfirmModal
        showModal={showModal}
        onClose={handleCloseModal}
        eventFunc={passName ? removeCartItem : handleClearCart}
        id={passId ? passId : null}
        e={passevent}
        name={passName ? passName : 'all items in the cart'}
      />

      <CouponConfirmModal
        showModal={couponShowModal}
        onClose={() => setCouponShowModal(false)}
        eventFunc={verifyCoupon}
      />

      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        setPaymentSessionId={setPaymentSessionId}></PaymentModal>

      <WalletPaymentModal
        isOpen={walletShowModal}
        onClose={() => {
          setWalletShowModal(false)
        }}
        wallet={wallet}
        setPayByWallet={setPayByWallet}
        setDisabled={setDisabled}
        setWalletAmount={setWalletAmount}
        handleCash={handleCash}
        walletAmount={walletAmount}
        partialPaymentType={partialPaymentType}
        max={Number(total.toFixed(2))} //need to change
        setPartialPaymentType={setPartialPaymentType}
        subTotalExclVat={subTotalExclVat}
        newShippingPrice={newShippingPrice}
        totalTax={totalTax + shippingTaxAmt}
        discount={discount}
        _handleCash={_handleCash}
        setSelectedCreditCard={setSelectedCreditCard}
        setSelectedDelivery={setSelectedDelivery}
        setpayOnCard={setpayOnCard}
        coupon={coupon}
        clearCoupon={clearCoupon}
      />

      <AddForm
        {...aForm}
        isOpenAddModal={isOpenAddModal}
        handleEditFormModal={handleEditFormModal}
        handleAddFormModal={handleAddFormModal}
      />
      <EditForm
        {...aForm}
        isOpenEditModal={isOpenEditModal}
        handleEditFormModal={handleEditFormModal}
        editedAddressId={editedAddressId}
        hasSelectedCoordinates={hasSelectedCoordinates}
        setHasSelectedCoordinates={setHasSelectedCoordinates}
      />
      <section className='checkout-wrapper section-padding-strict bgcolor-white pb-5'>
        <Steps step={step} />
        <div className='container'>
          <StepButton step={step} stepForward={stepForward} />
          <div className='row'>
            <div className='col-lg-9'>
              <StepDetailText step={step} />
              <div className='checkout-form'>
                {list?.isBulkUpload && step === 1 && (
                  <div className='d-flex justify-content-end mb-2'>
                    <button
                      htmlFor='customUpload'
                      className='btn btn-sm btn-primary text-white d-flex'
                      onClick={(e) => handleConfirm(e)}>
                      <RemoveShoppingCart fontSize='small' />
                      Clear Cart
                    </button>
                  </div>
                )}
                <div className={classes.root}>
                  {step === 1 && (
                    <>
                      {list?.isBulkUpload ? (
                        <BulkOrderStep1 {...s1Bulk} />
                      ) : (
                        <Step1 {...s1} />
                      )}
                    </>
                  )}
                  {step === 2 && <Step2 {...s2} />}
                  {step === 3 && <Step3 {...s3} />}
                  {step === 4 && <Step4 {...s4} />}
                </div>
                {step === 5 && <Step5 {...s5} />}
              </div>
            </div>
            {step !== 6 &&
              (list?.bulkCartList?.length > 0 ||
                list?.itemsInCart?.length > 0) && <StepReview {...sReview} />}
          </div>
        </div>
      </section>
      <Backdrop style={{ zIndex: 100000 }} open={disabler}>
        <Box
          bgcolor='#fff'
          padding='2rem 1.5rem'
          borderRadius='8px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'>
          <CircularProgress color='primary' size={40} />
          <Box mt='1rem'>
            <Typography align='center' variant='h6' color='white'>
              Finalizing you order.
            </Typography>
            <Typography align='center' variant='h6' color='white'>
              Please don't refresh the page while it is in progress.
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <ShowMessageModal
        isModalOpen={isModalOpen}
        toggle={toggle}
        message={
          'We are excited about your interest in placing a second order with us. Before you proceed, we have to verify some details. Our team will be in touch with you shortly to assist with your order.'
        }
      />
    </>
  )
}
