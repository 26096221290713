import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { SetPhoneNumber } from "../../../../Store/action/signupActions";
import { popUp } from "../../../../utils";
import { UpdateUserInfo } from "../../../../Store/action/loginActions";
import { phoneNoValidator } from "../../../../services/numValidator";
import { getOTP, updateUserProfile, verifyOTP } from "../../../../Store/action/userAction";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: this.props.login.phone,
      verifyLoading: false,
      otpLoading: false,
      codeSent: false,
      errMsg: "",
      errMessage:'',
      otpCode: "",
      typingTimeout: 0,
      phoneValid: false,
      isContinueEnabled: false,errorM:""
    };
    this.validator = new SimpleReactValidator();
    this.handleOTP = this.handleOTP.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleVerifyOTP = this.handleVerifyOTP.bind(this);
    this.verifyPhoneNumber = this.verifyPhoneNumber.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onBlurChange = this.onBlurChange.bind(this);
    this.remove4th0 = this.remove4th0.bind(this);
  }
  remove4th0 (zero){
    zero=zero[3]==='0'?zero.slice(0,3)+zero.slice(4):zero;
    console.log(zero)
    return zero;
  }
  
  onBlurChange(phone){
   let mobileNo =phone.target?.value?.replace(/\s/g, '').trim()
    if(mobileNo.length>=6){
      var phoneno = /^\+9710/;
      if(mobileNo.match(phoneno)) { 
        this.setState({ otpLoading: false, codeSent: false, errMessage: "Invalid Mobile No" });

      }
      else{ this.setState({ otpLoading: true, codeSent: false, errMessage: "" });}
    }

  }
  handleChangePhone(phone) {
   // const inputPhoneNumber = e?.target?.value;
    if (phone.length !== 12) {
      //  setErrorM('Mobile Number must be 9 digits');
         this.setState({ otpLoading: false, codeSent: false,  errMessage: "Mobile Number must be 9 digits" });
       } else {
         this.setState({ otpLoading: true, codeSent: false, errMessage: "" });
       }
    this.setState({ codeSent: false });
    let self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      phone: this.remove4th0(phone?.replace(/\s/g, '').trim()),
      phoneValid: false,
      typingTimeout: setTimeout(function () {
        self.verifyPhoneNumber(phone);
      }, 1000),
    });
  }

  handleChangeInput(e) {
    e.preventDefault();
    this.setState({ otpCode: e.target.value });
  }

  //SEND OTP TO MOBILE
  handleOTP(e) {
    e.preventDefault();
    this.setState({ otpLoading: true, codeSent: false, errMsg: "" });

    const postData = {
      value: "+" + this.state.phone,
      by: "sms",
      forRegister: false
    }

    getOTP(postData)
      .then((res) => {
        this.setState({
          otpLoading: false,
          codeSent: true,
          isContinueEnabled: true,
        });
      })
      .catch((err) => {
        this.setState({
          otpLoading: false,
          codeSent: false,
          errMsg: err?.message,
        });
      });
  }

  //VERIFY OTP ENTERED BY USER
  handleVerifyOTP(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ verifyLoading: true, errMsg: "" });
      const identify = "+" + this.state.phone;

      const postData = {
        identify: identify,
        code: this.state.otpCode,
        forRegister: false
      }

      verifyOTP(postData)
        .then((res) => {
          if (res.data?.message === "success") {
            this.setState({ verifyLoading: false, phone: identify });
            this.updatePhone()
          }
        })
        .catch((err) => {
          this.setState({
            errMsg: err?.message,
            codeSent: false,
            verifyLoading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }


  //METHOD CALLED ON SUCCES
  onSuccess() {
    this.setState({
      verifyLoading: false,
      otpLoading: false,
      codeSent: false,
      errMsg: "",
      errMessage:'',
      otpCode: '',
      typingTimeout: 0,
      phoneValid: false,
      isContinueEnabled: false,
    })
    popUp("Phone Updated Successfully !");
  }

  //UPDATING USER PHONE NO
  updatePhone() {
    let formData = {
      phone: this?.state?.phone,
    }

    this.props.updateUserProfile({
      formData,
      userId: this.props?.login?._id,
      onSuccess: this.onSuccess,
      onFail: () => { }
    });
  }

  //CALLING EXTERNAL API TO VERIFY PHONE NO
  async verifyPhoneNumber(number) {
    try {
      const res = await phoneNoValidator({ contact: number });
      this.setState({ phoneValid: res?.valid });
    } catch (error) {
      popUp(error?.message || "Error while validating phone number")
    }
  }

  render() {
    const { t } = this.props;
    const {
      otpLoading,
      phone,
      codeSent,
      errMsg,
      errMessage,
      verifyLoading,
      otpCode,
      phoneValid,
      isContinueEnabled,
    } = this.state;

    return (
      <Fragment>
        <div className="form-group  opt-password-wrapper">

          <PhoneInput
            placeholder={t("auth_phone_placeholder")}
            name="Phone"
            value={this.remove4th0(phone?.replace(/\s/g, '').trim()) || ""}
            onChange={(phone) => this.handleChangePhone(phone)}
            onBlur={(phone) => this.onBlurChange(phone)}
            inputStyle={{ border: "none" }}
          />
          <button
            type="button"
            disabled={phoneValid && otpLoading === false ? false : true || errMessage !=='' ? true : false}
            onClick={this.handleOTP}
            className="btn get-otp-btn"
            id="get-opt-btn"
          >
            {otpLoading && (
              <i className="las la-spinner la-spin mr-2"></i>
            )}
            {t("auth_get_otp")}
          </button>
          <div className="text-danger">
            {this.validator.message("Phone", phone, "required")}
          </div>
          
        </div>
        {errMessage &&  <div className="text-danger">{errMessage}</div>}
        <div className="opt-password-wrapper form-group">
          <input
            type="text"
            name="otpCode"
            className="form-control"
            placeholder="OTP"
            value={this.state.otpCode || ""}
            onChange={this.handleChangeInput}
          />
          <button
            type="submit"
            disabled={
              !(isContinueEnabled
                ? !verifyLoading
                  ? otpCode
                  : false
                : false) || (errMessage === '' ? false : true)
            }
            onClick={this.handleVerifyOTP}
            className="btn btn-primary text-center pt-3"
          >
            {verifyLoading && (
              <i className="las la-spinner la-spin mr-2"></i>
            )}
            <p style={{ lineHeight: '10px' }}>Update Phone</p>
          </button>
        </div>

        <div className="text-danger">
          {this.validator.message("otp code", otpCode, "required|string")}
        </div>

        {codeSent === true && (
          <p className="text-info text-center">
            We have sent code to {this.state.phone}.{" "}
            <a href=" " onClick={this.handleOTP}>
              Send Again
            </a>
            ?
          </p>
        )}

        {errMsg && (
          <p className="text-danger text-center">
            {errMsg}{" "}
            <span className="text-info">
              <a href=" " onClick={this.handleOTP}>
                Send Again
              </a>
              ?
            </span>
          </p>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
    login: state.login,

  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    setPhoneNumber: (data) => dispatch(SetPhoneNumber(data)),
    updateUserInfo: (data) => dispatch(UpdateUserInfo(data)),
    updateUserProfile: (data) => dispatch(updateUserProfile(data))
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(UpdatePassword);
