import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
	logo: {
		[theme.breakpoints.up('xs')]: {
			width: '50%'
		},
		[theme.breakpoints.up('sm')]: {
			width: '80%'
		},
		[theme.breakpoints.up('md')]: {
			width: '80%'
		},
		[theme.breakpoints.up('lg')]: {
			width: '80%'
		},
	}
}));

const ScrollButton = () => {
	const classes = useStyles();
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
		 in place of 'smooth' */
		});
	};

	window.addEventListener("scroll", toggleVisible);

	return (
		<>
			<img
				onClick={scrollToTop}
				src="/assets/img/logo-footer.svg"
				alt="logoImage"
				className={classes.logo}
			/>
		</>
	);
};

export default ScrollButton;
