import React, { Component, Fragment } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  UpdateQuotationItems,
  ClearQuotation,
} from "../../../../Store/action/quotationActions";
import ExcelQuotation from "../modal/excel-quotation";
import GetAppIcon from "@material-ui/icons/GetApp";
import MaterialTable from "material-table";
import Loader from "../Loader";
import { popUp } from "../../../../utils";
import { Button } from "@material-ui/core";
import CssTextField from "../SearchField";
import PageTitle from "../PageTitle";
import ConfirmModal from "../modal/confirmation";
import moment from "moment-timezone";

class BuyerQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationExcels: [],
      seller: null,
      notes: "",
      modalQuotation: false,
      loadQuotationWithId: null,
      selectedSeller: "",
      getSeller: [],
      selectedTabs: "all",
      searchField: "",
      loader: false,
      showModal: false,
      passId: "",
      passevent: "",
      passname: "",
      disableBtn: false,
      quotationListNotes: [],
      editQuotation: false,
    };
    this.getStatus = this.getStatus.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/users?role=SELLER&all=true&status=Approved")
      .then((res) => this.setState({ getSeller: res?.data?.users }))
      .catch((err) =>
        popUp(err?.response?.data?.message)
      );

    this.refreshQuotationExcels();
    this.refreshQuotationProduct();
  }

  refreshQuotationExcels() {
    axios
      .get("/api/quotation/list-excel?buyer=" + this.props.login._id)
      .then((res) => {
        if (res.data.list) this.setState({ quotationExcels: res.data.list });
      })
      .catch((err) => {
        popUp(err?.response?.data?.message);
      });
  }
  
  refreshQuotationProduct() {
    axios
      .get("/api/quotation/list-rfq?buyer=" + this.props.login._id)
      .then((res) => {
        if (res.data.list)
          this.setState({ quotationListNotes: res.data.list });
      })
      .catch((err) => {
        popUp(err?.response?.data?.message);
      });
  }

  incrementQty(index) {
    let { quotationItems } = this.props.quotation;

    quotationItems[index].qty = quotationItems[index].qty + 1;

    this.props.updateQuotationItems({
      quotationItems,
    });
  }

  decrementQty(index) {
    let { quotationItems } = this.props.quotation;

    quotationItems[index].qty = Math.max(0, quotationItems[index].qty - 1);

    this.props.updateQuotationItems({
      quotationItems,
    });
  }

  removeItem = (e, index) => {
    let { quotationItems } = this.props.quotation;

    quotationItems.splice(index, 1);

    if (quotationItems.length > 0)
      this.props.updateQuotationItems({
        quotationItems,
      });
    else this.props.clearQuotation();
    this.setState({ showModal: false });
  };

  hasQuotation = () => this.props.quotation.quotationSeller != null;

  sendQuotation = (e) => {
    this.setState({ disableBtn: true });
    let { quotationItems } = this.props.quotation;
    const listingData = quotationItems.map((listing, index) => {
      return {
        listingId: listing.listingId,
        quantity: listing.qty,
      };
    });
    const dataToSave = {
      listing: listingData,
      seller: process.env.REACT_APP_QUOTATION_SELLER_ID,
      buyer: this.props?.login?._id,
      notes: this.state.notes,
      status: "Pending",
    };

    axios
      .post(`/api/products/send-quotation`, dataToSave)
      .then((res) => {
        if (res?.data?.message === "success")
          popUp("Your quotation was successfully sent.");
        this.setState({ seller: null, notes: "" });
        this.props.clearQuotation();
        this.setState({ disableBtn: false });
        this.refreshQuotationProduct();
      })
      .catch((err) => {
        popUp(err?.response?.data?.message);
        this.setState({ seller: "", notes: "" });
        this.refreshQuotationProduct();
      });
  };

  getStatus(status) {
    if (status === "Active") {
      return "active";
    }
    if (status === "Pending") {
      return "pending";
    }
  }

  handleChangeSelect(selectedOpton, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOpton.value });
  }

  handleConfirm = (e, id, q) => {
    this.setState({
      showModal: true,
      passId: id,
      passevent: e,
      passname: q.name,
    });
  };

  render() {
    if (this.state.loader) return <Loader />;

    return (
      <>
        <ConfirmModal
          showModal={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          eventFunc={this.removeItem}
          id={this.state.passId}
          e={this.state.passevent}
          name={this.state.passname}
        />

        <PageTitle title="My Quotation" />
        <div className="mt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="order-tab">
                <ul className="nav nav-tabs" id="ex1" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${this.state.selectedTabs === "all" && "active"
                        } nav-link`}
                      id="all-orders"
                      data-mdb-toggle="tab"
                      href="#all-orders"
                      role="tab"
                      aria-controls="all-orders"
                      aria-selected="true"
                      style={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginRight: "25px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ selectedTabs: "all" });
                      }}
                    >
                      New Request By Product
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${this.state.selectedTabs === "NOTES" && "active"
                        } nav-link`}
                      id="ex1-tab-2"
                      data-mdb-toggle="tab"
                      href="#ex1-tabs-2"
                      role="tab"
                      aria-controls="ex1-tabs-2"
                      aria-selected="false"
                      style={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginRight: "25px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ selectedTabs: "NOTES" });
                      }}
                    >
                      My Requests by Excel file
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${this.state.selectedTabs === "product" && "active"
                        } nav-link`}
                      id="ex1-tab-2"
                      data-mdb-toggle="tab"
                      href="#ex1-tabs-2"
                      role="tab"
                      aria-controls="ex1-tabs-2"
                      aria-selected="false"
                      style={{ paddingLeft: "0", paddingRight: "0" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ selectedTabs: "product" });
                      }}
                    >
                      My Requests by Product
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {this.state.selectedTabs === "all" && (
            <div>{this.renderNewQuotation()}</div>
          )}

          {this.state.selectedTabs === "NOTES" && (
            <div>{this.renderQuotationExcel()}</div>
          )}
          {this.state.selectedTabs === "product" && (
            <div>{this.renderQuotationProduct()}</div>
          )}
        </div>
      </>
    );
  }

  renderNewQuotation() {
    return (
      <div className="row" style={{ marginTop: "-7px" }}>
        <div className="col-md-9">
          <div className="item-list">
            <table className="table bg-transparent quotation">
              <thead>
                <tr>
                  <th
                    colSpan="6"
                    className="p-0 transparent border-0 pb-2 pl-2 pt-5"
                  >
                    {this.props.quotation.quotationItems.length > 0 ? (
                      <></>
                    ) : (
                      <>
                        <i className="las la-store-alt mr-2"></i> Your Quotation
                        Draft is Empty, Go to &nbsp;
                        <Link to="/all-listings">Product listing</Link>&nbsp; to
                        add product
                      </>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {this.props.quotation.quotationItems.map((q, i) => {
                  return (
                    <tr key={i}>
                      <td width="75">
                        {q.pic ? (
                          <img
                            src={q.pic}
                            style={{
                              height: 80,
                              width: 80,
                              objectFit: "cover",
                              objectPosition: "center",
                              borderRadius: "100%",
                            }}
                            className="imageAvatar"
                            alt={q.name}
                          />
                        ) : (
                          <img
                            src={`/assets/img/avatar.png`}
                            style={{
                              height: 80,
                              width: 80,
                              objectFit: "cover",
                              objectPosition: "center",
                              borderRadius: "100%",
                            }}
                            className="imageAvatar"
                            alt="Avatar.jpg"
                          />
                        )}
                      </td>
                      <td style={{ paddingTop: 5 }}>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginBottom: "-10px",
                          }}
                        >
                          {q.name}
                        </p>
                      </td>
                      <td>
                        <div className="d-flex justify-content-between">
                          <button
                            disabled={q.qty < 2}
                            className="btn btn-qty"
                            onClick={(e) => this.decrementQty(i)}
                          >
                            -
                          </button>
                          <span>{q.qty}</span>
                          <button
                            className="btn btn-qty"
                            onClick={(e) => this.incrementQty(i)}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td style={{ textAlign: 'end' }}>
                        <a
                          href="#!"
                          onClick={(e) => this.handleConfirm(e, i, q)}
                        >
                          <i className="las la-times mr-1"></i>
                          Remove
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {this.props.quotation.quotationItems.length > 0 && (
            <NavLink to={"/all-listings"} className="btn btn-primary">
              Add More Product
            </NavLink>
          )}
        </div>
        {this.props.quotation.quotationItems.length > 0 && (
          <div
            className="col-md-3"
            style={{ marginTop: 52, backgroundColor: "#fff", padding: "20px" }}
          >
            <h3 className="mb-2">Notes for Seller</h3>
            <p>
              The seller will receive a Request for Quotation (RFQ){" "}
              <span style={{ color: "rgb(243, 146, 0)", fontStyle: "italic" }}>
              </span>
            </p>
            <div className="form-group">
              <textarea
                rows="5"
                className="form-control"
                id="notes-for-seller"
                placeholder="Enter text"
                onChange={(e) => this.setState({ notes: e.target.value })}
              />
            </div>
            <button
              className="btn btn-block"
              style={{ backgroundColor: "#f39200", color: "white", padding: 0 }}
              onClick={this.sendQuotation}
              disabled={this.state.disableBtn}
            >
              Submit Request for Quotation
            </button>
          </div>
        )}
      </div>
    );
  }

  renderQuotationProduct() {
    const columns1 = [
      {
        title: <span className="tryit">Date</span>,
        field: "",
        render: (lists) => {
          return (
            <span>
              {moment(lists?.createdAt).tz("Asia/Dubai").format("DD/MM/YYYY")}
              <br />
              {moment(lists?.createdAt).tz("Asia/Dubai").format("HH:ss:mm")}
            </span>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Notes</span>,
        field: "",
        render: (lists) => lists?.notes,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Status</span>,
        field: "",
        render: (lists) => {
          return (
            <div
              className={`${this.getStatus(
                lists?.status
              )}-quotation-status-one`}
            >
              {this.getStatus(lists?.status)}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Action</span>,
        field: "",
        render: (lists) => (
          <NavLink to={`/rfq/view/${lists?._id}`}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: "#fff", fontSize: 10 }}
            >
              View Order
            </Button>
          </NavLink>
        ),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },

      {
        title: <span className="tryit">Quotations</span>,
        field: "",
        render: (lists) => {
          return (
            <div>
              {lists?.originalName === null ? (
                <p style={{ fontStyle: "italic" }}>No Quotations Yet!</p>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                >
                  <a
                    href={`${lists?.name}`}
                    download
                  >
                    <GetAppIcon style={{ color: "green", fontSize: 30 }} />
                  </a>
                </div>
              )}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="d-flex mt-2" style={{}}>
              <h4 className="mt-3 mr-auto" style={{ margin: 4 }}>
                RFQ List By Products
              </h4>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    marginBottom: 5,
                    marginRight: "20px",
                  }}
                >
                  <div>
                    <CssTextField
                      id="standard-basic"
                      value={this.state.searchField}
                      onChange={(e) =>
                        this.setState({ searchField: e.target.value })
                      }
                      label="Search Field"
                    />
                  </div>
                  <div>
                    <i className="las la-search la-search search-icon-datatable"></i>
                  </div>
                </div>
              </div>
            </div>

            <MaterialTable
              style={{ zIndex: 0 }}
              title={null}
              data={this.state.quotationListNotes}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <img
                      src="/assets/img/nodata.png"
                      className="datatableimage"
                    />
                  ),
                },
              }}
              columns={columns1}
              isLoading={this.state.loader}
              options={{
                search: false,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 50, 100],
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  closeQuotationModal = () => {
    this.setState({
      modalQuotation: false,
      loadQuotationWithId: null,
      editQuotation: false,
    });
  };

  openQuotation = (index) => {
    const quotation = this.state.quotationExcels[index];
    this.setState({
      modalQuotation: true,
      loadQuotationWithId: quotation._id,
      editQuotation: true,
    });
  };

  handleCancleRFQ(index) {

    const quotation = this.state.quotationExcels[index];
    const id = quotation._id

    axios.delete(`/api/products/delete-upload-order?id=${id}`)
      .then((res) => {
        this.refreshQuotationExcels();
        popUp('Successfully deleted your Quotation Response!')
      }).catch((err) => {
        popUp('Failed to deleted your Quotation Response!')
      })
  }

  renderQuotationExcel() {
    const columns = [
      {
        title: <span className="tryit">S.N</span>,
        field: "",
        render: (rowData) => <p>{rowData.tableData.id + 1}</p>,
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
        width: "10%",
      },
      {
        title: <span className="tryit">Status</span>,
        field: "",
        render: (quotationExcels) => {
          return (
            <div
              className={`${this.getStatus(
                quotationExcels?.status
              )}-quotation-status-one`}
            >
              {this.getStatus(quotationExcels?.status)}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },

      {
        title: <span className="tryit">Action</span>,
        field: "",
        render: (rowData) => (
          <>
            <div className="primary-btn"
              onClick={(e) => this.openQuotation(rowData.tableData.id)}
            >Edit</div>
            <div
              className="primary-btn mt-2"
              style={{ backgroundColor: '#f39200' }}
              onClick={(e) => this.handleCancleRFQ(rowData.tableData.id)}
            >
              Delete</div>
          </>
        ),
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Request Quotation</span>,
        field: "",
        render: (quotationExcels) => {
          return (
            <div>
              {quotationExcels?.name && (
                <div style={{ cursor: "pointer" }}>
                  <a
                    href={`${quotationExcels?.name}`}
                    download
                  >
                    <GetAppIcon style={{ color: "green", fontSize: 30 }} />
                  </a>
                </div>
              )}
            </div>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
      {
        title: <span className="tryit">Response Quotation</span>,
        field: "",
        render: (quotationExcels) => {
          return (
            <>
              {quotationExcels?.status === "Active" ? (
                <div style={{ cursor: "pointer" }}>
                  <a
                    href={`${quotationExcels?.responseFileUrl}`}
                    download
                  >
                    <GetAppIcon style={{ color: "green", fontSize: 30 }} />
                  </a>
                </div>
              ) : (
                <span style={{ fontSize: 12, fontStyle: "italic" }}>
                  No Response Yet!
                </span>
              )}
            </>
          );
        },
        headerStyle: {
          backgroundColor: "#1a0451",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 800,
          fontFamily: "Montserrat",
        },
      },
    ];

    const filteredOrders = this.state.quotationExcels.filter((item) => {
      return (
        item?.status
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?._id
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase()) ||
        item?.seller.details.company_name
          .toLowerCase()
          .includes(this.state?.searchField?.toLowerCase())
      );
    });

    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="d-flex mt-2" style={{}}>
              <h4 className="mt-3 mr-auto" style={{ margin: 4 }}>
                List Quotation by Excel file
              </h4>
              <div style={{ display: "flex" }}>
                <button
                  style={{ margin: 4, marginBottom: 5 }}
                  className="btn btn-primary btn-xs mr-auto"
                  onClick={(e) => this.setState({ modalQuotation: true })}
                >
                  New Request
                </button>
              </div>
            </div>

            <MaterialTable
              title={null}
              style={{ zIndex: 0 }}
              data={filteredOrders}
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <img
                      src="/assets/img/nodata.png"
                      className="datatableimage"
                    />
                  ),
                },
              }}
              columns={columns}
              isLoading={this.state.loader}
              cellDataGetter={() => this.getSerial()}
              rowGetter={({ index }) => this.state.quotationExcels[index]}
              rowCount={this.state.quotationExcels.length}
              options={{
                search: false,
                rowStyle: (x) => {
                  if (x.tableData.id % 2) {
                    return { backgroundColor: "#f2f2f2" };
                  }
                },
                paging: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 50, 100],
              }}
            />
          </div>
        </div>
        <Modal
          visible={this.state.modalQuotation}
          width="800"
          effect="fadeInUp"
        >
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-default mr-auto"
              style={{ fontSize: 16 }}
              onClick={this.closeQuotationModal}
            >
              X
            </button>
          </div>
          <div className="p-3">
            <ExcelQuotation
              loadWithId={this.state.loadQuotationWithId}
              onDataLoaded={() => this.setState({ loadQuotationWithId: null })}
              style={{ padding: "5px" }}
              editQuotation={this.state.editQuotation}
              onFinished={() => {
                this.refreshQuotationExcels();
                this.closeQuotationModal();
              }}
              modalQuotation={this.state.modalQuotation}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    updateQuotationItems: (data) => dispatch(UpdateQuotationItems(data)),
    clearQuotation: (data) => dispatch(ClearQuotation(data)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(
  BuyerQuotation
);
