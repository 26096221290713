import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { ga4Events } from "../../../utils";

export class PageBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyer: false,
      user: false,
      userRole: "",
    };
  }

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("login"));

    if (currentUser) {
      this.setState({ userRole: currentUser.role });
      this.setState({ user: true });
    }
    if (!currentUser || currentUser.role !== "BUYER") {
      this.setState({ buyer: true });
    }
  }

  
  render() {
    const handleNavClick = (menu_item_name) => {
      ga4Events({ action: 'dashboard_menu_click', category: 'User', menu_item_name })
    }
    return (
      <Fragment>
        {this.state.user ? (
          <section className="sub-menu-container ">
            {this.state?.userRole == "BUYER" ? (
              <>
                {/* Buyer dashboard user */}
                <div style={{ padingLeft: "50px", width: "100%" }}>
                  <ul
                    className="sub-menu-bar"
                    style={{ justifyContent: "center" }}
                  >
                    <NavLink to="/my-dashboard" onClick={() => handleNavClick("Dashboard")}>
                      <li>Dashboard</li>
                    </NavLink>
                    <NavLink to="/my-profile" onClick={() => handleNavClick("My Profile")}>
                      <li>My Profile</li>
                    </NavLink>
                    <NavLink to="/messages" onClick={() => handleNavClick("Messages")}>
                      <li> Messages</li>
                    </NavLink>
                    <NavLink to="/checkout" onClick={() => handleNavClick("My Cart")}>
                      <li>My Cart</li>
                    </NavLink>
                    <NavLink to="/my-order" onClick={() => handleNavClick("My Orders")}>
                      <li>My Orders</li>
                    </NavLink>
                    <NavLink to="/quotations" onClick={() => handleNavClick("My Quotations")}>
                      <li>My Quotations</li>
                    </NavLink>
                    <NavLink to="/your-address" onClick={() => handleNavClick("My Addresses")}>
                      <li>My Addresses</li>
                    </NavLink>

                    <NavLink to="/reviews" onClick={() => handleNavClick("My Reviews")}>
                      <li>My Reviews</li>
                    </NavLink>
                  </ul>
                </div>
              </>
            ) : (
              <>
                {/* Seller Dashboard user */}
                <div style={{ width: "100%" }}>
                  <ul className="sub-menu-bar" style={{ padingLeft: "50px" }}>
                    <NavLink to="/my-dashboard" onClick={() => handleNavClick("Dashboard")}>
                      <li>Dashboard</li>
                    </NavLink>
                    <NavLink to="/my-profile" onClick={() => handleNavClick("My Profile")}>
                      <li>My Profile</li>
                    </NavLink>
                    <NavLink to="/my-store" onClick={() => handleNavClick("My Store")}>
                      <li>My Store</li>
                    </NavLink>
                    <NavLink to="/my-listings" onClick={() => handleNavClick("My Listings")}>
                      <li>My Listings</li>
                    </NavLink>
                    <NavLink to="/messages" onClick={() => handleNavClick("Messages")}>
                      <li> Messages</li>
                    </NavLink>
                    <NavLink to="/my-sales" onClick={() => handleNavClick("My Sales")}>
                      <li>My Sales</li>
                    </NavLink>
                    <NavLink to="/seller-deliveries" onClick={() => handleNavClick("Deliveries")}>
                      <li>Deliveries</li>
                    </NavLink>
                    <NavLink to="/payments" onClick={() => handleNavClick("Payments")}>
                      <li>Payments</li>
                    </NavLink>
                    <NavLink to="/quotations" onClick={() => handleNavClick("Quotations")}>
                      <li>Quotations</li>
                    </NavLink>
                    <NavLink to="/your-address" onClick={() => handleNavClick("My Addresses")}>
                      <li>My Addresses</li>
                    </NavLink>

                    <NavLink to="/reviews" onClick={() => handleNavClick("Reviews")}>
                      <li>Reviews</li>
                    </NavLink>
                  </ul>
                </div>
              </>
            )}
          </section>
        ) : null}
      </Fragment>
    );
  }
}
