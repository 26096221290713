import React, { useEffect, useState, useRef, useMemo } from 'react';
import Header from '../../layout/header';
import PreHeader from '../../layout/pre-header';
import { PageBanner } from '../../content/element/page-banner';
import Footer from '../../layout/footer';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { isDateExceedsDays, popUp, ProductImage, getDiscountAmount } from '../../../utils';
import InvoiceTax from '../../content/element/InvoiceTax';
import TaxModal from '../../content/element/InvoiceTax/Modal';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import RemarkModal from './Remark';
import { compose } from 'redux';
import { SetActiveRoom, Initialize } from '../../../Store/action/chatActions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { formatPhoneNumber, calcOrderSummary } from '../../../utils';
import { cbm, changeOrderToGroup } from '../../../utils';
import getPrice from '../../../getPrice';
import Select from 'react-select';
import ConfirmModal from '../../content/element/modal/ConfirmModal';
import ConfirmModalDelete from '../../content/element/modal/confirmation';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EinvoiceModal from '../../content/element/Einvoice/Modal';
import LoadingOverlay from 'react-loading-overlay';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Fragment } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import './style.css';
import ShowDynamicCurrency from '../../common/ShowDynamicCurrency';
import ShowDynamicPrice from '../../common/ShowDynamicPrice';
import ReturnProducts from '../../content/element/modal/ReturnProducts';
import ReturnProductsList from './ReturnProductsList';
import UploadedInvoices from './UploadedInvoices';
import { uploadFileToAssets } from '../../../services/upload';

const selectStyle = {
  valueContainer: () => ({
    textTransform: 'capitalize',
    paddingLeft: '5px',
  }),
};

const Trackingorder = (props) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [updateLog, setUpdateLog] = React.useState({});
  const [stockQuantity, setStockQuantity] = React.useState({});
  const [selectedSeller, setSelectedSeller] = useState('');
  const [returnProduct, setReturnProduct] = useState([]);
  const [isReturnModal, setIsReturnModal] = useState(false);
  const [productname, setProductname] = useState('');
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [calculatedData, setCalculatedData] = useState(null);
  const [discountPerUnit, setDiscountPerUnit] = useState(0);

  const [deliveredQuantity, setDeliveredQuantity] = useState('');
  const [remarkModal, setRemarkModal] = React.useState(false);
  const [isTaxModal, setIsTaxModal] = React.useState(false);
  const [isEinvoiceModal, setIsEinvoiceModal] = React.useState(false);
  const [warehouse, setWarehouse] = React.useState([]);
  const [businessmodalProduct, setbusinessmodalProduct] = React.useState('');
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [selectedLog, setSelectedLog] = React.useState({
    productName: '',
    id: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [passId, setPassId] = useState('');
  const [passevent, setPassevent] = useState('');
  const [passName, setPassName] = useState('');
  const [passData, setPassData] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  function handleCloseModal() {
    setShowModal(false);
  }
  const removeItemFromsellerUploads = async (e, id) => {
    let data = {
      order_id: orderDetail?._id,
      seller_uploads_id: id,
      invoice: passData?.file_url,
      actionBy: currentUser?._id,
      onModel: 'users',
    };
    axios
      .delete(`api/order/delete-seller-uploads`, { data })
      .then((res) => {
        popUp('Successfully deleted upload');
        setShowModal(false);
        fetchMyAPI();
      })
      .catch((err) => {
        console.log(err);
        popUp('Failed to deleted upload');
      });
  };
  const { slug } = useParams();
  const [logs, setLogs] = useState([]);

  async function fetchMyAPI() {
    let res = await axios.get(`/api/order/${slug}`).catch((err) => {
      console.log(err);
    });
  
    setOrderDetail(res?.data?.order);
    setCalculatedData(res?.data?.calculatedData);
    let discountPUAmt = res?.data?.calculatedData?.discountPerUnit || 0;
    setDiscountPerUnit(discountPUAmt);
  }

  async function fetchMyOrderLogs() {
    const orderResponse = await axios.get(`/api/order/get-order-logs/${slug}`);
    setLogs(orderResponse.data?.orderLog);
  }

  const currentUser = JSON.parse(localStorage.getItem('login'));
  const orderProducts = orderDetail?.products?.filter(
    (obj) => obj.seller?._id === currentUser._id
  );

  const groupproducts =
    currentUser.role === 'SELLER'
      ? orderDetail &&
        orderDetail?.products &&
        changeOrderToGroup(orderProducts)
      : orderDetail &&
        orderDetail?.products &&
        changeOrderToGroup(orderDetail?.products);

  const showEinvoice = (seller_id) => {
    setSelectedSeller(seller_id);
    setIsEinvoiceModal(true);
  };

  async function getAllwarehouse() {
    await axios
      .get('/api/warehouse')
      .then((res) => {
        setWarehouse(res.data.warehouse);
      })
      .catch((err) => console.log());
  }

  const [settings, setSettings] = useState(null);
  async function getReturnSettins() {
    await axios
      .get('/api/settings')
      .then((res) => {
        setSettings(res.data);
      })
      .catch((err) => console.log());
  }

  useEffect(() => {
    getAllwarehouse();
  }, []);

  useEffect(() => {
    if (orderDetail?.products) {
      const products = orderDetail?.products?.filter(
        (obj) => obj.seller?._id === currentUser._id
      );
      setSelectedLog({
        productName: products[0]?.listing?.partName,
        id: products[0]?.listing?._id,
      });
    }
    orderDetail?._id && getReturnProduct(orderDetail?._id);
  }, [orderDetail]);

  useEffect(() => {
    if (slug) {
      fetchMyAPI();
      fetchMyOrderLogs();
      getReturnSettins();
    }
  }, [slug]);

  const getReturnProduct = (id) => {
    axios
      .get(`/api/order/return-sales?order_id=${id}`)
      .then((res) => {
        setReturnProduct(res?.data);
      })
      .catch((err) => console.log(err));
  };

  function getLog(log) {
    if (!log?.upadtedStatus) {
      return;
    }
    switch (log?.upadtedStatus) {
      case 'PENDING':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div> Order confirmed by Seller {log?.user?.name}</div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'Preparing':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  Your order is being prepared(Updated by:{' '}
                  {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'Shipped to HUB':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been shipped to consolidation center(Updated
                  by: {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'Out for Delivery':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order is out for delivery(Updated by:{' '}
                  {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'delivered':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been delivered(Updated by:{' '}
                  {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'Received at HUB':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been received at consolidation center(Updated
                  by: {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'Prep.SO':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been prepared to shiped out from consolidation
                  center(Updated by: {log?.updatedBy?.name},{' '}
                  {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'delayed':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been delayed. (Updated by:{' '}
                  {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'issue':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/tick.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has issue. (Updated by: {
                    log?.updatedBy?.name
                  }, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      case 'cancelled':
        return (
          <li key={log?._id}>
            <div style={{ display: 'flex' }}>
              <img
                src='/assets/img/cancel.png'
                style={{ width: '14px', margin: 'auto' }}
              />
              <div style={{ marginLeft: '20px' }}>
                <div>
                  {' '}
                  Your order has been cancelled(Updated by:{' '}
                  {log?.updatedBy?.name}, {log?.updatedBy?.email}).
                </div>
                {log?.remarks && (
                  <div className='order-log-remarks'>{log?.remarks}</div>
                )}
              </div>
            </div>
            <div>
              {moment(log?.date).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm A')}
            </div>
          </li>
        );
      default:
        return null;
    }
  }

  const [selecetedStatus, setSelectedStatus] = useState('');
  const handleRemarkModal = (bol) => {
    if (bol === false) {
      setSelectedStatus('');
    }
    setRemarkModal(bol);
    fetchMyOrderLogs();
    fetchMyAPI();
  };
  const actionStage = (status, businessmodal) => {
    const orderStageArrayPure = [
      'PENDING',
      'Preparing',
      'cancelled',
      'delayed',
      'Out for Delivery',
      'delivered',
      'issue',
    ];

    const orderStageArrayCons = [
      'PENDING',
      'Preparing',
      'delayed',
      'Shipped to HUB',
      'cancelled',
    ];

    const orderStageConsPend = ['Shipped to HUB'];

    let activeStage = [];
    if (businessmodal === 'Pure Play') {
      if (status === 'cancelled') {
        const activeIndex = orderStageArrayPure.indexOf(status) + 1;
        activeStage = orderStageArrayPure;
        activeStage.length = activeIndex + 1;
        return activeStage;
      } else if (status === 'issue') {
        return ['PENDING', 'Preparing', 'issue'];
      } else if (status === 'delayed') {
        return [];
      } else {
        const activeIndex = orderStageArrayPure.indexOf(status);
        activeStage = orderStageArrayPure;
        activeStage.length = activeIndex + 1;
        return activeStage;
      }
    }
    if (businessmodal === 'Consolidation') {
      if (status === 'PENDING') {
        return orderStageConsPend;
      } else if (status === 'cancelled') {
        const activeIndex = orderStageArrayCons.indexOf(status) + 1;
        activeStage = orderStageArrayCons;
        activeStage.length = activeIndex + 1;
        return activeStage;
      } else if (status === 'delayed') {
        return [];
      } else {
        const activeIndex = orderStageArrayCons.indexOf(status);
        activeStage = orderStageArrayCons;
        activeStage.length = activeIndex + 1;
        return activeStage;
      }
    }
  };

  const getAction = (listing, businessmodal) => {
    const orderStagePure = [
      'PENDING',
      'Preparing',
      'cancelled',
      'delayed',
      'Out for Delivery',
      'delivered',
      'issue',
    ];
    const orderStageCons = [
      'PENDING',
      'Preparing',
      'delayed',
      'Shipped to HUB',
      'cancelled',
    ];

    const statusOptionPure = orderStagePure.map((stage) => {
      return {
        label: stage?.charAt(0).toUpperCase() + stage.toLowerCase().slice(1),
        value: stage,
        isdisabled: actionStage(listing?.status, businessmodal).includes(stage),
      };
    });

    const statusOptionCons = orderStageCons.map((stage) => {
      let splittedStage = stage?.split('.');
      let label = '';
      if (splittedStage.length > 1) {
        label =
          splittedStage[0].charAt(0).toUpperCase() +
          splittedStage[0].toLowerCase().slice(1) +
          '.' +
          splittedStage[1];
      } else {
        label =
          splittedStage[0].charAt(0).toUpperCase() +
          splittedStage[0].toLowerCase().slice(1);
      }
      return {
        label: label,
        value: stage,
        isdisabled: actionStage(listing?.status, businessmodal).includes(stage),
      };
    });

    return (
      <Select
        className='react-select'
        placeholder={listing?.status}
        options={
          businessmodal && businessmodal === 'Pure Play'
            ? statusOptionPure
            : businessmodal === 'Consolidation' && statusOptionCons
        }
        menuPortalTarget={document.body}
        value={selecetedStatus}
        option
        styles={selectStyle}
        isDisabled={
          listing?.status === 'cancelled' ||
          listing?.status === 'delivered' ||
          listing?.status === 'Received at HUB' ||
          listing?.status === 'Prep.SO' ||
          listing?.status === 'Shipped to HUB' ||
          (businessmodal === 'Consolidation' &&
            listing?.status === 'Out for Delivery') ||
          (businessmodal === 'Consolidation' && listing?.status === 'issue')
        }
        // styles={{ color: "black" }}
        isOptionDisabled={(option) => option.isdisabled}
        onChange={(selectedOption) => {
          if (selectedOption.value) {
            const cancelledAmount =
              listing?.delivery_option?.price * listing?.quantity -
              listing?.shipping_price_individual;
            const newQuantity = listing?.quantity;
            const data = {
              status: selectedOption.value,
              id: orderDetail?._id,
              previousState: listing?.status,
              productId: listing?.delivery_option?._id,
              orderDate: orderDetail?.order_date,
              productDetails: listing?.listing,
              warehouseId: listing?.warehouse,
            };
            const stockquantity = {};
            if (selectedOption.value === 'cancelled') {
              data.cancelledAmount = cancelledAmount;
              stockquantity.newQuantity = newQuantity;
              stockquantity.id = listing?.delivery_option?._id;
            }
            setStockQuantity(stockquantity);
            setUpdateLog(data);
            setRemarkModal(true);
            setbusinessmodalProduct(businessmodal);
            setSelectedStatus(selectedOption.value);
            // setSelectedLog(listing?.delivery_option?._id);
          }
        }}
      />
    );
  };

  const noOfItems = (groupproducts) => {
    let total = 0;
    groupproducts?.map((gp) => {
      total += gp.data.length;
    });
    return total;
  };

  const handleCancel = async (data, quantity) => {
    setLoading(true);
    let item = data.delivery_option;
    let unitPrice = (item?.priceExclVat) || 0;

    const discountAmount = getDiscountAmount(unitPrice, quantity, item, discountPerUnit, orderDetail?.coupon);
    const vatCalc = ((unitPrice * quantity) - discountAmount) * data?.delivery_option?.vatPercent / 100;
    const cancelledAmount =
    (unitPrice * quantity) + vatCalc - discountAmount - data?.shipping_price_individual;
    // const newQuantity = quantity;
  
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    // if(data.delivery_option?.business_model == 'Consolidation' && data.status == 'PENDING'){
    //   popUp("Sorry! You can't cancel Pending SKU of Consolidation Model");
    //   setLoading(false);
    // }else{
      try {
        await axios.patch(`/api/order/update-order-by-product`, {
          id: orderDetail?._id,
          status: 'cancelled',
          listing_id: data?.listing?._id,
          remarks: 'Order has been cancelled',
          cancelledAmount: cancelledAmount,
          updatedBy: currentUser._id,
          onModel: 'users',
        });
        popUp('Your order has been cancelled');
        await fetchMyAPI();

        setSelectedLog({
          id: data?.listing?._id,
          productName: data?.listing?.partName,
        });

        // updatestock(newQuantity, data?.listing?._id);
        fetchMyOrderLogs();
      } catch (error) {
        popUp(error?.response?.data?.message || 'Server Error');
        console.log('err', error);
      } finally {
        setLoading(false);
      }
    // }
  };
  const [uploadFile, setUploadFile] = useState('');
  const [check, setCheck] = useState(false);

  const addSelectedFile = async (file) => {
    try {
      setLoadingButton(true);
      if (file) {
        setCheck(true);

        const uploadedFile = await uploadFileToAssets({
          file,
          type: 'uploads',
        });

        setUploadFile(uploadedFile?._id || '');
      }
    } catch (error) {
      popUp(error?.message || 'Error while uploading seller invoice');
    } finally {
      setLoadingButton(false);
    }
  };
  function clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  const handleSubmitFile = async () => {
    setLoadingButton(true);
    let data = {
      seller_id: currentUser._id,
      file_id: uploadFile,
      list: selectedItem?.map((value) => {
        return {
          _id: value.listing?._id,
          partName: value?.listing?.partName,
          partSKU: value?.listing?.partSKU,
        };
      }),
      log: {
        order_id: orderDetail?._id,
        invoice: uploadFile[0],
        actionBy: currentUser?._id,
        onModel: 'users',
      },
    };

    let url = uploadFile[0];
    await axios
      .patch(`/api/order/seller-uploads?order_id=${orderDetail?._id}`, data)
      .then((res) => {
        popUp('Successfully uploaded invoice!');
        setCheck(false);
        setUploadFile('');
        clearFileInput(document.getElementById('myFileInput'));
        fetchMyOrderLogs();
        fetchMyAPI();
        setSelectedItem([]);
        // setLoading(false)
      })
      .catch((error) => {
        popUp(error?.response?.data?.message || 'Failed to upload invoice!');
        axios
          .delete('/api/imageupload/', { data: { url } })
          .then((res) => {})
          .catch((err) => console.log(err));
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const handleCloseReturnModel = () => {
    setIsReturnModal(false);
  };

  const handleCheckedProduct = (name, listing) => {
    let qty = returnProduct
      .filter((item) => item.list?._id === listing?.delivery_option?._id)
      .reduce((a, b) => a + b.quantity, 0);

    setDeliveredQuantity(listing?.quantity - qty);
    setList(listing);
    setProductname(name);
    axios
      .get(`/api/order/return-sales?order_id=${orderDetail?._id}`)
      .then((res) => {
        let findproducts = res?.data?.filter(
          (item) => item?.list?._id === listing?.listing?._id
        );
        if (findproducts?.length > 0) {
          let getquantity = findproducts.reduce(
            (accumulator, current) => accumulator + current.quantity,
            0
          );
          if (listing?.quantity > getquantity) {
            setIsReturnModal(true);
          } else {
            popUp(
              'You cannot return product now, because the quantity exceeded!'
            );
          }
        } else {
          setIsReturnModal(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleselectedItems = (e, listing) => {
    if (selectedItem?.includes(listing)) {
      setSelectedItem(selectedItem.filter((value) => value != listing));
    } else {
      setSelectedItem([...selectedItem, listing]);
    }
  };

  const showRefundAmount = returnProduct.some(
    (item) => item.status === 'quality_check_pass'
  );
  //end

  const canReturnProduct = ({ listing }) => {
    const noofDays = settings?.setting?.defaultReturnPolicyDay?.day;
    let delivered_date = moment(
      listing?.delivered_date
        ? listing?.delivered_date
        : listing?.delivery_date,
      'YYYY-MM-DD'
    );
    let today = moment().startOf('day');
    let diff = moment.duration(today.diff(delivered_date)).asDays();
    return diff < noofDays;
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner text={`Loading...`}>
        <PageBanner />
        <RemarkModal
          remarkModal={remarkModal}
          handleRemarkModal={handleRemarkModal}
          data={updateLog}
          stockQuantity={stockQuantity}
          fetchMyOrderLogs={fetchMyOrderLogs}
          setSelectedLog={setSelectedLog}
          warehouse={warehouse}
          businessmodal={businessmodalProduct}
        />
        <ConfirmModalDelete
          showModal={showModal}
          onClose={handleCloseModal}
          eventFunc={removeItemFromsellerUploads}
          id={passId}
          e={passevent}
          name={passName}
        />
        <ConfirmModal
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <TaxModal
          isTaxModal={isTaxModal}
          setIsTaxModal={setIsTaxModal}
          data={orderDetail}
          groupproducts={groupproducts}
        />
        <EinvoiceModal
          isEinvoiceModal={isEinvoiceModal}
          setIsEinvoiceModal={setIsEinvoiceModal}
          calculatedData={calculatedData}
          data={orderDetail}
          groupproducts={
            groupproducts?.filter((gp) => gp.sellerId == selectedSeller) || []
          }
          returnProduct={
            returnProduct?.filter((r) => {
              if (
                r?.list?.user == selectedSeller ||
                r?.list?.user?._id == selectedSeller
              ) {
                return r;
              }
            }) || []
          }
        />
        <ReturnProducts
          isReturnModal={isReturnModal}
          setIsReturnModal={setIsReturnModal}
          getReturnProduct={getReturnProduct}
          fetchMyAPI={fetchMyAPI}
          productname={productname}
          list={list}
          order_id={orderDetail?._id}
          originalQuantity={deliveredQuantity}
          handleCloseReturnModel={handleCloseReturnModel}
        />

        {currentUser?.role === 'SELLER' && (
          <div
            className='mt-3'
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          ></div>
        )}

        <div className='container mb-5'>
          <div className='review-title-header'>
            <div className='d-flex'>
              <div className='review-title-meta'>
                {' '}
                Order Details:{' '}
                <span style={{ fontSize: '18px', color: '#7a82a6' }}>
                  {orderDetail?.order_id}
                </span>
              </div>
            </div>
            <NavLink to='/my-order' className='order-back-to-home-btn'>
              <ArrowBackIosIcon
                style={{
                  marginRight: '5px',
                  fontSize: 16,
                  alignItems: 'center',
                  marginTop: 1,
                }}
              />
              Back to Home
            </NavLink>
          </div>
          <div className='row'>
            <div
              className='col-md-8  mb-4'
              style={{ padding: '20px', border: '1px solid #F4F6F8' }}
            >
              <div className='tracking-meta'>
                <div className='meta-title'>
                  <div className='meta-title-heading'>
                    Your package is{' '}
                    <strong>{orderDetail?.status?.toUpperCase()}</strong>
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    We will sent to :{' '}
                    <span style={{ fontSize: '14px', fontWeight: 600 }}>
                      {orderDetail?.delivery_address?.address}{' '}
                      {orderDetail?.delivery_address?.postcode}
                      {orderDetail?.delivery_address?.location
                        ? orderDetail?.delivery_address?.location
                        : null}
                      {orderDetail?.delivery_address?.region ? <>,</> : null}
                      {orderDetail?.delivery_address?.region
                        ? orderDetail?.delivery_address?.region
                        : null}
                      {orderDetail?.delivery_address?.state ? <>,</> : null}
                      {orderDetail?.delivery_address?.state
                        ? orderDetail?.delivery_address?.state
                        : null}
                      {orderDetail?.delivery_address?.ship_to_site_code ? (
                        <>,</>
                      ) : null}
                      {orderDetail?.delivery_address?.ship_to_site_code
                        ? orderDetail?.delivery_address?.ship_to_site_code
                        : null}
                    </span>
                  </div>
                </div>

                <div>
                  <div
                    className='meta-order-id-container'
                    style={{ padding: '10px' }}
                  >
                    <div
                      style={{
                        color: '#161D25',
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '11px',
                      }}
                    >
                      Order ID :
                    </div>
                    <div
                      style={{
                        color: '5A7184',
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      {orderDetail?.order_id}
                    </div>
                  </div>
                  {/* {showInvoice && <>
                {(currentUser.role === "SELLER" && orderDetail?.seller_uploads?.length>0) ? (
                  <>
                  <div
                    className="primary-btn"
                    style={{ margin: " 5px 0 0 0" }}
                    onClick={() => setIsTaxModal(true)}
                  >
                    Invoice tax
                  </div>
                  </>
                ) :null}
                </>} */}
                </div>
              </div>

              {selectedItem.length > 0 && currentUser?.role === 'SELLER' && (
                <div>
                  <div className='d-flex mb-3'>
                    <input
                      type='file'
                      id='myFileInput'
                      style={{ display: 'none' }}
                      // disabled={orderDetail?.seller_uploads?.length>0}
                      onChange={(e) => addSelectedFile(e.target.files[0])}
                      disabled={loadingButton}
                    />

                    <label htmlFor='myFileInput'>
                      <Button
                        variant='contained'
                        color='primary'
                        component='span'
                        size='small'
                        disabled={loadingButton}
                        style={{
                          cursor: loadingButton ? 'no-drop' : 'pointer',
                        }}
                      >
                        Upload Invoice
                      </Button>
                    </label>
                  </div>
                  {check && (
                    <button
                      className='primary-btn'
                      style={{
                        margin: 2,
                        cursor: loadingButton ? 'no-drop' : 'pointer',
                      }}
                      onClick={handleSubmitFile}
                      disabled={loadingButton}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}

              <div
                style={{
                  marginTop: '67px',
                }}
              >
                <div className='shipping-step'>
                  {groupproducts &&
                    groupproducts.length > 0 &&
                    groupproducts.map((item, index) => {
                      const isAllDelivered = item.data.every(
                        (p) =>
                          p.status == 'delivered' || p.status == 'cancelled'
                      );
                      return (
                        <>
                          <div style={{ textAlign: 'right' }}>
                            {currentUser.role === 'BUYER' && isAllDelivered && (
                              <div
                                className='primary-btn'
                                style={{
                                  position: 'static',
                                  right: '35px',
                                  width: '125px',
                                  top: '12px',
                                }}
                                onClick={() => showEinvoice(item.sellerId)}
                              >
                                Show E-invoice
                              </div>
                            )}
                          </div>
                          <table
                            key={index}
                            id='directorist-checkout-table'
                            className='table bg-transparent checkout-table order-details-table'
                          >
                            <thead>
                              <tr style={{ position: 'relative' }}>
                                <th colSpan='12'>{item?.aliasName}</th>
                              </tr>
                            </thead>

                            <tbody className='bg-white'>
                              <tr>
                                <td
                                  colSpan='3'
                                  className='order-detail-table-title'
                                >
                                  {currentUser?.role === 'SELLER' && (
                                    <Checkbox
                                      checked={
                                        selectedItem?.length ===
                                        item?.data?.length
                                      }
                                      onChange={(e) => {
                                        let allSelected =
                                          selectedItem?.length ===
                                          item?.data?.length;
                                        if (allSelected) {
                                          setSelectedItem([]);
                                        } else {
                                          setSelectedItem([
                                            ...(item?.data || []),
                                          ]);
                                        }
                                      }}
                                      color='primary'
                                      style={{
                                        marginBottom: '6px',
                                        marginRight: '22px',
                                      }}
                                    />
                                  )}
                                  Name
                                </td>
                                <td
                                  className='order-detail-table-title'
                                  width='80px'
                                >
                                  Price(Excl. VAT)
                                </td>
                                <td
                                  className='order-detail-table-title'
                                  style={{ textAlign: 'center' }}
                                >
                                  Quantity
                                </td>

                                <td
                                  className='order-detail-table-title'
                                  style={{ textAlign: 'center' }}
                                >
                                  Status
                                </td>

                                <td
                                  className='order-detail-table-title'
                                  style={{ textAlign: 'center' }}
                                >
                                  Delivery Date
                                </td>

                                <td
                                  style={{ textAlign: 'center' }}
                                  className='order-detail-table-title'
                                >
                                  Action
                                </td>
                              </tr>
                              {item?.data.map((listing, i) => {
                                const splitstatus = listing.status.split('.');
                                const newstatus = splitstatus[0];

                                const deliverydateofres =
                                  listing?.delivery_date &&
                                  listing?.delivery_date.split(' ');
                                var duration1 = moment.duration(
                                  moment().diff(deliverydateofres?.[0])
                                );
                                var days = duration1.asDays();
                                const isReturnAvailable = isDateExceedsDays(
                                  listing?.delivered_date
                                    ? listing?.delivered_date
                                    : listing?.delivery_date,
                                  settings?.setting?.defaultReturnPolicyDay?.day
                                );
                                const noofDays =
                                  settings?.setting?.defaultReturnPolicyDay
                                    ?.day;
                                return (
                                  <tr
                                    key={i}
                                    className={
                                      currentUser?.role &&
                                      listing?.status !== 'delivered' &&
                                      days &&
                                      days > 0
                                        ? 'set-background'
                                        : ''
                                    }
                                  >
                                    <td>
                                      {currentUser?.role === 'SELLER' && (
                                        <Checkbox
                                          checked={selectedItem?.includes(
                                            listing
                                          )}
                                          name={listing?._id}
                                          onChange={(e) =>
                                            handleselectedItems(e, listing)
                                          }
                                          color='primary'
                                        />
                                      )}
                                    </td>
                                    <td width='75'>
                                      <ProductImage
                                        avatar={
                                          listing?.delivery_option
                                            ?.imageArray?.[0] ||
                                          listing?.delivery_option?.pic
                                        }
                                        alt='p_name'
                                        style={{
                                          width: '75px',
                                          height: '75px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </td>
                                    <td width='220'>
                                      <span
                                        className={`${
                                          currentUser.role === 'BUYER' &&
                                          listing?.status === 'cancelled' &&
                                          'cancelled-product'
                                        }`}
                                      >
                                        {' '}
                                        {listing?.delivery_option?.partName}
                                      </span>
                                      <br />
                                      <small
                                        className={`${
                                          currentUser.role === 'BUYER' &&
                                          listing?.status === 'cancelled' &&
                                          'cancelled-product'
                                        }`}
                                        style={{
                                          color: 'GrayText',
                                          whiteSpace: 'pre-wrap',
                                        }}
                                      >
                                        {' '}
                                        {listing?.delivery_option?.partSKU}
                                      </small>
                                    </td>
                                    <td width='80px'>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          fontWeight: '500',
                                          color: 'black',
                                          marginRight: '3px',
                                        }}
                                      >
                                        <ShowDynamicCurrency />
                                      </span>{' '}
                                      <span
                                        className={`${
                                          currentUser.role === 'SELLER' &&
                                          listing?.status === 'cancelled' &&
                                          'cancelled-product'
                                        }`}
                                      >
                                        <ShowDynamicPrice
                                          price={listing?.delivery_option?.priceExclVat?.toFixed(
                                            2
                                          )}
                                          hideExc
                                        />
                                      </span>
                                    </td>

                                    <td
                                      width='150'
                                      style={{ textAlign: 'center' }}
                                    >
                                      {listing?.quantity}
                                    </td>
                                    <td width='190'>
                                      {currentUser.role === 'SELLER' ? (
                                        <div>
                                          <div>
                                            <div
                                              className={`buyer-order-status ${listing?.status
                                                ?.toLowerCase()
                                                .replaceAll(' ', '')} `}
                                            >
                                              {listing?.status}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div
                                            className={`buyer-order-status ${
                                              currentUser.role === 'BUYER' &&
                                              listing?.status
                                                ?.toLowerCase()
                                                .replaceAll(' ', '')
                                            } `}
                                          >
                                            {listing?.status}
                                          </div>
                                        </div>
                                      )}
                                    </td>

                                    <td
                                      width='150'
                                      style={{ textAlign: 'center' }}
                                    >
                                      {listing?.delivery_date
                                        ? listing?.delivery_date
                                        : '-'}
                                    </td>

                                    <td style={{ textAlign: 'center' }}>
                                      <div
                                        className='btn view-log-btn'
                                        onClick={() => {
                                          setSelectedLog({
                                            id: listing?.delivery_option?._id,
                                            productName:
                                              listing?.delivery_option
                                                ?.partName,
                                          });
                                        }}
                                      >
                                        View Log
                                      </div>
                                      {currentUser.role === 'BUYER' &&
                                        listing?.status === 'PENDING' && (
                                          <>
                                            {listing?.status ===
                                            'Shipped to HUB' ? (
                                              <div
                                                className='btn cancel-log-btn'
                                                disabled={true}
                                                style={{
                                                  backgroundColor: 'grey',
                                                  cursor: 'not-allowed',
                                                }}
                                              >
                                                Cancel
                                              </div>
                                            ) : (
                                              <> { (listing?.delivery_option?.business_model !== 'Consolidation' || (listing?.delivery_option?.business_model === 'Consolidation'&& listing?.status !== 'PENDING')) &&
                                              <div
                                                className='btn cancel-log-btn'
                                                onClick={() => {
                                                  const filtereOrder = orderDetail?.products?.filter(item => item.status !== 'cancelled')                                          
                                                  console.log('orders ....' +JSON.stringify(filtereOrder))
                                                  console.log('filtereOrder ....' +filtereOrder.length)
                                                  if(((calculatedData?.subTotalExclVAT - calculatedData?.discount - (listing?.listing?.priceExclVat-listing?.delivery_option?.unit_discount)*listing?.quantity) < orderDetail?.MOVShippingFee?.MOV) && (calculatedData?.shippingPrice !== orderDetail?.MOVShippingFee?.shipping*1.05) && filtereOrder?.length>1){

                                                  let shippingCanBeDeducted = orderDetail?.MOVShippingFee?.shipping*1.05
                                                    setConfirmDialog({
                                                      isOpen: true,
                                                      title:
                                                        `Are you sure you want to cancel this SKU? A shipping fee of ${shippingCanBeDeducted} will be deducted from your refund.`,
                                                      subTitle:
                                                        '  This process cannot be undone.',
                                                      onConfirm: () =>
                                                        handleCancel(
                                                          listing,
                                                          listing?.quantity
                                                        ),
                                                    })
                                                  }else{
                                                     console.log('shippingPrice NO....' + calculatedData?.shippingPrice)
                                                    setConfirmDialog({
                                                      isOpen: true,
                                                      title:
                                                        ' Do you really want to delete?',
                                                      subTitle:
                                                        '  This process cannot be undone.',
                                                      onConfirm: () =>
                                                        handleCancel(
                                                          listing,
                                                          listing?.quantity
                                                        ),
                                                    });
                                                  }
                                                }}
                                              >
                                                Cancel
                                              </div>
                                              }
                                            </>
                                            )}
                                          </>
                                        )}
                                    {listing?.status === 'delivered' &&
                                        canReturnProduct({
                                          listing,
                                        }) && (
                                          <div
                                            className='btn return-log-btn'
                                            onClick={() =>
                                              handleCheckedProduct(
                                                listing?.delivery_option
                                                  ?.partName,
                                                listing
                                              )
                                            }
                                          >
                                            Return
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>{' '}
                        </>
                      );
                    })}
                </div>
              </div>
              <hr style={{ marginTop: '35px', marginBottom: '37px' }} />

              <div className='track-detail-container'>
                <div className='track-detail-title'>
                  Track details: {selectedLog.productName}
                </div>
                <ul className='order-tracking-status'>
                  {logs
                    ?.filter((log) => log?.listing?._id === selectedLog?.id)
                    .reverse()
                    .map((log) => {
                      return getLog(log);
                    })}
                  <li>
                    <div>
                      <img
                        src='/assets/img/tick.png'
                        style={{ width: '14px', marginRight: '20px' }}
                      />
                      Your order has been received
                    </div>
                    <div>
                      {moment(orderDetail?.order_date)
                        .tz('Asia/Dubai')
                        .format('DD/MM/YYYY hh:mm A')}
                    </div>
                  </li>
                </ul>
              </div>
              {returnProduct?.length > 0 && (
                <div className='track-detail-container'>
                  <div className='track-detail-title'>Return Sales</div>
                  <ReturnProductsList
                    order_id={orderDetail?._id}
                    returnProduct={returnProduct}
                  />
                </div>
              )}

              <UploadedInvoices
                orderDetail={orderDetail}
                currentUser={currentUser}
                setShowModal={setShowModal}
                setPassId={setPassId}
                setPassData={setPassData}
              />
            </div>
            <div className='col-md-4  '>
              <div
                className='package-container'
                style={{ border: '1px solid #F4F6F8' }}
              >
                <div className='package-container-title'>Delivery Info</div>
                <div></div>
              </div>
              <div
                className='price-detail-container'
                style={{ border: '1px solid #F4F6F8' }}
              >
                <div
                  className='price-detail-meta'
                  style={{
                    width: '100%',

                    borderBottom: '1px solid #F4F6F8',
                  }}
                >
                  <div
                    className='price-detail-container seller-info-container '
                    style={{ paddingTop: '15px' }}
                  >
                    <div className='price-detail-container-title'>
                      Buyer Name
                    </div>
                    <div>{orderDetail?.user?.details?.garage_name}</div>
                  </div>

                  <div className='price-detail-container seller-info-container'>
                    <div className='price-detail-container-title'>
                      Buyer Contact
                    </div>
                    <div>
                      {orderDetail?.user?.phone &&
                        formatPhoneNumber(orderDetail?.user?.phone)}
                    </div>
                  </div>
                  {/* <div
                  className="price-detail-container seller-info-container "
                >
                  <div className="price-detail-container-title">Garage Name</div>
                  <div>{orderDetail?.user?.details?.garage_name}</div>
                </div> */}
                  <div className='price-detail-container seller-info-container '>
                    <div className='price-detail-container-title'>
                      Shipping Contact
                    </div>
                    <div>{orderDetail?.delivery_address?.contact}</div>
                  </div>

                  <div
                    style={{ padding: '2px 20px' }}
                    className='seller-info-container'
                  >
                    {/* <div className="price-detail-container">
                    <div className="price-detail-container-title">
                      Delivery Address
                    </div>
                    <div>
                      {orderDetail?.delivery_address?.address}{" "}
                      {orderDetail?.delivery_address?.postcode}{" "}
                      {orderDetail?.delivery_address?.location}
                    </div>
                  </div>
                   */}
                    <div className='price-detail-container '>
                      <div className='price-detail-container-title'>
                        Order Date
                      </div>
                      <div>
                        {moment(orderDetail?.order_date)
                          .tz('Asia/Dubai')
                          .format('DD/MM/YYYY hh:mm A')}
                      </div>
                    </div>
                    {/* <div className="price-detail-container">
                    <div className="price-detail-container-title">
                      Delivery Date
                    </div>
                    <div>{moment(deliverydate).tz('Asia/Dubai').format("DD/MM/YYYY")}</div>
                  </div> */}

                    <div className='price-detail-container'>
                      <div className='price-detail-container-title'>
                        Number of Items
                      </div>
                      <div>{noOfItems(groupproducts)}</div>
                    </div>

                    <div className='price-detail-container'>
                      <div className='price-detail-container-title'>
                        <strong style={{ color: '#161d25' }}>Payment</strong>
                      </div>
                    </div>

                    <div className='price-detail-container'>
                      <div className='price-detail-container-title'>Type</div>
                      <div>
                        {orderDetail?.isPaidOnline &&
                        orderDetail?.online_payment ? (
                          <>
                            <span>
                              {orderDetail?.walletAmount > 0 && (
                                <span>Wallet Payment ,</span>
                              )}{' '}
                              Card Payment <br />
                            </span>
                          </>
                        ) : orderDetail?.walletAmount ? (
                          <>
                            {orderDetail?.walletAmount > 0 && (
                              <span>
                                Wallet Payment
                                {orderDetail?.walletAmount !==
                                  orderDetail?.unchanged_total_price && (
                                  <span>,</span>
                                )}
                              </span>
                            )}
                            {orderDetail?.walletAmount !==
                              orderDetail?.unchanged_total_price && (
                              <span> Cash On Delivery</span>
                            )}
                          </>
                        ) : (
                          <span>Cash On Delivery</span>
                        )}
                      </div>
                    </div>
                    {orderDetail?.isPaidOnline && (
                      <>
                        <div className='price-detail-container'>
                          <div className='price-detail-container-title'>
                            Card Holder Name
                          </div>
                          <div>
                            {orderDetail?.online_payment?.details?.paymentMethod
                              ?.cardholderName ??
                              orderDetail?.online_payment?.details?._embedded
                                ?.payment?.[0].paymentMethod?.cardholderName}
                          </div>
                          <div className='price-detail-container'>
                            <div className='price-detail-container-title'>
                              {orderDetail?.online_payment?.details
                                ?.paymentMethod?.name ??
                                orderDetail?.online_payment?.details?._embedded
                                  ?.payment?.[0].paymentMethod?.name}{' '}
                              Card
                            </div>
                            <div>
                              {orderDetail?.online_payment?.details
                                ?.paymentMethod?.pan ??
                                orderDetail?.online_payment?.details?._embedded
                                  ?.payment?.[0].paymentMethod?.pan}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {orderDetail?.order_note && (
                <div style={{ border: '1px solid #F4F6F8', padding: '20px' }}>
                  <>
                    {' '}
                    <div className='package-note'>Notes</div>
                    <div className='tracking-note-meta'>
                      {orderDetail?.order_note}
                    </div>
                  </>
                </div>
              )}
              <div
                className='price-detail-container'
                style={{ border: '1px solid #F4F6F8', borderTop: 0 }}
              >
                <div
                  className='price-detail-meta'
                  style={{
                    width: '100%',
                    marginTop: '15px',
                    padding: '15px 20px',
                    borderBottom: '1px solid #F4F6F8',
                  }}
                >
                  <div className='price-detail-container'>
                    <div className='price-detail-container-title'>
                      Subtotal (Excl. VAT)
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: 10,
                          fontWeight: '800',
                          color: '#000000',
                        }}
                      >
                        <ShowDynamicCurrency /> &nbsp;
                      </span>

                      <ShowDynamicPrice
                        price={
                          calculatedData?.subTotalExclVAT
                            ? calculatedData?.subTotalExclVAT?.toFixed(2)
                            : 0
                        }
                        hideExc
                      />
                    </div>
                  </div>

                  <div
                    className='price-detail-container'
                    style={{ borderBottom: '1px solid #F4F6F8' }}
                  >
                    <div className='price-detail-container-title'>Discount</div>
                    <div>
                      <span
                        style={{
                          fontSize: 10,
                          fontWeight: '800',
                          color: '#000000',
                        }}
                      >
                        <ShowDynamicCurrency /> &nbsp;
                      </span>
                      <ShowDynamicPrice
                        price={
                          calculatedData?.discount
                            ? calculatedData?.discount.toFixed(2)
                            : 0
                        }
                        hideExc
                      />
                    </div>
                  </div>

                  {currentUser.role === 'BUYER' && (
                    <div className='price-detail-container'>
                      <div className='price-detail-container-title'>
                        Shipping (Incl. VAT)
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            fontWeight: '800',
                            color: '#000000',
                          }}
                        >
                          <ShowDynamicCurrency /> &nbsp;
                        </span>
                        
                        <ShowDynamicPrice

                          price={
                            calculatedData?.shippingPrice
                              ? calculatedData?.shippingPrice?.toFixed(
                                  2
                                )
                              : 0
                          }
                          hideExc
                        />
                      </div>
                    </div>
                  )}

                  <div className='price-detail-container'>
                    <div className='price-detail-container-title'>Tax</div>
                    <div>
                      <span className='currency-style'>
                        {' '}
                        <ShowDynamicCurrency />
                      </span>{' '}
                      <ShowDynamicPrice
                        price={
                          calculatedData?.tax
                            ? calculatedData?.tax?.toFixed(2)
                            : 0
                        }
                        hideExc
                      />
                    </div>
                  </div>

                  <div className='price-detail-container'>
                    <div className='price-detail-container-title'>
                      From Wallet
                    </div>
                    <div>
                      <span className='currency-style'>
                        {' '}
                        <ShowDynamicCurrency />
                      </span>{' '}
                      <ShowDynamicPrice
                        price={orderDetail?.walletAmount}
                        hideExc
                      />
                    </div>
                  </div>

                  <div
                    className='price-detail-container'
                    style={{ marginTop: '10px' }}
                  >
                    <div className='price-detail-container-total'>
                      Total Price
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: 10,
                          fontWeight: '800',
                          color: '#000000',
                        }}
                      >
                        <ShowDynamicCurrency /> &nbsp;
                      </span>
                      <ShowDynamicPrice
                        price={
                          calculatedData?.total
                            ? calculatedData?.total?.toFixed(2)
                            : 0
                        }
                        hideExc
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-2'>
                <NavLink
                  to='/contact'
                  className='order-back-to-home-btn-order-detail'
                >
                  <span className='mr-2' style={{ color: '#fff' }}>
                    NEED HELP? ASK OUR SUPPORT
                  </span>
                  <img
                    src='/assets/img/support-icon.svg'
                    height={20}
                    alt='support'
                  />
                </NavLink>
              </div>

              {showRefundAmount ? (
                <div className='mt-2'>
                  <div
                    className='package-container'
                    style={{ border: '1px solid #F4F6F8' }}
                  >
                    <div className='package-container-title'>Refund Info</div>
                  </div>
                  <div
                    className='price-detail-meta'
                    style={{
                      width: '100%',
                      marginTop: '15px',
                      padding: '15px 20px',
                      borderBottom: '1px solid #F4F6F8',
                      backgroundColor: '#f9fafb',
                    }}
                  >
                    <div className='price-detail-container '>
                      <div className='price-detail-container-title'>
                        Total Order Amount
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            fontWeight: '800',
                            color: '#000000',
                          }}
                        >
                          <ShowDynamicCurrency /> &nbsp;
                        </span>
                        <ShowDynamicPrice
                          price={
                            calculatedData?.totalOrderAmount
                              ? calculatedData?.totalOrderAmount?.toFixed(2)
                              : 0
                          }
                          hideExc
                        />
                      </div>
                    </div>

                    <div
                      className='price-detail-container'
                      style={{ borderBottom: '1px solid #F4F6F8' }}
                    >
                      <div className='price-detail-container-title'>
                        Remaining Order Amount
                      </div>
                      <div>
                        <span className='currency-style'>
                          {' '}
                          <ShowDynamicCurrency />
                        </span>{' '}
                        <ShowDynamicPrice
                          price={
                            calculatedData?.remainingOrderAmount
                              ? calculatedData?.remainingOrderAmount?.toFixed(2)
                              : 0
                          }
                          hideExc
                        />
                      </div>
                    </div>

                    <div
                      className='price-detail-container'
                      style={{ marginTop: '10px' }}
                    >
                      <div className='price-detail-container-total'>
                        Refundable Amount
                      </div>
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            fontWeight: '800',
                            color: '#000000',
                          }}
                        >
                          <ShowDynamicCurrency /> &nbsp;
                        </span>
                        <ShowDynamicPrice
                          price={
                            calculatedData?.netPayable
                              ? calculatedData?.netPayable?.toFixed(2)
                              : 0
                          }
                          hideExc
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    quotation: state.quotation,
    currency: state.currency,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    setChatRoom: (data) => dispatch(SetActiveRoom(data)),
    chatRoomInitialize: () => dispatch(Initialize()),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(Trackingorder);
