
//Function that check if user cart exists and if not creates it and returns
export const getCartDetail = (userId) => {
    let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
    
    let userIndex = cart?.findIndex(c => c?.id === userId);
    
    if (userIndex < 0) {
        userIndex = cart?.length;
        const user = {
            id: userId,
            items: []
        }
        cart[userIndex] = user
    }

    if (!cart?.[userIndex]?.items) {
        cart[userIndex]["items"] = [];
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    return {
        userCart: cart[userIndex],
        index: userIndex
    };
}

//Function to update the items in cart for particular user 
export const updateCartItems = (data, action = "add_single") => {
    const currentUser = JSON.parse(localStorage.getItem('login'))

    const { index, userCart } = getCartDetail(currentUser?._id);

    const cart = JSON.parse(localStorage.getItem("cart"));

    switch(action) {
        case "add_single":
            userCart["items"].push(data);
            break;
        case "update_single":
            userCart["items"] = data;
            break;
        case "add_multiple": 
            userCart["items"] = [
                ...userCart["items"],
                ...data
            ];
            break;
        default:
            userCart["items"].push(data);
            break;
    }
    cart[index] = userCart;

    localStorage.setItem("cart", JSON.stringify(cart));

    return userCart;
}