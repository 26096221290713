import React, { useRef, useState } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import { Share } from '@material-ui/icons'
import ShareModal from './ShareModal'
import { ga4Events } from '../../utils'
import './custom.css'
import Carousel from 'react-multi-carousel'
import TextOverlay from '../common/TextOverlay'

const Gallery = ({ data, business_model, deliveryTime, id, link, currentUser }) => {
  const pagination = []
  if (data && data.length > 0) {
    data.map((item) => {
      pagination.push(item)
    })
  }
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
    ga4Events({
      action: 'pdp_share_click',
      category: 'Listing',
      product_id: id,
    })
  }

  const slider1Ref = useRef(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsive2 = {
    superLargeDesktop: {
      breakpoint: {
        max: 4000,
        min: 1400
      },
      items: 8,
      partialVisibilityGutter: 40
    },
    largeDesktop: {
      breakpoint: {
        max: 1400,
        min: 1200
      },
      items: 6,
      partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: {
        max: 1200,
        min: 990
      },
      items: 5,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 990,
        min: 500
      },
      items: 4,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: {
        max: 500,
        min: 0
      },
      items: 2,
      partialVisibilityGutter: 30
    },
  };


  const handleThumbnailClick = (index) => {
    setActiveItemIndex(index);
    slider1Ref.current.goToSlide(index + 2);
  };

  const carouselStyle = {
    backgroundColor: "#ddd",
    borderRadius: "12px",
  };

  return (
    <div className='gallery-wrapper'>
      <div>
        <div className='carousel-container'>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            deviceType={null}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            sliderSynced={activeItemIndex}
            beforeChange={(oldIndex, newIndex) => setActiveItemIndex(newIndex)}
            ref={slider1Ref}
            arrows={false}
          >
            {data?.map((img, i) => {
              return (
                <div key={i}  >
                  {currentUser && (
                    <div
                      onClick={() => toggle()}
                      style={{
                        top: 10,
                        right: 10,
                        float: 'right',
                        position: 'relative',
                        zIndex: 1,
                        border: 'none',
                        cursor: 'pointer',
                        color: 'black',
                        marginTop: "0px"
                      }}>
                      <Share />
                    </div>
                  )}
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        className: "large",
                        alt: 'Product Image',
                        src: img,
                        isFluidWidth: true,
                        isFluidHeight: false,
                        width: 380,
                        height: 480,
                      },
                      largeImage: {
                        className: "small",
                        src: img,
                        isFluidWidth: true,
                        isFluidHeight: false,
                        width: 1200,
                        height: 1800,
                      },
                      lensStyle: { backgroundColor: 'rgba(0,0,0,.6)'},
                      enlargedImagePosition: 'over',

                    }}
                  />
                  {
                    deliveryTime ?
                      <TextOverlay value={deliveryTime} /> :
                      null
                  }
                </div>
              )
            })}
          </Carousel>
        </div >
        <div style={carouselStyle} className="carousel-container">
          <Carousel
            showDots={false}
            responsive={responsive2}
            ssr={true}
            infinite={true}
            autoPlay={false}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            draggable={false}
            sliderClass=""
            slidesToSlide={3}
            swipeable
            deviceType={null}
            dotListClass="custom-dot-list-style"
            sliderSynced={activeItemIndex}
            beforeChange={(oldIndex, newIndex) => setActiveItemIndex(newIndex)}
            itemClass="carousel-item-padding-40-px"
          >
            {data.map((slide, i) => (
              <div key={slide.id}
                className='slide-div'
                onClick={() => handleThumbnailClick(i)}
                style={{
                  padding: '3px',
                  borderRadius: "10px"
                }}
              >
                <div className='listing-subimages'>
                  <img
                    src={slide || '/assets/img/product-default.jpg'}
                    alt={`product-images-${i}`}
                    key={`product-images-${i}`}
                    style={{ cursor: "pointer" }}
                  />

                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <ShareModal isModalOpen={isOpen} toggle={toggle} id={id} link={link} />
    </div>
  )
}

export default Gallery
