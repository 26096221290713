import { Card, CardContent, CardMedia, Grid, Hidden, ListItem, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { ga4Events } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
        rmBox: {
            boxShadow: 'none',
            position: 'relative'
        },
        rmpaddinglt: {
            paddingLeft: 0
        },
        brandName: {
            // position: 'absolute', 
            // marginTop: '-22px', 
            // marginLeft: '10px',
            paddingTop: '8px',
            color: '#333333', 
            fontWeight: 700, 
            fontSize: '14px'
        }
  }));

const PartsCategory = ({ itemToShow, clickedUser }) => {
    const classes = useStyles();
    const [imageError, setImageError] = useState(false);
    const handleImageError = () => {
        setImageError(true);
    }

    let handleGA = (item, e) => {
        ga4Events({
            action: `catalog_view_${item.name}_click`,
            category: "Listing",
            subcategory_id: item && item.sub_category && item.sub_category[0]?._id,
            subcategory_name: item && item.sub_category && item.sub_category[0]?.name,
        });
    };

    return (
        <Grid container spacing={2} className="parts-view">
            {itemToShow?.map((brand, index) => (
                <Grid item xs={6} md={4} lg={4} key={brand.name}>
                    <NavLink to={`/spare-parts/${brand.name}`} 
                        onClick={(e) => {
                            handleGA(brand, e);
                        }}
                    >
                        <Card sx={{ maxWidth: 345 }} className={classes.rmBox}>
                            <CardMedia
                                component="img"
                                height="80"
                                src={imageError ? "/assets/img/byparts.jpg" : brand?.brand_img}
                                onError={handleImageError}
                                onClick={clickedUser}
                            />
                            <Typography
                                className={classes.brandName}>
                                {brand.name}
                            </Typography>
                            <CardContent className={classes.rmpaddinglt}>
                                {brand?.subCategories && brand.subCategories.length
                                    ? brand.subCategories.map((sub, ind) => {
                                        let activeCategory = brand.name
                                            ?.toLowerCase()
                                            ?.replaceAll(" ", "-")
                                            ?.replaceAll("/", "-");
                                        let subCat = sub?.name
                                            ?.toLowerCase()
                                            ?.replaceAll(" ", "-")
                                            ?.replaceAll("/", "-");
                                        return (
                                            <NavLink key={`${brand.name}-${subCat}`}
                                                to={`/spare-parts/${activeCategory}/${subCat}`}
                                                onClick={(e) => {
                                                    handleGA(brand, e);
                                                }} style={{ color: '#333', padding: 0 }}
                                            >
                                                <Hidden xsDown={true}>
                                                    <ListItem style={{ padding: 0, marginBottom: '2px', fontSize: '12px', fontWeight: 400 }}>{sub.name}</ListItem>
                                                </Hidden>
                                            </NavLink>
                                        );
                                    })
                                    : null}
                            </CardContent>
                        </Card>
                    </NavLink>
                </Grid>
            ))}
        </Grid>
    )
}

export default PartsCategory