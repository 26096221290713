import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Badge } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import Stepper from "./../common/StepperOrderDetails";
import { Row, Col } from "reactstrap";
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const OrderDetails = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [modalOrderDetails, setModalOrderDetails] = useState(false);
  const [modalBuyerContact, setModalSellerContact] = useState(false);
  const [modalBuyParts24, setModalBuyParts24] = useState(false);
  const [activeTabDate, setActiveTabDate] = useState("1");
  const [badgeColor, setBadgeColor] = useState("");

  const toggleDate = (tab) => {
    if (activeTabDate !== tab) setActiveTabDate(tab);
  };

  const ratingChanged1 = (newRating) => {
  };
  const ratingChanged2 = (newRating) => {
  };
  const ratingChanged3 = (newRating) => {
  };

  const ModalRateOrder = (props) => {
    const toggle = () => {
      setModal(!modal);
    };
    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modal}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Give a Rating</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>*indicates required fields</Typography>
            <br />
            <Typography>
              Seller<h6> Tommy A Car Parts</h6>
            </Typography>{" "}
            <br />
            <br />
            <Row>
              <Col sm="7">
                <h5>Item Descripition</h5>
                <Typography>How accurately they were described?</Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged1}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="7">
                <h5>Communication</h5>
                <Typography>
                  Did the seller communicate well with you?
                </Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged2}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="7">
                <h5>Shipping Time</h5>
                <Typography>How quickly the seller ship the item?</Typography>
              </Col>
              <Col sm="5">
                <ReactStars
                  count={5}
                  onChange={ratingChanged3}
                  size={24}
                  activeColor="#ffd700"
                />
              </Col>
            </Row>
            <br />
            <br />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="light" onClick={toggle}>
              Maybe later
            </Button>{" "}
            <Button size="sm" color="primary" onClick={toggle}>
              Submit
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalOrderDetails = (props) => {
    const toggle = () => {
      setModalOrderDetails(!modalOrderDetails);
    };
    return (
      <div>
        Total AED222 . 299206 AED35.99 AED35.99
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalOrderDetails}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Order Details</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>
              <h1>Order Details</h1>
            </Typography>
            <br />
            <br />
            <Row>
              <Col sm="2">
                <img
                  src="/assets/img/stepperimg/gilan.png"
                  alt="item"
                  style={{ width: "100px" }}
                />
              </Col>
              <Col sm="4">
                <Typography>
                  <h6>Castrol EDGE Engine Oil 5W-30 5 Litre Item SKU</h6>
                </Typography>
              </Col>
            </Row>

            <br />

            <Row>
              <Col>
                <FormGroup>
                  <Label>Order Placed</Label>
                  <Typography>2020-02-16</Typography>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label> Order Number</Label>
                  <Typography>232-123-123</Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Total</Label>
                  <Typography>AED222</Typography>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label> Seller</Label>
                  <Typography> Tommy A car Parts</Typography>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Deliver</Label>
                  <Typography>To Mary Clock</Typography>
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" onClick={toggle}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalBuyerContact = (props) => {
    const toggle = () => {
      setModalSellerContact(!modalBuyerContact);
    };

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalBuyerContact}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Contact Buyer</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>
              Buyer: <a href="./">Mary</a>
            </Typography>
            <Typography>
              Order: <a href="./">12234241231</a>
            </Typography>
            <br />
            <br />
            <Row>
              <Col>
                <FormGroup>
                  <Label for="exampleSelect">Status</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    <option>Pending</option>
                    <option>Delivered</option>
                    <option>Delay</option>
                    <option>Cancelled</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="enquiry">Enquiry</Label>
                  <Input
                    type="textarea"
                    name="enquiry"
                    id="enquiry"
                    placeholder="Write here..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="message">Photo</Label>
                  <Input type="file" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Send a copy to my email address
                  </Label>
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="light" onClick={toggle}>
              Close
            </Button>{" "}
            <Button size="sm" color="primary" onClick={toggle}>
              Contact
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const ModalBuyParts24 = () => {
    const toggle = () => {
      setModalBuyParts24(!modalBuyParts24);
    };

    return (
      <div>
        <Button color="danger" onClick={toggle}></Button>
        <Modal
          isOpen={modalBuyParts24}
          toggle={toggle}
          style={{ maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={toggle}>Contact BuyParts24</ModalHeader>
          <ModalBody style={{ padding: "20px" }}>
            <Typography>
              Order: <a href="./">12234241231</a>
            </Typography>
            <br />
            <br />

            <Row>
              <Col>
                <FormGroup>
                  <Label for="enquiry">Enquiry</Label>
                  <Input
                    type="textarea"
                    name="enquiry"
                    id="enquiry"
                    placeholder="Write here..."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="message">Photo</Label>
                  <Input type="file" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Send a copy to my email address
                  </Label>
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="light" onClick={toggle}>
              Close
            </Button>{" "}
            <Button size="sm" color="primary" onClick={toggle}>
              Contact
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  const callbackFunction = (childData) => {
    setBadgeColor(childData);
  };

  return (
    <Fragment>
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div
          className="container-fluid section-padding-all-listing"
          maxWidth="lg"
        >
          <div style={{ visibility: "hidden" }}>
            {ModalBuyerContact()}
            {ModalBuyParts24()}
          </div>

          <Row>
            <Col>
              <Typography className="" style={{ textAlign: "left" }}>
                <h2>Orders Details</h2>
              </Typography>
            </Col>
            <Col>
              <Typography
                className=""
                style={{ textAlign: "left", fontSize: "12px" }}
              >
                Status{" "}
                <Badge
                  color={
                    badgeColor === 0
                      ? "primary"
                      : badgeColor === 1
                        ? "warning"
                        : badgeColor === 2
                          ? "info"
                          : "success"
                  }
                >
                  {badgeColor === 0
                    ? "Pending"
                    : badgeColor === 1
                      ? "Preparing"
                      : badgeColor === 2
                        ? "In Transit"
                        : "Delivered"}
                </Badge>
              </Typography>
            </Col>
            <Col></Col>
            <Col sm="5">
              <Button color="light" size="sm">
                View Invoice
              </Button>

              <Button
                style={{ marginLeft: "5px" }}
                color="primary"
                size="sm"
                onClick={() => {
                  setModalSellerContact(true);
                }}
              >
                Contact Buyer
              </Button>

              <Button
                style={{ marginLeft: "5px" }}
                color="primary"
                size="sm"
                onClick={() => {
                  setModalBuyParts24(true);
                }}
              >
                Contact BuyParts24 Support
              </Button>
            </Col>
          </Row>

          <br />

          <br />
          <div
            style={{
              padding: "5px",
            }}
          >
            <Row>
              <Col>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Order Number</h6>
                  <p>232-123-123</p>
                </Typography>
              </Col>
              <Col>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Customer Id</h6>
                  <p>2343242</p>
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Customer Name</h6>
                  <p>Mary mcdonald</p>
                </Typography>
              </Col>
              <Col>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Payment Type</h6>
                  <p>AED222</p>
                </Typography>
              </Col>
              <Col>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Shipping To</h6>
                  <p>
                    101/90 Mary St, Surry Hills New South Wales 2000, Australia
                    marymcdonald@gmail.com +000 666 000 000
                  </p>
                </Typography>
              </Col>
              <Col>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Deliver Service</h6>
                  <FormGroup>
                    <Input type="select" name="select" id="exampleSelect">
                      <option>None</option>
                      <option>DHL</option>
                    </Input>
                  </FormGroup>
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <h6 style={{ marginBottom: "0px" }}>Tracking Number</h6>
                  <p>0024567100</p>
                </Typography>
              </Col>
            </Row>
            <div>
              <Stepper parentCallback={callbackFunction} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(OrderDetails);
