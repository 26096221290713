import React from "react";

const NodataCard = () => {
  return (
    <>
      <div className="col-lg-12 mb-4">
        <div className="atbd_single_listing atbd_listing_list">
            <h1>No data</h1>
        </div>
      </div>
    </>
  );
};

export default NodataCard;
