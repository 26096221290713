import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { PageBanner } from '../content/element/page-banner'
import ListingCardGrid from '../content/element/card/new-card-listing-grid-my'
import { Range } from 'rc-slider'
import axios from 'axios'
import Select from 'react-select'

import {
  GetListingsCount,
  GetMyListings,
  GetMyListingsInPagination,
} from '../../Store/action/listingActions'
import Nodata from '../content/element/Nodata'
import Loader from '../content/element/Loader'
import PageTitle from '../content/element/PageTitle'
import Pending from '../content/element/UserStatus/Pending'
import Pagination from '@material-ui/lab/Pagination'
import { SLIDER_CHANGE_FACTOR } from '../../constants';

class MyListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _listing: [],
      loading: true,
      activeCategory: '',
      activeSubCategory: '',
      search: '',
      business_model: '',
      stock_count: '',
      price: [0, 50000000],
      quantity: [0, 1000000],
      page: 1,
      size: 20,
      listing_count: 0,
      categories: [],
      subCategories: [],
      dynamicFilter: {
        minQuantity: 0,
        maxQuantity: 50000,
        minPrice: 0,
        maxPrice: 50000,
      },
    }
    this.sliderChange = this.sliderChange.bind(this)
    this.sliderQntChange = this.sliderQntChange.bind(this)
    this.resetFilter = this.resetFilter.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.initilize = this.initilize.bind(this)
  }

  initilize() {
    axios.get(`/api/listings/get-filter`).then((res) => {
      this.setState({ dynamicFilter: res.data })
      this.setState({
        quantity: [Number(res.data.minQuantity), Number(res.data.maxQuantity)],
        price: [Number(res.data.minPrice), Number(res.data.maxPrice)],
      })
    })

    let filter = {
      category: this.state.activeCategory,
      subCategory: this.state.activeSubCategory,
      search: this.state.search,
      business_model: this.state.business_model.value,
      stock_count: this.state.stock_count,
      max_price: this.state.price[1],
      min_price: this.state.price[0],
      max_stock_count: this.state.quantity[1],
      min_stock_count: this.state.quantity[0],
    }
    let isInitalRender = true
    this.props.getListingInPagination(this.state.page, this.state.size, filter)
  }

  sliderChange(e) {
    this.setState({ price: e })
  }
  sliderQntChange(e) {
    this.setState({ quantity: e })
  }

  resetFilter() {
    const { dynamicFilter } = this.state;

    const state = {
      activeCategory: '',
      activeSubCategory: '',
      search: '',
      business_model: '',
      stock_count: '',
      price: [dynamicFilter?.minPrice, dynamicFilter?.maxPrice],
      quantity: [dynamicFilter?.minQuantity, dynamicFilter?.maxQuantity],
      page: 1,
      size: 18,
    }
    this.setState(
      state,
      () => {
        this.props.getListingInPagination(
          1,
          20,
          state
        )
      }
    )
  }

  applyFilter() {
    let filter = {
      category: this.state.activeCategory.value,
      subcategory: this.state.activeSubCategory.value,
      search: this.state.search,
      businessModel: this.state.business_model.value,
      stockCount: this.state.stock_count,
      maxPrice: this.state.price[1],
      minPrice: this.state.price[0],
      maxStockCount: this.state.quantity[1],
      minStockCount: this.state.quantity[0],
    }
    this.props.getListingInPagination(
      this.state.page,
      this.state.size,
      filter
    )
  }

  componentDidMount() {
    this.initilize()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list.listing !== this.props.list.listing) {
      const { isLoading, listing, listing_count_pagination } = this.props.list
      this.setState({
        _listing: listing,
        loading: isLoading,
      })
      this.setState({ dynamicFilter: prevState.dynamicFilter })
    }

    if (prevState.activeCategory !== this.state.activeCategory) {
      let result = this.props.allCategories.find(
        (cat) => cat._id === this.state.activeCategory.value
      )

      result &&
        this.setState({ subCategories: result.subCategories })
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
  handleChangeSubCategory = (option) => {
    this.setState({ activeSubCategory: option })
  }
  handleChangeBusinessModel = (option) => {
    this.setState({ business_model: option })
  }
  handleChangePartSkuOrName = (e) => {
    this.setState({ search: e.target.value })
  }
  handleChangeStockCount = (e) => {
    this.setState({ stock_count: e.target.value })
  }

  handleChangeCategory = (option) => {
    this.setState(
      {
        activeCategory: option,
        activeSubCategory: '',
        page: 1,
      })
  }

  handleChangePagination = (event, value) => {
    let filter = {
      category: this.state.activeCategory.value,
      subCategory: this.state.activeSubCategory.value,
      search: this.state.search,
      business_model: this.state.business_model.value,
      stock_count: this.state.stock_count,
      max_price: this.state.price[1],
      min_price: this.state.price[0],
      max_stock_count: this.state.quantity[1],
      min_stock_count: this.state.quantity[0],
    }
    this.setState(
      {
        page: value,
      },
      () => {
        this.props.getListingInPagination(value, this.state.size, filter)
      }
    )
  }

  render() {
    const sortedArraySubCategories =
      this.state.activeCategory &&
      this.state.subCategories &&
      this.state.subCategories.sort((a, b) => a?.name.localeCompare(b.name))

    const { isLoading, listing, isInitalRender } = this.props.list
    const { t } = this.props

    const currentUser = JSON.parse(localStorage.getItem('login'))
    if (currentUser && currentUser.status === "Pending" && currentUser.KYCStatus && currentUser?.details?.emirate === null) return <Pending />

    return (
      <Fragment>
        <PageBanner title={t('my_listings_title')} />
        <section className='listing-cards mt-2 mb-5'>
          <div className='container'>
            <PageTitle title='My Listing' />
            <div className='row mt-2'>
              <div className='col-lg-12'>
                <div className=' order-lg-0 order-0 mt-5 pb-5 mt-lg-0'>
                  <div className='listings-sidebar'>
                    <div className='search-area default-ad-search pb-3'>
                      <div
                        className='row search-area-header s mt-3'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div className='filterBY-cat mt-3 col-md-4'>
                          <FormGroup>
                            <Label for='location'>Category</Label>
                            <Select
                              id='activeCategory'
                              className={`react-select`}
                              classNamePrefix='react-select'
                              placeholder='Choose Category...'
                              name='activeCategory'
                              value={this.state.activeCategory}
                              onChange={(selectedOption) =>
                                this.handleChangeCategory(selectedOption)
                              }
                              options={
                                this.props.allCategories.length > 0 &&
                                this.props.allCategories.map((item) => {
                                  return {
                                    label: item.name,
                                    value: item._id,
                                  }
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className='filterBY-subcat mt-3 col-md-4'>
                          <FormGroup>
                            <Label for='location'>Sub Category</Label>
                            <Select
                              className={`react-select`}
                              classNamePrefix='react-select'
                              placeholder='Choose SubCategory...'
                              onChange={(option) =>
                                this.handleChangeSubCategory(option)
                              }
                              isDisabled={!this.state.activeCategory}
                              value={this.state.activeSubCategory}
                              options={
                                sortedArraySubCategories &&
                                sortedArraySubCategories.map((item) => {
                                  return {
                                    label: item.name,
                                    value: item._id,
                                  }
                                })
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className='filterBY- mt-3 col-md-4'>
                          <FormGroup>
                            <Label for='location'>Part Name/Sku/Number</Label>
                            <Input
                              type='search'
                              name='partSukOrName'
                              id='part-sku-name-search'
                              placeholder='Enter Part SKU/Number'
                              value={this.state.search}
                              onChange={(e) =>
                                this.handleChangePartSkuOrName(e)
                              }
                            />
                          </FormGroup>
                        </div>
                        <div className='filterBY- mt-3 col-md-4'>
                          <Label for='location'>Quantity Filter</Label>

                          <div className='range ml-2 mt-2'>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <span
                                onClick={() => {
                                  let initalVal = this.state.quantity[1]
                                  let afterDecreament =
                                    initalVal - SLIDER_CHANGE_FACTOR

                                  if (afterDecreament > 0) {
                                    let quantity = [
                                      this.state.quantity[0],
                                      afterDecreament,
                                    ]
                                    this.setState({ quantity })
                                  }
                                }}
                                className='las la-minus-circle'
                                style={{
                                  marginRight: '15px',
                                  fontSize: '18px',
                                  cursor: 'pointer',
                                }}
                              ></span>
                              <Range
                                style={{ width: 150 }}
                                min={
                                  this.state.dynamicFilter.minQuantity &&
                                  this.state.dynamicFilter.minQuantity
                                }
                                max={
                                  this.state.dynamicFilter.maxQuantity &&
                                  this.state.dynamicFilter.maxQuantity
                                }
                                value={this.state.quantity}
                                marks
                                onChange={this.sliderQntChange}
                              />
                              <span
                                onClick={() => {
                                  let initalVal = this.state.quantity[0]
                                  let afterIncreament =
                                    initalVal + SLIDER_CHANGE_FACTOR
                                  if (
                                    afterIncreament <
                                    this.state.dynamicFilter.maxQuantity
                                  ) {
                                    let quantity = [
                                      afterIncreament,
                                      this.state.quantity[1],
                                    ]
                                    this.setState({ quantity })
                                  }
                                }}
                                className='las la-plus-circle'
                                style={{
                                  marginLeft: '15px',
                                  fontSize: '18px',
                                  cursor: 'pointer',
                                }}
                              ></span>
                            </div>
                            <span>{`Min:${this.state.quantity[0]} - Max:${this.state.quantity[1]}`}</span>
                          </div>
                        </div>
                        <div className='filterBY-business-model mt-3 col-md-4'>
                          <FormGroup>
                            <Label for='location'>Business Model</Label>
                            <Select
                              className={`react-select`}
                              classNamePrefix='react-select'
                              placeholder='Choose Business model...'
                              onChange={(option) =>
                                this.handleChangeBusinessModel(option)
                              }
                              value={this.state.business_model}
                              options={[
                                'Pure Play',
                                'Consolidation',
                                'Fulfilment',
                              ].map((item) => {
                                return {
                                  label: item,
                                  value: item,
                                }
                              })}
                            />
                          </FormGroup>
                        </div>

                        <div
                          className='filterBy-price-div mt-3 col-md-4 d-flex align-items-center'
                          style={{ height: '100%', width: 'auto' }}
                        >
                          <div>
                            <Label for='location'>Price Filter</Label>
                            <div className='range ml-2 mt-2'>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  onClick={() => {
                                    let initalVal = this.state.price[1]
                                    let afterDecreament =
                                      initalVal - SLIDER_CHANGE_FACTOR

                                    if (afterDecreament > 0) {
                                      let price = [
                                        this.state.price[0],
                                        afterDecreament,
                                      ]
                                      this.setState({ price })
                                    }
                                  }}
                                  className='las la-minus-circle'
                                  style={{
                                    marginRight: '15px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                  }}
                                ></span>
                                <Range
                                  style={{ width: 150 }}
                                  min={
                                    this.state.dynamicFilter.minPrice &&
                                    this.state.dynamicFilter.minPrice
                                  }
                                  max={
                                    this.state.dynamicFilter.maxPrice &&
                                    this.state.dynamicFilter.maxPrice
                                  }
                                  value={this.state.price}
                                  marks
                                  onChange={this.sliderChange}
                                />
                                <span
                                  onClick={() => {
                                    let initalVal = this.state.price[0]
                                    let afterIncreament =
                                      initalVal + SLIDER_CHANGE_FACTOR
                                    if (
                                      afterIncreament <
                                      this.state.dynamicFilter.maxPrice
                                    ) {
                                      let price = [
                                        afterIncreament,
                                        this.state.price[1],
                                      ]
                                      this.setState({ price })
                                    }
                                  }}
                                  className='las la-plus-circle'
                                  style={{
                                    marginLeft: '15px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                  }}
                                ></span>
                              </div>
                              <span>{`Min:${this.state.price[0]} - Max:${this.state.price[1]}`}</span>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12 d-flex justify-content-end'>
                          <button
                            className='btn btn-primary'
                            onClick={(e) => this.applyFilter(e)}
                          >
                            APPLY FILTER
                          </button>
                          <button
                            className='btn btn btn-light ml-4'
                            onClick={(e) => this.resetFilter(e)}
                          >
                            RESET FILTER
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className='row'>
                <Loader />
              </div>
            ) : this.props.list.listing_count_pagination === 0 ? (
              <Nodata text='You have not made any listing yet. They will show here as soon as you make your item for sale' />
            ) : (
              <div className='row' style={{ rowGap: '31px' }}>
                <Fragment>
                  <ListingCardGrid size={6} list={this.state._listing} />
                </Fragment>
              </div>
            )}
            <div className='row' style={{ marginLeft: 2, marginTop: '40px' }}>
              <Pagination
                count={Math.ceil(
                  this.props.list.listing_count_pagination / this.state.size
                )}
                color='primary'
                page={this.state.page}
                onChange={this.handleChangePagination}
              />
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.list,
    login: state.login,
    allCategories: state.category.allCategories,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    getMyListing: () => dispatch(GetMyListings()),
    getListingsCount: (filter, isInitalRender = false) =>
      dispatch(GetListingsCount(filter, isInitalRender)),
    getListingInPagination: (page, size, filter) =>
      dispatch(GetMyListingsInPagination(page, size, filter)),
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(MyListing)
