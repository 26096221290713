import React, { Fragment, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router";
import { PageBanner } from "../../content/element/page-banner";
import { getAllListingBrands } from "../../../Store/action/brandActions";

const Index = () => {
    const [tireKeyword, setTireKeyword] = useState('')
    const [batteryKeyword, setBatteryKeyword] = useState('')
    const [batteryYear] = useState('')
    const [tireYear] = useState('')
    const [width, setWidth] = useState(null)
    const [rimSize, setRimSize] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState([])
    const [seletedBrandBatteries, setSelectedBrandBatteries] = useState([])
    const [makes, setMakes] = useState([])
    const [models] = useState([])
    const [height, setHeight] = useState(null)
    const [voltage, setVoltage] = useState(null)
    const [amp, setAmp] = useState(null)

    const dispatch = useDispatch();
    const history = useHistory();

    const { batteriesBrands, tyreBrands } = useSelector(state => {
        return {
            batteriesBrands: state.brand.batteriesBrands,
            tyreBrands: state.brand.tyreBrands
        }
    })

    const batteriesBrandList = useMemo(() => {
        return batteriesBrands?.length ? batteriesBrands?.map(brand => ({
            label: brand,
            value: brand,
        })) : [];
    }, [batteriesBrands]);

    const tireBrandList = useMemo(() => {
        return tyreBrands?.length ? tyreBrands?.map(brand => ({
            label: brand,
            value: brand,
        })) : [];
    }, [tyreBrands]);

    const handleChangeBrand = (options) => {
        if (!options) {
            setMakes([])
            return;
        }
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrand(brands)
    }

    const handleChangeBrandBatteries = (options) => {
        const brands = options?.map((o) => {
            return o.value;
        });
        setSelectedBrandBatteries(brands)
    }

    const searchTires = async () => {
        let filterOptions = { type: "tires" };

        if (width) {
            filterOptions = { ...filterOptions, width }
        }
        if (height) {
            filterOptions = { ...filterOptions, height }
        }
        if (rimSize) {
            filterOptions = { ...filterOptions, rim_size: rimSize }
        }
        if (tireYear) {
            filterOptions = { ...filterOptions, tire_year: tireYear }
        }
        if (selectedBrand.length > 0) {
            filterOptions = { ...filterOptions, brands: selectedBrand }
        }

        const encodedSearch = encodeURIComponent(tireKeyword?.trim())

        history.push({
            pathname: `/all-listings`,
            search: `?type=tires${encodedSearch ? `&search=${encodedSearch}` : ''}`,
            state: {
                search: encodedSearch,
                extraData: filterOptions
            }
        });
    }

    const searchBatteries = async () => {
        let filterOptions = {type: "batteries"}
        
        if (voltage) {
            filterOptions = { ...filterOptions, voltage }
        }
        if (amp) {
            filterOptions = { ...filterOptions, amp }
        }
        if (batteryYear) {
            filterOptions = { ...filterOptions, battery_year: batteryKeyword }
        }
        if (makes.length > 0) {
            filterOptions = { ...filterOptions, makes }
        }
        if (models.length > 0) {
            filterOptions = { ...filterOptions, models }
        }
        if (seletedBrandBatteries.length > 0) {
            filterOptions = { ...filterOptions, brands: seletedBrandBatteries }
        }
        const encodedSearch = encodeURIComponent(batteryKeyword?.trim())

        history.push({
            pathname: `/all-listings`,
            search: `?type=batteries${encodedSearch ? `&search=${encodedSearch}` : ''}`,
            state: {
                search: encodedSearch,
                extraData: filterOptions
            }
        });
    }

    useEffect(() => {
        dispatch(getAllListingBrands({
            filter: "?type=tires",
            type: "GET_TIRE_BRAND_SUCCESS"
        }));

        dispatch(getAllListingBrands({
            filter: "?type=batteries",
            type: "GET_BATTERY_BRAND_SUCCESS"
        }));
         dispatch(getAllListingBrands({
            filter: "?type=lubricants",
            type: "GET_LUBRICANTS_BRAND_SUCCESS"
        }));
    }, [])

    return (
        <Fragment>
            <PageBanner title="Parts Search Vin" />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="searh-vin-page">
                            <section className="search-vin-col">

                                <h2>Tyre</h2>
                                <p>
                                    Search tyres accordingly.
                                </p>
                                <div className="vin-search-wrapper">
                                    <i className="la la-search"></i>
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Tyres..."
                                        onChange={(e) => setTireKeyword(e.target.value)}
                                        value={tireKeyword}
                                    />
                                </div>
                                <p className="ex-text">
                                    For example: tyres
                                </p>

                                <div className="car-identified-wrap">
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Width:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="width"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="185"
                                                onChange={(e) => setWidth(e.target.value)}
                                                value={width}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Height:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="height"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="65"
                                                onChange={(e) => setHeight(e.target.value)}
                                                value={height}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            RIM Size:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="rimSize"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setRimSize(e.target.value)}
                                                value={rimSize}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Brand:
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                placeholder="Choose Brands..."
                                                name="make"
                                                onChange={handleChangeBrand}
                                                options={tireBrandList}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        id="findTires"
                                        style={{ fontSize: "1rem" }}
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={searchTires}
                                    >
                                        Find Tyres
                                    </button>
                                </div>
                            </section>


                            {/* for batteries */}
                            <div className="separator-line">
                                <span>or</span>
                            </div>
                            <section className="search-vin-col">
                                <h2>Batteries</h2>
                                <p>
                                    Search Batteries accordingly.
                                </p>
                                <div className="vin-search-wrapper">
                                    <i className="la la-search"></i>
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Batteries..."
                                        onChange={(e) => setBatteryKeyword(e.target.value)}
                                        value={batteryKeyword}
                                    />
                                </div>
                                <p className="ex-text">
                                    For example: batteries
                                </p>
                                <div className="car-identified-wrap">
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Brand:
                                        </label>
                                        <div className="col-sm-9">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                placeholder="Choose Brands..."
                                                name="partBrand"
                                                onChange={handleChangeBrandBatteries}
                                                options={batteriesBrandList}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Voltage:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="voltage"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setVoltage(e.target.value)}
                                                value={voltage}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-3">
                                            Amp:
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                name="amp"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="15"
                                                onChange={(e) => setAmp(e.target.value)}
                                                value={amp}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        id="findBatteries"
                                        style={{ fontSize: "1rem" }}
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={searchBatteries}
                                    >
                                        Find Batteries
                                    </button>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Index
