import React from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

const ComingSoon = () => {
  const history = useHistory();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 50,
          flexDirection: "column",
        }}
      >
        <img src="/assets/img/coming-soon.png"></img>
        <Button
          variant="contained"
          style={{ backgroundColor: "grey", color: "#fff", width: 120 }}
          onClick={() => history.goBack()}
          size="large"
        >
          Go Back
        </Button>
      </div>
    </>
  );
};

export default ComingSoon;
