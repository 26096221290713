import axios from "axios";
export const getAllBrands = _ => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_ALL_BRAND_BEGIN",
            })
            axios
                .get("/api/brand/all")
                .then(res => {
                    dispatch({
                        type: "GET_ALL_BRAND_SUCCESS",
                        data: res.data
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        } catch (err) {
            dispatch({
                type: "GET_ALL_BRAND_ERROR",
            })
        }
    };
};
export const filterCategories = category => {
    return (dispatch, getState) => {
        const { subCategories, brands } = getState().brand;
        let filteredSubCategories = [], filteredBrands = brands, selectedSubCategory = "";
        if (category !== "all") {
            filteredSubCategories = subCategories &&
                subCategories?.length &&
                subCategories?.filter(subCategory => subCategory?.category_id === category);
            selectedSubCategory = filteredSubCategories[0]?._id || ""
            filteredBrands = brands &&
                brands?.length &&
                brands?.filter(brand => brand?.category?._id === category && brand?.sub_category?.some(subCategory => subCategory?._id === selectedSubCategory));
        }
        dispatch({
            type: "FILTER_CATEGORY",
            data: {
                selectedCategory: category,
                filteredSubCategories,
                filteredBrands,
                selectedSubCategory
            }
        })
    }
}

export const filterSubCategories = subCategory => {
    return (dispatch, getState) => {
        const { selectedCategory, brands } = getState().brand;
        let filteredBrands = [];
        filteredBrands = brands &&
            brands?.length &&
            brands?.filter(brand => brand?.category?._id === selectedCategory && brand?.sub_category?.some(subCat => subCat?._id === subCategory));
        dispatch({
            type: "FILTER_SUB_CATEGORY",
            data: {
                selectedSubCategory: subCategory,
                filteredBrands,
            }
        })
    }
}

export const getAllListingBrands = ({ filter, type = "GET_ALL_LISTING_BRAND_SUCCESS" }) => {
    return (dispatch) => {
        dispatch({
            type: "GET_ALL_LISTING_BRAND_BEGIN",
        })
        axios
            .get(`/api/listing-brands${filter ? filter : ''}`)
            .then(res => {
                dispatch({
                    type: type,
                    data: res.data
                });

            })
            .catch(err => {
                dispatch({
                    type: "GET_TIRE_BRAND_ERROR",
                })
            });
    }
}

export const getCatalogBrands = ({ filter, type = "GET_ALL_LISTING_BRAND_SUCCESS" }) => {
    return (dispatch) => {
        dispatch({
            type: "GET_CATALOG_DATA_SUCCESS",
        })
        axios
            .get(`/api/listings${filter ? filter : ''}`)
            .then(res => {
                dispatch({
                    type: type,
                    data: res.data
                });

            })
            .catch(err => {
                dispatch({
                    type: "GET_TIRE_BRAND_ERROR",
                })
            });
    }
}


export const getAllLubricants = _ => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_ALL_LUBS_BEGIN",
            })
            axios
                .get("/api/brand/allLub")
                .then(res => {
                    console.log('Lubricants >>> ' + JSON.stringify(res.data))
                    dispatch({
                        type: "GET_ALL_LUBS_SUCCESS",
                        data: res.data
                    })
                })
                .catch(err => {
                    console.log(err)
                });
        } catch (err) {
            dispatch({
                type: "GET_ALL_LUBS_ERROR",
            })
        }
    };
};
