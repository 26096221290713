import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

 const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey',
        fontFamily:"Montserrat"
      },
      '& label': {
        color: 'grey',
        fontFamily:"Montserrat"
      },
      '& textarea': {
        color: 'grey',
        fontFamily:"Montserrat"
      },
      
    },
  })(TextField);

  export default CssTextField
  

