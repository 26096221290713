import React from 'react'
import { Button } from 'reactstrap';

const AddressBox = ({ item, disabledBtn, setDefaultAddress, handleEdit, handleRemove }) => {
    return (
        <div
            className={`card-address ${item?.default_address && "default_address"
                }`}
            style={{ border: "1px solid #ddd", marginBottom: "20px" }}
        >
            <div className="user-details-container">
                <div className="user-image">
                    <img src="/assets/img/avatar.png" alt="avatar" />
                </div>

                <div className="user-details">
                    <div className="user-name">
                        {item?.first_name} {item?.last_name}
                    </div>
                    <p className="user-address-detail">
                        {item?.postcode} {item?.suburb} {item?.address}{" "}
                        {item?.ship_to_site_code}{" "}
                        {item?.state}
                        <br /> {item?.contact}
                    </p>
                </div>
            </div>
            <hr className="address-hr" />
            <div className="user-details-footer">
                {item.default_address ? (
                    ""
                ) : (
                    <Button
                        size="sm"
                        color="#FFF"
                        onClick={() => setDefaultAddress(item?._id)}
                        disabled={disabledBtn}
                        className="btn-address"
                        style={{ marginRight: "0px", marginLeft: "auto" }}
                    >
                        Set Default
                    </Button>
                )}
                <Button
                    size="sm"
                    color="#FFF"
                    onClick={() => handleEdit(item)}
                    style={
                        item.default_address
                            ? { marginLeft: "auto" }
                            : { marginLeft: "0px" }
                    }
                    className="btn-address"
                >
                    Edit
                </Button>
                <Button
                    size="sm"
                    color="#FFF"
                    onClick={() =>handleRemove(item)}
                    style={{ marginLeft: "0px" }}
                    className="btn-address"
                >
                    Remove
                </Button>

                <div
                    color="primary"
                    className={
                        item.default_address ? "address-true" : "d-none"
                    }
                >
                    <p style={{ color: "#fff" }}>Default</p>
                </div>
            </div>
        </div>
    )
}

export default AddressBox