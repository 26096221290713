import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";

import SearchLocationInput from "../../common/SearchLocationInput";
import { LogInAc } from "../../../Store/action/loginActions";
import { ga4Events, popUp, validateFile } from "../../../utils";
import { emiratesOptions } from "../../../constants";
import { uploadFileToAssets } from "../../../services/upload";

const userTypeOptions = [
  "BuyParts24",
  "BuyParts24_DNE",
  "BuyParts24_AbuDhabi&Alain",
];
class RegisterSeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emirate: null,
      user_type: null,
      company_name: null,
      company_address: null,
      company_area: null,
      trade_license_no: null,
      trade_license_upload: "",
      vat_registration_no: null,
      vat_registration_upload: null,
      submitLoading: false,
      listOfAllTradeNumberInSys: [],
      listOfAllVatNumberInSys: [],
      acceptTerms: false,
      isRegisteredForVat: false
    };
    this.validator = new SimpleReactValidator({
      validators: {
        tradeUniqueCheck: {
          message: "Already registered with this Trade Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllTradeNumberInSys.includes(val)) {
              return true;
            }
            return false;
          },
          required: true,
        },
        vatUniqueCheck: {
          message: "Already registered with this Vrade Registration number.",
          rule: (val, params, validator) => {
            if (!this.state.listOfAllVatNumberInSys.includes(val)) {
              return true;
            }
            return true
          },
          required: true,
        },
        tradeOrVatRegexCheck: {
          message: "The :attribute registration number should be between 4 to 15 digits only.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z0-9]{4,15}$/);
          },
        },
      },
    });

    this.handleSelect = this.handleSelect.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSelectedFile = this.setSelectedFile.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/products/get-regions")
      .then((res) => {
        this.setState({ stateData: res?.data?.regions });
      })
      .catch((e) =>
        popUp("There was a problem with server. Please try again.")
      );

    axios
      .get(`/api/users/get-all-vat-trade-no?type=SELLER&forVat=false`)
      .then((res) => {
        this.setState({ listOfAllTradeNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/api/users/get-all-vat-trade-no?type=SELLER&forVat=true`)
      .then((res) => {
        this.setState({ listOfAllVatNumberInSys: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });

    if (!this.props.signup.accountType) {
      this.props.history.push("/register");
      return;
    }
    if (!this.props.signup.email) {
      this.props.history.push("/register/verify-email");
      return;
    }
    if (!this.props.signup.phone) {
      this.props.history.push("/register/verify-phone");
      return;
    }
    if (!this.props.signup.password) {
      this.props.history.push("/register/password");
      return;
    }
  }

  setAddress(address) {
    this.setState({ company_address: address });
  }

  handleSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value });
  }

  handleUserSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value });
  }

  handleInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  async setSelectedFile(file, name) {
    if (file) {
      try {
        const data = await uploadFileToAssets({ file: file, type: "user" });
        this.setState({
          [name]: data?._id
        })
      } catch (error) {
        popUp(error?.message)
      }
    }
  }

  acceptTerms(e) {
    this.setState({ acceptTerms: e.target.checked });
  }
  
  handleSubmit(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ submitLoading: true });
      const fulladdress =
        this.state.company_area + "," + this.state.company_address;
      const details = {
        emirate: this.state.emirate,
        company_address: fulladdress,
        company_name: this.state.company_name,
        trade_license_no: this.state.trade_license_no,
        vat_registration_no: this.state.vat_registration_no,
        trade_license_upload: this.state.trade_license_upload,
        vat_registration_upload: this.state.vat_registration_upload,
      };
      let obj = {
        user_type: this.state.user_type,
        password: this.props.signup.password?.trim(),
        email: this.props.signup.email?.trim()?.toLowerCase(),
        phone: this.props.signup.phone?.trim(),
        role: "SELLER",
        details: details,
      };
      ga4Events({
        action: 'signup_button_click',
        category: 'User'
      });
      axios
        .post(`/api/users/signup`, obj)
        .then((res) => {
          popUp("You have successfully registered as seller.");
          this.setState({ submitLoading: false });
          ga4Events({
            action: 'create_user',
            category: 'User',
            bp_user_id: res.data?._id,
          });
          window.location.href = "/thankyou-register";
        })
        .catch((err) => {
          this.setState({ submitLoading: false });
          popUp("There was a problem with server. Please try again.");
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Fragment>
        <section className="add-listing-wrapper border-bottom section-bg section-padding-strict">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="atbd_content_module">
                  <div className="atbd_content_module__tittle_area">
                    <div className="atbd_area_title">
                      <h4>
                        <span className="la la-user"></span>Mandatory
                        Information
                      </h4>
                    </div>
                  </div>
                  <div className="atbdb_content_module_contents mb-5">
                    <form action="/">
                      <div className="form-group">
                        <label htmlFor="emirate" className="form-label">
                          Emirate <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="emirate"
                          id="emirate"
                          placeholder="Choose Emirate..."
                          options={emiratesOptions?.map((emirate) => {
                            return {
                              label: emirate,
                              value: emirate,
                            };
                          })}
                          onChange={this.handleSelect}
                        />
                      </div>
                      <div className="text-danger">
                        {this.validator.message(
                          "emirate",
                          this.state.emirate,
                          `required`
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="company-name" className="form-label">
                          Company Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company-name"
                          placeholder="Enter Company Name"
                          value={this.state.company_name || ""}
                          name="company_name"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "company_name",
                            this.state.company_name,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="company-address" className="form-label">
                          Company Address <span style={{ color: 'red' }}>*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-4">
                            <Select
                              className={`react-select`}
                              classNamePrefix="react-select"
                              name="company_area"
                              id="company_area"
                              placeholder="Choose company Area"
                              options={this.state?.stateData?.map((type) => {
                                return {
                                  label: type.name,
                                  value: type.name,
                                };
                              })}
                              onChange={this.handleSelect}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "company_area",
                                this.state.company_area,
                                `required`
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <SearchLocationInput
                              setAddress={this.setAddress}
                              databaseaddress={""}
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "company_address",
                                this.state.company_address,
                                `required`
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-no"
                          className="form-label"
                        >
                          Trade License No <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="trade-license-no"
                          placeholder="Enter Trade Licnse No"
                          value={this.state.trade_license_no || ""}
                          name="trade_license_no"
                          onChange={this.handleInput}
                          required
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade",
                            this.state.trade_license_no,
                            `required|tradeUniqueCheck`
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="trade-license-upload"
                          className="form-label"
                        >
                          Trade License Upload <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="trade-license-upload"
                          onChange={(e) => {
                            let isLt5M = validateFile(e.target.files[0])
                            if (!isLt5M) {
                              e.target.value = ''
                              return false

                            }
                            this.setSelectedFile(
                              e.target.files[0],
                              "trade_license_upload"
                            )
                          }
                          }
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "trade_license_upload",
                            this.state.trade_license_upload,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="register_vat"
                          id="register_vat"
                          value={this.state.isRegisteredForVat}
                          onChange={() => {
                            this.setState(prevState => ({
                              isRegisteredForVat: !prevState.isRegisteredForVat
                            }));
                            this.validator.purgeFields(['vat_registration_no', 'vat_registration_upload']);
                          }}
                        />
                        <label
                          htmlFor="register_vat"
                          className="not_empty custom-control-label"
                        >
                          Are you registered for VAT?
                        </label>
                      </div>

                      {
                        this.state.isRegisteredForVat &&
                        <>
                          <div className="form-group">
                            <label htmlFor="vat-reg-no" className="form-label">
                              VAT Registration No
                              {
                                this.state.isRegisteredForVat &&
                                <span style={{ color: 'red' }}>*</span>
                              }
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="vat-reg-no"
                              placeholder="Enter Registration No"
                              value={this.state.vat_registration_no || ""}
                              name="vat_registration_no"
                              onChange={this.handleInput}
                              required
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "vat",
                                this.state.vat_registration_no,
                                `required|tradeOrVatRegexCheck|vatUniqueCheck`
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label
                              htmlFor="vat-cretificate-upload"
                              className="form-label"
                            >
                              VAT Certificate Upload
                              {
                                this.state.isRegisteredForVat &&
                                <span style={{ color: 'red' }}>*</span>
                              }
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="vat-cretificate-upload"
                              onChange={(e) => {
                                let isLt5M = validateFile(e.target.files[0])
                                if (!isLt5M) {
                                  e.target.value = ''
                                  return
                                }
                                this.setSelectedFile(
                                  e.target.files[0],
                                  "vat_registration_upload"
                                )
                              }
                              }
                            />
                            <div className="text-danger">
                              {this.validator.message(
                                "vat_registration_upload",
                                this.state.vat_registration_upload,
                                `required`
                              )}
                            </div>
                          </div>
                        </>
                      }

                      <div className="form-group">
                        <label htmlFor="user_type" className="form-label">
                          Seller Account Type <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix="react-select"
                          name="user_type"
                          id="user_type"
                          placeholder="Choose Type..."
                          options={userTypeOptions?.map((type) => {
                            return {
                              label: type,
                              value: type,
                            };
                          })}
                          onChange={this.handleUserSelect}
                        />
                        <div className="text-danger">
                          {this.validator.message(
                            "user_type",
                            this.state.user_type,
                            `required`
                          )}
                        </div>
                      </div>

                      <div className="atbd_term_and_condition_area custom-control custom-checkbox checkbox-outline checkbox-outline-primary">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="listing_t"
                          value="1"
                          id="listing_t"
                          onChange={this.acceptTerms}
                        />
                        <label
                          htmlFor="listing_t"
                          className="not_empty custom-control-label"
                        >
                          By Clicking, you are accepting{" "}
                          <NavLink to="/terms">
                            the terms and conditions and privacy policy <span style={{ color: 'red' }}>*</span>
                          </NavLink>
                        </label>
                        <div className="text-danger">
                          {this.validator.message(
                            "Terms and Conditions",
                            this.state.acceptTerms,
                            `accepted`
                          )}
                        </div>
                      </div>
                      <div className="btn_wrap list_submit m-top-25">
                        <button
                          type="submit"
                          disabled={
                            this.state.submitLoading === false ? false : true
                          }
                          className="btn btn-primary btn-lg listing_submit_btn"
                          onClick={this.handleSubmit}
                        >
                          {this.state.submitLoading && (
                            <i className="las la-spinner la-spin mr-2"></i>
                          )}
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    login: (data) => dispatch(LogInAc(data)),
  };
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(RegisterSeller);
