import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./MobileMenu.css";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider, makeStyles } from "@material-ui/core";
import { Button, ButtonGroup } from "reactstrap";
import {
  GridViewIcon, ArrowForwardIosIcon, ListItemIcon, Search, Person2Icon, ShoppingCartOutlinedIcon,
  PersonOutlineOutlinedIcon,
  NoteAddOutlinedIcon,
  InsertDriveFileOutlinedIcon,
  FmdGoodOutlinedIcon,
  ListAltOutlinedIcon,
  ShoppingBagOutlinedIcon,
  FavoriteBorderOutlinedIcon,
  EmailOutlinedIcon,
  LockOutlinedIcon,
  TextSnippetOutlinedIcon,
  LogoutOutlinedIcon
} from "../../common/MaterialIcons";
import { ga4Events } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../../../Store/action/logoutAction";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: '25px',
    marginBottom: '8px'
  },
  arrowIcon: {
    transform: "scale(0.6)",
    marginBottom: '8px'
  },
  logout: {
    minWidth: '25px',
    marginBottom: '12px'
  },
  headerBanner: {
    backgroundColor: '#F0F0F0',
    height: '32px',
    textAlign: 'center'
  },
  headerSubBanner: {
    backgroundColor: '#F4F4F4',
    height: '32px',
    textAlign: 'center'
  },
  bannerText: {
    paddingTop: 6,
    color: '#1D71B8',
    fontSize: '12px',
    fontWeight: 700
  },
  bannerAnchor: {
    fontWeight: 800,
    textDecoration: 'underline !important',
    paddingLeft: 10
  }
}));
const MobileMenu = ({ showMobileMenu, loggedUser, moreMobileFooter }) => {
  const { itemsInCart, bulkCartListCount } = useSelector(state => state.list);
  const dispatch = useDispatch();
  const classes = useStyles();

  const logOut = (e) => {
    e.preventDefault();
    dispatch(LogOut(null))
    ga4Events({ action: "profile_menu_logout", category: "User" });
    localStorage.removeItem("user");
    localStorage.clear();

    window.location.href = "/";
  };
  return (
    <div className="mobile-menu-container">
      {showMobileMenu && (
        <>
          <div className={classes.headerBanner}>
            <div className={classes.bannerText}>{'10% Off Mobile oil - EXPRESS20'}
              <a className={classes.bannerAnchor}>{'SHOP NOW  >'}</a>
            </div>
          </div>
          <div className={classes.headerSubBanner}>
            <div className={classes.bannerText}> Same Day Delivery</div>
          </div>
          <div className="mobile-menu" style={{ marginBottom: 105 }}>
            {/* Logo and Icons in the same line */}
            <div className="logo-icons-container">
              {/* Logo */}
              <div className="logo">
                <a href="/" >
                  <img src="/assets/img/logo1.svg" alt="logoImage" width="130" />
                </a>
              </div>
              {/* Right side icons */}
              <div className="icons">
                <Search />
                <PersonOutlineOutlinedIcon />
                <NavLink className="mobilelinkText"
                  to="/checkout"
                  onClick={() =>
                    moreMobileFooter(false)
                  }
                >
                  <ShoppingCartOutlinedIcon />
                </NavLink>
                {itemsInCart?.length || bulkCartListCount ?
                  <span className="cart-count" style={{ position: 'absolute', right: '16px' }}>  {itemsInCart?.length || bulkCartListCount}</span> : <></>
                }
              </div>
            </div>

            {/* Greeting */}
            <div className="greeting">
              <span className="small-font flex-span">Hi,</span>
              <span className="bold-font"> {loggedUser}</span>
            </div>

            {/* Menu List */}
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"

            >
              <NavLink className="mobilelinkText"
                to="/my-dashboard"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <GridViewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/my-profile"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <PersonOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/credits"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <NoteAddOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Wallet" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/quotations"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <InsertDriveFileOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Quotation" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/your-address"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <FmdGoodOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Address" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/my-order"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <ListAltOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Orders" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/coming-soon"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <ShoppingBagOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Purchased Items" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/coming-soon"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <FavoriteBorderOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Wishlist" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/my-dashboard"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <EmailOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/help"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <img
                      src="/assets/img/help.svg"
                      alt="Help"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/terms"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <TextSnippetOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Terms & Conditions" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
              <Divider />
              <NavLink className="mobilelinkText"
                to="/privacy"
                onClick={() =>
                  moreMobileFooter(false)
                }
              >
                <ListItemButton>
                  <ListItemIcon className={classes.icon}>
                    <LockOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Privacy Policy" />
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </ListItemButton>
              </NavLink>
            </List>

            <ButtonGroup className="d-flex" style={{ marginBottom: 20, marginTop: 20 }}>
              <Button
                style={{ color: '#F39200' }}
                outline
                onClick={logOut}
              >
                <LogoutOutlinedIcon className={classes.logout} />
                <span className="logout">LOGOUT</span>
              </Button>
            </ButtonGroup>
          </div>
        </>

      )}
    </div>
  );
};

export default MobileMenu;
