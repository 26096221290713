import { LocationOn } from '@material-ui/icons';
import { GoogleApiWrapper } from 'google-maps-react';
import React, { useEffect, useRef, useState } from 'react'
import usePlacesAutocomplete from "use-places-autocomplete";

import './style.css';

const SearchCityInput = ({
    placeholder = "",
    address = "",
    setAddress
}) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: ["(cities)"],
            componentRestrictions: { country: "ae" },
            language: "en"
        },
        debounce: 300,
    });
    
    const wrapperRef = useRef(null);
    const [showSuggestion, setShowSuggestion] = useState(false);

     // use useEffect to blur the input element on component update
   useEffect(() => {
    const handleVisibilityChange = (event) => {
        if(wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowSuggestion(false);
        }  
    };
    document.addEventListener("click", handleVisibilityChange);

    return () => {
      document.removeEventListener("click", handleVisibilityChange);
    };
  }, []);

    useEffect(() => {
        if(address) {
            setValue(address);
        }
    }, [address])
    

    const handleInput = (e) => {
        // Update the keyword of the input element
        const { value } = e.target;
        setValue(value);
        setAddress(value);
        if (value) {
            setShowSuggestion(true);
        }
    };

    const handleSelect =
        ({ description }) =>
            () => {
                // When user selects a place, we can replace the keyword without request data from API
                // by setting the second parameter to "false"
                setValue(description, false);
                setAddress(description);
                setShowSuggestion(false);
            };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;
            return (
                <li className='city__suggestion-li' key={place_id} onClick={handleSelect(suggestion)}>
                    <LocationOn className='city__suggestion-icon' />
                    <strong className='city__suggestion-main'>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <div ref={wrapperRef} style={{ position: "relative" }}>
            <input
                className='form-control'
                value={value}
                onChange={handleInput}
                disabled={!ready}
                onFocus={() => setShowSuggestion(true)}
                placeholder={placeholder}
                name='location'
                autoComplete="off"
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && showSuggestion && <ul className='city__suggestion-ul'>{renderSuggestions()}</ul>}
        </div>
    );
};
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(SearchCityInput);