import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";
import { FormGroup, Input, Alert } from "reactstrap";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
const Cart = () => {
  const [amount, setAmount] = useState(35);
  return (
    <Fragment>
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container">
          <Typography className="" style={{ textAlign: "left" }}>
            <h3>Cart</h3>
          </Typography>
          <Row>
            <Col xs="8" sm="8">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <p>Seller: Tommy A car parts</p>
                </Typography>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <img
                          src="/assets/img/stepperimg/cashsecurely.png"
                          alt="item"
                          style={{ width: "100px" }}
                        />
                      </Col>
                      <Col>
                        <Typography style={{ textAlign: "left" }}>
                          <h6>Castrol EDGE Engine Oil 5W-30 5 Litre</h6>
                          <p>Item SKU. 299206</p>
                        </Typography>
                      </Col>
                      <Col sm="3">
                        <Button
                          className="plusminus"
                          size="sm"
                          color="light"
                          onClick={() => {
                            if (amount < 1) {
                              setAmount(0);
                            }
                            setAmount(amount - 1);
                          }}
                        >
                          -
                        </Button>
                        <input
                          style={{ width: "35px" }}
                          type="text"
                          className="num"
                          value={'$' + amount}
                        />
                        <Button
                          className="plusminus"
                          color="light"
                          size="sm"
                          onClick={() => {
                            if (amount < 1) {
                              setAmount(0);
                            }
                            setAmount(amount + 1);
                          }}
                        >
                          +
                        </Button>
                      </Col>
                      <Col>
                        <Typography>AED35.99</Typography>
                      </Col>
                      <Col>
                        <Button color="light" className="">
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <br />
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Typography className="" style={{ textAlign: "left" }}>
                  <p>Seller: Tommy B car parts</p>
                </Typography>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <img
                          src="/assets/img/stepperimg/cashsecurely.png"
                          alt="item"
                          style={{ width: "100px" }}
                        />
                      </Col>
                      <Col>
                        <Typography style={{ textAlign: "left" }}>
                          <h6>Export Wet Look Aerosol Tires Shine 400g</h6>
                          <p>Item SKU. 1139</p>
                        </Typography>
                      </Col>
                      <Col sm="3">
                        <Button
                          className="plusminus"
                          size="sm"
                          color="light"
                          onClick={() => {
                            if (amount < 1) {
                              setAmount(0);
                            }
                            setAmount(amount - 1);
                          }}
                        >
                          -
                        </Button>
                        <input
                          style={{ width: "35px" }}
                          type="text"
                          className="num"
                          value={'$' + amount}
                        />
                        <Button
                          className="plusminus"
                          color="light"
                          size="sm"
                          onClick={() => {
                            if (amount < 1) {
                              setAmount(0);
                            }
                            setAmount(amount + 1);
                          }}
                        >
                          +
                        </Button>
                      </Col>
                      <Col>
                        <Typography>AED35.99</Typography>
                      </Col>
                      <Col>
                        <Button color="light" className="">
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col sm="4">
              <div
                style={{
                  padding: "5px",
                }}
              >
                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "left" }}>
                      Order Summary
                    </CardTitle>
                    <div style={{ padding: "" }}>
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Subtotal
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          76.40$
                        </p>
                      </div>
                      <br />
                      <br />
                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>
                          Discount
                        </p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          36.12$
                        </p>
                      </div>

                      <br />
                      <br />

                      <div>
                        <p style={{ float: "left", fontWeight: "bold" }}>Tax</p>
                        <p style={{ float: "right", fontWeight: "bold" }}>
                          1.12$
                        </p>
                      </div>
                      <br />
                      <br />

                      <div>
                        <p style={{ textAlign: "left", fontWeight: "bold" }}>
                          Shipping
                        </p>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy A car parts
                        </Typography>
                        <Typography style={{ textAlign: "left" }}>
                          Tommy B car parts
                        </Typography>
                      </div>
                      <br />
                      <div>
                        <FormGroup>
                          <Input
                            style={{ float: "left", width: "70%" }}
                            placeholder="Gift card/Discount code"
                            type="text"
                            name="text"
                            id="text"
                          />
                        </FormGroup>
                        <Button
                          style={{ float: "right" }}
                          color="primary"
                          size="sm"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <p style={{ float: "left", fontWeight: "bold" }}>Total</p>
                      <p style={{ float: "right", fontWeight: "bold" }}>
                        40.45$
                      </p>
                    </div>
                    <br />

                    <br />
                    <div>
                      <Button color="primary" style={{ width: "100%" }}>
                        Make a payment
                      </Button>
                    </div>
                  </CardBody>
                  <Alert
                    color="warning"
                    style={{
                      padding: "10px",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    Free standard shipping to U.A.E. when you spend over AED100.00
                  </Alert>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
};

export default connect({})(Cart);
