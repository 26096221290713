import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Alert } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Badge } from "reactstrap";

import ReactStars from "react-rating-stars-component";

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

const Placed = ({ history }) => {
  return (
    <Fragment>
      <section className="blog-area blog-grid section-padding-strict section-bg">
        <div className="container">
          <div>
            <Card style={{ textAlign: "left" }}>
              <Typography>
                <h5>Order Summary</h5>
              </Typography>
              <br />
              <Row>
                <Col sm="2">
                  <img
                    src="/assets/img/stepperimg/gilan.png"
                    alt="item"
                    style={{ width: "100px" }}
                  />
                </Col>
                <Col sm="3">
                  <Typography>
                    <h6 style={{ color: "red" }}>1 x AED35.99</h6>
                  </Typography>
                </Col>
                <Col sm="3">
                  <Typography>
                    <h6>AED35.99</h6>
                  </Typography>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup>
                    <Label>SubTotal</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Discount</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>Tax</Label>
                  </FormGroup>
                  <FormGroup>
                    <h6>Shipping</h6>
                    <Typography> Tommy A car Parts</Typography>
                    <Typography> Tommy B car Parts</Typography>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>AED76.98</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ color: "red" }}>AED36</Label>
                  </FormGroup>
                  <FormGroup>
                    <Label>$.123</Label>
                  </FormGroup>
                  <br />
                  <FormGroup>
                    <Typography>AED12.99 </Typography>
                    <Typography> AED12.99</Typography>
                  </FormGroup>
                </Col>
                <Col>
                  <br />
                  <br />
                  <FormGroup>
                    <h5> Total</h5>
                    <Typography>AED52.96</Typography>
                  </FormGroup>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Card>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps)(Placed);
