import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div
        className="breadcrumb-wrapper cover innerpage-banner"
        style={{ backgroundImage: 'url("/assets/img/section-banner.jpg")' }}
      >
        <div className="container">
          <h1>Privacy Policy</h1>
        </div>
      </div>
      <div
        className="content-wrapper privacy-policy-wrapper"
        style={{ paddingBottom: 0 ,background: '#F5F5F7'}}
      >
        <div className="container">
          <div
            className="termsandconditions-content"
            style={{ paddingTop: 50 }}
          >
            <h3>WHO WE ARE</h3>
            <p style={{ textAlign: "justify" }}>
              The website{" "}
              <a href="https://www.buyparts24.com" target="_blank">
                https://www.buyparts24.com
              </a>{" "}
              is operated by <strong>GA AUTO PLATFORMS DMCC</strong>, with
              e-market place service licence No. DMCC-808255 and general trading
              licence number DMCC-808254 whose registered office address is Unit
              No 2182 Business Centre, Level No1- Jewellery & Gemplex 3- Dubai,
              United Arab Emirates, of PO Box 294377 (
              <strong>“we”, “our” or “us”</strong>) so that we may: (1) help
              direct you to products and services which may be of interest to
              you; and (2) contact you with information and offers, suggestions
              and recommendations of products and services which may be of
              interest to you.{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              Where we use “our site” in this privacy notice, we mean{" "}
              <a href="https://www.buyparts24.com" target="_blank">
                https://www.buyparts24.com
              </a>
            </p>
          </div>

          <div
            className="termsandconditions-content"
            style={{ paddingTop: 30 }}
          >
            <h3>OUR PRIVACY TERMS</h3>
            <p style={{ textAlign: "justify" }}>
              <strong>The information we collect about you</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              Each time you visit our site, we may automatically collect
              information about you and the device you are using, including:
            </p>
            <p>
              <ul>
                <li>
                  information about which pages you visit and which resources you
                  use on our site
                </li>
                <li>information about how you got to our site</li>
                <li>
                  your internet protocol address known as your IP address which is
                  used to connect your device to the internet
                </li>
                <li>
                  information about your device, including type, model number,
                  configuration, network strength, battery usage operating system
                  and platform
                </li>
                <li>the type and version of the browser you are using</li>
                <li>
                  traffic data, which is aggregated information about the number
                  of visitors, sessions, users and page visits to our site
                </li>
                <li>location data</li>
                <li>time zone setting</li>
                <li>language preferences</li>
                <li>weblogs</li>
              </ul>
            </p>
            <p>
              We also collect any information you provide to us directly by
              filling in any of the forms on our site.
            </p>
            <p>
              If you sign up with us as a buyer or seller, we may also collect:{" "}
            </p>
            <p>
              <ul>
                <li>your name</li>
                <li>your email address</li>
                <li>your address</li>
                <li>your telephone number</li>
              </ul>
            </p>
            <p>
              <strong>Where do we get your information from?</strong>
            </p>
            <p>
              We collect the information you provide to us directly, or by
              filling in any of the forms on our website.
            </p> 
            <p>
              We also collect information directly from your device.  We use cookies to collect
              some of this information. Please see our ‘Our Use of Cookies’
              below for details of the information we collect through our site.
            </p>
            <p>
              <strong>Why do we need your information?</strong>
            </p>
            <p>
              We need the information we collect automatically about your visit
              to our site to:
            </p>
            <p>
              <ul>
                <li>administer our site</li>
                <li>
                  improve our site to ensure that content is presented in the most
                  effective manner
                </li>
                <li>
                  allow you to participate in interactive features of our services
                  when you choose to do so
                </li>
                <li>
                  analyse how visitors use our site (including behaviour patterns
                  and the tracking of visits across multiple devices)
                </li>
                <li>improve your online experience.</li>
              </ul>
            </p>
            <p>
              We need the information you provide directly by filling in any of
              the forms on our site to:
            </p>
            <p>
              <ul>
                <li>
                  direct you to products and/or services which may be of interest
                  to you
                </li>
                <li>
                  contact you via SMS, email, Phone Calls, Social Media messaging 
                  apps (eg: WhatsApp, telegram) with information and offers, 
                  suggestions and recommendations of products and/or services which 
                  may be of interest to you
                </li>
                <li>
                  contact you about those products and/or services on our site in
                  which you have expressed an interest
                </li>
              </ul>
            </p>
            <p>
              You do not have to give us any information or confirm that the
              information we have obtained from other sources is correct.
            </p>
            <p>
              <strong>Who do we share your information with?</strong>
            </p>
            <p>
              We sometimes share some of your information with other
              organisations, namely affiliates or subsidiaries who are bound by
              similar confidentiality obligations.
            </p>
            <p>
              We use Google analytics to collect details of how you use our
              site, as well as anonymous data that you enter into our forms. We
              do this to help us analyse how visitors use our site (including
              behaviour patterns and the tracking of visits across multiple
              devices).  All the information that is collected is anonymous and
              is only used to improve how our site works. Please see ‘Our Use of
              Cookies’ below for more details about Google analytics.
            </p>
            <p>
              We also use a number of carefully selected third parties to supply
              us and you with products and services. We will only share your
              information with our suppliers where it is necessary for them to
              provide us, or you with the products and services requested.
            </p>
            <p>
              <strong>Where is your information stored?</strong>
            </p>
            <p>
              Your information is stored on servers and filing systems in the in
              the United Arab Emirates .{" "}
            </p>
            <p>
              Our site may, from time to time, contain links to and from the
              websites of third parties (e.g. payment processing sites).  If you
              follow a link to any of these websites, please note that these
              websites have their own terms and conditions, privacy notices and
              cookie notices and we do not accept any responsibility or
              liability for those notices or your use of those websites.
            </p>
            <p>
              Unfortunately sending information via the internet is not totally
              secure. Although we do our best to protect your information, we
              cannot guarantee the security of any information you transmit to
              our site; any transmission is at your own risk. Once we have
              received your information, we will use strict procedures and
              security features to prevent unauthorised access.
            </p>
            <p>
              <strong>How long do we keep your information for?</strong>
            </p>
            <p>We only keep your information for as long as we need it.</p>
            <p>
              We keep your information for this long so that we can ensure we
              contact you with information on products, services, suggestions,
              offers and recommendations relevant and appropriate to you.
            </p>
            <p>
              <strong>
                Do we carry out profiling and automated decision making?
              </strong>
            </p>
            <p>
              We use Google analytics to collect details of how you use our
              site, as well as anonymous data that you enter into our forms. We
              do this to help us analyse how visitors use our site (including
              behaviour patterns and the tracking of visits across multiple
              devices), and to administer our site.  Please see ‘Our Use of
              Cookies’ below for more details about Google analytics.
            </p>
            <p>
              <strong>What rights do you have?</strong>
            </p>
            <p>
              Under the data protection rules, you have a number of rights in
              respect of your information, which include the right to:
            </p>
            <p>
              <ul>
                <li>access your information</li>
                <li>
                  withdraw your consent to the processing of your information at
                  any time
                </li>
                <li>
                  ask us to make changes to the information we hold about you to
                  make sure that it is accurate and up to date
                </li>
                <li>
                  delete or erase your information (sometimes called the right to
                  be forgotten)
                </li>
                <li>stop or restrict our processing of your information</li>
                <li>object to our processing your information</li>
                <li>not be subject to automated decision-making and</li>
                <li>
                  request the transfer of some of the information we hold about
                  you (known as data portability).
                </li>
              </ul>
            </p>
            <p>
              If you would like to exercise any of your rights, please contact
              us at{" "}
              <a href="mailto:helpdesk@buyparts24.com">helpdesk@buyparts24.com</a>. Please note that an archive copy
              of any information provided to us may be retained by us for our
              records and for audit purposes. Subject to the information you
              have requested us to delete, we may not be able to continue
              providing you with our services and may need to close your
              account.{" "}
            </p>
            <p>
              You can unsubscribe from our emails at any time by clicking on
              “unsubscribe” at the bottom of the email.
            </p>
            <p>
              <strong>Making a complaint</strong>
            </p>
            <p>
              If you have any questions, concerns or complaints about the way we
              process your personal information, please contact{" "}
              <a href="mailto:helpdesk@buyparts24.com">helpdesk@buyparts24.com</a>
            </p>
          </div>

          <div
            className="termsandconditions-content"
            style={{ paddingTop: 30 }}
          >
            <h3>OUR USE OF COOKIES</h3>
            <p>
              <strong>What are cookies?</strong>
            </p>
            <p>
              Cookies are text files containing small amounts of information
              which are downloaded to your device when you visit our site. Our
              site recognises those cookies on each subsequent visit, enabling
              our site to recognise you.
            </p>
            <p>
              Unless you have adjusted your browser settings to refuse cookies
              (and details of how to do this are signposted at the end of this
              policy), our site will set cookies as soon as you visit our site.
            </p>
            <p>
              <strong>How and why we use cookies</strong>
            </p>
            <p>We use cookies on our site to:</p>
            <p>
              <ul>
                <li>recognise you whenever you visit our site;</li>
                <li>
                  measure how you use our site so it can be updated and improved
                  to give you the best possible experience on our site.;
                </li>
              </ul>
            </p>
            <p>
              The information we obtain from our use of cookies will not usually
              contain information from which you can easily be identified, such
              as your name.  However, we do collect some personal data relating
              to your computer or other electronic device, such as your IP
              address, your browser and/or other internet log information.
            </p>
            <p>
              In most cases we will need your consent to use cookies on our
              site.  The exception to this is where the cookie is essential for
              us to provide you with a service you have requested.
            </p>
            <p>
              You may withdraw your consent or acceptance at any time by
              following the instructions for disabling cookies, signposted at
              the end of this policy.
            </p>
            <p>We use third party performance cookies on our site. </p>
            <p>
              These non-essential cookies collect information about how visitors
              use our site, for instance which pages visitors go to most often,
              and if they get error messages from web pages. These cookies don’t
              collect information that identifies a visitor. All information
              these cookies collect is aggregated and therefore anonymous. It is
              only used to improve how a website works.
            </p>
            <p>
              We use Google Analytics to collect information about how you use
              our site. We don’t allow Google to use or share our analytics
              data.
            </p>
            <p>
              We use the information to compile reports and to help us improve
              our site. The cookies collect information in an anonymous form,
              including the number of visitors to our site, where visitors have
              come to our site from and the pages they visited.
            </p>
            <p>Google Analytics stores information about:</p>
            <p>
              <ul>
                <li>The pages you visit on our site</li>
                <li>The actions you perform on our site</li>
                <li>How long you spend on each page</li>
                <li>How you got to our site</li>
                <li>What you click on while you’re visiting our site</li>
                <li>
                  Information about the browser and device you use to navigate our
                  site, (including an anonymised IP address).
                </li>
                <li>
                  We don’t collect or store any personal information (for example
                  your name or address) that can be used to identify who you are.
                </li>
              </ul>
            </p>
            <p>
              More details about how Google Analytics collects and processes
              data are available at{" "}
              <a href="https://www.google.com/policies/privacy/partners" target="_blank">
                https://www.google.com/policies/privacy/partners
              </a>
            </p>
            <p>
              You can opt out of being tracked by Google Analytics cookies
              across all websites{" "}
              <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">
                http://tools.google.com/dlpage/gaoptout
              </a>
            </p>
            <p>
              <strong>Where to find more information?</strong>
            </p>
            <p>
              You can adjust the settings in your web browser to determine
              whether (all) sites can set cookies on your computer.  More
              information about cookies and how to manage them, specific to your
              browser, can be found at{" "}
              <a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank">
                https://ico.org.uk/for-the-public/online/cookies/
              </a>
              .
            </p>
            <p>
              If you have any further questions, comments or requests regarding
              our use of cookies or how we use cookies on our site, please
              contact us at{" "}
              <a href="mailto:helpdesk@buyparts24.com">helpdesk@buyparts24.com</a>.
            </p>
            <p>
              <strong>Changes to this notice</strong>
            </p>
            <p>
              We will regularly review this notice and keep it updated to make
              sure that the information we provide you with is accurate and up
              to date.
            </p>
            <p>
              Any changes to this notice will be highlighted on our site so that
              you can see what has been changed.
            </p>
            <p>This notice was last reviewed and updated in May 2023.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
