import React from "react";
import { PageBanner } from "../page-banner";

const index = ({ header }) => {
  return (
    <>
      <PageBanner />
      {header && (
        <>
          <PageBanner />
        </>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <img
          src="assets/img/emptymessage.png"
          alt="No-data-image"
          width="30%"
        />{" "}
        <div
          style={{
            fontWeight: "700",
            fontSize: "18px",
            color: "#1e71b8",
            width: "50%",
            textAlign: "center",
          }}
        >
          Your account is still pending approval, Please wait for the admin to verify and approve your account to continue with your purchase.
        </div>
      </div>
    </>
  );
};

export default index;
