import React from "react";

const index = ({ text }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <img
          src="assets/img/emptymessage.png"
          alt="No-data-image"
          width="30%"
        />{" "}
        <div
          style={{
            fontWeight: "700",
            fontSize: "18px",
            color: "#1e71b8",
            width: "50%",
            textAlign: "center",
          }}
        >
          {text}
        </div>
      </div>
    </>
  );
};

export default index;
