import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ga4Events } from '../../utils';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share';



const ShareModal = ({ isModalOpen, toggle, id, link }) => {
    const pageUrl = window.location.href;
    const handleGaEvent = (action) => {
        ga4Events({
            action: "pdp_share_click",
            category: "Listing",
            product_id: id,
        });
    }



    return (
        <>
            <Modal
                isOpen={isModalOpen}
                toggle={(e) => {
                    toggle(e);
                }}
                centered
                size="lg"
                style={{ maxWidth: '600px' }}
            >
                <ModalBody >
                    <div className='mb-3' >
                        <button
                            type='button'
                            className='close mr-2'
                            data-dismiss='modal'
                            aria-label='Close'
                            style={{ outline: 'none', color: "black" }}
                            onClick={toggle}
                        >
                            <img
                                src='/assets/img/close.png'
                                alt='close'
                                style={{ fontSize: 37, height: 19, color: "black" }}
                            />
                        </button>
                    </div>
                    <div style={{ padding: "5px" }}>
                        <div className='text-center'>
                            <div className='logo logo-top' style={{ marginLeft: "27px" }}>
                                <img
                                    src='/assets/img/logo-icon.png'
                                    alt='buyParts'
                                    style={{ height: 60, }}
                                />
                            </div>
                            <h4 className='mt-3 mb-3' style={{ fontSize: 26 }}>Share Via:</h4>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                                marginTop: "5px",
                            }}>


                            <div style={{
                                borderRight: "1px solid #ededf6",
                                textAlign: "center",
                                padding: 20

                            }}
                                onClick={() => handleGaEvent("pdp_share_email_click")}
                            >
                                <EmailShareButton url={pageUrl}>
                                    <a onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                        href={""}
                                        target='_blank'
                                        style={{ fontSize: "60px", display: "block" }}>
                                        <span style={{ fontSize: "60px", color: "black" }}> @ </span>
                                    </a>
                                    <br />
                                    <span style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bold",

                                    }}>Email</span>
                                </EmailShareButton>

                            </div>




                            <div style={{
                                borderRight: "1px solid #ededf6",
                                textAlign: "center",
                                padding: 20

                            }}
                                onClick={() => handleGaEvent("pdp_share_whatsapp_click")}
                            >
                                <WhatsappShareButton url={pageUrl}>
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault()
                                        }}
                                        href={""}
                                        target='_blank'
                                        style={{ fontSize: "60px", display: "block" }}
                                    >

                                        <span >
                                            <img
                                                src="/assets/img/social-logos/whatsapp.png"
                                                width="60"
                                                alt="facebook"

                                            />
                                        </span>

                                    </a>
                                    <br />

                                    <span style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bold",

                                    }}>Whatsapp</span>


                                </WhatsappShareButton>

                            </div>

                            <div style={{
                                borderRight: "1px solid #ededf6",
                                textAlign: "center",
                                padding: 20
                            }}
                                onClick={() => handleGaEvent("pdp_share_facebook_click")}
                            >
                                <FacebookShareButton url={pageUrl} >
                                    <a
                                        href=""
                                        target="_blank"
                                        style={{ fontSize: "60px", display: "block" }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                        }}
                                    >
                                        <span>
                                            <img
                                                src="/assets/img/social-logos/facebook.png"
                                                width="60"
                                                alt="facebook"
                                            />
                                        </span>
                                    </a>
                                    <br />
                                    <span style={{
                                        color: "black",
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                    }}>Facebook</span>

                                </FacebookShareButton>


                            </div>

                        </div>

                        <button
                            type='submit'
                            className='btn btn-block submitBtn'
                            style={{
                                backgroundColor: '#f39200',
                                color: '#ffff',
                                whiteSpace: 'nowrap',
                                fontSize: '15px',
                                marginTop: "20px"
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(pageUrl);
                                handleGaEvent("pdp_share_copy_link_click")

                            }}
                        >
                            Copy Link
                        </button>

                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProp = (dispatch) => {
    return {

    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProp)
)(ShareModal);
