import axios from "axios";

export const getHomepageSection = () => {
    return async (dispatch) => {
        dispatch({
            type: "GET_HOMEPAGE_SECTION_BEGIN",
        })
        axios
            .get(`/api/homepage-settings?status=true&all=true`)
            .then(res => {
                dispatch({
                    type: "GET_HOMEPAGE_SECTION_SUCCESS",
                    data: res.data?.homePageSettings
                })
            })
            .catch(err => {
                dispatch({
                    type: "GET_HOMEPAGE_SECTION_ERROR",
                })
            });
    };
};