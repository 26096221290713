import React from "react";
import { useSelector } from "react-redux";
const ShowDynamicPrice = (props) => {
  const { currency, conversionRate } = useSelector((state) => {
    return {
      currency: state.currency.currency,
      conversionRate: state.currency.conversionRate,
    };
  });

  const { price, isHighlighted, isShowExcVat, hideExc } = props;

  return (
    <>
      <span className={isHighlighted ? "text-danger" : ""} style={{
        fontSize: '14px',
        color: '#333333',
        fontWeight: 700,
        marginLeft: '6px'
      }}>
        {conversionRate == 0
          ? Number(price)
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : (Number(price) * conversionRate)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>
      {!hideExc && <span style={{ fontSize: 11, fontWeight: 400, marginLeft: '10px' }} className="text-muted">Exc. VAT</span>
      }
    </>
  );
};

export default ShowDynamicPrice;
