import axios from "axios";

export const getAllRegions = _ => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_REGION_BEGIN",
            })
            const res = await axios.get('/api/products/get-regions');
            dispatch({
                type: "GET_REGION_SUCCESS",
                data: res?.data?.regions
            })
        } catch (err) {
            dispatch({
                type: "GET_REGION_ERROR",
            })
        }
    };
};

export const getAllTradeNo = type => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_ALL_TRADE_NO_BEGIN",
            })
            const res = await axios.get(`/api/users/get-all-trade-no?type=${type}`);
            dispatch({
                type: "GET_ALL_TRADE_NO_SUCCESS",
                data: res?.data
            })
        } catch (err) {
            dispatch({
                type: "GET_ALL_TRADE_NO_ERROR",
            })
        }
    };
};

export const getAllVATNo = type => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_ALL_VAT_NO_BEGIN",
            })
            const res = await axios.get(`/api/users/get-all-vat-no?type=${type}`);
            dispatch({
                type: "GET_ALL_VAT_NO_SUCCESS",
                data: res?.data
            })
        } catch (err) {
            dispatch({
                type: "GET_ALL_VAT_NO_ERROR",
            })
        }
    };
};

export const getAllStates = _ => {
    return async dispatch => {
        try {
            dispatch({
                type: "GET_STATE_BEGIN",
            })
            const res = await axios.get('/api/products/get-state');
            dispatch({
                type: "GET_STATE_SUCCESS",
                data: res?.data?.state || []
            })
        } catch (err) {
            dispatch({
                type: "GET_STATE_ERROR",
            })
        }
    };
};