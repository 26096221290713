import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router';
import { ga4Events, getCarBrandLogo, popUp } from '../../../../../utils';
import "./VinHistory.css";

export default function VinHistory({ setOpen, setSearch }) {
  const history = useHistory();
  const { vinSearchHistory } = useSelector(state => state.list)
  const [vinSearchData, setVinSearchData] = useState({
    categoriesVIN: [],
    catalogId: null,
    criteria: null,
    carIdentified: false,
    makeFromVIN: null,
    modelFromVIN: null,
    carId: null,
    startTime: Date.now(),
  })

  const login = localStorage.getItem("login");
  const user = JSON.parse(login);

  const handleSearchHistoryClick = (e, vinNumber) => {
    ga4Events({
      action: "vin_search_by_history",
      category: "Listing",
      vin_number: vinNumber,
    });
    setSearch(vinNumber);
    setOpen(false);
    // history.push(`/parts-search-vin?vin=${vinNumber}`);
    // getVehiclesByVIN(vinNumber);
  };

  /**
 * 
 * Added this method to call vehicles-by-vin-tradesoft and update state
 */
  const getVehiclesByVIN = async (vinCode) => {
    if (vinCode) {
      await axios
        .get(
          `/api/info/get-vehicles-by-vin-tradesoft?vinCode=${vinCode}&userId=${user?._id}`
        )
        .then((res) => {
          if (res.data) {
            console.log('vechical--' + res.data);
            
            let updatedValue = {};
            updatedValue = {
              makeFromVIN: res.data.make,
              modelFromVIN: res.data.model,
              categoriesVIN: res.data.group2, 
              criteria: res.data.criteria,
              carIdentified: true,
              isSearchByVin: true,
              carId: res.data.carId,
              catalogId: res.data.catalogId
            }
            setVinSearchData((prevState) => ({ ...prevState, ...updatedValue }))
            showSparePartsTradeSoft(vinCode);
          } else {
            let updatedNoValue = {};
            updatedNoValue = { carIdentified: false, isSearchByVin: true };
            setVinSearchData((prevState) => ({ ...prevState, ...updatedNoValue }))
          }
        })
        .catch((err) => {
          let updatedErrorValue = {};
          updatedErrorValue = {
            carIdentified: false,
            isSearchByVin: false,
          }
          setVinSearchData((prevState) => ({ ...prevState, ...updatedErrorValue }))

          if (err?.response?.status === 404) {
            popUp('Cannot find the result for given VIN number.')
            return
          }
          popUp('There was a problem with server. Please try again.')
        })
    }
  }

  /**
 * 
 * Call from getVehiclesByVIN and redirect to PLP page
 */
  const showSparePartsTradeSoft = (vinSearch) => {
    if (vinSearch) {
      // this.setState({ isLoadingSpareParts: true })
      const extraData = {
        vinSearch: vinSearch,
        make: vinSearchData.makeFromVIN,
        model: vinSearchData.modelFromVIN,
        startTime: vinSearchData.startTime,
        endTime: Date.now(),
      }
      console.log(extraData, 'extraData ********************')
      history.push({
        pathname: `/all-listings`,
        search: `?type=vin-search`,
        state: { type: 'vin-search', extraData },
      })
    }
  }

  return (
    <ul class="dropdown-vin-menu">
      <p className="menu-title">Recent VIN Searches</p>
      {vinSearchHistory && vinSearchHistory?.length !== 0 ? (
        (vinSearchHistory.slice(0, 2))?.map((vin) => {
          let brand = vin?.brand_name ?? "";
          const brandImage = vin?.brand?.brand_img
            ? vin?.brand?.brand_img
            : getCarBrandLogo({ brandName: brand });

          return (
            <li
              key={vin?._id}
              onMouseDown={(e) => handleSearchHistoryClick(e, vin?.vin_number)}
            >
              <div style={{ fontSize: "11px" }}>
                <div style={{ float: "left" }}>
                  {" "}
                  <img
                    style={{
                      width: "30px",
                      marginRight: "1rem",
                    }}
                    src={brandImage}
                    alt={brand}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/img/logo.png";
                    }}
                    className="brand-image"
                  />
                </div>
                <div
                  style={{
                    display: "block",
                    paddingLeft: "45px",
                  }}
                >
                  {" "}
                  <p
                    style={{
                      paddingBottom: "5px",
                      lineHeight: "11px",
                      marginBottom: "0",
                    }}
                  >
                    {vin?.vin_number}
                  </p>
                  <p
                    style={{
                      paddingBottom: "0",
                      lineHeight: "11px",
                      marginBottom: "0",
                    }}
                  >
                    {brand}
                  </p>
                </div>
              </div>
            </li>
          );
        })
      ) : (
        <p className="not-found-message">No history to show</p>
      )}
    </ul>
  );
}
