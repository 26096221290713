import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";

import {
  AddToCart,
  GetHomeListing,
  setPathname,
} from "../../../../Store/action/listingActions";

import { stringToUrl } from "../../../../utils";
import ListingCardSeller from "./listingCardSeller";
const skeletonNumbers = [1, 2, 3, 4];

const ListingSkeleton = () => {
  return skeletonNumbers.map((val) => {
    return (
      <div className="col-lg-6 col-xl-6 col-sm-6 col-md-6" key={val}>
        <div className="atbd_single_listing ">
          <article className="atbd_single_listing_wrapper">
            <figure className="atbd_listing_thumbnail_area">
              <div className="atbd_listing_image">
                <Skeleton height={200} />
              </div>
              <div className="atbd_thumbnail_overlay_content">
                <ul className="atbd_upper_badge">
                  <li>
                    <Skeleton />
                  </li>
                </ul>
              </div>
            </figure>
            <div className="atbd_listing_info">
              <h4 className="atbd_listing_title">
                <Skeleton />
              </h4>
              <div className="price-group">
                <Skeleton />
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  });
};

class SellerListingGrid extends Component {
  render() {
    const { isLoading, listings } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <ListingSkeleton />
        ) : (
          listings?.map((value, index) => {
            const {
              partName,
              partSKU,
              _id,
              date,
              business_model
            } = value;
            const title = `${partName}`;
            const badge =
              moment.duration(moment().diff(moment(date))).asHours() <= 48
                ? "new listing"
                : "";
            const link =
              stringToUrl(partName) + "--" + stringToUrl(partSKU) + `/${_id}`;
            const data = { ...value, title, link, badge, business_model };
            return (
              <ListingCardSeller
                key={index}
                {...data}
                addToCart={this.props.addToCart}
                setPathname={this.props.setPathname}
              />
            );
          })
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {
    getHomeListing: () => dispatch(GetHomeListing()),
    addToCart: (data, eventData) => dispatch(AddToCart(data, eventData)),
    setPathname: (pathname) => dispatch(setPathname(pathname))

  };
};

export default connect(mapStateToProps, mapDispatchToProp)(SellerListingGrid);
