const initState = {
  isDeliveryTimeLoading: false,
  defaultDeliveryTime: [],
  isOnMaintainance: false,
  isLoading: true
}

const SettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_DELIVERY_TIME_BEGIN':
      return {
        ...state,
        isDeliveryTimeLoading: true,
        defaultDeliveryTime: []
      }
    case 'SET_DEFAULT_DELIVERY_TIME':
      return {
        ...state,
        isDeliveryTimeLoading: false,
        defaultDeliveryTime: action?.data,
      }
    case 'GET_DELIVERY_TIME_ERROR':
      return {
        ...state,
        isDeliveryTimeLoading: false,
        defaultDeliveryTime: null
      }
    case 'SET_MAINTAINACE_MODE':
      return {
        ...state,
        isOnMaintainance: action?.data
      }
    case 'SET_PAGE_LOADING':
      return {
        ...state,
        isLoading: action?.data
      }
    default:
      return state;
  }
};
export default SettingsReducer;

