import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import Listing from "../../container/all-listing";
import { getCollectionListing } from '../../../Store/action/listingActions';

const Collections = () => {
  const { isLoading } = useSelector(state => state.list);
  const dispatch = useDispatch();

  const { slug } = useParams();

  const search = useLocation()?.search;
  const searchParams = new URLSearchParams(search)

  const getAllQueryParams = () => {
    const categoryId = searchParams.get("categoryId");
    const partBrand = searchParams.get("brand");

    return {
      categoryId,
      partBrand
    }
  }

  useEffect(() => {
    const { categoryId, partBrand } = getAllQueryParams();

    dispatch(getCollectionListing({
      query: {
        slug,
        categoryId,
        partBrand
      },
      action: "",
      isFirstRender: true
    }))
  }, [])


  const handleFilter = (queryData, action = "") => {
    const { categoryId } = getAllQueryParams();

    let query = {
      ...queryData,
      slug,
      categoryId,
    }
    dispatch(getCollectionListing({
      query,
      action,
      isFirstRender: action === "reset" || action === "brand_search"
    }))
  }

  return (
    <Listing
      handleFilter={handleFilter}
      loading={isLoading}
      slug={slug}
    />
  )
}

export default Collections