import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { useHistory } from 'react-router-dom';

import { SetEmailAddress } from "../../../Store/action/signupActions";
import { ga4Events, popUp } from "../../../utils";
import { getOTP, verifyOTP } from '../../../Store/action/userAction';

const VerifyEmail = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [otp, setOTP] = useState("");

    const validator = useRef(new SimpleReactValidator());

    const [verifyLoading, setVerifyLoading] = useState(false);
    const [otpLoading, setOTPLoading] = useState(false);

    const [codeSent, setCodeSent] = useState(false);

    const [, forceUpdate] = useState();

    const { t } = useTranslation();

    const signup = useSelector(state => state.signup);

    useLayoutEffect(() => {
        if (!signup.accountType) {
            history.push("/register");
            return;
        }
    }, []);

    const handleGetOTP = (e) => {
        e.preventDefault();
        setCodeSent(false);
        setOTPLoading(true);

        const postData = {
            value: email?.trim()?.toLowerCase(),
            by: "email",
            forRegister: true
        }

        getOTP(postData)
            .then((res) => {
                setOTPLoading(false);
                setCodeSent(true);
            })
            .catch((err) => {
                popUp(err?.message);
                setOTPLoading(false);
                setCodeSent(false);
            });
    }

    const handleVerifyOTP = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            setVerifyLoading(true);
            const identify = email?.trim().toLowerCase();

            const postData = {
                identify,
                code: otp,
                forRegister: true
            }

            verifyOTP(postData)
                .then((res) => {
                        setVerifyLoading(true);
                        ga4Events({
                            action: 'register_email_otp_verified',
                            category: 'User',
                            email: email?.trim()?.toLowerCase()
                        });
                        dispatch(SetEmailAddress(identify));
                        history.push("/register/verify-phone");
                })
                .catch((err) => {
                    popUp(err?.message);
                    setCodeSent(false);
                    setVerifyLoading(false);
                });
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    }
 
    return (
        <section className="verify-email-page section-padding-strict mb-5">
          <div className="container">
            <p className="text-center">
              Please verify your email with One Time Password
            </p>
              <div
                className="form-group opt-password-wrapper"
                style={{ display: "flex" }}
              >
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="button"
                  disabled={!email || otpLoading}
                  onClick={handleGetOTP}
                  className="btn get-otp-btn"
                  id="get-opt-btn"
                >
                  {otpLoading && (
                    <i className="las la-spinner la-spin mr-2"></i>
                  )}
                  {t("auth_get_otp")}
                </button>
              </div>
            <div
              className="opt-password-wrapper form-group"
              style={{ display: "flex" }}
            >
              <input
                type="text"
                name="otpCode"
                className="form-control"
                placeholder="OTP"
                onChange={(e) => setOTP(e.target.value)}
              />
              <button
                type="submit"
                disabled={!email || verifyLoading || !otp}
                onClick={handleVerifyOTP}
                className="btn btn-primary"
              >
                {verifyLoading && (
                  <i className="las la-spinner la-spin mr-2"></i>
                )}
                {t("auth_continue")}
              </button>
            </div>
            <div className="text-danger">
              {validator.current.message("otp code", otp, "required|string")}
            </div>

            {codeSent === true && (
              <p className="text-info text-center">
                We have sent code to {email?.trim()?.toLocaleLowerCase()}.{" "}
                <a href=" " onClick={handleGetOTP}>
                  {t("auth_send_again")}
                </a>
                ?
              </p>
            )}
          </div>
        </section>
    )
}

export default VerifyEmail