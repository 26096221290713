import { Avatar } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import React from 'react'

const AddressSkeletonLoader = () => {
    return (
        <div className='add-address-container p-4 d-flex mb-4'>
            <Skeleton variant="circular" width={60} height={60} className='mr-2'/>
            <div>
                <Skeleton variant="text" width={210} height={20} />
                <Skeleton variant="text" width={210} height={100} className='mt-2' />
            </div>
        </div>
    )
}

export default AddressSkeletonLoader