import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'

import { SectionTitle } from '../content/element/section-title'
import { PageBanner } from '../content/element/page-banner'
import InputDropdown from '../content/element/input-dropdown'
import { countrList, businessModal } from '../../constants'
import { popUp, validateFile } from '../../utils'
import { uploadFileToAssets } from '../../services/upload'
import { getAllListingBrands } from '../../Store/action/brandActions'

class EditParts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      makeList: [],
      modelList: [],
      price: null,
      priceExclVat: null,
      vatPercent: null,
      currency: 'AED',
      quantity: null,
      imageArray: [],
      originalImages: [],
      pic: null,
      category: null,
      subCategory: null,
      partName: null,
      partHSCode: null,
      partBrand: null,
      partSKU: null,
      type: null,
      fittingPosition: null,
      description: null,
      additionalNotes: null,
      submitLoading: false,
      makes: [],
      models: [],
      allTireSubCategories: [],
      showTireSubCategories: false,
      subCategoryTire: null,
      heightDimension: null,
      widthDimension: null,
      depthDimension: null,
      weight: null,
      countryOrigin: null,
      clickCollect: false,
      delivery: false,
      categories: [],
      subCategories: [],
      tireSubCategories: [],
      business_model: '',
      makeYear: null,
      utqg: null,
      serviceDescription: null,
      rimSize: null,
      size: null,
      amp: null,
      voltage: null,
      tiresCategoriesId: [],
      inventoryStock: null,
      partBrands: [],
      reservedQty: 0,
    }
    this.validator = new SimpleReactValidator()
    this.handleChangeNumeric = this.handleChangeNumeric.bind(this)
    this.handleUnitOptionChange = this.handleUnitOptionChange.bind(this)
    this.openFileDlg = this.openFileDlg.bind(this)
    this.uploadPic = this.uploadPic.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleChangeMake = this.handleChangeMake.bind(this)
    this.handleChangeModel = this.handleChangeModel.bind(this)
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.handleAdditionalNotesChange =
      this.handleAdditionalNotesChange.bind(this)
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
    this.getModelList = this.getModelList.bind(this)
  }

  getTireSubCategories = () => {
    let ids = []

    axios.get('/api/subcategories/get-tire-subcategories').then((res) => {
      this.setState({ allTireSubCategories: res.data.data })
      res?.data?.data?.map((item) => ids.push(item?._id))
    })
    this.setState({ tiresCategoriesId: ids })
  }

  componentDidMount() {
    this.getTireSubCategories()

    this.props.getAllListingBrands({
      filter: '?all=true',
    })

    axios
      .get(`/api/listings/${this.props.match.params.id}?requiredMakeModel=true`)
      .then((res) => {
        axios.get('/api/categories').then((cat) => {
          let categories = cat.data.map((data) => ({
            ...data,
            label: data.name,
            value: data._id,
          }))
          axios
            .get('/api/subcategories/' + res.data?.listing?.category?._id)
            .then((subCat) => {
              this.setState({
                categories,
                subCategories: subCat.data.map((data) => ({
                  ...data,
                  label: data.name,
                  value: data._id,
                })),
              })
            })
        })

        let makeList = res.data?.listing?.makeList
        makeList = makeList?.map((data) => {
          return { label: data?.name, value: data?.id_car_make, _id: data?._id }
        })
        let modelList = res.data?.listing?.modelList
        modelList = modelList?.map((data) => {
          return {
            label: data?.name,
            value: data?.id_car_model,
            _id: data?._id,
          }
        })

        this.setState({
          ...this.state,

          ...res.data?.listing,
          category: res?.data?.listing?.category?._id || '',
          subCategory: res?.data?.listing?.subCategory?._id || '',
          makes: res?.data?.listing?.makes?.length
            ? res?.data?.listing?.makes?.map((make) => make?._id)
            : [],
          models: res?.data?.listing?.models?.length
            ? res?.data?.listing?.models?.map((model) => model?._id)
            : [],
          subCategory: res?.data?.listing?.subCategory?._id || '',
          makeList,
          modelList,
          reservedQty:
            res.data.listing.inventoryStock - res.data.listing.quantity,
          showTireSubCategories: this.state.tiresCategoriesId.includes(
            res.data?.subCategory
          ),
        })

        let tiresubcats = this.state.allTireSubCategories
          .filter((s) => s.category_id === res.data?.subCategory)
          .map((s) => ({ value: s._id, label: s.name }))
        this.setState({ tireSubCategories: tiresubcats })
      })
      .catch((err) => {
        console.log(err)
        popUp('There was a problem with server. Please try again.')
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listingBrands !== this.props.listingBrands) {
      this.setState({
        partBrands: this.props?.listingBrands?.map((d) => ({
          label: d.name,
          value: d.name,
        })),
      })
    }
  }

  getModelList(makes) {
    axios
      .post('/api/car_models/get-models', { makes })
      .then((res) => {
        const list = res.data.map((data) => {
          return { label: data.name, value: data.id_car_model, _id: data._id }
        })
        this.setState({ modelList: list })
      })
      .catch((err) => {
        popUp('There was a problem with server. Please try again.')
      })
  }

  handleChangeCategory = (selectedOption, fieldAttr) => {
    this.handleChangeSelect(selectedOption, fieldAttr)
    this.setState({
      subCategory: null,
      showTireSubCategories: false,
      subCategoryTire: null,
      rimSize: null,
      utqg: null,
      serviceDescription: null,
      size: null,
    })
    let result = this.props.allCategories.find(
      (cat) => cat?._id === selectedOption.value
    )

    this.setState({
      subCategories: result?.subCategories?.length
        ? result?.subCategories?.map((data) => ({
            ...data,
            label: data.name,
            value: data._id,
          }))
        : [],
    })
  }

  handleChangeMake(options) {
    if (!options) {
      this.setState({ makes: null, modelList: [] })
      return
    }
    const make_ids = options.map((o) => {
      return o?._id
    })
    const makes = options.map((o) => o._id)
    this.setState({ makes })
    this.getModelList(make_ids)
  }

  handleChangeModel(options) {
    if (!options) {
      this.setState({ models: null })
      return
    }
    const models = options.map((o) => o._id)
    this.setState({ models })
  }

  handleChangeNumeric(name, value) {
    this.setState({ [name]: value })
  }

  handleUnitOptionChange(name, value) {
    this.setState({ [name]: value })
  }

  openFileDlg(e) {
    e.preventDefault()
    this.upload.click()
  }

  uploadPic(e) {
    e.preventDefault()
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const uploadedFiles = files?.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      })
      this.setState({
        imageArray: [...this.state.imageArray, ...uploadedFiles],
      })
    }
  }

  handleChangeSelect(selectedOption, fieldAttr) {
    this.setState({ [fieldAttr.name]: selectedOption.value })
  }

  handleChangeCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChangeSubCategory(option) {
    this.setState({ subCategory: option })
    let isIncludes = this.state.tiresCategoriesId.includes(option.value)
    if (isIncludes) {
      this.setState({ showTireSubCategories: true })
      let tiresubcats = this.state.allTireSubCategories
        .filter((s) => s.category_id === option.value)
        .map((s) => ({ value: s._id, label: s.name }))
      this.setState({ tireSubCategories: tiresubcats })
    } else {
      this.setState({
        showTireSubCategories: false,
        subCategoryTire: null,
        rimSize: null,
        utqg: null,
        serviceDescription: null,
        size: null,
      })
    }
  }

  handleInput(e) {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDescriptionChange(val) {
    this.setState({
      description: val,
    })
  }

  handleAdditionalNotesChange(val) {
    this.setState({
      additionalNotes: val,
    })
  }

  handleSeriveInfoChange = (val) => {
    this.setState({
      serviceDescription: val,
    })
  }

  async handleSubmit(e) {
    try {
      this.setState({ submitLoading: true })

      const { imageArray } = this.state
      e.preventDefault()
      if (this.validator.allValid()) {
        this.setState({ submitLoading: true })
        let newImageArray = [],
          fileUrl = []

        if (imageArray?.length) {
          const files = []
          imageArray?.forEach((file) => {
            if (typeof file === 'object') {
              files.push(file)
            } else if (typeof file === 'string') {
              fileUrl.push(file)
            }
          })
          newImageArray = files?.length
            ? await uploadFileToAssets({
                file: files,
                type: 'listing',
                multiple: true,
              })
            : []
          newImageArray = newImageArray?._id
            ? [newImageArray?._id]
            : newImageArray?.map((pic) => pic?._id)
        }

        newImageArray = [...fileUrl, ...newImageArray]

        let formData = {}
        formData = {
          list_id: this.props.match.params.id,
          priceExclVat: this.state.priceExclVat,
          vatPercent: this.state.vatPercent,
          currency: this.state.currency,
          quantity: this.state.quantity,
          category: this.state.category,
          subCategory:
            this.state?.subCategory?.value || this.state?.subCategory || null,
          subCategoryTire:
            this.state?.subCategoryTire?.value ||
            this.state?.subCategoryTire ||
            null,
          partName: this.state.partName,
          partHSCode: this.state.partHSCode,
          partSKU: this.state.partSKU,
          partBrand: this.state.partBrand,
          type: this.state.type,
          description: this.state.description,
          additionalNotes: this.state.additionalNotes,
          fittingPosition: this.state.fittingPosition,
          makes: this.state.makes,
          models: this.state.models,
          heightDimension: parseFloat(this.state.heightDimension),
          widthDimension: parseFloat(this.state.widthDimension),
          weight: parseFloat(this.state.weight),
          depthDimension: parseFloat(this.state.depthDimension),
          countryOrigin: this.state.countryOrigin,
          clickCollect: this.state.clickCollect,
          delivery: this.state.delivery,
          user: this.props.login._id,
          business_model: this.state.business_model,
          pic: newImageArray[0],
          imageArray: newImageArray,
          makeYear: this.state.makeYear,
          utqg: this.state.utqg,
          serviceDescription: this.state.serviceDescription,
          rimSize: this.state.rimSize,
          size: this.state.size,
          amp: this.state.amp,
          voltage: this.state.voltage,
          inventoryStock: this.state.inventoryStock,
        }
        axios
          .put(`/api/listings/${this.props.match.params.id}`, formData)
          .then((res) => {
            this.setState({ submitLoading: false })
            popUp(res.data.message || 'Sucessfully Updated !')
            this.props.history.push('/my-listings')
          })
          .catch((err) => {
            popUp(
              err.response.data.message ||
                'There was a problem with server. Please try again.'
            )
            this.setState({ submitLoading: false })
          })
      } else {
        this.validator.showMessages()
        this.forceUpdate()
        this.setState({ submitLoading: false })
      }
    } catch (error) {
      this.setState({ submitLoading: false })
      popUp(error?.message || 'Error while editing listing')
    }
  }

  closeImage = async (imageURI) => {
    const newArray = this.state.imageArray.filter((item, i) => {
      return i !== imageURI
    })

    this.setState({
      imageArray: newArray,
    })
  }

  render() {
    const fitting_position = ['Front', 'Rear']
    const formattedFittingPosition = fitting_position.map((type) => {
      return { label: type, value: type }
    })

    const part_type = ['Genuine', 'Aftermarket', 'TBA']
    const formattedPartType = part_type.map((type) => {
      return { label: type, value: type }
    })
    let {
      categories,
      subCategories,
      showTireSubCategories,
      tireSubCategories,
      partBrands,
    } = this.state
    return (
      <Fragment>
        <PageBanner title='Sell your spare part' />
        <section className='sell-part-wrapper section-bg'>
          <div className='container'>
            <SectionTitle color title='Edit Product' />

            <div className='atbd_content_module mt-4'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>General Information
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group text-center'>
                    <div className='photo-container'>
                      {this.state?.imageArray?.map((imageURI, k) => (
                        <div key={k} className='image-upload-div'>
                          <div
                            className='image-cross-icon-div'
                            onClick={() => this.closeImage(k)}>
                            <CloseIcon style={{ fontSize: 22 }} />
                          </div>
                          <img
                            key={k}
                            className='photo-uploaded m-3'
                            src={imageURI?.preview || imageURI}
                            width='300'
                            style={{ width: '150px', height: '150px' }}
                            alt='pic'
                          />
                        </div>
                      ))}
                    </div>
                    <div className='custom-file-upload'>
                      <input
                        name='imageArray'
                        id='customUpload'
                        type='file'
                        ref={(ref) => (this.upload = ref)}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          let isLt5M = validateFile(e.target.files[0])
                          if (!isLt5M) {
                            e.target.value = ''
                            return
                          }
                          this.uploadPic(e)
                        }}
                      />
                      <label
                        htmlFor='customUpload'
                        className='btn btn-sm btn-primary mt-3'
                        onClick={this.openFileDlg}>
                        Upload Picture
                      </label>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Part Name:</label>
                      <input
                        type='text'
                        name='partName'
                        className='form-control'
                        onChange={this.handleInput}
                        value={this.state.partName || ''}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partName',
                          this.state.partName,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>Part Brand:</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        name='partBrand'
                        placeholder='Choose Part Brand...'
                        options={partBrands}
                        onChange={this.handleChangeSelect}
                        value={
                          this.state.partBrand
                            ? {
                                label: this.state.partBrand,
                                value: this.state.partBrand,
                              }
                            : null
                        }
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partBrand',
                          this.state.partBrand,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Category</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        name='category'
                        placeholder='Choose Category...'
                        options={categories}
                        onChange={this.handleChangeCategory}
                        value={
                          this.state.category && this.state.categories.length
                            ? {
                                label: this.state.categories.find(
                                  (c) => c._id === this.state.category
                                )?.name,
                                value: this.state.category,
                              }
                            : null
                        }
                      />

                      <div className='text-danger'>
                        {this.validator.message(
                          'category',
                          this.state.category,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>Sub Category</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        name='make'
                        placeholder='Choose...'
                        onChange={(option) =>
                          this.handleChangeSubCategory(option)
                        }
                        options={subCategories}
                        value={
                          this.state.category
                            ? subCategories.find(
                                (x) => x.value === this.state.subCategory
                              )
                            : null
                        }
                      />
                    </div>
                    {showTireSubCategories && (
                      <div className='col-md-12'>
                        <label>
                          Sub Category Tire
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          className={`react-select`}
                          classNamePrefix='react-select'
                          name='subCategoryTire'
                          placeholder='Choose Category...'
                          options={tireSubCategories}
                          value={
                            this.state.subCategory
                              ? tireSubCategories.find(
                                  (x) => x.value === this.state.subCategoryTire
                                )
                              : null
                          }
                          onChange={(e) => {
                            this.setState({ subCategoryTire: e })
                          }}
                        />
                        <div className='text-danger'>
                          {this.validator.message(
                            'subCategoryTire',
                            this.state.subCategoryTire,
                            `required`
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Part HS Code:</label>
                      <input
                        type='text'
                        name='partHSCode'
                        className='form-control'
                        onChange={this.handleInput}
                        value={this.state.partHSCode || ''}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Part SKU:</label>
                      <input
                        type='text'
                        name='partSKU'
                        disabled={true}
                        className='form-control'
                        onChange={this.handleInput}
                        value={this.state.partSKU || ''}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'partSKU',
                          this.state.partSKU,
                          `required`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Type:</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix='react-select'
                      name='type'
                      placeholder='Choose Type...'
                      options={formattedPartType ? formattedPartType : []}
                      onChange={this.handleChangeSelect}
                      value={
                        this.state.type
                          ? {
                              label: this.state.type,
                              value: this.state.type,
                            }
                          : null
                      }
                    />

                    <div className='text-danger'>
                      {this.validator.message(
                        'type',
                        this.state.type,
                        `required`
                      )}
                    </div>
                  </div>

                  <div className='form-group'>
                    <label>Description:</label>
                    <ReactQuill
                      theme='snow'
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                    />
                    <div className='text-danger'>
                      {this.validator.message(
                        'description',
                        this.state.description,
                        `required`
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Pricing & Availability
                    (required)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>
                        Price (Exc. VAT) <span style={{ color: 'red' }}>*</span>
                      </label>
                      <InputDropdown
                        m_name='priceExclVat'
                        o_name='currency'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='AED'
                        value={this.state.priceExclVat}
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'priceExclVat',
                          this.state.priceExclVat,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        VAT Percent <span style={{ color: 'red' }}>*</span>
                      </label>
                      <NumberFormat
                        value={this.state.vatPercent}
                        className={`form-control`}
                        thousandSeparator={true}
                        onValueChange={(values) =>
                          this.setState({ vatPercent: values.floatValue })
                        }
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'vatPercent',
                          this.state.vatPercent,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Quantity Available{' '}
                      </label>
                      <NumberFormat
                        value={this.state.quantity}
                        className={`form-control`}
                        thousandSeparator={true}
                        disabled
                      />
                      <div className='text-danger'>
                        {this.validator.message(
                          'quantity',
                          this.state.quantity,
                          `required`
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label>
                        Inventory Stock <span style={{ color: 'red' }}>*</span>
                      </label>
                      <NumberFormat
                        value={this.state.inventoryStock}
                        className={`form-control`}
                        thousandSeparator={true}
                        onValueChange={(values) => {
                          let quantity =
                            parseInt(values.floatValue) -
                              this.state.reservedQty || 0

                          if (quantity < 0) {
                            quantity = 0
                          }
                          if (
                            this.state.reservedQty >
                            (parseInt(values.floatValue) || 0)
                          ) {
                            this.setState({
                              inventoryStockerror: `Reserved Quantity is  ${this.state.reservedQty}`,
                            })
                          } else {
                            this.setState({
                              inventoryStockerror: ``,
                            })
                          }
                          this.setState({
                            quantity,
                            inventoryStock: values.floatValue,
                          })
                        }}
                      />
                      <div className='text-danger'>
                        {this.state.inventoryStockerror ||
                          this.validator.message(
                            'Inventory',
                            this.state.inventoryStock,
                            `required`
                          )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Fitting & Compatibility
                    (optional)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Make:</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose...'
                        name='make'
                        onChange={this.handleChangeMake}
                        options={this.state.makeList}
                        isMulti={true}
                        value={
                          this.state.makeList
                            ? this.state?.makeList?.filter((x) =>
                                this.state?.makes?.includes(x._id)
                              )
                            : null
                        }
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Model:</label>
                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose...'
                        name='model'
                        onChange={this.handleChangeModel}
                        options={this.state.modelList}
                        isMulti={true}
                        value={
                          this.state.modelList
                            ? this.state.modelList.filter((x) =>
                                this.state.models?.includes(x?._id)
                              )
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Fitting Position:</label>
                    <Select
                      className={`react-select`}
                      classNamePrefix='react-select'
                      placeholder='Choose position...'
                      name='fittingPosition'
                      onChange={this.handleChangeSelect}
                      options={
                        formattedFittingPosition ? formattedFittingPosition : []
                      }
                      value={
                        this.state.fittingPosition
                          ? {
                              label: this.state.fittingPosition,
                              value: this.state.fittingPosition,
                            }
                          : null
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-user'></span>Details (optional)
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Height Dimension:</label>
                      <InputDropdown
                        m_name='heightDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.heightDimension}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Width Dimension:</label>
                      <InputDropdown
                        m_name='widthDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.widthDimension}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-6'>
                      <label>Depth Dimension:</label>
                      <InputDropdown
                        m_name='depthDimension'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='MM'
                        value={this.state.depthDimension}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Weight:</label>
                      <InputDropdown
                        m_name='weight'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='Grams'
                        value={this.state.weight}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Size</label>
                      <InputDropdown
                        m_name='size'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='size'
                        value={this.state.size}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>RIM Size</label>
                      <InputDropdown
                        m_name='rimSize'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='RIM'
                        value={this.state.rimSize}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Amp</label>
                      <InputDropdown
                        m_name='amp'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='amp/hr'
                        value={this.state.amp}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Voltage</label>
                      <InputDropdown
                        m_name='voltage'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='volt'
                        value={this.state.voltage}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>UTQG</label>
                      <InputDropdown
                        m_name='utqg'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='utqg'
                        value={this.state.utqg}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Make Year</label>
                      <InputDropdown
                        m_name='makeYear'
                        o_name='unit'
                        handleChangeNumeric={this.handleChangeNumeric}
                        handleOptionChange={this.handleUnitOptionChange}
                        defaultOption='YYYY'
                        value={this.state.makeYear}
                      />
                    </div>
                    <div className='col-md-6'>
                      <label>Country of Origin</label>

                      <Select
                        className={`react-select`}
                        classNamePrefix='react-select'
                        placeholder='Choose Country of Origin...'
                        name='countryOrigin'
                        onChange={this.handleChangeSelect}
                        options={countrList.map((country) => {
                          return { label: country.label, value: country.label }
                        })}
                        value={
                          this.state.countryOrigin
                            ? {
                                label: this.state.countryOrigin,
                                value: this.state.countryOrigin,
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <label>Additional Notes:</label>
                      <ReactQuill
                        theme='snow'
                        value={this.state.additionalNotes}
                        onChange={this.handleAdditionalNotesChange}
                      />
                      {showTireSubCategories && (
                        <div className='col-md-12'>
                          <label>Service Description</label>
                          <ReactQuill
                            theme='snow'
                            value={this.state.serviceDescription}
                            onChange={this.handleSeriveInfoChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='atbd_content_module'>
              <div className='atbd_content_module__tittle_area'>
                <div className='atbd_area_title'>
                  <h4>
                    <span className='la la-shopping-cart'></span>Shipping
                    Options
                  </h4>
                </div>
              </div>
              <div className='atbdb_content_module_contents'>
                <form className='form-vertical'>
                  <div className='form-group row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label>Select business Model:</label>
                        <Select
                          className={`react-select`}
                          classNamePrefix='react-select'
                          placeholder='Choose business Model...'
                          name='business_model'
                          onChange={this.handleChangeSelect}
                          options={businessModal?.map((business_model) => {
                            return {
                              label: business_model,
                              value: business_model,
                            }
                          })}
                          value={
                            this.state.business_model
                              ? {
                                  label: this.state.business_model,
                                  value: this.state.business_model,
                                }
                              : null
                          }
                        />
                        <div className='text-danger'>
                          {this.validator.message(
                            'business_model',
                            this.state.business_model,
                            `required`
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='form-group mt-5 text-center'>
              <button
                disabled={this.state.submitLoading}
                className='btn btn-primary btn-lg listing_submit_btn'
                onClick={this.handleSubmit}>
                {this.state.submitLoading && (
                  <i className='las la-spinner la-spin mr-2'></i>
                )}
                Update your listing
              </button>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selling: state.selling,
    login: state.login,
    allCategories: state.category.allCategories,
    listingBrands: state.brand.listingBrands,
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    getAllListingBrands: (data) => dispatch(getAllListingBrands(data)),
  }
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(EditParts)
