import React from 'react'
import CollectionGrid from './CollectionGrid'

const ThumbnailGrid = ({ section }) => {
  {
    switch (section?.sectionData?.label) {
      case 'collection':
          return (
            <CollectionGrid
              section={section}
            />
          )
    }
  }
}

export default ThumbnailGrid