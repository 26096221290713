import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SearchImageSKU from "./modal/search-image-sku";
import { withRouter } from "react-router";
import { useHistory } from "react-router";
import { PartNoSearch } from "../../layout/home-search/HomeSearch";

const noAction = (e) => e.preventDefault();

const AdvSearch = ({ placeholder, name, showMobileSearch, handleBackFromSearch, loginToggle, searchData }) => {
  const currentUser = JSON.parse(localStorage.getItem("login"));
   const history = useHistory();
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    noAction(e)
    setError(false);
    const { value } = e.target || {}

 //   if (value.match(/^[\w\s\.\/\,\"\'\-]+$/) || value === "") {
      setSearch(value);
//    }
  }

  const uploadFile = (e) => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showMobileSearch) {
      handleBackFromSearch();
    }
    if (!search) {
      setError(true);
      return;
    }
    history.push(
      `/all-listings?${name}=${search?.trim()}`
    )
  }

  useEffect(() => {
    setSearch(searchData);
  }, [searchData])

  return (
    <>
      <SearchImageSKU showModal={showModal} onClose={onClose} />
      <div className="directory_content_area">
        <div className="col-md-12" style={{ padding: 0 }}>
          {showMobileSearch
            ?
            <form className="search_img display-flex">
              <div className="search_form col-md-12 no-padding">
                <div className="atbd_seach_button_wrapper">
                  <button className="btn btn-md btn-primary btn_search d-flex pl-2">
                    <i className="las la-search" />
                  </button>
                </div>
                <input
                  style={{ fontSize: "14px", fontWeight: "400", cursor: 'pointer' }}
                  type="text"
                  value={search || ""}
                  name={"search"}
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  // placeholder={"Find spare parts.."}
                  placeholder={placeholder}
                  className={error ? "error" : ""}
                  onKeyDown={(event) => event.key == 'Enter' && handleSubmit(event)}
                />
              </div>
            </form>
            :
            <form onSubmit={(e) => handleSubmit(e)} className="search_img display-flex">
              <div className="search_form col-md-10 no-padding search-width" >
                <div className="search_form col-md-12 no-padding search-width">
                  <div className="atbd_seach_button_wrapper">
                    <button className="btn btn-md btn-primary btn_search d-flex pl-2">
                      <i className="las la-search" />
                    </button>
                  </div>
                  <input
                    style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}
                    type="text"
                    value={search || ""}
                    name={"search"}
                    autoComplete="off"
                    onChange={(e) => handleChange(e)}
                    // placeholder={"Find spare parts.."}
                    placeholder={placeholder}
                    className={error ? "error" : ""}
                  />
                  <div className="atbd_seach_button_wrapper_start">
                    <button
                      type="button"
                      onClick={uploadFile}
                      className="btn btn-md btn-primary btn_search d-flex p-1" style={{ marginRight: '13px' }}
                    >
                      <i className="las la-camera" />
                    </button>
                  </div>
                </div>
                <PartNoSearch search={search} />
              </div>
              <div className="col-md-2" style={{ paddingRight: '3px' }}>
                {
                  !currentUser
                    ?
                    <button
                      type="button"
                      className="btn btn-primary search-btn"
                      onClick={loginToggle}
                    >
                      SEARCH
                    </button>
                    :
                    <button
                      type="submit"
                      className="btn btn-primary search-btn"

                    >
                      SEARCH
                    </button>
                }

              </div>
            </form>
          }
        </div>
      </div>
    </>
  );

}

const mapStateToProps = (state) => {
  return {
    list: state.list,
  };
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(withRouter(AdvSearch));
