import React, { useEffect, useState } from "react";
import { numberWithCommas, popUp } from "../../../../utils";
import { NavLink, useHistory } from "react-router-dom";
import NotLoggedInModal from "../modal/not-logged-modal";
import { Tooltip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { handRequestForPrice } from "./card-listing-grid";

const ListingCardSeller = ({
	link,
	imageArray,
	badge,
	title,
	priceExclVat,
	addToCart,
	setPathname,
	quantity,
	_id,
	partSKU,
	partName,
	partBrand,
	category,
	variant,
	pic
}) => {
	const { currency, conversionRate } = useSelector((state) => {
		return {
			currency: state.currency.currency,
			conversionRate: state.currency.conversionRate,
		};
	});

	const { quotationItems } = useSelector((state) => state.quotation);
	const [errMsg, setErrorMsg] = React.useState("");
	const [image, setImage] = useState(
		imageArray ? imageArray[0] : "/assets/img/product-default.jpg"
	);
	const history = useHistory();
	const [isLogin, setIsLogin] = React.useState(false);

	const dispatch = useDispatch()

	function handleError() {
		setImage("/assets/img/product-default.jpg");
	}
	const currentUser = JSON.parse(localStorage.getItem("login"));

	const handleAddToClick = (id) => {
		if (currentUser) {
			const eventData = {
				action: "seller_page_add_to_cart",
				category: "Listing",
				product_id: _id,
				product_name: partName,
				product_category: category?.name,
				product_brand: partBrand,
				product_variant: variant
			}
			addToCart(id, eventData);
			setPathname(history.location.pathname)
			localStorage.setItem('filterPath', JSON.stringify(history.location.pathname))
		} else {
			setErrorMsg(
				"You need to register as a buyer to be able to make an order on BuyParts24"
			);
			setIsLogin(true);
		}
	};

	const [isCopied, setIsCopied] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);

	}, [isCopied])

	function handleNewQuotation() {
		if (currentUser) {
			handRequestForPrice(
				quotationItems,
				{ _id, partName, priceExclVat, qty: 1, pic },
				dispatch
			)
		} else {
			popUp(
				'You need to register as a buyer to be able to request price'
			)
		}
	}

	return (
		<div
			className="col-lg-4 col-xl-4 col-sm-4 col-md-4"
			style={{ marginBottom: 30 }}
		>
			<NotLoggedInModal showModal={isLogin} onClose={setIsLogin} msg={errMsg} />
			<div className="atbd_single_listing ">
				<article className="atbd_single_listing_wrapper">
					<figure className="atbd_listing_thumbnail_area">
						<div className="atbd_listing_image">
							<NavLink to={`/spare-part-details/${link}`}>
								<img
									onError={handleError}
									src={image || "/assets/img/product-default.jpg"}
									alt="Listing"
								/>
							</NavLink>
						</div>
						<div className="atbd_thumbnail_overlay_content">
							<ul className="atbd_upper_badge">
								<li>
									<span
										className={
											"text-capitalize atbd_badge atbd_badge_" +
											badge.replace(" ", "_")
										}
									>
										{badge}
									</span>
								</li>
							</ul>
						</div>
					</figure>
					<div className="atbd_listing_info">
						<h4 className="atbd_listing_title">
							<NavLink to={`/buy-spare-parts/${link}`}>{title}</NavLink>
						</h4>
						<>
							<button className="btn part-sku"
								onClick={() => {
									setIsCopied(true)
									navigator.clipboard.writeText(partSKU)
								}}
							>
								SKU{" "}

								{partSKU} {" "}
								<span className="ml-2">|</span>
								<Tooltip
									className="ml-1 cursur-pointer"
									title="SKU number copied to clipboard"
									placement="top"
									open={isCopied}
									arrow
									disableHoverListener>
									<svg xmlns="http://www.w3.org/2000/svg"
										height="18" viewBox="0 0 24 24" width="24">
										<path d="M0 0h24v24H0z" fill="none" />
										<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg>
								</Tooltip>
							</button>
							<div className="pricing-group">
								{quantity && quantity > 0 ? (
									<>
										<p className="symbol mr-1">
											{currency}
											<span className="price">
												{numberWithCommas(
													(priceExclVat * (conversionRate ? conversionRate : 1)).toFixed(
														2
													)
												)}
											</span>
										</p>
										<button
											className="btn cart-btn"
											onClick={() => handleAddToClick(_id)}
										>
											Add To Cart
										</button>
									</>
								) :
									<>
										<p className="symbol mr-1">
										</p>
										<button
											onClick={() => handleNewQuotation()}
											className='btn out-stock'
											style={{
												backgroundColor: "#1d71b8",
												color: "white"
											}}

										>
											{"Request For Price"}
										</button>
									</>
								}
							</div>
						</>
					</div>
				</article>
			</div>
		</div>
	);
};
export default ListingCardSeller;
