import React from 'react'
import './style.css';

const Maintainance = () => {
    return (
        <div className='maintainance__container'>
            <div className='maintainance__img-container'>
                <img src='/assets/img/bp_blue_logo.png' width="150px" height="150px" alt="icon" />
            </div>
            <article className='maintainance__body-container'>
                <div class='text-div'>
                    <p>We are currently under maintainance. Apologies for the inconveninence. We will be back shortly. For more information, reach out to us at: </p>
                </div>

                <div className='contact_container'>
                    <a className='contact_detail' href={`https://api.whatsapp.com/send?phone=971505623191`} target='_blank'>
                        <img
                            src='/assets/img/icons/whatsapp.png'
                            alt="Whatsapp"
                            width="30px"
                            height="30px"
                        />
                        <p>+971505623191</p>
                    </a>

                    <p>OR</p>

                    <a className='contact_detail' href={`mailto:sales@buyparts24.com`}>
                        <img
                            src='/assets/img/icons/email.png'
                            alt="Whatsapp"
                            width="30px"
                            height="30px"
                        />
                        <p>sales@buyparts24.com</p>
                    </a>
                </div>

            </article>
        </div>
    )
}

export default Maintainance