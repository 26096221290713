import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "./ToastNotifier.scss";
export const ToastNotificationContainer = ToastContainer;
export const toastNotify = toast;
export default toast;

export const NotifyToastWithButtons = (message, btnText, handleClick) => {
  toast.success(
    <div className="d-flex justify-content-between align">
      <p className="toast--message">
        {message
          ? message
          : "There was a problem with server. Please try again."}
      </p>
      {btnText && (
        <p className="toast--buttons" onClick={() => handleClick()}>
          {" "}
          {btnText}{" "}
        </p>
      )}
    </div>
  );
};

export const SuccessToast = (message) =>
  message &&
  toast(
    <div className="toast toast--success">
      <div className="toast--message">{message}</div>
    </div>,
    {
      toastId: typeof message === "string" ? message : undefined,
    }
  );

export const FailToast = (message) =>
  message &&
  toast(
    <div className="toast toast--error">
      <div className="toast--message">{message}</div>
    </div>,
    {
      toastId: typeof message === "string" ? message : undefined,
    }
  );

export const WarnToast = (message) =>
  message &&
  toast(
    <div className="toast toast--warning">
      <div className="toast--message">{message}</div>
    </div>,
    {
      toastId: typeof message === "string" ? message : undefined,
    }
  );
