import React from 'react'
import CollectionSlider from './CollectionSlider'

const ThumbnailSlider = ({ section }) => {
  {
    switch (section?.sectionData?.label) {
      case 'collection':
          return (
            <CollectionSlider
              section={section}
            />
          )
    }
  }
}

export default ThumbnailSlider