import React from "react";
import axios from "axios";
import Drawer from "@material-ui/core/Drawer";
import StarRatingComponent from "react-star-rating-component";
import "./style.css";
import NotLoggedInModal from "../modal/not-logged-modal";
import { numberWithCommas, ProductImage } from "../../../../utils";

const Index = ({
  isDrawerOpen,
  handleToggleDrawer,
  data,
  addToCart,
  staticData,
  dataStar,
  seller,
}) => {
  const [reviewData, setReviewData] = React.useState([]);
  const [sortedArry, setSortedArry] = React.useState([]);
  const [checkLogin, setCheckLogin] = React.useState(false);
  const currentUser = JSON.parse(localStorage.getItem("login"));

  const getRating = async () => {
    let getReviewOfAllProduct = data?.map(async (item) => {
      let res = await axios.get(`/api/review/${item?._id}`);
      return res;
    });
    getReviewOfAllProduct = await Promise.all(getReviewOfAllProduct);

    const calculateReview = [];
    getReviewOfAllProduct.forEach((list) => {
      const _calculateReview =
        list.data.reduce((acc, item) => Number(item.star) + acc, 0) /
        list.data.length;

      if (list?.data[0]?.list) {
        calculateReview.push({
          averageRating: _calculateReview.toFixed(1),
          id: list?.data[0]?.list,
        });
      }
    });
    setReviewData(calculateReview);
    const sortByPrice = data?.sort((a, b) => a.price - b.price);
    setSortedArry(sortByPrice);
  };

  React.useEffect(() => {
    data.length && getRating();
  }, [data.length]);

  const handleAddToCart = (id) => {
    if (!currentUser) {
      setCheckLogin(true);
    } else {
      addToCart(id);
      setCheckLogin(false);
    }
  };

  let url = staticData?.imageArray
    ? staticData?.imageArray.length > 0
      ? staticData?.imageArray[0]
      : "/assets/img/product-default.jpg"
    : "/assets/img/product-default.jpg";

  return (
    <Drawer
      anchor={"right"}
      open={isDrawerOpen}
      onClose={() => handleToggleDrawer(false)}
    >
      <NotLoggedInModal
        showModal={checkLogin}
        onClose={setCheckLogin}
        msg={
          "You need to register as a buyer to be able to make an order on BuyParts24"
        }
      />
      <div className="drawer-container">
        <div className="drawer-header">
          <div className="item-image">
            <ProductImage avatar={url} alt={staticData?.partName}/>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <div className="item-title">{staticData?.partName}</div>
            <div className="item-rating">
              <StarRatingComponent
                name="rate2"
                editing={false}
                renderStarIcon={() => <i className="la la-star" />}
                renderStarIconHalf={() => (
                  <i
                    className="la la-star-half-alt"
                    style={{ color: "#ffb400" }}
                  />
                )}
                starColor="#ffb400"
                emptyStarColor={"#cecece"}
                starCount={5}
                value={dataStar}
              />
              <span style={{ marginLeft: "5px" }}>
                {dataStar} {""}rating
              </span>
            </div>
          </div>
        </div>
        <div className="drawer-horizontalline" />
        <div className="drawer-filtercontainer">
          <div className="drawer-filtertext">2 other options</div>
          <div className="drawer-filtertextmeta">
            sorted by price: low to high
          </div>
        </div>
        {sortedArry.length &&
          sortedArry
            .filter((item) => item.user !== null)
          .map((item, i) => {
              return (
                <div key={i}>
                  <div className="item-container">
                    <div className="item-header">
                      <div
                        className="item-price mt-auto"
                        style={{ width: "80px" }}
                      >
                        <span className="currency">AED</span>
                        <span className="price">{numberWithCommas(item.price)}</span>
                        <div className="item-shipby" style={{ fontSize: '10px', fontWeight:500 }}>
                        
                        ({numberWithCommas(item.quantity)} Qty's left)
                        </div>
                      </div>
                      <div className="mt-auto">
                        {" "}
                        <StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <i className="la la-star" />}
                          renderStarIconHalf={() => (
                            <i
                              className="la la-star-half-alt"
                              style={{ color: "#ffb400" }}
                            />
                          )}
                          starColor="#ffb400"
                          emptyStarColor={"#cecece"}
                          starCount={5}
                          value={
                            reviewData.find((data) => data?.id === item?._id)
                              ?.averageRating
                          }
                        />
                      </div>
                      <div
                        className="item-btn"
                        onClick={() => handleAddToCart(item?._id)}
                      >
                        <button
                          className="btn btn-block"
                          style={{
                            backgroundColor: "#f39200",
                            color: "white",
                          }}
                        >
                          {" "}
                          Add to Cart
                        </button>
                      </div>
                    </div>
                    <div className="item-description">
                      <div className="item-shipdescription">
                        <div className="item-shipby">Ships from</div>
                        <div className="item-shippingdescription">
                          <span>{item?.user?.details?.emirate}</span>
                        </div>
                        <div />
                      </div>
                      <div className="item-solddescription">
                        <div className="item-soldby">Sold by</div>
                        <div className="item-shippingdescription">
                          <a href={"/stores/" + item?.user?.slug}>
                            <strong>{item?.user?.details?.company_name}</strong>
                          </a>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>
                  <div className="drawer-horizontalline" />
                </div>
              );
            })}
        <div className="drawer-endcontainer">
          <div className="drawer-end mt-auto">End of results</div>
        </div>
      </div>
    </Drawer>
  );
};

export default Index;
